import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import { useHistory } from "react-router-dom";
import bellPic from "../assets/bell.svg";
import dotpic from "../assets/Group1.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import axios from "axios";
// import { HubConnectionBuilder } from "@microsoft/signalr";
import { useContext } from "react";
import Loader from "react-loader-spinner";
import AltertModal from "../component/AlertModal/AltertModal";
import { monthlyAlert } from "../component/ContextApi/MonthlyAlert";
import { getCompanyInfo } from "../component/actions/GetCompanyInfo";
import { getallRoles } from "../component/actions/getroles";
import { useSubbtn } from "../context/subBtn";
import { useLocation } from "react-router-dom";
import { useRoles } from "../context/roles";

function SideButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active
            ? "grey side_button_hover row  sideText sideButton  mt-4"
            : "row side_button_hover sideText sideButton  mt-4"
        }
      >
        {props.icon}
        <p className=" ml-3 sideText green_text toggleButton">{props.text}</p>
      </button>
    </>
  );
}
function SideSubButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active ? "sub_button_style_underline " : "sub_button_style "
        }
      >
        <span className="sideText">{props.text}</span>
        <span>
          {props.btnComponent !== undefined ? props.btnComponent : null}
        </span>
      </button>
      <br></br>
    </>
  );
}
const Layout = (props) => {
  const subbuttonRef = useRef(null);
  let { arr, children } = props;

  const history = useHistory();
  const { subbtn, setSubbtn, setTab, tab, setScrollPosition, scrollPosition } =
    useSubbtn();
  const [tabcount, setTabCount] = useState("");
  const { roles, setRoles } = useRoles();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      sessionStorage.clear();
      history.push({ pathname: "/" });
    }
  }, [history]);
  // toggle state start
  const companyInfo = useSelector((state) => state.getCompany);
  const rolesdata = useSelector((state) => state.GetAllRole);
  let dispatch = useDispatch();
  const [reload, setreload] = useState(true);
  const [toggle, setToggle] = useState(true);

  // const [connection, setConnection] = useState(null);
  // const [chat, setChat] = useState([]);
  const { changeState } = useContext(monthlyAlert);
  // const latestChat = useRef(null);
  const [allNotification, setallNotification] = useState([]);

  // latestChat.current = chat;
  const [showAlert, setshowAlert] = useState(false);
  const [showNotificationModal, setshowNotificationModal] = useState(false);
  const [notificationRow, setnotificationRow] = useState(false);
  const [loading, setloading] = useState(false);
  const [matchedTabsState, setMatchedTabsState] = useState({});
  const [matchedSubTabState, setMatchedSubTabState] = useState({});
  const [matchedMenuState, setMatchedMenuState] = useState({});

  // const [chosen1, setChosen1] = useState("Dashboard");
  // subbutton
  const [dash, setDash] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("isPaid") === "false") {
      changeState(true);
      setshowAlert(true);
    } else {
      changeState(false);
      setshowAlert(false);
    }
  }, []);

  useEffect(() => {
    if (rolesdata?.data?.Roles) {
      setRoles(rolesdata);
    }
  }, [rolesdata]);

  useEffect(() => {
    if (subbtn?.subbtn) {
      const matchingMenuItem = subbtn?.subbtn.find((item) =>
        currentUrl.includes(item.route)
      );
      if (matchingMenuItem && matchingMenuItem.subTab) {
        setTab(matchingMenuItem.subTab);
        setTabCount(matchingMenuItem.subTab);
      } else setTab(null);
    } else setTab(null);
  }, [subbtn]);

  useEffect(() => {
    dispatch(getallRoles());
  }, [dispatch]);

  useEffect(() => {
    if (!roles) {
      dispatch(getallRoles());
    }

    //Roles SubTab Matchibg Here
    const tempMatchedTabsState = {};
    if (Array.isArray(tabcount)) {
      tabcount.forEach((tab) => {
        if (roles?.data.Roles[tab.name]) {
          tempMatchedTabsState[tab.name] = true;
        }
      });
    }
    setMatchedTabsState(tempMatchedTabsState);
  }, [roles, dispatch, tabcount]);

  const length = Object.keys(matchedTabsState).length;

  useEffect(() => {
    //Roles Tab Matchibg Here
    const tempMatchedSubTabState = {};
    if (Array.isArray(subbtn?.subbtn)) {
      subbtn.subbtn.forEach((tab) => {
        if (roles?.data.Roles[tab.rolename]) {
          tempMatchedSubTabState[tab.text] = true;
        }
      });
    }
    setMatchedSubTabState(tempMatchedSubTabState);
  }, [roles, subbtn]);

  //
  //
  //
  //

  useEffect(() => {
    //Roles Tab Matchibg Here
    const tempMatchedMenuState = {};
    if (Array.isArray(arr)) {
      arr.forEach((tab) => {
        if (roles?.data.Roles[tab.rolename]) {
          tempMatchedMenuState[tab.text] = true;
        }
      });
    }
    setMatchedMenuState(tempMatchedMenuState);
  }, [roles, arr]);

  const getAllNotification = () => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Vouchers/PendingNotifications`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.IsSuccess === true) {
          setallNotification(res.data.Data);
          toast.info(res.data.message);
        } else {
          toast.info(res.data.message);
        }
      })
      .catch((err) => {
        toast.info("Something went wrong");
      });
  };
  useEffect(() => {
    getAllNotification();
    const delay = 30 * 60 * 1000;
    const interval = setInterval(getAllNotification, delay);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const newConnection = new HubConnectionBuilder()
  //     .withUrl(
  //       `${
  //         process.env.REACT_APP_API_URL
  //       }RevertBackNotification?Id=${localStorage.getItem("LoginId")}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "content-type": "application/json",
  //         },
  //       }
  //     )
  //     .withAutomaticReconnect()
  //     .build();

  //   setConnection(newConnection);
  // }, []);

  useEffect(() => {
    //
    if (companyInfo?.data?.length === 0 && companyInfo.loading === true) {
      dispatch(getCompanyInfo());
    }
  }, [companyInfo, dispatch]);
  const toggleClose = () => {
    let list = document.getElementsByClassName("toggleButton");
    if (toggle === true) {
      setToggle(false);
      for (var i = 0; i < list.length; i++) {
        list[i].style.display = "none";
      }
      document.getElementById("tog").removeAttribute("style");
    } else {
      setToggle(true);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "block";
      }
      document.getElementById("tog").setAttribute("style", "width: 15em;");
    }
  };

  const handleDraw = () => {
    openDrawer();
  };

  function openDrawer() {
    document.getElementById("myDrawer").style.right = "0";
    document.querySelector(".drawer-backdrop").style.opacity = "1";
    document.querySelector(".drawer-backdrop").style.pointerEvents = "auto";
  }

  function closeDrawer() {
    document.getElementById("myDrawer").style.right = "-400px";
    document.querySelector(".drawer-backdrop").style.opacity = "0";
    document.querySelector(".drawer-backdrop").style.pointerEvents = "none";
  }
  const handleMarkRead = () => {
    setloading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}Vouchers/MarkNotification?id=${notificationRow?.NotificationsId}`,
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.IsSuccess === true) {
          setloading(false);
          getAllNotification();
          setshowNotificationModal(false);
          setnotificationRow(null);
          toast.info(res.data.Message);
        } else {
          setloading(false);

          toast.info(res.data.Message);
        }
      })
      .catch((err) => {
        setloading(false);

        toast.info("Something went wrong");
      });
  };
  const location = useLocation();
  const currentUrl = location.pathname;

  // const navigate=useNavigate();
  //
  useEffect(() => {
    if (arr) {
      const matchingMenuItem = arr.find((item) =>
        currentUrl.includes(
          item.text === "Chart of Acc."
            ? "chart-of-acc"
            : item.text === "Land & Seller"
            ? "land-and-seller"
            : item.text === "Change Request"
            ? "change-request"
            : item.text === "Revert Vouchers"
            ? "revert-back-vouchers"
            : item.text.toLowerCase()
        )
      );
      setDash(matchingMenuItem?.text);
      setSubbtn(matchingMenuItem);
    }
  }, []);

  const handleChange = () => {
    history.push("/change-request");
  };
  useEffect(() => {
    if (subbuttonRef.current) {
      subbuttonRef.current.scrollTop = scrollPosition;
    }
  }, [reload, scrollPosition, subbuttonRef.current]);

  return (
    <>
      {/* top name bar */}
      <ToastContainer theme={"dark"} autoClose={2000} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* toggle button */}

        <div
          className="col-auto p-0 sidebuttonsheight main_btn_style"
          id="tog"
          style={{ width: "13em " }}
        >
          <div
            onClick={() => {
              toggleClose();
            }}
          >
            {" "}
            <img src={dotpic} alt="dots" className="ml-3 Icongroup" />
          </div>

          <div className="line_styling">
            <hr></hr>
          </div>
          <div className=" m_top_custom  pr-3 ">
            {/* buttons map function */}

            {arr[0]?.rolename === "Roles" ||
            arr[0]?.rolename === "Users" ||
            arr[0]?.rolename === "Change Request" ||
            arr[0]?.rolename === "Revert Back Vouchers"
              ? arr.map((btn, index) => (
                  <SideButton
                    key={index}
                    text={btn.text}
                    icon={btn.icon}
                    onClick={() => {
                      if (showAlert) {
                        changeState(true);
                      }

                      setSubbtn(btn);

                      if (!btn.subbtn) {
                        history.push(btn.url);
                      } else {
                        history.push(btn.subbtn[0].url);
                      }
                    }}
                    active={
                      btn.rolename === "Change Request"
                        ? currentUrl.toLowerCase().includes("change-request")
                          ? true
                          : false
                        : btn.rolename === "Revert Back Vouchers"
                        ? currentUrl
                            .toLowerCase()
                            .includes("revert-back-vouchers")
                          ? true
                          : false
                        : currentUrl
                            .toLowerCase()
                            .includes(btn.text?.toLowerCase())
                        ? true
                        : false
                    }
                  />
                ))
              : arr
                  .filter((SingleTab) => matchedMenuState[SingleTab.text])
                  .map(
                    (btn, index) => (
                      <SideButton
                        key={index}
                        text={btn.text}
                        icon={btn.icon}
                        onClick={() => {
                          if (showAlert) {
                            changeState(true);
                          }

                          setSubbtn(btn);

                          if (!btn.subbtn) {
                            history.push(btn.url);
                          } else {
                            history.push(btn.subbtn[0].url);
                          }
                        }}
                        active={
                          currentUrl
                            .toLowerCase()
                            .includes(
                              btn.text === "Chart of Acc."
                                ? "chart-of-acc"
                                : btn.text === "Land & Seller"
                                ? "land-and-seller"
                                : btn.text === "Change Request"
                                ? "change-request"
                                : btn.text?.toLowerCase()
                            )
                            ? true
                            : false
                        }
                      />
                    )
                    // ) : null
                  )}
          </div>
          {currentUrl.includes("account") ? (
            <h4
              title={localStorage.getItem("ProjectName")}
              className="toggleButton cur_sor green_text projectName"
            >
              {localStorage.getItem("ProjectName")}{" "}
            </h4>
          ) : (
            <></>
          )}
        </div>

        <div className="col" style={{ overflowX: "auto" }}>
          {/* top nav bar start */}

          <div className="row pt-1">
            <div className="col-auto pt-1">
              <span className="name_heading mt-1 green_text ">
                {localStorage.getItem("UserName")}
              </span>
              <br></br>
              <span className="designation_head green_text ">
                {localStorage.getItem("Designation")}
              </span>
            </div>
            <div className="col  pt-1">
              <div className="row pl-md-5 pl-lg-5 ">
                <div className="col-10 nav-space">
                  <div
                    style={{
                      display: roles?.data.Roles?.Accounts ? "block" : "none",
                    }}
                  >
                    <Link to="/projectSelection">
                      {" "}
                      <button
                        className={
                          currentUrl.includes("account")
                            ? "top_menu_btn-select "
                            : "top_menu_btn blue_text"
                        }
                      >
                        Accounts
                      </button>
                    </Link>
                  </div>
                  <div
                    style={{
                      display: roles?.data.Roles?.Inventory ? "block" : "none",
                    }}
                  >
                    <Link to="/inventory/dashboard">
                      {" "}
                      <button
                        className={
                          currentUrl.includes("inventory")
                            ? "top_menu_btn-select "
                            : "top_menu_btn blue_text"
                        }
                      >
                        Inventory
                      </button>
                    </Link>
                  </div>

                  <div
                    style={{
                      display: roles?.data.Roles?.HR ? "block" : "none",
                    }}
                  >
                    <Link to="/hr/dashboard">
                      {" "}
                      <button
                        className={
                          currentUrl.includes("hr")
                            ? "top_menu_btn-select "
                            : "top_menu_btn blue_text"
                        }
                      >
                        Human Resource
                      </button>
                    </Link>
                  </div>
                  {/* <div
                    style={{
                      display: roles?.data.Roles?.CRM ? "block" : "none",
                    }}
                  >
             
                    <Link
                      to={`/crm/dashboard?LoginIdNew=${localStorage.getItem(
                        "LoginIdNew"
                      )}&UserNameNew=${localStorage.getItem(
                        "UserNameNew"
                      )}&DesignationNew=${localStorage.getItem(
                        "DesignationNew"
                      )}&OpeningYearNew=${localStorage.getItem(
                        "OpeningYearNew"
                      )}&tokenNew=${localStorage.getItem("tokenNew")}`}
                    >
                      {" "}
                      <button
                        className={
                          currentUrl.includes("crm")
                            ? "top_menu_btn-select "
                            : "top_menu_btn blue_text"
                        }
                      >
                        CRM
                      </button>
                    </Link>
                  </div>
                  <div
                    style={{
                      display: roles?.data.Roles?.Assets ? "block" : "none",
                    }}
                  >
                    <Link to="/asset/dashboard">
                      {" "}
                      <button
                        className={
                          currentUrl.includes("asset")
                            ? "top_menu_btn-select "
                            : "top_menu_btn blue_text"
                        }
                      >
                        Assets
                      </button>
                    </Link>
                  </div> */}
                </div>
                <div className=" col-md-2  ">
                  <span className="right_nav">
                    <span className=" mt-1 mr-1">
                      <Dropdown onClick={handleDraw} id="openDrawerBtn">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <img
                            src={bellPic}
                            alt="bell"
                            className="ml-3 Icontaxbell"
                          />
                          <span className="badge badge-pill badge-primary">
                            {allNotification.length}
                          </span>
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu
                          style={{
                            width: "460px",
                            maxHeight: "50vh",
                            overflow: "auto",
                          }}
                        >
                          <Dropdown.Header>
                            <h5>Notifications ({allData.length})</h5>
                            <hr />
                          </Dropdown.Header>
                          {allData?.map((item, idx) => {
                            return (
                              <Dropdown.ItemText style={{ display: "flex" }}>
                                <small style={{ width: "85%" }}>
                                  {" "}
                                  Please check {item.Description} in{" "}
                                  {item.EntryId}
                                </small>
                                <button
                                  onClick={() => {
                                    axios({
                                      // url: `${window.$url}Lenders/AddLender`,
                                      url: `${window.$url}RevertBack/Execute`,
                                      method: "Post",
                                      headers: {
                                        Authorization: `Bearer ${localStorage.getItem(
                                          "token"
                                        )}`,
                                        "content-type": "application/json",
                                      },
                                      data: JSON.stringify({
                                        RevertBackId: item.RevertBackId,
                                        ExecuteionDate:
                                          moment().format("YYYY-MM-DD"),
                                      }),
                                    })
                                      .then((res) => {
                                        if (res.data.IsSuccess === true) {
                                          toast.info(res.data.message);
                                          dispatch(
                                            getAllRevertUserVouchers(
                                              localStorage.getItem("LoginId")
                                            )
                                          );
                                        } else {
                                          toast.info(res.data.message);
                                        }
                                      })
                                      .catch((err) => {
                                        toast.info("Something went wrong");
                                      });
                                  }}
                                  className="save_btn"
                                  style={{ width: "15%", height: "25px" }}
                                >
                                  <small>Excecute</small>
                                </button>
                                <hr />
                              </Dropdown.ItemText>
                            );
                          })}
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </span>
                    <span className=" ml-2">
                      <img
                        className="imageCircle"
                        src={companyInfo?.data?.Logo}
                        alt="Company logo"
                        // src={profilePic}
                        // alt="profile"
                      />
                    </span>
                    <span>
                      <Dropdown className="accountBtn  drop_icon_style">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <RiArrowDropDownLine className=" drop_icon_style" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              // sessionStorage.clear();
                              history.push({ pathname: "/" });
                            }}
                          >
                            {"  "}
                            Logout
                          </Dropdown.Item>
                          {rolesdata?.data?.Roles &&
                            rolesdata?.data?.Roles[`Revert Back Vouchers`] && (
                              <Dropdown.Item
                                onClick={() => {
                                  history.push({
                                    pathname: "/revert-back-vouchers",
                                  });
                                }}
                              >
                                {"  "}
                                Revert Back Vouchers
                              </Dropdown.Item>
                            )}
                          {/* {localStorage.getItem("Designation") === "Admin" && (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  setview(<MainAddRoleCom />);
                                  setChosen1("");
                                }}
                              >
                                {"  "}
                                Role
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setview(<RevertBackVouchers />);
                                  setChosen1("");
                                }}
                              >
                                {"  "}
                                Revert Back Vouchers
                              </Dropdown.Item>
                            </>
                          )} */}
                          {/* {rolesdata?.data?.Roles?.Users && (
                            <Dropdown.Item
                              onClick={() => {
                                history.push("/Users");
                              }}
                            >
                              {"  "}
                              Users
                            </Dropdown.Item>
                          )}
                          {rolesdata?.data?.Roles?.Users && (
                            <Dropdown.Item
                              onClick={() => history.push("/Roles")}
                            >
                              {"  "}
                              Roles
                            </Dropdown.Item>
                          )}
                          {rolesdata?.data?.Roles &&
                            rolesdata?.data?.Roles[`Change Request`] && (
                              <Dropdown.Item onClick={handleChange}>
                                {"  "}
                                Change Request
                              </Dropdown.Item>
                            )} */}
                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<ChangeInReqMain />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request 2
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-auto subbuttonheight pl-2 pr-2">
              {subbtn?.subbtn !== undefined && subbtn.subbtn !== null
                ? subbtn.subbtn.map((btn, index) =>
                    btn !== null && btn.text !== undefined ? (
                      (rolesdata?.data?.Roles &&
                        rolesdata.data.Roles[btn.rolename] !== undefined) ||
                      localStorage.getItem("Designation") === "Admin" ? (
                        <SideSubButton
                          key={index}
                          text={btn.text}
                          btnComponent={btn.btnComponent}
                          onClick={() => {
                            if (showAlert) {
                              changeState(true);
                            }
                            setsubbutton(btn.text);
                            if (btn.subTab) {
                              setTab(btn.subTab);
                              history.push(btn.subTab[0].url);
                            } else {
                              setTab([]);
                              history.push(btn.url);
                            }
                            setreload(!reload);
                          }}
                          active={
                            currentUrl.includes(btn.route ? btn.route : btn.url)
                              ? true
                              : false
                          }
                        />
                      ) : null
                    ) : null
                  )
                : null}
            </div> */}
            <div
              className="col-auto subbuttonheight pl-2 pr-2"
              id="sideBtn"
              ref={subbuttonRef}
            >
              {subbtn?.subbtn
                ?.filter((SingleTab) => matchedSubTabState[SingleTab.text])
                .map((btn, index) =>
                  btn !== null &&
                  btn.text !== undefined &&
                  ((rolesdata?.data?.Roles &&
                    rolesdata.data.Roles[btn.rolename] !== undefined) ||
                    localStorage.getItem("Designation") === "Admin") ? (
                    <SideSubButton
                      key={index}
                      text={btn.text}
                      btnComponent={btn.btnComponent}
                      onClick={() => {
                        if (showAlert) {
                          changeState(true);
                        }

                        setScrollPosition(subbuttonRef?.current?.scrollTop);
                        if (btn.subTab) {
                          setTab(btn.subTab);
                          history.push(btn.subTab[0].url);
                        } else {
                          setTab([]);
                          history.push(btn.url);
                        }
                        setreload(!reload);
                      }}
                      active={
                        currentUrl.includes(btn?.route ? btn?.route : btn?.url)
                          ? true
                          : false
                      }
                    />
                  ) : null
                )}
            </div>

            <div className="col overflow-auto viewcompmg">
              {/* component rendering */}
              <div className="row  ">
                <div
                  // className="dash_background col-md-12 p-0"
                  className={
                    dash === "Dashboard"
                      ? "dash_background_dashboard col-md-12 p-0"
                      : "dash_background col-md-12 p-0"
                  }
                >
                  <div className="row ">
                    <div className="col-md-12 text-center pl-pr-0">
                      {Array.isArray(tab) &&
                        tab
                          .filter(
                            (SingleTab) => matchedTabsState[SingleTab.name]
                          )
                          .map((SingleTab, index) => (
                            <button
                              key={index}
                              className={
                                currentUrl.includes(SingleTab?.route)
                                  ? length < 3
                                    ? "comp_btns_selected2 ml-1"
                                    : "comp_btns_selected ml-1"
                                  : length < 3
                                  ? "comp_btns2 ml-1"
                                  : "comp_btns ml-1"
                              }
                              onClick={() => {
                                history.push(SingleTab.url);
                              }}
                            >
                              <span
                                className="comp_btns_text"
                                style={
                                  // SingleTab?.name === "General Receipt"
                                  //   ? {}
                                  //   : localStorage.getItem("ProjectName") !==
                                  //     "HEAD OFFICE"
                                  //   ? {
                                  //       textDecorationLine: "none",
                                  //     }
                                  // :
                                  {
                                    // textDecorationLine: "line-through",
                                    // textDecorationStyle: "solid",
                                    textDecorationLine: "none",
                                  }
                                }
                              >
                                <div>{SingleTab?.info}</div>
                              </span>
                            </button>
                          ))}
                    </div>

                    <div
                      className="col-md-12 px-3 "
                      style={{ minWidth: "50vw" }}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* top nav bar end */}
      </div>
      <AltertModal />
      <div style={{}} className="custom-drawer" id="myDrawer">
        <div className="drawer-content">
          <div className="drawerHeader">
            <span style={{ fontSize: "20px" }}>
              <b>Notifications</b>
            </span>
            <span>
              <AiOutlineCloseCircle
                onClick={closeDrawer}
                style={{ cursor: "pointer" }}
                size={20}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingTop: "10px",
            }}
          >
            {allNotification?.map((noti, i) => {
              return (
                <div className="drawerContentBox" key={i}>
                  <span>{noti?.Narration}</span>
                  <span>
                    <MdVisibility
                      title="see details"
                      onClick={() => {
                        setnotificationRow(noti);
                        closeDrawer();
                        setshowNotificationModal(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="drawer-backdrop"></div>
      {showNotificationModal && (
        <div id="mymodal" style={{ display: "block" }} className="modal">
          <div
            style={{ maxWidth: "32%", height: "31%" }}
            className="modal-content-cat_voucher"
          >
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Details</h4>
                <span
                  className="close pr-2  "
                  onClick={() => {
                    setshowNotificationModal(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>

            <div className="row p-4">
              <div className="col-6 py-2">
                <span>
                  <b>Voucher No:</b>{" "}
                </span>
                <span>{notificationRow?.GeneralVoucherId}</span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>Date:</b>{" "}
                </span>
                <span>
                  {moment(notificationRow?.Date).format("DD-MMM-YYYY")}
                </span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>Amount:</b>{" "}
                </span>
                <span>{notificationRow?.Amount}</span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>Status:</b>{" "}
                </span>
                <span>{notificationRow?.Status}</span>
              </div>
              <div className="col-6 py-2">
                <span>
                  <b>User:</b>{" "}
                </span>
                <span> {notificationRow?.AddedBy?.UserName}</span>
              </div>
              <div className="col-12  py-2">
                <span>
                  <b>Narration:</b>{" "}
                </span>
                <span>{notificationRow?.Narration}</span>
              </div>
              <div
                style={{ display: "flex", gap: "10px", justifyContent: "end" }}
                className="col-12 text-right"
              >
                {loading && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
                <button
                  className="print_btn float-right"
                  disabled={loading}
                  onClick={() => {
                    handleMarkRead();
                  }}
                >
                  {/* <TiPrinter size="1.3em" /> */}
                  Mark as read
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {rolesdata?.loading === true ? <FullScreenLoader /> : <></>} */}
    </>
  );
};
export default Layout;
// Layout.propTypes = {
//   activePage: PropTypes.any.isRequired,
// };
