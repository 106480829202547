import axios from "axios";
import { toast } from "react-toastify";
export const GetAllMeasurement = (data) => {
  return {
    type: "get_allmeasureunit",
    payload: data,
    loader: false,
  };
};
export function getallmeasurment() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/AreaMeasureUnits/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllMeasurement(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllDimension = (data) => {
  return {
    type: "get_alldimensionunit",
    payload: data,
    loader: false,
  };
};
export function getalldimension() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/DimensionUnits/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllDimension(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllUnits = (data) => {
  return {
    type: "get_allunits",
    payload: data,
    loader: false,
  };
};
export function getallunits() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/Units/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllUnits(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllUnitCategory = (data) => {
  return {
    type: "get_allunits_category",
    payload: data,
    loader: false,
  };
};
export function getallunitcategory() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/UnitCategory/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllUnitCategory(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllUnitTags = (data) => {
  return {
    type: "get_allunits_tags",
    payload: data,
    loader: false,
  };
};
export function getallunittags() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/Tag/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllUnitTags(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}