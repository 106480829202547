import React, { Component } from "react";
// import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class AllNewPurchasePrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div className="row m-3 p-0">
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Purchase Units </u>
            </h4>

            <div className="mt-3">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>

                      <th>Date</th>
                      <th>Project</th>
                      <th>Section </th>
                      <th>Seller</th>
                      <th>Unit</th>
                      <th>Area</th>
                      <th>Dimension</th>
                      <th>Purchase Rate</th>
                      <th>Total Cost</th>
                      <th>Purchase Cost</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData !== undefined
                      ? this.props.printData.map((val, index) =>
                        this.props.select === "all" ||
                          this.props.select === "" ? (
                          <tr key={index}>
                            <td className=" cur_sor ">{index + 1}</td>
                            <td className=" cur_sor ">
                              {val.Date.split("T")[0]}
                            </td>

                            {val.Project !== null &&
                              val.Project !== undefined ? (
                              <td className=" cur_sor ">{val.Project}</td>
                            ) : (
                              <td></td>
                            )}
                            {val.Unit !== null && val.Unit !== undefined ? (
                              <td className=" cur_sor ">
                                {val.Unit.Section}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td className=" cur_sor ">
                              {val?.Seller ? val?.Seller : val?.Customer}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.NetMarla + " M"
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                "x" +
                                val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                  val.Unit.CostPerMarla
                                ).toLocaleString()
                                : null}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                  val.Unit.TotalCost
                                ).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val?.ProjectOwner === "OTHER"
                                ? val?.PurchaseCost
                                : null}
                            </td>
                          </tr>
                        ) : val.ProjectOwner === "OTHER" &&
                          this.props.select === "other" ? (
                          <tr key={index}>
                            <td className=" cur_sor ">{index + 1}</td>
                            <td className=" cur_sor ">
                              {val.Date.split("T")[0]}
                            </td>

                            {val.Project !== null &&
                              val.Project !== undefined ? (
                              <td className=" cur_sor ">{val.Project}</td>
                            ) : (
                              <td></td>
                            )}
                            {val.Unit !== null && val.Unit !== undefined ? (
                              <td className=" cur_sor ">
                                {val.Unit.Section}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td className=" cur_sor ">
                              {val?.Seller ? val?.Seller : val?.Customer}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.NetMarla + " M"
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                "x" +
                                val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                  val.Unit.CostPerMarla
                                ).toLocaleString()
                                : null}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                  val.Unit.TotalCost
                                ).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val?.ProjectOwner === "OTHER"
                                ? val?.PurchaseCost
                                : null}
                            </td>
                          </tr>
                        ) : val.ProjectOwner === "PERSONAL" &&
                          this.props.select === "personal" ? (
                          <tr key={index}>
                            <td className=" cur_sor ">{index + 1}</td>
                            <td className=" cur_sor ">
                              {val.Date.split("T")[0]}
                            </td>

                            {val.Project !== null &&
                              val.Project !== undefined ? (
                              <td className=" cur_sor ">{val.Project}</td>
                            ) : (
                              <td></td>
                            )}
                            {val.Unit !== null && val.Unit !== undefined ? (
                              <td className=" cur_sor ">
                                {val.Unit.Section}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td className=" cur_sor ">
                              {val?.Seller ? val?.Seller : val?.Customer}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.UnitName
                                : null}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.NetMarla + " M"
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? val.Unit.DimensionLength +
                                "x" +
                                val.Unit.DimensionWidth
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                  val.Unit.CostPerMarla
                                ).toLocaleString()
                                : null}
                            </td>

                            <td className=" cur_sor ">
                              {val.Unit !== null && val.Unit !== undefined
                                ? parseFloat(
                                  val.Unit.TotalCost
                                ).toLocaleString()
                                : null}
                            </td>
                            <td className=" cur_sor ">
                              {val?.ProjectOwner === "OTHER"
                                ? val?.PurchaseCost
                                : null}
                            </td>
                          </tr>
                        ) : null
                      )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
