import moment from "moment";
import React, { Component } from "react";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class GeneralLedgerPrint extends Component {
  render() {
    // const getPageMargins = () => {
    //     return `@page { margin: 155mm 15mm 15mm 15mm !important; }`;
    // };
    // <style>{getPageMargins()}</style>

    return (
      <div style={{ height: "100%" }}>
        <div className="row m-0 p-0">
          <div className="row m-0 p-0">
            <PrintHeader />
          </div>
          <div className="row col-12">
            <div className="col-12 mt-2">
              <h4 className=" text-center " style={{ color: "red" }}>
                <u>General Ledger </u>
              </h4>
              <h6>
                <div>
                  From :{" "}
                  {this.props.statefromdate !== undefined
                    ? moment(this.props.statefromdate.split("T")[0]).format(
                      "DD-MMM-YYYY"
                    )
                    : null}
                </div>
              </h6>
              <h6>
                <div>
                  To :{" "}
                  {this.props.statetodate !== undefined
                    ? moment(this.props.statetodate.split("T")[0]).format(
                      "DD-MMM-YYYY"
                    )
                    : null}
                </div>
              </h6>
              <h6>
                <div>
                  Account Title :{" "}
                  {this.props.accountTitle !== undefined
                    ? this.props.accountTitle
                    : null}
                </div>
              </h6>
            </div>
          </div>
        </div>

        <div className="row m-0 p-0 ">
          <div className=" col-12" style={{ border: "none" }}>
            <table class="table">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Date</th>
                  <th>Narration</th>
                  <th>Reference</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>

                  {/* <td>{val.EntryDate.split("T")[0]}</td> */}
                  <td>
                    {" "}
                    {this.props.entrydate !== undefined
                      ? moment(this.props.entrydate?.split("T")[0])?.format(
                        "DD-"
                      )
                      : ""}
                    <br />
                    {this.props.entrydate !== undefined
                      ? moment(this.props.entrydate?.split("T")[0])?.format(
                        "MMM-"
                      )
                      : ""}
                    <br />
                    {this.props.entrydate !== undefined
                      ? moment(this.props.entrydate?.split("T")[0])?.format(
                        "YYYY"
                      )
                      : ""}
                  </td>

                  <th colSpan={1}>Opening Balance</th>

                  <td></td>

                  <th></th>
                  <th></th>
                  <td colSpan={1}>
                    <div>
                      {" "}
                      {this.props.stateopeningbal !== undefined
                        ? parseFloat(
                          this.props.stateopeningbal
                        ).toLocaleString()
                        : 0}
                    </div>
                  </td>

                  <td>
                    {" "}
                    {this.props.stateopenbalancetype &&
                      this.props.stateopenbalancetype}
                  </td>
                </tr>
                {this.props.printData !== undefined
                  ? this.props.printData.map((val, index) => (
                    <>
                      <tr
                        style={{ background: "red " }}
                        // className="print-tr"
                        // style={{
                        //   backgroundColor: "none !important",

                        //   borderLeft: " 1px solid #CAF5F5",
                        //   borderRight: "1px solid #CAF5F5",
                        // }}
                        key={index}
                      >
                        <td>{index + 2}</td>
                        <td>
                          {moment(val.EntryDate.split("T")[0]).format("DD-")}
                          <br />
                          {moment(val.EntryDate.split("T")[0]).format("MMM-")}
                          <br />
                          {moment(val.EntryDate.split("T")[0]).format("YYYY")}
                          <br />
                        </td>
                        <td
                          style={{
                            fontSize: "9px !important !important ",
                          }}
                        >
                          {val.Narration?.length <= 132
                            ? val.Narration
                            : val.Narration?.slice(0, 132) + "..."}
                        </td>
                        <td>{val.VoucherId}</td>
                        {val.TransactionType === "DEBIT" ? (
                          <td>{parseFloat(val.Amount).toLocaleString()}</td>
                        ) : (
                          <td></td>
                        )}
                        {val.TransactionType === "CREDIT" ? (
                          <td>{parseFloat(val.Amount).toLocaleString()}</td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          {val.AccountBalance < 0
                            ? parseFloat(val.AccountBalance)
                              .toLocaleString()
                              .split("-")[1]
                            : parseFloat(val.AccountBalance).toLocaleString()}
                        </td>
                        <td>{val.BalanceType}</td>
                      </tr>
                      {/* {index > 8 && (index + 4) % 14 == 0 ? (
                              <>
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="text-left mt-4"
                                  ></td>
                                  <td colSpan={4} className=" text-center">
                                    <img
                                      src={gravity}
                                      style={{
                                        height: "175px",
                                        width: "175px",
                                        borderRadius: "100px",
                                      }}
                                      className="pt-2"
                                      alt="Company logo"
                                    />
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    borderLeft: " 1px solid #CAF5F5",
                                    borderRight: "1px solid #CAF5F5",
                                  }}
                                >
                                  <th>Sr</th>
                                  <th>Date</th>
                                  <th>Narration</th>
                                  <th>Reference</th>
                                  <th>Debit</th>
                                  <th>Credit</th>
                                  <th>Balance</th>
                                  <th>Type</th>
                                </tr>
                              </>
                            ) : null} */}
                    </>
                  ))
                  : null}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <label className="input_label m-0">Total:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="input_total_styling "
                      placeholder="Debit"
                      disabled
                      defaultValue={parseFloat(
                        this.props.statedebit
                      ).toLocaleString()}
                      key={this.props.statedebit}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="input_total_styling "
                      placeholder="Credit"
                      disabled
                      defaultValue={parseFloat(
                        this.props.statecredit
                      ).toLocaleString()}
                      key={this.props.statecredit}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="input_total_styling "
                      placeholder="Balance"
                      disabled
                      value={this.props?.balance}
                    ></input>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralLedgerPrint;