import React from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = ({ data, datasets }) => {
  const seriesData = datasets;

  const options = {
    title: {
      text: "Cash History (last 30 days)",
    },
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: data,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val?.toLocaleString();
        },
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={seriesData}
        type="area"
        height={295}
      />
    </div>
  );
};

export default AreaChart;
