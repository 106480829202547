import { useEffect } from "react";
import { useState } from "react";

import Loader from "react-loader-spinner";

const SoldModal = ({ data }) => {
  const [filterdata, setfilterdata] = useState([]);
  // print function

  const onTextChange = (e) => {
    if (
      document.getElementById("namefilter").value === "" &&
      document.getElementById("statusfilter").value === "" &&
      document.getElementById("contractstatusfilter").value === ""
    ) {
      setfilterdata(data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("namefilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("statusfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("contractstatusfilter").value,
          "i"
        );

        suggestions = data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("namefilter").value === "" ||
                regex.test(val.UnitName)) &&
              (document.getElementById("statusfilter").value === "" ||
                regex1.test(val.Status)) &&
              (document.getElementById("contractstatusfilter").value === "" ||
                regex2.test(val.Contract.ContractStatus))
          );
      }
      setfilterdata(suggestions);
    }
  };
  const [completeStatus, setCompleteStatus] = useState(0);
  const [pendingStatus, setPendingStatus] = useState(0);
  const [transferredStatus, setTransferredStatus] = useState(0);

  useEffect(() => {
    if (data) {
      let complete = 0;
      let pending = 0;
      let transferred = 0;

      setfilterdata(data);
      data.forEach((status) => {
        if (status.Contract?.ContractStatus?.toLowerCase() === "completed") {
          complete += 1;
        } else if (
          status.Contract?.ContractStatus?.toLowerCase() === "pending"
        ) {
          pending += 1;
        } else if (
          status.Contract?.ContractStatus?.toLowerCase() === "transferred"
        ) {
          transferred += 1;
        }
      });
      setCompleteStatus(complete);
      setTransferredStatus(transferred);
      setPendingStatus(pending);
    }
  }, [data]);

  return (
    <>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="namefilter"
                onChange={onTextChange}
                placeholder="Unit Name"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="statusfilter"
                onChange={onTextChange}
                placeholder="Status"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="contractstatusfilter"
                onChange={onTextChange}
                placeholder="Contract Status"
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {filterdata !== undefined ? (
                filterdata.loading ? (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                ) : null
              ) : null}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr. </th>

                    <th>Unit Name </th>
                    <th>Status</th>
                    <th>Contract Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>{val?.UnitName}</td>
                          <td>{val?.Status}</td>

                          <td>{val.Contract?.ContractStatus}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-9">
              <div
                className="row"
                style={{
                  display: "flex",
                }}
              >
                <div className="col-md-4">
                  <label className="input_label m-0">
                    Contract Status Completed:
                  </label>
                  <input
                    type="text"
                    value={parseFloat(completeStatus)?.toLocaleString()}
                    readOnly
                    className=" ml-1 input_styling"
                  />
                </div>
                <div className="col-md-4">
                  <label className="input_label m-0">
                    Contract Status Pending:
                  </label>
                  <input
                    type="text"
                    value={parseFloat(pendingStatus)?.toLocaleString()}
                    readOnly
                    className=" ml-1 input_styling"
                  />
                </div>
                <div className="col-md-4">
                  <label className="input_label m-0">
                    Contract Status Transferred:
                  </label>
                  <input
                    type="text"
                    value={parseFloat(transferredStatus)?.toLocaleString()}
                    readOnly
                    className=" ml-1 input_styling"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoldModal;
