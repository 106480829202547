import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallacounts } from "../../../actions/getheading";
import { BsCloudUpload } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ExternalCommissionForm = ({
  CommissionType,
  // cancelExternalCommissionForm,
}) => {
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  // const resultsallaccount = useSelector((state) => state.getAllAccountsReducer);
  const [disableBtn, setdisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  let vouchernumber = `COS-${Math.floor(Date.now())}`;
  const [formData, setformData] = useState({
    CommisionOnSaleRecordId: vouchernumber,
    CustomerName: "",
    SellerName: "",
    Address: "",
    UnitNumber: "",
    ContractPrice: "",
    CommissionPer: "",
    CommissionAmount: "",
    Narration: "",
    Date: moment().format("YYYY-MM-DD"),
    ProjectId: localStorage.getItem("ProjectId"),
    AccountId: "",
    CommissionType: "",
    ChequeNo: "",
    Type: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallacounts());
    dispatch(getallbanks());
    dispatch(getallcash());
  }, [dispatch]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "ContractPrice") {
      setformData({
        ...formData,
        ["CommissionAmount"]: (value / 100) * formData?.CommissionPer,
        [name]: value,
      });
    } else if (name === "CommissionPer") {
      setformData({
        ...formData,
        ["CommissionAmount"]: (formData?.ContractPrice / 100) * value,
        [name]: value,
      });
    } else if (name === "CommissionAmount") {
      setformData({
        ...formData,
        ["CommissionPer"]: (value / formData?.ContractPrice) * 100,
        [name]: value,
      });
    } else {
      setformData({ ...formData, [name]: value });
    }
  };

  const savedata = () => {
    setLoading(true);
    if (formData?.Date === "") {
      toast.info("Date is Required");
      setLoading(false);
    } else if (formData?.CustomerName === "") {
      toast.info("Customer Name is Required");
      setLoading(false);
    } else if (formData?.SellerName === "") {
      toast.info("Seller Name is Required");
      setLoading(false);
    } else if (formData?.UnitNumber === "") {
      toast.info("Unit Number is Required");
      setLoading(false);
    } else if (formData?.Address === "") {
      toast.info("Address is Required");
      setLoading(false);
    } else if (formData?.ContractPrice <= 0) {
      toast.info("Invalid Contract Price");
      setLoading(false);
    } else if (formData?.CommissionPer <= 0) {
      toast.info("Invalid Commission %");
      setLoading(false);
    } else if (formData?.CommissionAmount <= 0) {
      toast.info("Invalid Commission Amount");
      setLoading(false);
    } else if (formData?.Narration === "") {
      toast.info("Narration is Required");
      setLoading(false);
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
      setLoading(false);
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      document.getElementById("chequeno").value === ""
    ) {
      toast.info("Cheque no is Required");
      setLoading(false);
    } else if (!formData.AccountId) {
      toast.info("Select Cash or Bank");
      setLoading(false);
    } else {
      setdisableBtn(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Commission/AddExternalCommission`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          ...formData,
          CommissionType: CommissionType,
        }),
      }).then(
        (result) => {
          setdisableBtn(false);
          setLoading(false);
          if (result.data.IsSuccess === true) {
            setformData({
              CommisionOnSaleRecordId: vouchernumber,
              CustomerName: "",
              SellerName: "",
              Address: "",
              UnitNumber: "",
              ContractPrice: "",
              CommissionPer: "",
              CommissionAmount: "",
              Narration: "",
              Date: moment().format("YYYY-MM-DD"),
              ProjectId: localStorage.getItem("ProjectId"),
              AccountId: "",
              CommissionType: "",
              ChequeNo: "",
              Type: "",
            });
            document.getElementById("vouchertype").value = "";
            document.getElementById("banks").value = "";
            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          setdisableBtn(false);
          setLoading(false);
        }
      );
    }
  };

  return (
    <>
      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4 p-0">
          <input
            type="date"
            className="input_date_styling"
            onChange={handleChange}
            value={formData?.Date}
            id="date"
            name="Date"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Record Id:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            disabled
            value={formData?.CommisionOnSaleRecordId}
            onChange={handleChange}
            className="input_styling  "
            id="recordId"
            name="CommisionOnSaleRecordId"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Customer Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling "
            value={formData?.CustomerName}
            onChange={handleChange}
            id="customerName"
            name="CustomerName"
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Seller Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling  "
            value={formData?.SellerName}
            onChange={handleChange}
            id="sellerName"
            name="SellerName"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Unit Number:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling "
            value={formData?.UnitNumber}
            onChange={handleChange}
            id="unitNumber"
            name="UnitNumber"
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Address:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling  "
            value={formData?.Address}
            onChange={handleChange}
            id="address"
            name="Address"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4 p-0">
          <select
            id="vouchertype"
            name="Type"
            onChange={(e) => {
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
                handleChange(e);
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
                handleChange(e);
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling  "
          >
            <option value="">Select</option>
            <option>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Contract Price:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling "
            value={formData?.ContractPrice}
            onChange={handleChange}
            id="contractPrice"
            name="ContractPrice"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <select
              name="AccountId"
              id="cashs"
              className="input_styling  "
              value={formData?.AccountId}
              onChange={handleChange}
            >
              <option value="">Select</option>
              {resultsallcash.data.map((x) => (
                <option value={x.AccountId} data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row  ">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Bank:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <select
              name="AccountId"
              id="banks"
              className="input_styling  "
              value={formData?.AccountId}
              onChange={handleChange}
            >
              <option value="">Select</option>
              {resultsallbanks.data.map((x) => (
                <option data-id={x.AccountId} value={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-2 mt-4 p-0"></div>

          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4  p-0">
            <input
              id="chequeno"
              name="ChequeNo"
              type="text"
              placeholder="Cheque No"
              value={formData?.ChequeNo}
              onChange={handleChange}
              className="input_styling "
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Commission %:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling "
            onChange={handleChange}
            value={formData?.CommissionPer}
            id="commPer"
            name="CommissionPer"
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Commission Amount:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling  "
            value={formData?.CommissionAmount}
            onChange={handleChange}
            id="commAmount"
            name="CommissionAmount"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Narration:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <textarea
            className="input_address "
            value={formData?.Narration}
            onChange={handleChange}
            id="narration"
            name="Narration"
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        <div className="d-flex justify-content-end">
          <div>
            {loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
          <div>
            <span>
              <button
                className="cancel_btn ml-2  "
                // onClick={() => cancelExternalCommissionForm()}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
            </span>

            <span>
              <button
                disabled={disableBtn}
                className="save_btn ml-2  "
                id="headingsave"
                onClick={savedata}
              >
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalCommissionForm;
