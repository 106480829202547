import axios from 'axios';
import { toast } from "react-toastify";
export const GetUserData = (data) => {

    return ({
        type: "get_userData",
        payload: data,
        loader: false
    })
}
export function getUsersData() {
    return (dispatch) => {
        axios({
          url: `${process.env.REACT_APP_API_URL}Auth/GetAllUser`,
          method: "Get",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            if (result.data.IsSuccess === true) {
              dispatch(GetUserData(result.data));
            } else {
              toast.info(result.data.Message);
            }
          },
          (error) => {
            toast.info("Something went wrong!");
          }
        );
    }
}