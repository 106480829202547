import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import { BsCloudUpload } from "react-icons/bs";
// import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
// import { MdDeleteForever } from "react-icons/md";

import { toast } from "react-toastify";
// import { FiEdit2 } from "react-icons/fi";

import Loader from "react-loader-spinner";
const MainAttendanceCom = () => {
  // const showAttendanceTable = () => {
  //   stateAttendance("Attendance");
  // };
  const cancelAttendanceTable = () => {
    stateAttendance("AttendanceTable");
  };
  const [showAttendance, stateAttendance] = useState("AttendanceTable");
  console.log(showAttendance);
  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              <Attendance cancelAttendanceTable={cancelAttendanceTable} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainAttendanceCom;
export const Attendance = (props) => {
  const [statemarkdata, setmarkdata] = useState([]);
  const [loader1, setloader1] = useState(false);
  // const [toggle, setToggle] = useState(true);
  const [attendancedate, setattendancedate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [test1, settest1] = useState(false);
  useEffect(() => {
    markdate();
  }, [attendancedate]);
  function markdate() {
    setloader1(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}Attendance/MarkAll?Date=${attendancedate}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setmarkdata(result.data.Data);
        setloader1(false);
      },
      (error) => {
        setloader1(false);
        toast.info("Something went wrong!");
      }
    );
  }
  function UpdateAttendance() {
    let array = [];
    statemarkdata.forEach((x, idx) => {
      if (x.attendancedata !== undefined && x.attendancedata !== null) {
        array.push(x.attendancedata);
      }
    });

    let data = {
      Attendance: array,
      Date: attendancedate,
    };

    setloader1(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}Attendance/Update`,
      method: "put",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify(data),
    }).then(
      (result) => {
        toast.info(result.data.Message);
        setloader1(false);
        markdate();
      },
      (error) => {
        setloader1(false);
        toast.info("Something went wrong!");
      }
    );
  }

  return (
    <>
      <div>
        <div className="row ">
          <div className="col-md-1 mt-md-4   p-0">
            <label className="input_label m-0">Date:</label>
          </div>
          <div className="col-md-3 mt-md-4 text-left p-0">
            <input
              type="date"
              className="input_date"
              id="attendancedate"
              defaultValue={moment().format("YYYY-MM-DD")}
              onChange={(e) => {
                setattendancedate(e.target.value);
              }}
            />{" "}
          </div>
          <div className="col-md-1 mt-md-4  text-left p-0"></div>
          <div className="col-md-1 mt-md-4   p-0">
            {loader1 && (
              <Loader
                type="ThreeDots"
                color="green"
                height={40}
                width={40}
              />
            )}
          </div>
          <div className="col-md-5 mt-md-4 text-right  p-0"></div>
          <div className="col-md-1 mt-md-4   p-0">
            <button
              onClick={UpdateAttendance}
              className="att_btn2 ml-2  att_button"
            >
              Save
            </button>
          </div>
        </div>

        <div className="row ">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive vouchers_table_height2 mt-4">
              <table className="table table-borderless table-hover m-0">
                <thead>
                  <tr>
                    <th>Sr</th>

                    <th>Name</th>

                    <th>Designation</th>

                    <th>Comments</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {statemarkdata !== undefined
                    ? statemarkdata.map((val, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>

                        <td>{val.EmpName}</td>

                        <td>{val.DesignationName}</td>
                        <td>
                          <input
                            type="text"
                            key={"comment".concat(idx, val.AttendanceId)}
                            id={"comment".concat(idx, val.AttendanceId)}
                            defaultValue={val.Comments}
                            className="input_styling"
                            onChange={(e) => {
                              statemarkdata[idx].Comments = e.target.value;
                            }}
                          ></input>
                        </td>
                        <td>{val.Status}</td>
                        {/* <td>
                                                <button
                                                    className={
                                                        val.Status === "Present" ? "att_btn2" : "att_btn3"
                                                    }
                                                    id={"tog".concat(idx)}
                                                    onClick={() => {
                                                        let x = statemarkdata;
                                                        if (x[idx].Status === "Present")
                                                            x[idx].Status = "Absent";
                                                        else x[idx].Status = "Present";
                                                        setmarkdata(x);
                                                        setToggle(!toggle);
                                                    }}
                                                >
                                                    {val.Status}
                                                </button>{" "}

                                                
                                            </td> */}

                        <td>
                          {val.Status === "Absent" ? (
                            <button
                              key={`checkinbutton${idx}${val.AttendanceId}`}
                              id={`checkinbutton${idx}${val.AttendanceId}`}
                              className="checkinattendance"
                              onClick={() => {
                                if (
                                  statemarkdata[idx].colorstatus === undefined
                                ) {
                                  statemarkdata[idx].colorstatus =
                                    "darkgreen";
                                  document.getElementById(
                                    `checkinbutton${idx}${val.AttendanceId}`
                                  ).style.backgroundColor = "#39a039";
                                  statemarkdata[idx].attendancedata = {
                                    AttendanceId:
                                      statemarkdata[idx].AttendanceId,
                                    Status: "Present",
                                    Comments: statemarkdata[idx].Comments,
                                  };
                                  settest1(!test1);
                                } else if (
                                  statemarkdata[idx].colorstatus ===
                                  "lightgreen"
                                ) {
                                  statemarkdata[idx].colorstatus =
                                    "darkgreen";
                                  document.getElementById(
                                    `checkinbutton${idx}${val.AttendanceId}`
                                  ).style.backgroundColor = "#39a039";
                                  statemarkdata[idx].attendancedata = {
                                    AttendanceId:
                                      statemarkdata[idx].AttendanceId,
                                    Status: "Present",
                                    Comments: statemarkdata[idx].Comments,
                                  };
                                  settest1(!test1);
                                } else if (
                                  statemarkdata[idx].colorstatus ===
                                  "darkgreen"
                                ) {
                                  statemarkdata[idx].colorstatus =
                                    "lightgreen";
                                  document.getElementById(
                                    `checkinbutton${idx}${val.AttendanceId}`
                                  ).style.backgroundColor = "#bff1bf";
                                  statemarkdata[idx].attendancedata = null;
                                  settest1(!test1);
                                }
                              }}
                            >
                              Present
                            </button>
                          ) : (
                            <button
                              key={`checkoutbutton${idx}${val.AttendanceId}`}
                              id={`checkoutbutton${idx}${val.AttendanceId}`}
                              className="absent"
                              onClick={() => {
                                if (
                                  statemarkdata[idx].colorstatus === undefined
                                ) {
                                  statemarkdata[idx].colorstatus = "darkred";
                                  document.getElementById(
                                    `checkoutbutton${idx}${val.AttendanceId}`
                                  ).style.backgroundColor = "#c11515";
                                  statemarkdata[idx].attendancedata = {
                                    AttendanceId:
                                      statemarkdata[idx].AttendanceId,
                                    Status: "Absent",
                                    Comments: statemarkdata[idx].Comments,
                                  };
                                  settest1(!test1);
                                } else if (
                                  statemarkdata[idx].colorstatus ===
                                  "lightred"
                                ) {
                                  statemarkdata[idx].colorstatus = "darkred";
                                  document.getElementById(
                                    `checkoutbutton${idx}${val.AttendanceId}`
                                  ).style.backgroundColor = "#c11515";
                                  statemarkdata[idx].attendancedata = {
                                    AttendanceId:
                                      statemarkdata[idx].AttendanceId,
                                    Status: "Absent",
                                    Comments: statemarkdata[idx].Comments,
                                  };
                                  settest1(!test1);
                                } else if (
                                  statemarkdata[idx].colorstatus === "darkred"
                                ) {
                                  statemarkdata[idx].colorstatus = "lightred";
                                  document.getElementById(
                                    `checkoutbutton${idx}${val.AttendanceId}`
                                  ).style.backgroundColor = "#f9a3a3";
                                  statemarkdata[idx].attendancedata = null;
                                  settest1(!test1);
                                }
                              }}
                            >
                              Absent
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="col-6  text-right pr-0 pl-0">
                          


                        </div> */}
        </div>
      </div>
    </>
  );
};
export const AttendanceTable = (props) => {
  // const designationdatastate = useSelector(
  //   (state) => state.getAllSalariesTable
  // );
  const [statemarkdata, setmarkdata] = useState([]);
  const [statemarkdatafilter, setmarkdatafilter] = useState([]);
  // const [loader2, setloader2] = useState(false);

  // const [allDesignationData, setallDesignationData] = useState([]);
  // const [filerunits, setfilterunits] = useState();

  // let dispatch = useDispatch();

  // var suggestionlist = [];

  // const onTextChange = (e) => {
  //   const value = e.target.value;
  //   if (value === "") {
  //     setfilterunits(designationdatastate.data.Employees);
  //   } else {
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");

  //       suggestions = filerunits
  //         .sort()
  //         .filter((val) =>
  //           regex.test(val.Employee !== null ? val.Employee.EmpName : null)
  //         );
  //     }
  //     suggestionlist = suggestions;
  //     setfilterunits(suggestionlist);
  //   }
  // };

  // const openmodalallowancetable = (x) => {
  //   setallDesignationData(x);

  //   document.getElementById("modalofleadtable").style.display = "block";
  // };

  // useEffect(() => {

  //     dispatch(getallSalaryTable());

  // }, [dispatch]);
  // useEffect(() => {
  //   setfilterunits(designationdatastate.data.Employees);
  // }, [designationdatastate]);
  function markdate() {
    let timeElapsed = Date.now();
    let today = new Date(timeElapsed);
    let tdate = today.toLocaleDateString();
    axios({
      url: `${process.env.REACT_APP_API_URL}Attendance/MarkAll?Date=${tdate}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setmarkdata(result.data.Data);
        setmarkdatafilter(result.data.Data);
      },
      (error) => {
        toast.info("Error Loading Attendance Data!");
      }
    );
  }
  const onTextChangeAttendanceFilter = (e) => {
    const value = e.target.value;
    if (value === "") {
      setmarkdata(statemarkdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = statemarkdatafilter
          .sort()
          .filter((v) => regex.test(v.EmpName));
      }
      setmarkdata(suggestions);
    }
  };
  useEffect(() => {
    markdate();
  }, []);
  return (
    <>
      <div className="row  ">
        <div className="dash_background col-12 p-0">
          {/* < div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Salaries</h4>
                        </div>
                    </div> */}
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3   pr-0 pl-0">
              <input
                type="text"
                onChange={onTextChangeAttendanceFilter}
                autoComplete="off"
                placeholder="Search By Name"
                className="input_styling "
              ></input>
            </div>

            {/* <div className="col-md-1 pr-0 pl-0">
              {loader2 && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </div> */}
          </div>

          <div className="row ">
            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive mt-4">
                <table className="table table-borderless table-hover m-0">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Date</th>
                      <th>Name</th>
                      {/* <th>ContactNo</th> */}
                      <th>Status</th>

                      <th>Comments</th>
                      <th className="text-center">
                        <button
                          className="save_btn"
                          onClick={() => {
                            props.showAttendanceTable();
                          }}
                        >
                          New+
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statemarkdata.map((val, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>
                          {moment(val.Date.split("T")[0]).format("DD-MMM-YYYY")}
                        </td>
                        <td>{val.EmpName}</td>
                        <td>{val.Status} </td>

                        <td>{val.Comments} </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* model for show information*/}
      <div id="modalofleadtable" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Allowance Detail</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadtable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            <div className="table-responsive mt-4">
              <table class="table mt-2">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Allowance Name</th>
                    <th scope="col">Allowance Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {allDesignationData !== undefined && allDesignationData.EmployeeSalaryAllowances !== undefined

                                        // allDesignationData.length===0
                                        ?
                                        allDesignationData.EmployeeSalaryAllowances.map((x, id) => (
                                            <tr key={id}>
                                                <td>{x.Allowance.AllowanceName}</td>
                                                <td>{x.AllowanceAmount}</td>


                                            </tr>
                                        )) : null} */}
                </tbody>
              </table>
            </div>

            {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
