import React, { useState, useRef, useEffect } from "react";
import { TiPrinter } from "react-icons/ti";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import TrailBalancePrint from "../ReportPrints/TrailBalancePrint";

const TrailBalance = () => {
  const [statetraildata, settrailbalancedata] = useState([]);
  const [statedebit, setdebit] = useState(0);
  const [statecredit, setcredit] = useState(0);
  const [debitGrandTotalState, setDebitGrandTotalState] = useState(0);
  const [creditGrandTotalState, setCreditGrandTotalState] = useState(0);
  const [openingDebitTotal, setopeningDebitTotal] = useState(0);
  const [closingDebitTotal, setclosingDebitTotal] = useState(0);
  const [openingCreditTotal, setopeningCreditTotal] = useState(0);
  const [closingCreditTotal, setclosingCreditTotal] = useState(0);
  const [loader, setloader] = useState(false);
  const [csvData, setCsvData] = useState([]);
  let reportsData = [];
  // print function
  const componentRef = useRef();

  useEffect(() => {
    if (statetraildata.length) {
      makeCsvData();
    }
  }, [statetraildata]);

  function recursiveFun(ChildHeadsData) {
    return ChildHeadsData.map((ch) => {
      const o = {
        IdCode: ch.IdCode,
        Particular: ch.Particular,
        OpeningDebit: ch.OpeningDebit,
        OpeningCredit: ch.OpeningCredit,
        Debit: ch.Debit,
        Credit: ch.Credit,
        ClosingDebit: ch.ClosingDebit,
        ClosingCredit: ch.ClosingCredit,
      };
      reportsData.push(o);
      //

      if (ch.ChildHeads.length) {
        recursiveFun(ch.ChildHeads);
      }
      if (ch.ChildAcc.length) {
        recursiveFun(ch.ChildAcc);
      }

      // return o
    });
  }

  const makeCsvData = () => {
    let grandTotal = {
      IdCode: "",
      Particular: "Grand Total",
      OpeningDebit: 0,
      OpeningCredit: 0,
      Debit: 0,
      Credit: 0,
      ClosingDebit: 0,
      ClosingCredit: 0,
    };
    if (statetraildata.length) {
      for (let i = 0; i < statetraildata.length; i++) {
        let obj = {};

        obj["IdCode"] = statetraildata[i].IdCode;
        obj["Particular"] = statetraildata[i].Particular;
        obj["OpeningDebit"] = statetraildata[i].OpeningDebit;
        obj["OpeningCredit"] = statetraildata[i].OpeningCredit;
        obj["Debit"] = statetraildata[i].Debit;
        obj["Credit"] = statetraildata[i].Credit;
        obj["ClosingDebit"] = statetraildata[i].ClosingDebit;
        obj["ClosingCredit"] = statetraildata[i].ClosingCredit;
        reportsData.push(obj);

        grandTotal.OpeningDebit += parseInt(statetraildata[i].OpeningDebit);
        grandTotal.OpeningCredit += parseInt(statetraildata[i].OpeningCredit);
        grandTotal.Debit += parseInt(statetraildata[i].Debit);
        grandTotal.Credit += parseInt(statetraildata[i].Credit);
        grandTotal.ClosingDebit += parseInt(statetraildata[i].ClosingDebit);
        grandTotal.ClosingCredit += parseInt(statetraildata[i].ClosingCredit);

        if (statetraildata[i].ChildHeads.length) {
          recursiveFun(statetraildata[i].ChildHeads);
        }
      }
      reportsData.push(grandTotal);
      setCsvData([...reportsData]);
      //
      //
    }
  };

  const recursiveFuncation = (ChildHeads, ChildAcc) => {
    if (ChildHeads.length < 1 && ChildAcc.length < 1) {
      return;
    }

    return (
      <>
        {ChildHeads?.map((val, index) => {
          // if (val?.Opening === 0 && val.Balance === 0 && val.Closing === 0) {
          //   <></>;
          // } else {
          // if (
          //   val?.OpeningCredit === 0 &&
          //   val?.OpeningDebit === 0 &&
          //   val?.ClosingCredit === 0 &&
          //   val?.ClosingDebit === 0 &&
          //   val?.Credit === 0 &&
          //   val?.Debit === 0
          // ) {
          //   <></>;
          // } else {
          return (
            <>
              <tr style={{ backgroundColor: "white" }} key={index}>
                <td>
                  {val?.IdCode} || <b>{val?.Particular}</b>
                </td>
                {/* {val?.AccCategory === "AS" || val?.AccCategory === "EX" ? (
                    val?.OpeningDebit > 0 ? (
                      <>
                        <td>
                          <b>
                            {val?.OpeningDebit !== null
                              ? val?.OpeningDebit?.toLocaleString()
                              : null}
                          </b>
                        </td>

                        <td>
                          <b>
                            {val?.OpeningCredit !== null
                              ? val?.OpeningCredit.toLocaleString()
                              : null}
                          </b>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <b>
                            {val?.OpeningDebit !== null
                              ? Math.abs(val?.OpeningDebit)?.toLocaleString()
                              : null}
                          </b>
                        </td>
                        <td>
                          <b>
                            {val?.OpeningCredit !== null
                              ? Math.abs(val?.OpeningCredit)?.toLocaleString()
                              : null}
                          </b>
                        </td>
                      </>
                    )
                  ) : val?.OpeningCredit > 0 ? (
                    <>
                      <td>
                        <b>
                          {val?.OpeningDebit !== null
                            ? val?.OpeningDebit?.toLocaleString()
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {val?.OpeningCredit !== null
                            ? val?.OpeningCredit?.toLocaleString()
                            : null}
                        </b>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <b>
                          {val?.OpeningDebit !== null
                            ? Math.abs(val?.OpeningDebit)?.toLocaleString()
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {val?.OpeningCredit !== null
                            ? Math.abs(val?.OpeningCredit)?.toLocaleString()
                            : null}
                        </b>
                      </td>
                    </>
                  )} */}
                <td>
                  <b>
                    {val?.OpeningDebit !== null
                      ? Math.abs(val?.OpeningDebit)?.toLocaleString()
                      : null}
                  </b>
                </td>
                <td>
                  <b>
                    {val?.OpeningCredit !== null
                      ? Math.abs(val?.OpeningCredit)?.toLocaleString()
                      : null}
                  </b>
                </td>
                <td>
                  <b>
                    {val?.Debit !== null ? val?.Debit?.toLocaleString() : null}
                  </b>
                </td>
                <td>
                  {val?.Credit !== null ? val?.Credit?.toLocaleString() : null}
                </td>
                {/* {val?.AccCategory === "AS" || val?.AccCategory === "EX" ? (
                    val?.Balance > 0 ? (
                      <>
                        <td>
                          <b>
                            {val?.Debit !== null
                              ? val?.Debit?.toLocaleString()
                              : null}
                          </b>
                        </td>
                        <td></td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td>
                          <b>
                            {val?.Credit !== null
                              ? Math.abs(val?.Balance)?.toLocaleString()
                              : null}
                          </b>
                        </td>
                      </>
                    )
                  ) : val?.Balance > 0 ? (
                    <>
                      <td></td>
                      <td>
                        <b>
                          {val?.Debit !== null
                            ? val?.Debit?.toLocaleString()
                            : null}
                        </b>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <b>
                          {val?.Credit !== null
                            ? Math.abs(val?.Credit).toLocaleString()
                            : null}
                        </b>
                      </td>
                      <td></td>
                    </>
                  )} */}
                <td>
                  <b>
                    {val?.ClosingDebit !== null
                      ? val?.ClosingDebit?.toLocaleString()
                      : null}
                  </b>
                </td>
                <td>
                  <b>
                    {val?.ClosingCredit !== null
                      ? val?.ClosingCredit?.toLocaleString()
                      : null}
                  </b>
                </td>
                {/* {val?.AccCategory === "AS" || val?.AccCategory === "EX" ? (
                    val?.ClosingDebit > 0 ? (
                      <>
                        <td>
                          <b>
                            {val?.ClosingDebit !== null
                              ? val?.ClosingDebit?.toLocaleString()
                              : null}
                          </b>
                        </td>
                        <td>
                          <b>
                            {val?.ClosingCredit !== null
                              ? val?.ClosingCredit?.toLocaleString()
                              : null}
                          </b>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <td>
                            <b>
                              {val?.ClosingDebit !== null
                                ? val?.ClosingDebit?.toLocaleString()
                                : null}
                            </b>
                          </td>
                        </td>
                        <td>
                          <b>
                            {val?.ClosingCredit !== null
                              ? Math.abs(val?.ClosingCredit).toLocaleString()
                              : null}
                          </b>
                        </td>
                      </>
                    )
                  ) : val?.ClosingCredit > 0 ? (
                    <>
                      <td>
                        <b>
                          {val?.ClosingDebit !== null
                            ? val?.ClosingDebit?.toLocaleString()
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {val?.ClosingCredit !== null
                            ? val?.ClosingCredit?.toLocaleString()
                            : null}
                        </b>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <b>
                          {val?.ClosingDebit !== null
                            ? Math.abs(val?.ClosingDebit).toLocaleString()
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {val?.ClosingCredit !== null
                            ? Math.abs(val?.ClosingCredit).toLocaleString()
                            : null}
                        </b>
                      </td>
                    </>
                  )} */}
              </tr>

              {recursiveFuncation(val?.ChildHeads, val?.ChildAcc)}
            </>
          );
          // }
        })}
        {ChildAcc?.map((val1, index) => {
          // if (
          //   val1?.OpeningCredit === 0 &&
          //   val1?.OpeningDebit === 0 &&
          //   val1.Balance === 0 &&
          //   val1.ClosingDebit === 0 &&
          //   val1.ClosingCredit === 0
          // ) {
          //   <></>;
          // } else {
          if (
            val1?.OpeningCredit === 0 &&
            val1.OpeningDebit === 0 &&
            val1?.ClosingCredit === 0 &&
            val1?.ClosingDebit === 0 &&
            val1?.Credit === 0 &&
            val1?.Debit === 0
          ) {
            <></>;
          } else {
            return (
              <>
                <tr style={{ backgroundColor: "white" }} key={index}>
                  <td>
                    {val1?.IdCode} || {val1?.Particular}
                  </td>
                  {/* {val1?.AccCategory === "AS" || val1?.AccCategory === "EX" ? (
                    val1?.OpeningDebit > 0 ? (
                      <>
                        <td>
                          {val1?.OpeningDebit !== null
                            ? val1?.OpeningDebit?.toLocaleString()
                            : null}
                        </td>

                        <td>
                          {val1?.OpeningCredit !== null
                            ? val1?.OpeningCredit.toLocaleString()
                            : null}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {val1?.OpeningDebit !== null
                            ? Math.abs(val1?.OpeningDebit)?.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {val1?.OpeningCredit !== null
                            ? Math.abs(val1?.OpeningCredit)?.toLocaleString()
                            : null}
                        </td>
                      </>
                    )
                  ) : val1?.OpeningCredit > 0 ? (
                    <>
                      <td>
                        {val1?.OpeningDebit !== null
                          ? val1?.OpeningDebit?.toLocaleString()
                          : null}
                      </td>
                      <td>
                        {val1?.OpeningCredit !== null
                          ? val1?.OpeningCredit?.toLocaleString()
                          : null}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        {val1?.OpeningDebit !== null
                          ? Math.abs(val1?.OpeningDebit).toLocaleString()
                          : null}
                      </td>
                      <td>
                        {val1?.OpeningCredit !== null
                          ? Math.abs(val1?.OpeningCredit)?.toLocaleString()
                          : null}
                      </td>
                    </>
                  )} */}
                  <>
                    <td>
                      {val1?.OpeningDebit !== null
                        ? val1?.OpeningDebit?.toLocaleString()
                        : null}
                    </td>
                    <td>
                      {val1?.OpeningCredit !== null
                        ? val1?.OpeningCredit?.toLocaleString()
                        : null}
                    </td>
                    <td>
                      {val1?.Debit !== null
                        ? Math.abs(val1?.Debit).toLocaleString()
                        : null}
                    </td>
                    <td>
                      {val1?.Credit !== null
                        ? Math.abs(val1?.Credit).toLocaleString()
                        : null}
                    </td>
                  </>
                  <td>
                    <b>
                      {val1?.ClosingDebit !== null
                        ? val1?.ClosingDebit?.toLocaleString()
                        : null}
                    </b>
                  </td>
                  <td>
                    <b>
                      {val1?.ClosingCredit !== null
                        ? val1?.ClosingCredit?.toLocaleString()
                        : null}
                    </b>
                  </td>
                  {/* {val1?.AccCategory === "AS" || val1?.AccCategory === "EX" ? (
                    val1?.ClosingDebit > 0 ? (
                      <>
                        <td>
                          {val1?.ClosingDebit !== null
                            ? val1?.ClosingDebit.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {val1?.ClosingCredit !== null
                            ? val1?.ClosingCredit?.toLocaleString()
                            : null}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {val1?.ClosingDebit !== null
                            ? Math.abs(val1?.ClosingDebit)?.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {val1?.ClosingCredit !== null
                            ? Math.abs(val1?.ClosingCredit)?.toLocaleString()
                            : null}
                        </td>
                      </>
                    )
                  ) : val1?.Closing > 0 ? (
                    <>
                      <td>
                        <td>
                          {val1?.ClosingDebit !== null
                            ? val1?.ClosingDebit.toLocaleString()
                            : null}
                        </td>
                      </td>
                      <td>
                        {val1?.ClosingCredit !== null
                          ? val1?.ClosingCredit.toLocaleString()
                          : null}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        {val1?.ClosingDebit !== null
                          ? Math.abs(val1?.ClosingDebit)?.toLocaleString()
                          : null}
                      </td>
                      <td>
                        {val1?.ClosingCredit !== null
                          ? Math.abs(val1?.ClosingCredit).toLocaleString()
                          : null}
                      </td>
                    </>
                  )} */}
                </tr>
              </>
            );
          }
        })}
      </>
    );
  };

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  const savedata = () => {
    document.getElementById("go").disabled = true;
    if (
      document.getElementById("fromdate").value === "" ||
      document.getElementById("todate").value === ""
    ) {
      toast.info("Select FromDate To ToDate");
      document.getElementById("go").disabled = false;
    } else {
      setloader(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Reports/TrailBalance`,
        data: JSON.stringify({
          From: document.getElementById("fromdate").value,
          To: document.getElementById("todate").value,
          ProjectId: localStorage.getItem("ProjectId")
            ? localStorage.getItem("ProjectId")
            : null,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            settrailbalancedata(result.data.Data);

            var credit = 0;

            result.data.Data.map((val) => (credit += parseFloat(val.Credit)));
            setcredit(credit);
            var debit = 0;

            result.data.Data.map((val) => (debit += parseFloat(val.Debit)));
            setdebit(debit);

            let debitGrandTotal = 0;
            let creditGrandTotal = 0;
            let closingDebittotal = 0;
            let openingDebittotal = 0;
            let openingCredittotal = 0;
            let closingCredittotal = 0;

            result.data.Data.forEach((val, idx) => {
              // if (
              //   (val.AccCategory === "AS" || val.AccCategory === "EX") &&
              //   val.Balance > 0
              // ) {
              debitGrandTotal += parseFloat(val.Debit);
              // } else {
              creditGrandTotal += parseFloat(val.Credit);
              // }
              openingDebittotal += parseFloat(val.OpeningDebit);
              closingDebittotal += parseFloat(val.ClosingDebit);
              openingCredittotal += parseFloat(val.OpeningCredit);
              closingCredittotal += parseFloat(val.ClosingCredit);
            });

            setDebitGrandTotalState(debitGrandTotal);
            setCreditGrandTotalState(creditGrandTotal);
            setopeningDebitTotal(openingDebittotal);
            setclosingDebitTotal(closingDebittotal);
            setopeningCreditTotal(openingCredittotal);
            setclosingCreditTotal(closingCredittotal);

            document.getElementById("go").disabled = false;
            setloader(false);
          } else {
            setloader(false);
            document.getElementById("go").disabled = false;
          }
        },
        (error) => {
          setloader(false);
          document.getElementById("go").disabled = false;
        }
      );
    }
  };

  return (
    <>
      <div hidden>
        <TrailBalancePrint
          ref={componentRef}
          statecredit={statecredit}
          statedebit={statedebit}
          statetraildata={statetraildata}
          openingCreditTotal={openingCreditTotal}
          openingDebitTotal={openingDebitTotal}
          closingCreditTotal={closingCreditTotal}
          closingDebitTotal={closingDebitTotal}
          debitGrandTotalState={debitGrandTotalState}
          creditGrandTotalState={creditGrandTotalState}
          recursiveFuncation={recursiveFuncation}
        />
      </div>
      <div className="row   app_font ">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-3 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
                defaultValue={localStorage.getItem("OpeningYear")}
              ></input>
            </div>
            <div className="col-md-3 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button
                className="btn_Go"
                onClick={savedata}
                id="go"
              >
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {loader && (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              )}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive vouchers_table_height2 mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr style={{ backgroundColor: "white" }}>
                    <th></th>

                    <th
                      className="text-center"
                      colspan="2"
                    >
                      Opening
                    </th>

                    <th
                      className="text-center"
                      colspan="2"
                    >
                      For The Period
                    </th>

                    <th
                      className="text-center"
                      colspan="2"
                    >
                      Closing
                    </th>
                  </tr>
                  <tr style={{ backgroundColor: "white" }}>
                    <th>Id Code/Account Title</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {statetraildata.map((val, index) => {
                    // if (
                    //   val?.OpeningCredit === 0 &&
                    //   val?.OpeningDebit === 0 &&
                    //   val?.ClosingCredit === 0 &&
                    //   val?.ClosingDebit == 0 &&
                    //   val?.Credit === 0 &&
                    //   val?.Debit === 0
                    // ) {
                    //   <></>;
                    // } else {
                    return (
                      <>
                        <tr
                          style={{ backgroundColor: "lightblue" }}
                          key={index}
                        >
                          <td>
                            {val?.IdCode} || <b>{val.Particular}</b>
                          </td>
                          {/* {val?.AccCategory === "AS" ||
                              val?.AccCategory === "EX" ? (
                              val?.OpeningDebit > 0 ? (
                                <>
                                  <td>
                                    <b>
                                      {val?.OpeningDebit !== null
                                        ? val?.OpeningDebit.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.OpeningCredit !== null
                                        ? val?.OpeningCredit.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <b>
                                      {val?.OpeningDebit !== null
                                        ? Math.abs(
                                          val?.OpeningDebit
                                        )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.OpeningCredit !== null
                                        ? Math.abs(
                                          val?.OpeningCredit
                                        )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              )
                            ) : val?.OpeningCredit > 0 ? (
                              <>
                                <td>
                                  <b>
                                    {val?.OpeningDebit !== null
                                      ? val?.OpeningDebit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.OpeningCredit !== null
                                      ? val?.OpeningCredit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <b>
                                    {val?.OpeningDebit !== null
                                      ? Math.abs(
                                        val?.OpeningDebit
                                      )?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.OpeningCredit !== null
                                      ? Math.abs(
                                        val?.OpeningCredit
                                      )?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            )} */}
                          <td>
                            <b>
                              {val?.OpeningDebit !== null
                                ? val?.OpeningDebit?.toLocaleString()
                                : null}
                            </b>
                          </td>
                          <td>
                            <b>
                              {val?.OpeningCredit !== null
                                ? val?.OpeningCredit?.toLocaleString()
                                : null}
                            </b>
                          </td>
                          <td>
                            <b>
                              {val?.Debit !== null
                                ? val?.Debit?.toLocaleString()
                                : null}
                            </b>
                          </td>
                          <td>
                            {val?.Credit !== null
                              ? val?.Credit?.toLocaleString()
                              : null}
                          </td>
                          <td>
                            <b>
                              {val?.ClosingDebit !== null
                                ? val?.ClosingDebit?.toLocaleString()
                                : null}
                            </b>
                          </td>
                          <td>
                            <b>
                              {val?.ClosingCredit !== null
                                ? val?.ClosingCredit?.toLocaleString()
                                : null}
                            </b>
                          </td>
                          {/* {val.AccCategory === "AS" ||
                              val.AccCategory === "EX" ? (
                              val.Balance > 0 ? (
                                <>
                                  <td>
                                    <b>
                                      {val.Debit !== null
                                        ? val.Debit.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td></td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td>
                                    <b>
                                      {val.Credit !== null
                                        ? Math.abs(val.Credit).toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              )
                            ) : val.Balance > 0 ? (
                              <>
                                <td></td>
                                <td>
                                  <b>
                                    {val.Debit !== null
                                      ? val.Debit.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <b>
                                    {val.Credit !== null
                                      ? Math.abs(val.Credit).toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td></td>
                              </>
                            )} */}

                          {/* {val?.AccCategory === "AS" ||
                            val?.AccCategory === "EX" ? (
                              val?.ClosingDebit > 0 ? (
                                <>
                                  <td>
                                    <b>
                                      {val?.ClosingDebit !== null
                                        ? val?.ClosingDebit?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.ClosingCredit !== null
                                        ? val?.ClosingCredit?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <b>
                                      {val?.ClosingDebit !== null
                                        ? Math.abs(
                                            val?.ClosingDebit
                                          )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                  <td>
                                    <b>
                                      {val?.ClosingCredit !== null
                                        ? Math.abs(
                                            val?.ClosingCredit
                                          )?.toLocaleString()
                                        : null}
                                    </b>
                                  </td>
                                </>
                              )
                            ) : val?.ClosingCredit > 0 ? (
                              <>
                                <td>
                                  <b>
                                    {val?.ClosingDebit !== null
                                      ? val?.ClosingDebit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.ClosingCredit !== null
                                      ? val?.ClosingCredit?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <b>
                                    {val?.ClosingDebit !== null
                                      ? Math.abs(val?.Debit)?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {val?.ClosingCredit !== null
                                      ? Math.abs(val?.Credit)?.toLocaleString()
                                      : null}
                                  </b>
                                </td>
                              </>
                            )} */}
                        </tr>
                        {recursiveFuncation(val.ChildHeads, val?.ChildAcc)}
                      </>
                    );
                    // }
                  })}
                  <tr>
                    <td>Grand Total:</td>
                    <td>
                      <b>{openingDebitTotal.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{openingCreditTotal.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{debitGrandTotalState?.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{creditGrandTotalState?.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{closingDebitTotal?.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{closingCreditTotal?.toLocaleString()}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => printPaymentRec()}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {csvData && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                {/* Download CSV */}
                <CSVLink
                  style={{ color: "black" }}
                  filename="Trial Balance Csv"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrailBalance;
