import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";

import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallProject } from "../../../actions/getprojects";
import { getallDealSoldreceipt } from "../../../actions/vouchersactions/dealsoldreceipt";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import TaxModal from "../../TaxModal/TaxModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const DealSoldRecForm = () => {
  let vouchernumber = `DSR-${Math.floor(Date.now())}`;
  const history = useHistory();

  const recoveryReceiptData = useSelector((state) => state.getDealSoldReceipt);
  const resultsproject = useSelector((state) => state.getProject);
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const [stateamount, setamount] = useState("0");
  const [taxtotal, settaxtotal] = useState("0");
  const [allvalues, setallvalues] = useState([]);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  let dispatch = useDispatch();
  const [recoveryBookingList, setrecoveryBookingList] = useState([]);
  const [statebooking, setstatebooking] = useState({});
  const [showDetailModal, setshowDetailModal] = useState(false);

  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("Select Project");
    } else if (document.getElementById("manager").selectedIndex === 0) {
      toast.info("Select Affiliate");
    } else if (document.getElementById("unit").selectedIndex === 0) {
      toast.info("Select Deal");
    } else if (document.getElementById("narration").value === "") {
      toast.info("Narration can't be empty");
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else if (document.getElementById("total").value === "") {
      toast.info("Total can't be empty ");
    } else if (
      parseFloat(document.getElementById("total").value.replace(/,/g, "")) >
      parseFloat(statebooking.Amount) - parseFloat(statebooking.AmountReceived)
    ) {
      toast.info(
        `Amount should be less than ${statebooking !== undefined
          ? parseFloat(statebooking.Amount) -
          parseFloat(statebooking.AmountReceived)
          : null
        }`
      );
    } else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = null;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }

      {
      }
      let TaxList = [];
      // resultalltaxes.data.map((val, i) => {
      //     if (val.Checked===true) {
      //         TaxList.push({ TaxId: val.TaxId, TaxPer: val.TaxPer })
      //     }
      // })
      allvalues?.map((val, i) => {
        if (val.Checked === true) {
          TaxList.push({
            TaxId: Number(val.TaxId),
            TaxPer: Number(val.TaxPer),
            // TaxAmount: val.TaxAmount,
            // Checked: true,
            // TaxSubmitedDetailId: val.TaxSubmitedDetailId
            //   ? val.TaxSubmitedDetailId
            //   : 0,
            // ProjectId: localStorage.getItem("ProjectId"),
            // ReferenceId: "",
            // ReferenceType: "",
          });
        }
      });
      let Voucher = {
        EntryDate: document.getElementById("Date").value,
        BankAccountId:
          paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        PaymentType: paymentType,
        ChequeNumber: checkNumber,
        Narration: document.getElementById("narration").value,

        AffiliateCnic:
          document.getElementById("manager").options[
            document.getElementById("manager").selectedIndex
          ].dataset.id,

        ProjectId: parseInt(
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id
        ),
        DealSoldId: parseInt(
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.id
        ),
        Amount: parseFloat(
          document.getElementById("total").value.replace(/,/g, "")
        ),
        DealSoldReceiptVoucherId: document.getElementById("Voucher").value,
      };
      document.getElementById("recoverysave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Vouchers/DealSold/Receipt/Add`,
        data: JSON.stringify({
          Voucher,
          TaxList,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("recoverysave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("unit").selectedIndex = 0;
            document.getElementById("manager").selectedIndex = 0;
            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("chequeno").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;

            document.getElementById("total").value = "";
            document.getElementById("narration").value = "";

            dispatch(getallDealSoldreceipt());

            toast.info(result.data.Message);
            dispatch(getrecoveryreport());
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("recoverysave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    if (statebooking !== undefined && statebooking.Amount !== undefined)
      document.getElementById("total").value = (
        statebooking.Amount - statebooking.AmountReceived
      ).toLocaleString();
  }, [statebooking]);
  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallDealSoldreceipt());
    dispatch(getallbanks());
    dispatch(getallcash());
    dispatch(getallTaxTable());
  }, [dispatch]);
  // function closemodal() {
  //     if (parseFloat(stateamount.replace(/,/g, '')) - parseFloat(taxtotal.replace(/,/g, '')) < 0) {
  //         toast.info("Total of percentages of taxes should not greater then 100")
  //     } else {
  //         document.getElementById("mymodal").style.display = "none";
  //     }

  // }
  // function showmodal() {
  //     document.getElementById("mymodal").style.display = "block";

  // }
  return (
    <>
      <div className="col-md-12 text-right">
        <span>
          {recoveryReceiptData.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </span>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select className="input_styling ml-md-3 " id="project">
            <option>Select</option>
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
                parseInt(val.ProjectId) ? (
                <option key={index} data-id={val.ProjectId}>
                  {val.ProjectName}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Affiliate:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            className="input_styling ml-md-3 "
            id="manager"
            onChange={() => {
              setrecoveryBookingList(
                recoveryReceiptData.data[
                  document.getElementById("manager").options[
                    document.getElementById("manager").selectedIndex
                  ].dataset.idx
                ] !== undefined
                  ? recoveryReceiptData.data[
                    document.getElementById("manager").options[
                      document.getElementById("manager").selectedIndex
                    ].dataset.idx
                  ].DealSold
                  : []
              );
            }}
          >
            <option>Select</option>
            {recoveryReceiptData.data.map((val, index) => (
              <option data-idx={index} data-id={val.AffiliateCnic}>
                {val.AffiliateName !== undefined ? val.AffiliateName : null}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Deal:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            className="input_styling ml-md-3 "
            id="unit"
            onChange={() => {
              setstatebooking(
                recoveryBookingList[
                document.getElementById("unit").options[
                  document.getElementById("unit").selectedIndex
                ].dataset.idx
                ]
              );
            }}
          >
            <option>Select</option>
            {recoveryBookingList !== undefined
              ? recoveryBookingList.map((x, index) => (
                <option key={index} data-idx={index} data-id={x.DealSoldId}>
                  {x.DealName}
                </option>
              ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_styling ml-md-3 "
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            id="vouchertype"
            onChange={(e) => {
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling ml-md-3  "
          >
            <option>Select</option>
            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            disabled
            value={vouchernumber}
            className="input_styling ml-md-3 "
            id="Voucher"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <select id="cashs" className="input_styling  ml-md-3">
              <option>Select</option>
              {resultsallcash.data.map((x) => (
                <option selected data-id={x.AccountId}>
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <span id="bankdiv" style={{ display: "none" }}>
        <div className="row ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4 p-0">
            <label className="input_label m-0 pl-md-4">Bank:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <select id="banks" className="input_styling  ml-md-3">
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option data-id={x.AccountId}>{x.AccountTitle}</option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-1 mt-4 p-0">
            <label className="input_label m-0 pl-md-4">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4 ">
            <input
              id="chequeno"
              type="number"
              placeholder="Cheque No"
              className="input_styling ml-md-3 "
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Amount:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="text "
            className="input_styling ml-md-3 "
            id="total"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("total").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Net Amt:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            className="input_styling  ml-md-3 "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Narration:</label>
        </div>
        <div className="col-md-3 mt-4">
          <textarea className="input_address ml-md-3" id="narration"></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              if (document.getElementById("total").value !== "") {
                setamount(document.getElementById("total").value);
                setshowTaxmodal(true);
              }
            }}
          >
            Add Tax
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => {
              // props.cancelbookingtable;
              history.goBack();
            }}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2 "
            id="recoverysave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
      {/* <div id="mymodal" className="modal">

                <div className="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Select Taxes</h4>
                            <span
                                className="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-12  table_height pr-0 pl-0">

                            <div className="m-2 mt-4">

                                <table className="table table-borderless m-0">
                                    <thead>

                                        <tr >


                                            <th>Select</th>
                                            <th>Name</th>

                                            <th>Percentage</th>
                                            <th>Tax Amount</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resultalltaxes.data.map((val, index) => (
                                            <tr key={index}  >

                                                <td><input id={`advCheck${index}`} type="checkbox" name="check" onChange={(e) => {
                                                    resultalltaxes.data[index].Checked = e.target.checked

                                                    if (e.target.checked===true) {
                                                        settaxtotal(parseFloat(parseFloat(taxtotal.replace(/,/g, '')) + parseFloat(document.getElementById(`taxtotal${index}`).value.replace(/,/g, ''))).toLocaleString());


                                                    } else {
                                                        settaxtotal(parseFloat(parseFloat(taxtotal.replace(/,/g, '')) - parseFloat(document.getElementById(`taxtotal${index}`).value.replace(/,/g, ''))).toLocaleString());

                                                    }

                                                }} /></td>
                                                <td>{val.TaxName}</td>

                                                <td><input type="text" id={`taxper${index}`} defaultValue={val.TaxPer} onChange={(e) => {
                                                    resultalltaxes.data[index].TaxPer = e.target.value;
                                                    document.getElementById(`taxtotal${index}`).value = parseFloat((e.target.value / 100) * parseFloat(document.getElementById("total").value.replace(/,/g, ''))).toLocaleString();

                                                }}></input></td>

                                                <td><input type="text" id={`taxtotal${index}`} value={parseFloat((parseFloat(val.TaxPer) / 100) * parseFloat(stateamount.replace(/,/g, ''))).toLocaleString()} disabled></input>
                                                </td>


                                            </tr>
                                        ))}

                                    </tbody>


                                </table>
                            </div>
                            <div className="row">

                                <div className=" col-11 text-right pt-2 pr-0 pl-0">
                                    <label className="input_label m-0">  Total Tax:</label> <input type="text" value={taxtotal.toLocaleString()} disabled></input>

                                </div></div>
                        </div>
                    </div>

                </div>
            </div> */}
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Deal Sold Rec Receipt"
        />
      ) : null}
      {showTaxmodal === true ? (
        <TaxModal
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          // vouchData={props.vouchData}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
    </>
  );
};
