import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  getallLeadMeetingPopulate,
  getallLeadMeetingTable,
} from "../../actions/Crmactions/addleads";
import Loader from "react-loader-spinner";

import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const MainMeetingComp = () => {
  const showtasktable = () => {
    stateshowtask("AddMeeting");
  };
  const canceltasktable = () => {
    stateshowtask("AddMeetingTable");
  };
  const [showtask, stateshowtask] = useState("AddMeetingTable");

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showtask === "AddMeetingTable" ? (
                <AddMeetingTable
                  showtasktable={showtasktable}
                  canceltasktable={canceltasktable}
                />
              ) : (
                <AddMeeting canceltasktable={canceltasktable} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainMeetingComp;
export const AddMeeting = () => {
  const populateMeetingTask = useSelector(
    (state) => state.getLeadMeetingPopulate
  );
  const history = useHistory();

  let dispatch = useDispatch();
  const [stateleadid, setleadid] = useState();

  // var suggestionlist1 = [];

  // const designationSelecttion = (e) => {
  //   const value = e.target.value;
  //   if (value==="") {
  //     ReactDOM.render(null, document.getElementById("unitdemo"));
  //   } else {
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");
  //       suggestions = populateMeetingTask.data.Leads.sort().filter((v) =>
  //         regex.test(v.LeadIdentifier !== null ? v.LeadIdentifier : null)
  //       );
  //     }
  //     suggestionlist1 = suggestions;

  //     if (suggestionlist1.length === 0) {
  //       ReactDOM.render(null, document.getElementById("unitdemo"));
  //     } else {
  //       ReactDOM.render(
  //         <ul className="suggestbox">
  //           {suggestionlist1.map((item) => (
  //             <li className="listcss" onClick={() => suggestionselected1(item)}>
  //               {item === undefined ? item : item.LeadIdentifier}
  //             </li>
  //           ))}
  //         </ul>,
  //         document.getElementById("unitdemo")
  //       );
  //     }
  //   }
  // };
  const onTextChangeEmployeeName = () => {
    var inputValue = document.getElementById("inittiate").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        populateMeetingTask.data.Leads.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        setleadid(val.LeadId);

        break;
      }
    }
  };
  // const suggestionselected1 = (val) => {
  //   document.getElementById("inittiate").value = val.LeadIdentifier;
  //   setleadid(val.LeadId);

  //   // setUnitId(val.UnitId);

  //   suggestionlist1 = [];
  //   ReactDOM.render(null, document.getElementById("unitdemo"));
  // };
  const [meetingstate, setmeetingstate] = useState({
    LeadMeetingId: 0,
    LeadId: null,
    Locations: null,
    From: null,
    To: null,
    HostId: null,
    Purpose: null,
    Status: null,
    MeetingDate: null,
    Comments: null,
  });
  const saveleadmeetingdata = () => {
    if (document.getElementById("inittiate").value === "") {
      toast.info("Select Lead Identifier");
    } else if (document.getElementById("meetingdate").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("HostId").selectedIndex === 0) {
      toast.info("Select Host");
    } else if (document.getElementById("Location").value === "") {
      toast.info("Add Location");
    } else if (document.getElementById("Purpose").value === "") {
      toast.info("Enter Purpose");
    } else if (document.getElementById("meetingStatus").selectedIndex === 0) {
      toast.info("Select Status");
    } else {
      let Lead = meetingstate;
      Lead.LeadMeetingId = 0;
      Lead.LeadId = parseInt(stateleadid);
      Lead.From = document.getElementById("MeetingEntryTime").value;

      Lead.To = document.getElementById("MeetingDueTime").value;
      Lead.HostId = parseInt(
        document.getElementById("HostId").options[
          document.getElementById("HostId").selectedIndex
        ].dataset.id
      );
      Lead.Purpose = document.getElementById("Purpose").value;
      Lead.Locations = document.getElementById("Location").value;

      Lead.Status =
        document.getElementById("meetingStatus").options[
          document.getElementById("meetingStatus").selectedIndex
        ].value;
      Lead.Comments = document.getElementById("meetingComments").value;
      Lead.MeetingDate = document.getElementById("meetingdate").value;

      document.getElementById("leadmeetingsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Leads/Meeting/Add`,
        data: JSON.stringify(Lead),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadmeetingsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("MeetingDueTime").value = "";
            document.getElementById("MeetingEntryTime").value = "";
            document.getElementById("meetingComments").value = "";
            document.getElementById("meetingStatus").selectedIndex = 0;
            document.getElementById("Purpose").value = "";
            document.getElementById("Location").value = "";

            document.getElementById("HostId").selectedIndex = 0;
            setleadid(null);
            // props.canceltasktable();
            history.goBack();

            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadmeetingsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallLeadMeetingPopulate());
  }, [dispatch]);
  return (
    <>
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Add Meeting</h4>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-4 mt-md-4  text-left p-0"></div>

        <div className="col-md-1 mt-md-4  text-left p-0">
          <label className="input_label m-0">Lead</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            list="employeenamelist"
            id="inittiate"
            onChange={onTextChangeEmployeeName}
            placeholder="Enter Lead Identifier"
          ></input>
          <span className="estaric">*</span>
          <datalist id="employeenamelist">
            {populateMeetingTask?.data?.Leads?.map((x, idx) => (
              <option index={idx} value={x.LeadIdentifier}>
                {x.LeadId}
              </option>
            ))}
          </datalist>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4  text-left p-0"></div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Host :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select className="input_styling ml-md-3" id="HostId">
            <option>Select</option>

            {populateMeetingTask.data.Data !== undefined
              ? populateMeetingTask.data.Data.map((val, index) => (
                <option key={index} data-id={val.EmployeeId}>
                  {val.EmpName}
                </option>
              ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Location</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="Location"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Meeting Date</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="meetingdate"
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Status :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="meetingStatus"
            onChange={(e) => {
              if (e.target.value === "Done") {
                document.getElementById("hideshow").style.display = "inline";
                document.getElementById("hideshow2").style.display = "inline";
                document.getElementById("hideshow3").style.display = "inline";
              } else {
                document.getElementById("hideshow").style.display = "none";
                document.getElementById("hideshow2").style.display = "none";
                document.getElementById("hideshow3").style.display = "none";
              }
            }}
          >
            <option>Select</option>
            <option>Pending</option>
            <option>Done</option>
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <header id="hideshow" style={{ display: "none" }}>
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0 ">From :</label>
          </div>
          <div className="col-md-3 mt-md-4  p-0">
            <input
              type="time"
              className="input_date_styling  ml-md-3"
              id="MeetingEntryTime"
            ></input>
          </div>
          <div className="col-md-2 mt-md-4   p-0"></div>
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0">To</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <input
              type="time"
              className="input_date_styling  ml-md-3"
              id="MeetingDueTime"
            ></input>
          </div>
        </div>
      </header>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Purpose :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <textarea className="input_address ml-md-3 " id="Purpose"></textarea>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4  text-left p-0">
          <span id="hideshow2" style={{ display: "none" }}>
            {" "}
            <label className="input_label m-0">Comments</label>
          </span>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <span id="hideshow3" style={{ display: "none" }}>
            <textarea
              className="input_address ml-md-3 "
              id="meetingComments"
            ></textarea>
          </span>
        </div>
      </div>

      {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
      <div className=" float-right mt-md-4 ">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2  "
            id="leadmeetingsave"
            onClick={saveleadmeetingdata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};
export const AddMeetingTable = () => {
  const resultallmeetings = useSelector((state) => state.getLeadMeetingTabe);
  const rolesdata = useSelector((state) => state.GetAllRole);

  const history = useHistory();

  const [stateeditmeeting, seteditmeeting] = useState([]);
  const [stateleadid, setleadid] = useState();

  const [filerunits, setfilterunits] = useState();

  let dispatch = useDispatch();
  // print function
  // const componentRef = useRef();

  // const printPaymentRec = useReactToPrint({
  //     content: () => componentRef.current,
  // });
  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(resultallmeetings.data);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter(
            (val) =>
              regex.test(
                val.MeetingDate !== null
                  ? moment(val.MeetingDate.split("T")[0]).format("DD-MMM-YYYY")
                  : null
              ) || regex.test(val.Status)
          );
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };
  const [meetingstate, setmeetingstate] = useState({
    LeadMeetingId: 0,
    LeadId: null,
    Locations: null,
    From: null,
    To: null,
    HostId: null,
    Purpose: null,
    Status: null,
    MeetingDate: null,
    Comments: null,
  });
  const saveleadmeetingdata = () => {
    let Lead = meetingstate;
    Lead.LeadMeetingId = stateeditmeeting.LeadMeetingId;
    Lead.LeadId = parseInt(stateleadid);
    Lead.From = document.getElementById("MeetingEntryTime").value;

    Lead.To = document.getElementById("MeetingDueTime").value;
    Lead.HostId = null;
    Lead.Purpose = null;
    Lead.Locations = document.getElementById("Location").value;

    Lead.MeetingDate = document.getElementById("meetingdate").value;
    Lead.Status =
      document.getElementById("Status").options[
        document.getElementById("Status").selectedIndex
      ].value;
    Lead.Comments = document.getElementById("meetingComments").value;

    document.getElementById("leadmeetingsave").disabled = true;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Leads/Meeting/Add`,
      data: JSON.stringify(Lead),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("leadmeetingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("MeetingEntryTime").value = "";
          document.getElementById("MeetingDueTime").value = "";
          // document.getElementById("Location").value = "";
          document.getElementById("Status").selectedIndex = 0;
          // document.getElementById("meetingdate").value = "";
          document.getElementById("meetingComments").value = "";
          toast.info(result.data.Message);
          document.getElementById("modalofmeeting").style.display = "none";
          dispatch(getallLeadMeetingTable());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        document.getElementById("leadmeetingsave").disabled = false;
      }
    );
  };
  useEffect(() => {
    dispatch(getallLeadMeetingTable());
  }, [dispatch]);
  const openmodallmeetingedit = (x) => {
    setleadid(x.LeadId);
    seteditmeeting(x);
    document.getElementById("modalofmeeting").style.display = "block";
  };
  useEffect(() => {
    setfilterunits(resultallmeetings.data);
  }, [resultallmeetings]);
  return (
    <>
      {/* <div hidden>
                <ClientPrint
                    ref={componentRef}

                    printData={resultallmeetings}

                /></div> */}
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Date/Status"
            onChange={onTextChange}
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {resultallmeetings.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive vouchers_table_height2 mt-4">
            <table className="table table-borderless table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>

                  <th> Date</th>
                  <th>From</th>

                  <th>To</th>

                  <th>Host</th>
                  <th>Location</th>
                  <th>Purpose</th>
                  <th>Comment</th>
                  <th>Status</th>

                  <th>Duration</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("U") && (
                      <button
                        className="save_btn"
                        // onClick={() => {
                        //   props.showtasktable();
                        // }}
                        onClick={() => history.push("/crm/tasks/meeting/add")}
                      >
                        New+
                      </button>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>
                        {val.MeetingDate !== null
                          ? moment(val.MeetingDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                          : null}
                      </td>
                      <td>{val.From}</td>
                      <td>{val.To}</td>
                      <td>{val.Host.EmpName}</td>
                      <td>{val.Locations}</td>
                      <td>{val.Purpose}</td>

                      <td>{val.Comments}</td>
                      <td>{val.Status}</td>
                      <td>{val.Duration}</td>

                      <td className="text-center">
                        {val.Status !== "Done" ? (
                          <FiEdit2
                            size="1em"
                            onClick={() => {
                              openmodallmeetingedit(val);
                            }}
                          />
                        ) : null}
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                </div> */}
      </div>

      <div
        id="modalofmeeting"
        class="modalscreen "
      >
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Edit Task</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofmeeting").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container">
            <header
              id="hideshow"
              style={{ display: "none" }}
            >
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">From :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="time"
                    className="input_date_styling  ml-md-3"
                    key={stateeditmeeting.From}
                    defaultValue={
                      stateeditmeeting.From !== undefined
                        ? stateeditmeeting.From
                        : null
                    }
                    id="MeetingEntryTime"
                  ></input>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">To</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="time"
                    className="input_date_styling  ml-md-3"
                    key={stateeditmeeting.To}
                    defaultValue={
                      stateeditmeeting.To !== undefined
                        ? stateeditmeeting.To
                        : null
                    }
                    id="MeetingDueTime"
                  ></input>
                </div>
              </div>
            </header>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Meeting Date</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="date"
                  className="input_date_styling  ml-md-3"
                  key={stateeditmeeting.MeetingDate}
                  defaultValue={
                    stateeditmeeting.MeetingDate !== undefined &&
                      stateeditmeeting.MeetingDate !== null
                      ? moment(
                        stateeditmeeting.MeetingDate.split("T")[0]
                      ).format("DD-MMM-YYYY")
                      : null
                  }
                  id="meetingdate"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Location</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  key={stateeditmeeting.Locations}
                  defaultValue={
                    stateeditmeeting.Locations !== undefined
                      ? stateeditmeeting.Locations
                      : null
                  }
                  id="Location"
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Status :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select
                  className="input_styling ml-md-3"
                  id="Status"
                  onChange={(e) => {
                    if (e.target.value === "Done") {
                      document.getElementById("hideshow").style.display =
                        "inline";
                      document.getElementById("hideshow2").style.display =
                        "inline";
                      document.getElementById("hideshow3").style.display =
                        "inline";
                    } else {
                      document.getElementById("hideshow").style.display =
                        "none";
                      document.getElementById("hideshow2").style.display =
                        "none";
                      document.getElementById("hideshow3").style.display =
                        "none";
                    }
                  }}
                >
                  {stateeditmeeting.Status !== null ? (
                    <option selected> {stateeditmeeting.Status}</option>
                  ) : (
                    <option selected> Select</option>
                  )}

                  <option>Pending</option>
                  <option>Done</option>
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <span
                  id="hideshow2"
                  style={{ display: "none" }}
                >
                  {" "}
                  <label className="input_label m-0">Comments</label>
                </span>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                {/* <textarea className="input_address ml-md-3 " id="meetingComments" key={stateeditmeeting.Comments} defaultValue={stateeditmeeting !== undefined ? stateeditmeeting.Comments : null}></textarea> */}
                <span
                  id="hideshow3"
                  style={{ display: "none" }}
                >
                  <textarea
                    className="input_address ml-md-3 "
                    id="meetingComments"
                  ></textarea>
                </span>
              </div>
            </div>

            <div className="row ">
              <div className=" col-12 float-right mt-md-4 ">
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="leadmeetingsave"
                    onClick={saveleadmeetingdata}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
