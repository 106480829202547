// import axios from "axios";
import { useEffect } from "react";
// import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
// import { useReactToPrint } from "react-to-print";
// import { toast } from "react-toastify";
import ChangeStatusModal from "./ChangeStatusModal";
import { getUsersData } from "../../actions/Users/GetUsers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const Users = (props) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  // const componentRef = useRef();
  const Users = useSelector((state) => state.getFetchUserData.data?.Data);
  const Status = useSelector((state) => state.getFetchUserData.loading);
  useEffect(() => {
    dispatch(getUsersData());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserStatus(!selectedUser?.IsActive);
      showModal();
    } else {
      setSelectedUserStatus(false);
    }
  }, [selectedUser]);

  function showModal() {
    document.getElementById("changeStatusModal").style.display = "block";
  }

  function closeModal() {
    document.getElementById("changeStatusModal").style.display = "none";
    setSelectedUser(null);
  }
  return (
    <>
      <>
        <div className="row pt-3 ">
          <div className="col-md-1 pr-0 pl-0">
            <label className="input_label  m-0">Search:</label>
          </div>
          <div className="col-md-3   pr-0 pl-0">
            <input
              type="text "
              className="input_styling "
              placeholder="Name"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            ></input>
          </div>

          <div className="col-md-1 pr-0 pl-0">
            {Status == true ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
        </div>

        <div className="row ">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-4">
              <table className="table table-borderless table-hover m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Access</th>
                    <th>Projects</th>
                    <th>Status</th>
                    <th className="text-center">
                      <button
                        className="save_btn"
                        onClick={() => history.push("/Users/add")}
                      >
                        New+
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Users?.map((val, index) => {
                    if (
                      val.FullName.toLowerCase().includes(
                        searchValue.toLowerCase()
                      )
                    ) {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.FullName}</td>
                          <td>{val.Email}</td>
                          <td>{val.Access}</td>
                          <td>
                            {val?.Projects?.map((project, projectIndex) =>
                              projectIndex === val.Projects.length - 1
                                ? project
                                : project + ","
                            )}
                          </td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customSwitch${index}`}
                                checked={val?.IsActive}
                                onChange={() => {
                                  setSelectedUser(val);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customSwitch${index}`}
                              ></label>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <FiEdit2
                              title="Edit"
                              size="1.1em"
                              className="cur_sor"
                              onClick={() => {
                                history.push("/Users/add", val);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
      <ChangeStatusModal
        selectedUser={selectedUser}
        selectedUserStatus={selectedUserStatus}
        closemodal={closeModal}
        // callGetApi={callGetApi}
        getUsersData={getUsersData}
      />
    </>
  );
};
