import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallDSRpopulatetable } from "../../../actions/vouchersactions/dealsoldreceipt";
import {
  DealSoldRecModal,
  DealSoldRecPrint,
} from "../../accpayablepages/vouchersprints/receiptprints";
import { CSVLink } from "react-csv";
import DealSoldRecInfoModal from "./DealSoldRecInfoModal";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const DealSoldRecTable = (props) => {
  const resultsrrtable = useSelector(
    (state) => state.getAllDealSoldReceiptTable
  );
  const history = useHistory();

  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const rolesdata = useSelector((state) => state.GetAllRole);

  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  // print function
  const componentRef = useRef();

  const printRecoveryRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printDataModal, SetPrintDataModal] = useState();
  // print function
  const componentRefModal = useRef();

  const printGenrealRecModal = useReactToPrint({
    content: () => componentRefModal.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryRec();
    }
  }, [printData, statetoggle]);
  //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallDSRpopulatetable({}));
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsrrtable.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.DealSoldReceiptVoucherId) || regex.test(val.DealSold !== undefined && val.DealSold !== null ? val.DealSold.DealName : null)));

  //         }
  //         suggestionlist = suggestions;

  //         setfilterdata(suggestionlist);

  //     }
  // };
  const onTextChange = (e) => {
    if (
      document.getElementById("customerfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("dealfilter").value === ""
    ) {
      setfilterdata(resultsrrtable.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("customerfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("dealfilter").value,
          "i"
        );

        suggestions = resultsrrtable.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("customerfilter").value === "" ||
                regex.test(val.AffiliateCnicNavigation.AffiliateName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.DealSoldReceiptVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("dealfilter").value === "" ||
                regex3.test(val.DealSold.DealName))
          );
      }
      setfilterdata(suggestions);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallDSRpopulatetable(data));
  }, [dispatch]);
  useEffect(() => {
    setfilterdata(resultsrrtable.data);
  }, [resultsrrtable]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallDSRpopulatetable(data));
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        Voucher:
          val.DealSoldReceiptVoucherId === ""
            ? ""
            : val.DealSoldReceiptVoucherId,
        Affiliate:
          val.AffiliateCnicNavigation !== undefined
            ? val.AffiliateCnicNavigation.AffiliateName
            : "",
        Deal: val.DealSold !== undefined ? val.DealSold.DealName : "",
        Amount: parseFloat(val.Amount).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);
  return (
    <>
      <div hidden>
        <DealSoldRecPrint
          ref={componentRef}
          printData={printData}
        />
      </div>
      <div hidden>
        <DealSoldRecModal
          ref={componentRefModal}
          total={debitState}
          debitTotal={debitState}
          creditTotal={creditState}
          printData={printDataModal}
        />
      </div>
      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="customerfilter"
            onChange={onTextChange}
            placeholder="Affiliate"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="dealfilter"
            onChange={onTextChange}
            placeholder="Deal Name"
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsrrtable.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
        <DealSoldRecInfoModal />
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="fromdate"
          ></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="todate"
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button
            className="btn_Go"
            onClick={ongo}
          >
            Go
          </button>
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th style={{ minWidth: "85px" }}>Date</th>
              <th style={{ minWidth: "85px" }}>Added On</th>
              <th style={{ minWidth: "120px" }}>Voucher</th>
              {/* <th>Project</th> */}
              <th>Affiliate</th>
              <th>Deal</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    // onClick={() => {
                    //   props.showbookingtable();
                    // }}
                    onClick={() =>
                      history.push(
                        "/account/vouchers/receipt-entry/deal-sold-rec/add"
                      )
                    }
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.DealSoldReceiptVoucherId}
                      </td>
                      {/* {val.Project !== undefined && val.Project !== null ?
                                    <td>{val.Project.ProjectName}</td> : <td></td>} */}
                      {val.AffiliateCnicNavigation !== undefined ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.AffiliateCnicNavigation.AffiliateName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {val.DealSold !== undefined ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.DealSold.DealName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>

                      <td
                        className="text-center cur_sor"
                        onClick={() => {
                          SetPrintData(val);
                          settoggle(!statetoggle);
                        }}
                      >
                        <GrPrint size="1.3em" />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Deal_Sold_Receipt_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
      <div
        id="mymodal"
        className="modal"
      >
        <div className="modal-content-cat_voucher">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Transaction Detail</h4>
              <span
                className="close pr-2  "
                onClick={closemodal}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <div className="table-responsive_modal mt-4 table_height-voucher">
                <table className="table table-borderless m-0 ">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Submit On</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransactionData !== undefined
                      ? TransactionData.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(x.EntryDate?.slice(0, 10)).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{x.Account.AccountTitle}</td>
                            <td>{x.Narration}</td>
                            <td>
                              {x.TransactionType === "DEBIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>

                            <td>
                              {x.TransactionType === "CREDIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                    <tr>
                      <td></td>
                      <td></td>

                      <td>
                        {" "}
                        <label className="input_label m-0">Total:</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Debit"
                          disabled
                          defaultValue={parseFloat(debitState).toLocaleString()}
                          key={debitState}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input_total_styling "
                          placeholder="Credit"
                          disabled
                          defaultValue={parseFloat(
                            creditState
                          ).toLocaleString()}
                          key={creditState}
                        ></input>
                      </td>
                      {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printGenrealRecModal();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
