import {
  Document,
  Image,
  PDFViewer,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import QuickSand from "./Quicksand-SemiBold.ttf";
import { useState } from "react";
import check from "../../../../assets/checkmark.png";

Font.register({
  family: "Quicksand sans-serif",
  src: QuickSand,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Quicksand sans-serif",
    padding: "0px 20px",
    paddingTop: "4in",
    paddingBottom: "20.5px",
    fontSize: "12px",
    fontWeight: "light",
  },
  page2: {
    fontFamily: "Quicksand sans-serif",
    padding: "0px 20px",
    paddingBottom: "20.5px",
    paddingTop: "40.5px",

    fontSize: "12px",
    fontWeight: "light",
  },
  section: {
    border: "1px solid black",
    padding: 3,
    marginBottom: 2,
  },
  infoParent: {
    display: "flex",
    width: "100%",
    gap: 3,
    flexDirection: "row",
    paddingBottom: 1,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  infoChild: {
    // display: "flex",
    // flexDirection: "row",
    // gap: 10,
  },
  infoChild2: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  labelFont: {
    fontSize: "10px",
  },
  labelFontTerms: {
    fontSize: "12px",
  },
  labelFontTermsHead: {
    fontSize: "14px",
  },
  valueFont: {
    fontSize: "9px",
  },
  linePadding: {
    paddingBottom: 2,
  },
  listItem: {
    flexDirection: "row",
    marginBottom: 8,
  },
  bullet: {
    marginLeft: 5,
  },
  content: {
    flex: 1,
  },
  signatureLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
});
const tableStyles = StyleSheet.create({
  table: {
    // marginTop: "2px",
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    // borderRightWidth: 1,
    // borderBottomWidth: 1,
    borderColor: "#5b5b5b",
  },
  tableRow: {
    // margin: "5px",
    justifyContent: "space-between",

    flexDirection: "row",
    borderLeft: "1px solid black",
  },
  tableCellHeader: {
    // margin: 5,
    padding: 2,
    fontSize: 11,
    fontWeight: 700,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    textAlign: "left",
    borderTop: "1px solid black",

    // flex: 1,
    color: "#298b8b",
    borderColor: "#5b5b5b",
  },
  tableCell: {
    // margin: 5,
    padding: 2,
    fontSize: "8px",
    textAlign: "left",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "#5b5b5b",
  },
});

function ContractNewPrintTgic({ printData, setPrintData }) {
  const [tableRows, settableRow] = useState([]);
  const handleClick = () => {
    // setClose(true);
    setPrintData(null);
  };

  // useEffect(() => {
  //   const rowsPerPage = 20; // Adjust as needed
  //   const totalRows =
  //     tableRows.length; /* your API data length or any other way to get the total rows */

  //   const rowsOnLastPage = totalRows % rowsPerPage || rowsPerPage;
  //   setlastRows(rowsOnLastPage);
  // }, [tableRows]);
  useEffect(() => {
    let arr = [];
    let rowArr = [];
    if (printData.ContractInstallments?.length > 0) {
      printData.ContractInstallments?.forEach((row, id) => {
        if (printData?.ContractInstallments?.length === id + 1) {
          if (arr.length > 0) {
            arr.push(
              rowArr.length <= 30 ? (
                <Page size="LEGAL" style={styles.page}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.ContractId}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Date. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.EntryDate
                          ? new Date(printData.EntryDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerName}
                      </Text>{" "}
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerCnic2}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "extrabold" }}>
                        {" "}
                        Unit No.{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.Unit?.UnitName}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Size. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined && printData.Unit !== undefined
                          ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? printData.Unit.Marla +
                              "M " +
                              printData.Unit.Sarsai +
                              " S"
                            : printData.Unit.NetSqft.toFixed(
                                2
                              ).toLocaleString() + "SQFT"
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {printData !== undefined &&
                        printData.Unit !== undefined &&
                        printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? "Rate/Marla."
                          : "Rate/Sqft."}{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.SaleRate !== undefined
                          ? printData?.SaleRate?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null
                          ? printData?.TotalPrice?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null &&
                        printData?.CustomerReceivable !== null
                          ? (
                              printData?.TotalPrice -
                              printData?.CustomerReceivable
                            ).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.CustomerReceivable !== null
                          ? (printData?.CustomerReceivable).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                    <Text> Installment Plan</Text>
                  </View>
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                      Sr
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Description{" "}
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Balance{" "}
                    </Text>
                  </View>
                  {rowArr}
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? id + 3
                        : id + 2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.DueAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                        ? moment(
                            row.UnitReceiptDetail[
                              row.UnitReceiptDetail?.length - 1
                            ]?.UnitVoucher?.EntryDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : row.UnitReceiptDetail?.length !== 0 &&
                          row.UnitReceiptDetail[0]?.AdjustmentVoucher
                            ?.EntryDate !== null
                        ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                            "T"
                          )[0]
                        : null}{" "}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row?.ReceivedAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Buyer{" "}
                      </Text>
                      <View
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 8,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Project director{" "}
                      </Text>
                      <View
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>
                          Sign:{"    "}
                        </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                </Page>
              ) : (
                <>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(0, 30)}
                  </Page>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(30, rowArr.length)}
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? id + 3
                          : id + 2}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {moment(row.DueDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.DueAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !==
                          null
                          ? moment(
                              row.UnitReceiptDetail[
                                row.UnitReceiptDetail?.length - 1
                              ]?.UnitVoucher?.EntryDate?.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : row.UnitReceiptDetail?.length !== 0 &&
                            row.UnitReceiptDetail[0]?.AdjustmentVoucher
                              ?.EntryDate !== null
                          ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                              "T"
                            )[0]
                          : null}{" "}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row?.ReceivedAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "40px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Buyer{" "}
                        </Text>
                        <View
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Thumb:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          columnGap: 8,
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Project director{" "}
                        </Text>
                        <View
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Name:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{"    "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        {/* <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            CNIC:{"  "}{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View> */}
                      </View>
                    </View>
                  </Page>
                </>
              )
            );
          } else {
            arr.push(
              rowArr.length <= 30 ? (
                <Page size="LEGAL" style={styles.page}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.ContractId}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Date. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.EntryDate
                          ? new Date(printData.EntryDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerName}
                      </Text>{" "}
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerCnic2}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "extrabold" }}>
                        {" "}
                        Unit No.{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.Unit?.UnitName}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Size. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined && printData.Unit !== undefined
                          ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? printData.Unit.Marla +
                              "M " +
                              printData.Unit.Sarsai +
                              " S"
                            : printData.Unit.NetSqft.toFixed(
                                2
                              ).toLocaleString() + "SQFT"
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {printData !== undefined &&
                        printData.Unit !== undefined &&
                        printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? "Rate/Marla."
                          : "Rate/Sqft."}{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.SaleRate !== undefined
                          ? printData?.SaleRate?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null
                          ? printData?.TotalPrice?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null &&
                        printData?.CustomerReceivable !== null
                          ? (
                              printData?.TotalPrice -
                              printData?.CustomerReceivable
                            ).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.CustomerReceivable !== null
                          ? (printData?.CustomerReceivable).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                    <Text> Installment Plan</Text>
                  </View>
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                      Sr
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Description{" "}
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Balance{" "}
                    </Text>
                  </View>
                  <View key={id + 1} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Booking
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData !== undefined &&
                      printData.EntryDate !== undefined
                        ? moment(printData.EntryDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.AdvanceAmount !== undefined
                        ? printData.AdvanceAmount?.toLocaleString()
                        : 0}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {" "}
                      {printData !== undefined &&
                      printData.UnitVoucher?.length > 0
                        ? moment(
                            printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.AdvanceReceived?.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(
                        printData.AdvanceAmount - printData.AdvanceReceived
                      ).toLocaleString()}
                    </Text>
                  </View>
                  {printData?.RescheduledReceivedInstallment && (
                    <View key={id + 1} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        2
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? moment(
                              printData.RescheduledReceivedInstallment.DueDate?.split(
                                "T"
                              )[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {" "}
                        {printData.RescheduledReceivedInstallment
                          ? moment(
                              printData.RescheduledReceivedInstallment.DueDate?.split(
                                "T"
                              )[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {0}
                      </Text>
                    </View>
                  )}
                  {rowArr}
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {+printData.RescheduledReceivedInstallment
                        ? id + 3
                        : id + 2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.DueAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                        ? moment(
                            row.UnitReceiptDetail[
                              row.UnitReceiptDetail?.length - 1
                            ]?.UnitVoucher?.EntryDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : row.UnitReceiptDetail?.length !== 0 &&
                          row.UnitReceiptDetail[0]?.AdjustmentVoucher
                            ?.EntryDate !== null
                        ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                            "T"
                          )[0]
                        : null}{" "}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row?.ReceivedAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Buyer{" "}
                      </Text>
                      <View
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        columnGap: 8,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Project director{" "}
                      </Text>
                      <View
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>
                          Sign:{"    "}
                        </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                </Page>
              ) : (
                <>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    <View key={id + 1} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        1
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Booking
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData !== undefined &&
                        printData.EntryDate !== undefined
                          ? moment(printData.EntryDate.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceAmount !== undefined
                          ? printData.AdvanceAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {" "}
                        {printData !== undefined &&
                        printData.UnitVoucher?.length > 0
                          ? moment(
                              printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceReceived?.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(
                          printData.AdvanceAmount - printData.AdvanceReceived
                        ).toLocaleString()}
                      </Text>
                    </View>
                    {printData?.RescheduledReceivedInstallment && (
                      <View key={id + 1} style={tableStyles.tableRow}>
                        <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                          2
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          Installment
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {printData.RescheduledReceivedInstallment
                            ? moment(
                                printData.RescheduledReceivedInstallment.DueDate?.split(
                                  "T"
                                )[0]
                              ).format("DD-MMM-YYYY")
                            : null}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {printData.RescheduledReceivedInstallment
                            ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                            : 0}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {" "}
                          {printData.RescheduledReceivedInstallment
                            ? moment(
                                printData.RescheduledReceivedInstallment.DueDate?.split(
                                  "T"
                                )[0]
                              ).format("DD-MMM-YYYY")
                            : null}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {printData.RescheduledReceivedInstallment
                            ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                            : 0}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {0}
                        </Text>
                      </View>
                    )}
                    {rowArr.slice(0, 30)}
                  </Page>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(30, rowArr.length)}
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? id + 3
                          : id + 2}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {moment(row.DueDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.DueAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !==
                          null
                          ? moment(
                              row.UnitReceiptDetail[
                                row.UnitReceiptDetail?.length - 1
                              ]?.UnitVoucher?.EntryDate?.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : row.UnitReceiptDetail?.length !== 0 &&
                            row.UnitReceiptDetail[0]?.AdjustmentVoucher
                              ?.EntryDate !== null
                          ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                              "T"
                            )[0]
                          : null}{" "}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row?.ReceivedAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "40px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Buyer{" "}
                        </Text>
                        <View
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Thumb:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          columnGap: 8,
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Project director{" "}
                        </Text>
                        <View
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Name:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{"    "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            CNIC:{"  "}{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                      </View>
                    </View>
                  </Page>
                </>
              )
            );
          }
        } else if (id + 1 === 35 && (id + 1) % 35 === 0) {
          if (arr.length > 0) {
            arr.push(
              <Page size="LEGAL" style={styles.page}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.ContractId}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Date. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.EntryDate
                        ? new Date(printData.EntryDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerName}
                    </Text>{" "}
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerCnic2}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.Unit?.UnitName}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Size. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData.Unit !== undefined
                        ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? printData.Unit.Marla +
                            "M " +
                            printData.Unit.Sarsai +
                            " S"
                          : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                            "SQFT"
                        : null}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {" "}
                      {printData !== undefined &&
                      printData.Unit !== undefined &&
                      printData.Unit.RatePerMarlaOrsqft === "MARLA"
                        ? "Rate/Marla."
                        : "Rate/Sqft."}{" "}
                    </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.SaleRate !== undefined
                        ? printData?.SaleRate?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData?.TotalPrice !== null
                        ? printData?.TotalPrice?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.TotalPrice !== null &&
                      printData?.CustomerReceivable !== null
                        ? (
                            printData?.TotalPrice -
                            printData?.CustomerReceivable
                          ).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.CustomerReceivable !== null
                        ? (printData?.CustomerReceivable).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                </View>
                <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                  <Text> Installment Plan</Text>
                </View>
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                    Sr
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Description{" "}
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Balance{" "}
                  </Text>
                </View>
                {rowArr}
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                    {printData.RescheduledReceivedInstallment ? id + 3 : id + 2}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    Installment
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.DueAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                      ? moment(
                          row.UnitReceiptDetail[
                            row.UnitReceiptDetail?.length - 1
                          ]?.UnitVoucher?.EntryDate?.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.AdjustmentVoucher
                          ?.EntryDate !== null
                      ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                          "T"
                        )[0]
                      : null}{" "}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row?.ReceivedAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                  </Text>
                </View>
              </Page>
            );
          } else {
            arr.push(
              <Page size="LEGAL" style={styles.page}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.ContractId}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Date. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.EntryDate
                        ? new Date(printData.EntryDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerName}
                    </Text>{" "}
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerCnic2}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.Unit?.UnitName}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Size. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData.Unit !== undefined
                        ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? printData.Unit.Marla +
                            "M " +
                            printData.Unit.Sarsai +
                            " S"
                          : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                            "SQFT"
                        : null}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {" "}
                      {printData !== undefined &&
                      printData.Unit !== undefined &&
                      printData.Unit.RatePerMarlaOrsqft === "MARLA"
                        ? "Rate/Marla."
                        : "Rate/Sqft."}{" "}
                    </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.SaleRate !== undefined
                        ? printData?.SaleRate?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData?.TotalPrice !== null
                        ? printData?.TotalPrice?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.TotalPrice !== null &&
                      printData?.CustomerReceivable !== null
                        ? (
                            printData?.TotalPrice -
                            printData?.CustomerReceivable
                          ).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.CustomerReceivable !== null
                        ? (printData?.CustomerReceivable).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                </View>
                <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                  <Text> Installment Plan</Text>
                </View>
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                    Sr
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Description{" "}
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Balance{" "}
                  </Text>
                </View>
                <View key={id + 1} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    Booking
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {printData !== undefined &&
                    printData.EntryDate !== undefined
                      ? moment(printData.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )
                      : null}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {printData.AdvanceAmount !== undefined
                      ? printData.AdvanceAmount?.toLocaleString()
                      : 0}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {" "}
                    {printData !== undefined &&
                    printData.UnitVoucher?.length > 0
                      ? moment(
                          printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : null}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {printData.AdvanceReceived?.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {(
                      printData.AdvanceAmount - printData.AdvanceReceived
                    ).toLocaleString()}
                  </Text>
                </View>
                {printData?.RescheduledReceivedInstallment && (
                  <View key={id + 1} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? moment(
                            printData.RescheduledReceivedInstallment.DueDate?.split(
                              "T"
                            )[0]
                          ).format("DD-MMM-YYYY")
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                        : 0}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {" "}
                      {printData.RescheduledReceivedInstallment
                        ? moment(
                            printData.RescheduledReceivedInstallment.DueDate?.split(
                              "T"
                            )[0]
                          ).format("DD-MMM-YYYY")
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                        : 0}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {0}
                    </Text>
                  </View>
                )}
                {rowArr}
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                    {printData.RescheduledReceivedInstallment ? id + 3 : id + 2}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    Installment
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.DueAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                      ? moment(
                          row.UnitReceiptDetail[
                            row.UnitReceiptDetail?.length - 1
                          ]?.UnitVoucher?.EntryDate?.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.AdjustmentVoucher
                          ?.EntryDate !== null
                      ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                          "T"
                        )[0]
                      : null}{" "}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row?.ReceivedAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                  </Text>
                </View>
              </Page>
            );
          }

          rowArr = [];
        } else {
          rowArr.push(
            <View key={id} style={tableStyles.tableRow}>
              <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                {printData.RescheduledReceivedInstallment ? id + 3 : id + 2}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                Installment
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row.DueAmount.toLocaleString()}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row.UnitReceiptDetail?.length !== 0 &&
                row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                  ? moment(
                      row.UnitReceiptDetail[
                        row.UnitReceiptDetail?.length - 1
                      ]?.UnitVoucher?.EntryDate?.split("T")[0]
                    ).format("DD-MMM-YYYY")
                  : row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate !==
                      null
                  ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                      "T"
                    )[0]
                  : null}{" "}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row?.ReceivedAmount.toLocaleString()}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
              </Text>
            </View>
          );
        }
      });
    } else {
      arr.push(
        <Page size="LEGAL" style={styles.page}>
          {" "}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.ContractId}
              </Text>
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}>Date. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.EntryDate
                  ? new Date(printData.EntryDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : ""}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.CustomerCnicNavigation?.CustomerName}
              </Text>{" "}
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.CustomerCnicNavigation?.CustomerCnic2}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.Unit?.UnitName}
              </Text>
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}>Size. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined && printData.Unit !== undefined
                  ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                    ? printData.Unit.Marla + "M " + printData.Unit.Sarsai + " S"
                    : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                      "SQFT"
                  : null}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                {printData !== undefined &&
                printData.Unit !== undefined &&
                printData.Unit.RatePerMarlaOrsqft === "MARLA"
                  ? "Rate/Marla."
                  : "Rate/Sqft."}{" "}
              </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.SaleRate !== undefined
                  ? printData?.SaleRate?.toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined && printData?.TotalPrice !== null
                  ? printData?.TotalPrice?.toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined &&
                printData?.TotalPrice !== null &&
                printData?.CustomerReceivable !== null
                  ? (
                      printData?.TotalPrice - printData?.CustomerReceivable
                    ).toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Balance. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined &&
                printData?.CustomerReceivable !== null
                  ? (printData?.CustomerReceivable).toLocaleString()
                  : null}
              </Text>
            </Text>
          </View>
          <View style={{ fontWeight: "bold", marginTop: "9px" }}>
            <Text> Installment Plan</Text>
          </View>
          <View key={1} style={tableStyles.tableRow}>
            <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>Sr</Text>{" "}
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Description{" "}
            </Text>{" "}
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Due Date{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Due Amount{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Paid Date{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Paid Amount{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Balance{" "}
            </Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>Booking</Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData !== undefined && printData.EntryDate !== undefined
                ? moment(printData.EntryDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData.AdvanceAmount !== undefined
                ? printData.AdvanceAmount?.toLocaleString()
                : 0}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {" "}
              {printData !== undefined && printData.UnitVoucher?.length > 0
                ? moment(
                    printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                  ).format("DD-MMM-YYYY")
                : null}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData.AdvanceReceived?.toLocaleString()}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {(
                printData.AdvanceAmount - printData.AdvanceReceived
              ).toLocaleString()}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  paddingBottom: "5px",
                  alignSelf: "center",
                  fontWeight: "extrabold",
                }}
              >
                Buyer{" "}
              </Text>
              <View
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                columnGap: 8,
              }}
            >
              <Text
                style={{
                  paddingBottom: "5px",
                  alignSelf: "center",
                  fontWeight: "extrabold",
                }}
              >
                Project director{" "}
              </Text>
              <View
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Sign:{"    "}</Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        </Page>
      );
    }

    settableRow(arr);
  }, [printData]);

  return (
    <>
      <PDFViewer width={"100%"} height={"800px"}>
        {" "}
        <Document>
          <Page size="LEGAL" style={styles.page}>
            <View style={{ textAlign: "center", fontSize: "14px" }}>
              <Text>IQRAR NAMA</Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "100%",
                gap: 3,
                flexDirection: "row",
                paddingBottom: 1,
              }}
            >
              <Text>Contract</Text>
              <Text
                style={{
                  fontSize: "10px",
                  borderBottom: "1px solid black ",
                }}
              >
                {printData?.ContractId}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                padding: "0",
                gap: "3px",
              }}
            >
              <View
                style={{
                  width: "50%",
                  paddingRight: "5px",
                  padding: "3px",
                  ...styles.section,
                }}
              >
                <View style={{ fontWeight: "bold", padding: "5px 0" }}>
                  <Text>
                    Owner Information{" "}
                    {printData?.ContractJointBuyers?.length > 0
                      ? "(Buyer 1)"
                      : null}
                    :
                  </Text>
                </View>

                <Text style={{ ...styles.infoChild }}>
                  <Text
                    style={{
                      ...styles.labelFont,
                    }}
                  >
                    Name:{"             "}
                  </Text>
                  <Text style={{ ...styles.valueFont }}>
                    {printData?.CustomerCnicNavigation?.CustomerName}
                  </Text>
                </Text>
                <Text style={{ ...styles.infoChild }}>
                  <Text style={styles.labelFont}>Father/Husb:</Text>{" "}
                  <Text style={styles.valueFont}>
                    {printData?.CustomerCnicNavigation?.GuardianName}
                  </Text>
                </Text>
                <View style={styles.infoParent}>
                  <Text style={{ ...styles.infoChild, width: "50%" }}>
                    <Text style={styles.labelFont}>CNIC No.:{"   "}</Text>
                    <Text style={styles.valueFont}>
                      {printData?.CustomerCnicNavigation?.CustomerCnic2}
                    </Text>
                  </Text>
                  <Text style={{ ...styles.infoChild, width: "45%" }}>
                    <Text style={styles.labelFont}>Ph:</Text>
                    <Text style={styles.valueFont}>
                      {printData?.CustomerCnicNavigation?.ContactNumber1}
                    </Text>
                  </Text>
                </View>

                <View style={styles.infoParent}>
                  <View
                    style={{
                      ...styles.infoChild2,
                      display: "flex",
                      width: "83%",
                    }}
                  >
                    <Text style={styles.labelFont}>Address:</Text>

                    <Text style={styles.valueFont}>
                      {printData?.CustomerCnicNavigation.CustomerAddress}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: "50%",
                  paddingRight: "5px",
                  padding: "3px",
                  ...styles.section,
                }}
              >
                <View style={{ fontWeight: "bold", padding: "5px 0" }}>
                  <Text>Nominee Information:</Text>
                </View>
                <Text style={{ ...styles.infoChild }}>
                  <Text style={styles.labelFont}>Name:{"             "}</Text>
                  <Text style={styles.valueFont}>
                    {printData?.Nominee?.NomineeName}
                  </Text>
                </Text>
                <Text style={{ ...styles.infoChild, width: "85%" }}>
                  <Text style={styles.labelFont}>Father/Husb: </Text>
                  <Text style={styles.valueFont}>
                    {printData?.Nominee?.NomineeGuardianName}
                  </Text>{" "}
                </Text>
                <View style={styles.infoParent}>
                  <Text style={{ ...styles.infoChild, width: "50%" }}>
                    <Text style={styles.labelFont}>CNIC No.: </Text>
                    <Text style={styles.valueFont}>
                      {printData?.Nominee?.NomineeCnic}
                    </Text>
                  </Text>
                  <Text style={{ ...styles.infoChild, width: "45%" }}>
                    <Text style={styles.labelFont}>Ph:</Text>
                    <Text style={styles.valueFont}>
                      {printData?.Nominee?.NomineeCellNoOne}
                    </Text>
                  </Text>
                </View>
                <View style={styles.infoParent}>
                  <View style={{ ...styles.infoChild2, width: "83%" }}>
                    <Text style={styles.labelFont}>Address: </Text>
                    <Text style={styles.valueFont}>
                      {printData?.Nominee?.NomineeAddress}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {printData?.ContractJointBuyers?.length > 0 &&
              printData?.ContractJointBuyers.map((buyer, index) => (
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    padding: "0",
                    gap: "3px",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      paddingRight: "5px",
                      padding: "3px",
                      ...styles.section,
                    }}
                  >
                    <View style={{ fontWeight: "bold", padding: "5px 0" }}>
                      <Text>Owner Information (Buyer {index + 2}):</Text>
                    </View>
                    <Text style={{ ...styles.infoChild, width: "85%" }}>
                      <Text
                        style={{
                          ...styles.labelFont,
                        }}
                      >
                        Name:{"             "}
                      </Text>
                      <Text style={{ ...styles.valueFont }}>
                        {buyer?.CustomerCnicNavigation?.CustomerName}
                      </Text>
                    </Text>
                    <Text style={{ ...styles.infoChild, width: "85%" }}>
                      <Text style={styles.labelFont}>Father/Husb:</Text>
                      <Text style={styles.valueFont}>
                        {buyer?.CustomerCnicNavigation?.GuardianName}
                      </Text>{" "}
                    </Text>
                    <View style={styles.infoParent}>
                      <Text style={{ ...styles.infoChild, width: "50%" }}>
                        <Text style={styles.labelFont}>CNIC No.:{"   "}</Text>
                        <Text style={styles.valueFont}>
                          {buyer?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                      <Text style={{ ...styles.infoChild, width: "45%" }}>
                        <Text style={styles.labelFont}>Ph:</Text>
                        <Text style={styles.valueFont}>
                          {buyer?.CustomerCnicNavigation?.ContactNumber1}
                        </Text>
                      </Text>
                    </View>

                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild2, width: "85%" }}>
                        <Text style={styles.labelFont}>Address: </Text>
                        <Text style={styles.valueFont}>
                          {buyer?.CustomerCnicNavigation.CustomerAddress}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingRight: "5px",
                      padding: "3px",
                      ...styles.section,
                    }}
                  >
                    <View style={{ fontWeight: "bold", padding: "5px 0" }}>
                      <Text>Nominee Information:</Text>
                    </View>
                    <Text style={{ ...styles.infoChild }}>
                      <Text style={styles.labelFont}>
                        Name:{"             "}
                      </Text>
                      <Text style={styles.valueFont}>
                        {printData?.SecondNominee?.NomineeName}
                      </Text>
                    </Text>
                    <Text style={{ ...styles.infoChild, width: "85%" }}>
                      <Text style={styles.labelFont}>Father/Husb: </Text>
                      <Text style={styles.valueFont}>
                        {printData?.SecondNominee?.NomineeGuardianName}
                      </Text>{" "}
                    </Text>
                    <View style={styles.infoParent}>
                      <Text style={{ ...styles.infoChild, width: "50%" }}>
                        <Text style={styles.labelFont}>
                          CNIC No.:{"        "}
                        </Text>
                        <Text style={styles.valueFont}>
                          {printData?.SecondNominee?.NomineeCnic}
                        </Text>
                      </Text>
                      <Text style={{ ...styles.infoChild, width: "45%" }}>
                        <Text style={styles.labelFont}>Ph:</Text>
                        <Text style={styles.valueFont}>
                          {printData?.SecondNominee?.NomineeCellNoOne}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild2, width: "83%" }}>
                        <Text style={styles.labelFont}>
                          Address: {"           "}
                        </Text>
                        <Text style={styles.valueFont}>
                          {printData?.SecondNominee?.NomineeAddress}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ))}

            <View
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                padding: "0",
                gap: "3px",
              }}
            >
              <View
                style={{
                  width: "50%",
                  paddingRight: "5px",

                  padding: "3px",

                  ...styles.section,
                }}
              >
                <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                  <Text>Property Information:</Text>
                </View>
                <View style={styles.infoParent}>
                  <View style={{ ...styles.infoChild2, width: "100%" }}>
                    <Text style={styles.labelFont}>Name: </Text>
                    <Text style={styles.valueFont}>
                      {localStorage.getItem("ProjectName") === "TGIC"
                        ? "The Grand Iconic City"
                        : localStorage.getItem("ProjectName")}
                    </Text>
                  </View>{" "}
                </View>
                <View style={styles.infoParent}>
                  <View style={{ ...styles.infoChild2, width: "85%" }}>
                    <Text style={styles.labelFont}>Address:</Text>
                    <Text style={styles.valueFont}>
                      {printData?.Project?.ProjectLocation}
                    </Text>
                  </View>{" "}
                </View>
                <View style={styles.infoParent}>
                  <View style={{ ...styles.infoChild2, width: "100%" }}>
                    <Text style={styles.labelFont}>kh #:{"     "}</Text>
                    <Text style={styles.valueFont}>
                      {/* {printData?.Unit.LandPurchase?.KhewatNumber} */}
                      1010
                    </Text>
                  </View>{" "}
                </View>
                <View style={styles.infoParent}>
                  <View style={{ ...styles.infoChild2, width: "100%" }}>
                    <Text style={styles.labelFont}>Sq #:{"     "}</Text>
                    <Text style={styles.valueFont}>
                      {/* {printData?.Unit?.LandPurchase?.SqaNumber} */}
                      12
                    </Text>
                  </View>{" "}
                </View>
              </View>
              <View
                style={{
                  width: "50%",
                  padding: "3px",
                  ...styles.section,
                  flexWrap: "wrap",
                }}
              >
                <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                  <Text>Unit Information:</Text>
                </View>
                <View style={styles.infoParent}>
                  <View style={{ ...styles.infoChild2, width: "45%" }}>
                    <Text style={styles.labelFont}>S/Date:</Text>
                    <Text style={styles.valueFont}>
                      {printData !== undefined &&
                      printData?.EntryDate !== undefined
                        ? moment(printData?.EntryDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                        : null}
                    </Text>
                  </View>{" "}
                  <View style={{ ...styles.infoChild2, width: "45%" }}>
                    <Text style={styles.labelFont}>Res.:</Text>
                    <Text style={styles.valueFont}>
                      {printData !== undefined &&
                      printData.Unit?.Section !== undefined &&
                      printData.Unit?.Section?.Category.CategoryName !==
                        "COMMERCIAL" ? (
                        // <input
                        //   type="checkbox"
                        //   checked
                        //   style={{ width: "20px", height: "20px" }}
                        //   className=" ml-2"
                        // ></input>
                        <Image
                          src={check}
                          style={{
                            position: "absolute",
                            top: 1,
                            left: 2,
                            width: 8,
                            height: 8,
                          }}
                        />
                      ) : null}
                    </Text>
                    <Text style={styles.labelFont}>Comm.:</Text>
                    <Text style={styles.valueFont}>
                      {printData !== undefined &&
                      printData.Unit?.Section !== undefined &&
                      printData.Unit?.Section?.Category.CategoryName ===
                        "COMMERCIAL" ? (
                        // <input
                        //   type="checkbox"
                        //   checked
                        //   style={{ width: "20px", height: "20px" }}
                        //   className=" ml-2"
                        // ></input>
                        <Image src={check} />
                      ) : null}
                    </Text>
                  </View>{" "}
                </View>
                <View
                  style={{
                    ...styles.infoParent,
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ ...styles.infoChild2, width: "61%" }}>
                    <Text style={styles.labelFont}>Unit No:</Text>
                    <Text style={styles.valueFont}>
                      {printData?.Unit?.UnitName}
                    </Text>
                  </View>{" "}
                  <View style={{ ...styles.infoChild2, width: "29%" }}>
                    <Text style={styles.labelFont}>Size:</Text>
                    <Text style={styles.valueFont}>
                    {printData !== undefined && printData.Unit !== undefined
                          ? printData.Unit?.RatePerMarlaOrsqft == "MARLA"
                            ? printData.Unit.NetMarla + " M"
                            : printData.Unit?.NetSqft?.toFixed(
                                2
                              ).toLocaleString() + "SQFT"
                          : null}
                    </Text>
                  </View>{" "}
                </View>

                <View style={styles.infoParent}>
                  <View style={styles.infoChild2}>
                    <Text style={styles.labelFont}>
                      {printData !== undefined &&
                      printData?.Unit !== undefined &&
                      printData?.Unit?.RatePerMarlaOrsqft === "MARLA"
                        ? "Per Marla:"
                        : "Per Sqft:"}
                    </Text>
                    <Text style={styles.valueFont}>
                      {printData !== undefined
                        ? printData?.SaleRate?.toLocaleString()
                        : null}
                    </Text>
                  </View>{" "}
                </View>
                <View style={styles.infoParent}>
                  <View style={styles.infoChild2}>
                    <Text style={styles.labelFont}>Total Value:</Text>
                    <Text style={styles.valueFont}>
                      {printData?.TotalPrice?.toLocaleString()}
                    </Text>
                  </View>{" "}
                </View>
              </View>
            </View>
            <View>
              <View style={{ fontWeight: "bold", paddingBottom: "3px" }}>
                <Text>Declaration:</Text>
              </View>
              <View style={styles.labelFont}>
                <Text style={styles.linePadding}>
                  I/We do hereby confirm that I/We;
                </Text>
                <Text>
                  {" "}
                  a) have read and understood the terms and conditions and do
                  hereby agree to abide by the same
                </Text>
                <Text style={styles.linePadding}>
                  {" "}
                  b) have read and understood the terms and conditions and do
                  hereby agree to abide by the same.I/We do hereby further
                  confirm that I/We shall abide by the existing and any future
                  terms and conditions laid down by the Company,{" "}
                  <Text style={{ fontSize: "12px" }}>
                    " IGA Developers (Pvt.) Limited"
                  </Text>
                  .
                </Text>
                <Text style={styles.linePadding}>
                  {" "}
                  c) I/We do hereby further confirm that I/We shall pay the
                  charges / fee as may be decided by the Company for Common and
                  General services of the Society{" "}
                  <Text style={{ fontSize: "12px" }}>
                    "
                    {localStorage.getItem("ProjectName") === "TGIC"
                      ? "The Grand Iconic City"
                      : localStorage.getItem("ProjectName")}
                    "
                  </Text>
                  .
                </Text>
              </View>
            </View>
            {/* <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          > */}
            <View style={{ marginTop: "20px", width: "100%" }}>
              <View
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "100%" }}>
                  <Text style={{ alignSelf: "center", marginBottom: "5px" }}>
                    Buyer:
                  </Text>
                  {printData?.CustomerCnicNavigation?.Image && (
                    <View
                      style={{
                        width: "100%",
                        alignSelf: "center",
                        paddingRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          width: "80px",
                          height: "80px",
                        }}
                        src={{
                          uri: printData?.CustomerCnicNavigation?.Image,
                          method: "GET",
                          headers: { "Cache-Control": "no-cache" },
                        }}
                      />
                    </View>
                  )}
                  <View
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                      paddingTop: "15px",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                    <Text
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        borderBottom: "1px solid black",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "10px",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Thumb.</Text>
                    <Text
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        borderBottom: "1px solid black",
                      }}
                    ></Text>
                  </View>
                </View>

                {printData?.ContractJointBuyers?.length > 0 ? (
                  <>
                    <View style={{ width: "100%" }}>
                      <Text
                        style={{
                          alignSelf: "center",
                          marginBottom: "5px",
                        }}
                      >
                        Buyer 2:
                      </Text>
                      {printData?.ContractJointBuyers[0]?.CustomerCnicNavigation
                        ?.Image && (
                        <View
                          style={{
                            width: "100%",
                            alignSelf: "center",
                            paddingRight: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            style={{
                              width: "80px",
                              height: "80px",
                            }}
                            src={{
                              uri: printData?.ContractJointBuyers[0]
                                ?.CustomerCnicNavigation?.Image,
                              method: "GET",
                              headers: { "Cache-Control": "no-cache" },
                            }}
                          />
                        </View>
                      )}
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                          paddingTop: "15px",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb.</Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </>
                ) : null}
                <View style={{ width: "100%" }}>
                  <View style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "extrabold",
                      }}
                    >
                      Project Director
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                  ></View>
                  <View
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                    <Text
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        borderBottom: "1px solid black",
                      }}
                    ></Text>
                  </View>
                  {/* <View
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "5px",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    padding: "5px",
                    paddingBottom: "10px",
                  }}
                >
                  <Text style={{ fontWeight: "extrabold" }}>Thumb.</Text>
                  <Text
                    style={{
                      minWidth: "100px",
                      maxWidth: "100px",
                      borderBottom: "1px solid black",
                    }}
                  ></Text>
                </View> */}
                </View>
              </View>
            </View>
            {/* </View> */}
          </Page>
          <Page size="LEGAL" style={styles.page2}>
            <View>
              <View
                style={[
                  styles.labelFontTermsHead,
                  { fontWeight: "bold", paddingBottom: "5px" },
                ]}
              >
                <Text>Terms And Conditions</Text>
              </View>
              <View style={styles.labelFontTerms}>
                <Text style={styles.linePadding}>
                  Developer and the Purchaser are hereby agreed upon following
                  terms and conditions:
                </Text>
                <Text style={styles.linePadding}>
                  Hereby IGA Developer (Pvt.) Ltd. will be referred as “The
                  Developer” having its site office at The Grand Iconic City,
                  236 RB, Malkhanwala, Main Ring Road, Faisalabad, and;
                </Text>
                <Text style={styles.linePadding}>
                  The Purchaser of single unit will be referred as “applicant(s)
                  / buyer(s)”
                </Text>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>1. </Text>
                  <Text style={styles.content}>
                    Applicant(s) / buyer(s) agrees to the terms and conditions
                    set forth in this document and all other rules and by-laws
                    established so as to regulate itself, by the developer in
                    this document and rules and regulations governing allotment,
                    possession, ownership, construction and transfer of
                    plot/unit by any other authority, department competent to do
                    so in accordance with applicable laws.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>2. </Text>
                  <Text style={styles.content}>
                    Applicant/Purchaser agrees that he / she / they shall not
                    interfere in any decision of the Developer in any manner
                    whatsoever regarding usage of open spaces / common areas of
                    the project outside the walls / doors of the client’s booked
                    property including the utilization / usage of the rooftop
                    (In case Apartments project) of the project for the purpose
                    / intention to discretion of the Developer.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>3.{"  "}</Text>
                  <Text style={styles.content}>
                    The applicant(s) / buyer(s) understand(s), undertake(s),
                    acknowledge(s) and agree(s) that the Developer has exclusive
                    right at its own discretion or on any public authority’s
                    discretion may change, vary or modify the plans, features
                    list, unit/plot/shop number, designs shown and layouts shown
                    without notice or consent of the buyer(s). The buyer(s)
                    hereby agree(s) to accept those changes. As a result of
                    change in design of the Project, proportionate change will
                    be made in payment schedule, if any.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>4. </Text>
                  <Text style={styles.content}>
                    The Developer reserves the right to further extend the
                    “Project” and may utilize all the amenities wholly or
                    partially for further extensions or for uplifting standard
                    of living for its residents.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>5. </Text>
                  <Text style={styles.content}>
                    Each residential and commercial unit will have separate
                    booking form and File in later stages, so Applicant/Buyer
                    who intended to get more than one unit should submit same
                    numbers of booking forms. One booking form can only be used
                    for the booking of a single unit whether it is commercial or
                    residential.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>6. </Text>
                  <Text style={styles.content}>
                    No applicant(s) / buyer(s) shall be entitled to any Mark up
                    or interest on the amount paid to The Developer, in any
                    case.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>7.{"  "}</Text>
                  <Text style={styles.content}>
                    The exact size and location of the plot will remain
                    tentative and subject to adjustment in accordance with
                    demarcation measurement of the plot at time of handing over
                    of possession.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>8. </Text>
                  <Text style={styles.content}>
                    In case of extra area (over and above the allotted area)
                    with any plot, proportionate extra amount will be charged in
                    addition to the total amount likewise, in case of less area,
                    the proportionate amount will be adjusted accordingly.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>9. </Text>
                  <Text style={styles.content}>
                    All payments are to be made according to the category size
                    of the property, as per schedule of payment in favour of IGA
                    Developers (Pvt.) Ltd. (the Developer) in form of cash,
                    cheque, bank draft or bank online transfer in the designated
                    bank account of the IGA Developers along with the duly
                    filled booking form.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>10.{"  "}</Text>
                  <Text style={styles.content}>
                    The tentative timeline for the completion and delivery of
                    the booked unit in the project by the Developer to buyer(s)
                    is agreed in Three and half years approximately with a
                    nominal grace period from the date the buyer(s) has made 25%
                    down payment/ or from the issuance of File from IGA
                    Developer (Pvt.) Ltd. except for the condition which is
                    beyond the control of the Developer including but not
                    limited to, all natural delays, acts of God delays due to
                    Government & Semi Government activities, forceful
                    acquisition of land, riots, labour strikes and events of
                    Force Majeure.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>11.{"  "}</Text>
                  <Text style={styles.content}>
                    The applicant(s) / buyer(s) shall be entitled to physical
                    possession of the plot to start construction activities
                    after the lapse of three and half year and subject to 100%
                    payment of the sales price and other charges, if any. And as
                    well as after getting sanctioned/ approved site / building
                    plan from the Developer.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>12.{"  "}</Text>
                  <Text style={styles.content}>
                    The applicant(s) / buyer(s) undertake(s) that being the
                    owner of residential and / or commercial units after taking
                    over possession and construction thereon shall pay monthly
                    maintenance charges to be fixed by the Developer from time
                    to time subject to notice to the applicant/buyer(s).
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>13.{"  "}</Text>
                  <Text style={styles.content}>
                    It is mandatory for owner of every unit (i.e. commercial or
                    residential) to provide a copy of the rental agreement to
                    the Developer every time the unit is leased or rented out to
                    any person.
                  </Text>
                </View>
              </View>
            </View>
          </Page>
          <Page size="LEGAL" style={styles.page2}>
            <View>
              <View style={styles.labelFontTerms}>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>14.{"  "}</Text>
                  <Text style={styles.content}>
                    A property allotted to an applicant(s)/ buyer(s) shall not
                    be used by the buyer(s) for any purpose other than that
                    applied or meant for.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>15.{"  "}</Text>
                  <Text style={styles.content}>
                    In the event the applicant(s) / buyer(s) opted to proceed
                    with any illegal construction and / or development or make
                    unapproved improvements without prior written approval of
                    the Developer, the Developer shall at its sole discretion
                    demolish illegal construction / improvements / structure at
                    the risk and cost of the buyer(s).
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>16. </Text>
                  <Text style={styles.content}>
                    The applicant(s) / buyer(s) shall be responsible to pay all
                    the charges incurred on the utilities connection which are
                    not included in the sale price of the unit i.e. WASA, SNGPL,
                    FESCO, PTCL etc.{" "}
                  </Text>
                </View>

                <View style={styles.listItem}>
                  <Text style={styles.bullet}>17.{"  "}</Text>
                  <Text style={styles.content}>
                    The Developer undertakes to intimate the buyer(s) to take
                    possession of the unit within 15 days once it is ready for
                    possession, and if client fails to take such possession of
                    the unit within three months from serving such notice, the
                    client shall be liable to pay PKR 3 per square feet per
                    month as maintenance charges. These charges will be payable
                    on 1st of every month and may be revised from time to time
                    and the same will be accepted by unit owner.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>18.{"  "}</Text>
                  <Text style={styles.content}>
                    That escalation in prices will not be claimed by the
                    Developer except due to force majeure e.g. war, riots and
                    economic instability of the country, devaluation of rupee
                    resulting in more than 20 % inflation per annum (if annual
                    inflation continues at a rate of 20% from the date of
                    issuance of unit file) in which case the Developer shall
                    charge the cost of escalation proportional to the rates of
                    inflation.
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.labelFontTermsHead,
                  { fontWeight: "bold", paddingBottom: "5px" },
                ]}
              >
                <Text>Instalments, Penalties & Cancellation Policy:</Text>
              </View>
              <View style={styles.labelFontTerms}>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>19.{"  "}</Text>
                  <Text style={styles.content}>
                    The applicant(s) / buyer(s) is liable to pay the instalments
                    on or before the due date.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>20.{"  "}</Text>
                  <Text style={styles.content}>
                    Instalments received after the due date from the
                    applicant(s) / buyer(s) will only be accepted with surcharge
                    @ 1.5% per month (which will be taken as @ 0.05% daily)
                    outstanding amount form the defaulted date. Provided that if
                    any applicant(s) / buyer(s) fails to pay three successive
                    instalments within the prescribed period, the allotment is
                    liable to be cancelled, in the event of cancellation of the
                    property by the Developer, the submitted payment will be
                    refunded within 6 months with 25% deduction, without any
                    profit, interest or mark-up.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>21.{"  "}</Text>
                  <Text style={styles.content}>
                    The Developer reserves the right to allot / sell a property
                    cancelled from the name of the applicant(s) / buyer(s) due
                    to non-payment of dues, or any reason whatsoever, to any
                    other applicant or person and the ex-buyer(s) shall have no
                    right to such a property. The Developer’ decision in this
                    regard shall be final. Any dispute shall be resolved through
                    arbitration for which purchaser agreed upon the arbitrator
                    appointed by the Developer.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>22.{"  "}</Text>
                  <Text style={styles.content}>
                    The cancelled unit(s) can only be restored (if not already
                    resold) with the sole discretion of the Developer subject to
                    full payment of due instalments and late fee payment /
                    charges outstanding to-date for the entire period of the
                    default
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>23.{"  "}</Text>
                  <Text style={styles.content}>
                    A property once allotted or transferred cannot be
                    surrendered or applied for cancellation by the applicant and
                    all amounts paid on account thereof shall be NON-REFENDABLE.
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.labelFontTermsHead,
                  { fontWeight: "bold", paddingBottom: "5px" },
                ]}
              >
                <Text>File Transfer & Charges:</Text>
              </View>
              <View style={styles.labelFontTerms}>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>24.{"  "}</Text>
                  <Text style={styles.content}>
                    Possession and / or transfer letter of any unit (i.e.
                    commercial or residential) will be subject to settlement of
                    any pending dues the Developer (due to any variation of the
                    size of the unit) at the time of possession. The
                    applicant(s) / buyer(s) agree(s) to accept the changes in
                    actual size of unit and allow the Developer to adjust the
                    resulting charges accordingly at the time of final
                    measurement (“tateema") (i.e.: time of possession).
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>25.{"  "}</Text>
                  <Text style={styles.content}>
                    25. Property transfer charges will be borne by the
                    applicant(s) / buyer(s) as suggested by the legal advisor.
                    However, file transfer charges from one buyer(s) to the
                    other will be PKR 5,000/- per Marla and may vary at the
                    discretion of the Developer.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>26.{"  "}</Text>
                  <Text style={styles.content}>
                    The transfer of the file from one buyer to the other will be
                    preceded only on receipt of all the pending dues, late
                    payment charges and any other charges, if any.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>27.{"  "}</Text>
                  <Text style={styles.content}>
                    Both transferor and transferee must be personally present in
                    the site office of the Project or office designated for
                    transfer of files. Power of attorney will be required in
                    case the transferor or transferee are not personally
                    available in the Country.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>28.{"  "}</Text>
                  <Text style={styles.content}>
                    A No Objection Certificate (NOC) will be issued by the
                    Developer allowing transferor to transfer the units
                    accordingly. No unit will be transferred without an NOC.
                  </Text>
                </View>
              </View>
            </View>
          </Page>
          <Page size="LEGAL" style={styles.page2}>
            <View>
              <View style={styles.labelFontTerms}>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>29.{"  "}</Text>
                  <Text style={styles.content}>
                    The applicant(s) / buyer(s) shall be liable to pay all taxes
                    in respect of plot including but not limited to wealth tax,
                    excise tax, luxury tax, plot tax etc. along with any other
                    taxes levied, from time to time, by concerned Government or
                    building Authorities.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>30.{"  "}</Text>
                  <Text style={styles.content}>
                    All developments charges are included in this cost.
                    Provision of utility & service charges shall be obtained
                    later along with the possession charges which are 25,000/-
                    per Marla.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>31.{"  "}</Text>
                  <Text style={styles.content}>
                    An event of Force Majeure, as defined in THE CONTRACT ACT,
                    1872 shall have the same effect in relation to this
                    agreement.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>32.{"  "} </Text>
                  <Text style={styles.content}>
                    Partial invalidity: If any term of this agreement shall be
                    invalid or unenforceable, the remainder shall be interpreted
                    and governed by the laws of Pakistan.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>33.{"  "} </Text>
                  <Text style={styles.content}>
                    Governing Law:This agreement shall be interpreted and
                    governed by the laws of Pakistan.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>34.{"  "} </Text>
                  <Text style={styles.content}>
                    Entire Agreement: This document contains the entire
                    agreement of the parties and supersedes all previous
                    communications, representations and agreements, whether oral
                    or written, with respect to the allotment. This agreement
                    may not be modified except in writing signed and
                    acknowledged by both parties.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>35.{"  "} </Text>
                  <Text style={styles.content}>
                    Heirs and Assignees: This agreement shall be binding on the
                    heirs, assignees and successors in the interest of the
                    Developer and Purchaser/applicant.
                  </Text>
                </View>{" "}
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>36.{"  "} </Text>
                  <Text style={styles.content}>
                    Arbitration: Any dispute arising out of or in connection
                    with this agreement shall be resolved as provided in dispute
                    resolution of Arbitration Act, 1940. And the Purchaser
                    agrees with the assigned arbitrator of IGA Developer (Pvt.)
                    Ltd.
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.labelFontTermsHead,
                  { fontWeight: "bold", paddingBottom: "5px" },
                ]}
              >
                <Text>Change of Address:</Text>
              </View>

              <View style={styles.labelFontTerms}>
                <View style={styles.listItem}>
                  <Text style={styles.bullet}>37.{"  "}</Text>
                  <Text style={styles.content}>
                    All the correspondence will be dispatched to the address of
                    the applicant(s) / buyer(s) given in the application form
                    and shall be deemed to be duly received and served up on the
                    applicant(s) / buyer(s). The Developer shall not be
                    responsible for non-delivery of communication if change of
                    address is not notified by the applicant/buyer(s) in advance
                    or for the postal mishap.
                  </Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.content}>
                    The undersigned has hereby read, understood and agrees with
                    the terms and conditions written above.
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      paddingBottom: "5px",
                      alignSelf: "center",
                      fontWeight: "extrabold",
                    }}
                  >
                    Buyer 1:{" "}
                  </Text>
                  <View
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Name:</Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>CNIC #:</Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                </View>
                {printData?.ContractJointBuyers?.length > 0 && (
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        paddingBottom: "5px",
                        alignSelf: "center",
                        fontWeight: "extrabold",
                      }}
                    >
                      Buyer 2:{" "}
                    </Text>
                    <View
                      style={{
                        paddingBottom: "5px",
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "center",
                      }}
                    >
                      <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                      <Text
                        style={{
                          minWidth: "150px",
                          maxWidth: "150px",
                          borderBottom: "1px solid black",
                          paddingBottom: "10px",
                        }}
                      ></Text>
                    </View>
                    <View
                      style={{
                        paddingTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "center",
                      }}
                    >
                      <Text style={{ fontWeight: "extrabold" }}>Name:</Text>
                      <Text
                        style={{
                          minWidth: "150px",
                          maxWidth: "150px",
                          borderBottom: "1px solid black",
                          paddingBottom: "10px",
                        }}
                      ></Text>
                    </View>
                    <View
                      style={{
                        paddingTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "center",
                      }}
                    >
                      <Text style={{ fontWeight: "extrabold" }}>CNIC #:</Text>
                      <Text
                        style={{
                          minWidth: "150px",
                          maxWidth: "150px",
                          borderBottom: "1px solid black",
                          paddingBottom: "10px",
                        }}
                      ></Text>
                    </View>
                  </View>
                )}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      paddingBottom: "5px",
                      alignSelf: "center",
                      fontWeight: "extrabold",
                    }}
                  >
                    Witness 1:{" "}
                  </Text>
                  <View
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Name:</Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>CNIC #:</Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      paddingBottom: "5px",
                      alignSelf: "center",
                      fontWeight: "extrabold",
                    }}
                  >
                    Witness 2:{" "}
                  </Text>
                  <View
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>Name:</Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Text style={{ fontWeight: "extrabold" }}>CNIC #:</Text>
                    <Text
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        borderBottom: "1px solid black",
                        paddingBottom: "10px",
                      }}
                    ></Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
          {tableRows}

          {/* {lastRows > 13 && (
                <Page size="LEGAL" style={styles.page}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Buyer{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 4,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Project director{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>
                          Sign:{"    "}
                        </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>
                          CNIC:{"  "}{" "}
                        </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>{" "}
                </Page>
              )} */}
        </Document>
      </PDFViewer>
      <button
        style={{ color: "black !important", marginBottom: "20px" }}
        className="print_btn float-left"
        onClick={handleClick}
      >
        {"< Go Back"}
      </button>
    </>
  );
}

export default ContractNewPrintTgic;
