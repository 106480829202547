import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getProjectsReport } from "../../../actions/AccountReports/projectsreport";
import ProjectsReportPrint from "../ReportPrints/ProjectReportPrint";

const ProjectReport = () => {
  const resultsgetaccpay = useSelector((state) => state.GetProjectsReportRed);
  const [filterdata, setfilterdata] = useState([]);

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  const dispatch = useDispatch();

  const onTextChange = (e) => {
    if (
      document.getElementById("statusfilter").value === "" &&
      document.getElementById("unitfilter").value === "" &&
      document.getElementById("dimenstionfilter").value === ""
    ) {
      setfilterdata(resultsgetaccpay.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("statusfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("dimenstionfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );

        suggestions = resultsgetaccpay.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("statusfilter").value === "" ||
                regex.test(val.Status)) &&
              (document.getElementById("dimenstionfilter").value === "" ||
                regex1.test(val.DimensionLength + "x" + val.DimensionWidth)) &&
              (document.getElementById("unitfilter").value === "" ||
                regex2.test(val.UnitName))
          );
      }
      setfilterdata(suggestions);
    }
  };
  useEffect(() => {
    dispatch(getProjectsReport());
  }, [dispatch]);
  useEffect(() => {
    setfilterdata(resultsgetaccpay.data);
  }, [resultsgetaccpay]);

  let TotalValue = 0;
  let TotalDownPayment = 0;
  let TotalInstallments = 0;
  let TotalReceived = 0;

  if (filterdata !== undefined) {
    filterdata.map((val, index) => {
      TotalValue += parseFloat(val.Price);
      TotalDownPayment +=
        val.Contract !== null &&
          val.Contract.AdvanceReceived !== undefined &&
          val.Contract.AdvanceReceived !== null
          ? val.Contract.AdvanceReceived
          : 0;
      TotalInstallments +=
        val.Contract !== null &&
          val.Contract.InstallmentsReceived !== undefined &&
          val.Contract.InstallmentsReceived !== null
          ? val.Contract.InstallmentsReceived
          : 0;
      TotalReceived +=
        val.Contract !== null &&
          val.Contract.CustomerReceivable !== undefined &&
          val.Contract.CustomerReceivable !== null
          ? val.Contract.CustomerReceivable
          : 0;
    });
  }
  return (
    <>
      <div hidden>
        <ProjectsReportPrint
          ref={componentRef}
          // Total={Total}
          printData={filterdata !== undefined ? filterdata : null}
          TotalReceived={TotalReceived}
          TotalInstallments={TotalInstallments}
          TotalDownPayment={TotalDownPayment}
          TotalValue={TotalValue}
        />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="unitfilter"
                onChange={onTextChange}
                placeholder="Unit"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="dimenstionfilter"
                onChange={onTextChange}
                placeholder="Dimenstion"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="statusfilter"
                onChange={onTextChange}
                placeholder="Status"
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {resultsgetaccpay.loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive vouchers_table_height2 mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>

                    <th scope="col">Unit</th>
                    <th scope="col">Size</th>

                    <th scope="col">Rate</th>
                    <th scope="col">Total Value</th>

                    <th
                      scope="col"
                      className="text-center"
                      colSpan="2"
                    >
                      Received
                    </th>

                    {/* <th scope="col">Installments</th> */}
                    <th scope="col">Receivable</th>
                    <th scope="col">Status</th>

                    <th scope="col">Reference Sale Person</th>
                  </tr>
                  <tr>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col">Down Payment</th>

                    <th scope="col">Installments</th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((x, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{x.UnitName}</td>
                        <td>
                          {x.RatePerMarlaOrsqf === "MARLA"
                            ? x.NetMarla + "M"
                            : x.NetSqft + "Sqft"}
                        </td>
                        <td>{x.RatePerMarla.toLocaleString()}</td>
                        <td>{x.Price.toLocaleString()}</td>

                        <td>
                          {x.Contract !== null &&
                            x.Contract.AdvanceReceived !== undefined &&
                            x.Contract.AdvanceReceived !== null
                            ? x.Contract.AdvanceReceived.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {x.Contract !== null &&
                            x.Contract.InstallmentsReceived !== undefined &&
                            x.Contract.InstallmentsReceived !== null
                            ? x.Contract.InstallmentsReceived.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {x.Contract !== null &&
                            x.Contract.CustomerReceivable !== undefined &&
                            x.Contract.CustomerReceivable !== null
                            ? x.Contract.CustomerReceivable.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {x.Contract !== null &&
                            x.Contract.ContractStatus !== undefined &&
                            x.Contract.ContractStatus !== null
                            ? x.Contract.ContractStatus.toLocaleString()
                            : null}
                        </td>

                        <td>
                          {x.Contract !== null && x.Contract !== undefined
                            ? x.Contract.Reference
                            : null}
                        </td>
                      </tr>
                    ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>
                    <td
                      colSpan="2"
                      className="text-center"
                    >
                      {" "}
                      <b> Grand Total</b>
                    </td>

                    <td>
                      <b>{TotalValue.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalDownPayment.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalInstallments.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalReceived.toLocaleString()}</b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectReport;
