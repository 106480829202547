import React from "react";
import { useSelector } from "react-redux";
function PrintHeader() {
  const companyInfo =  useSelector((state) => state.getCompany);
let projectName = process.env.REACT_APP_PROJECT_NAME;
  return projectName === "gravity" ? (
    <>
      <div className="watermarked">
        <img
          src={companyInfo?.data?.Logo}
          style={{ height: "600px", width: "600px" }}
          alt="Company logo"
        />
      </div>

      <div className="col-8  mt-4">
        <h2 style={{ color: "#1b7a7f" }}>{companyInfo?.data?.CompanyName}</h2>
        <hr />
        <h6 style={{ color: "grey", marginTop: "5px" }}>
          <i>{companyInfo?.data?.Address}.</i>
        </h6>
        <div
          style={{
            borderTop: "2px dotted black",
            borderBottom: "2px dotted black",
            height: "7px",
          }}
        ></div>
      </div>

      <div className="col-4 text-center">
        <img
          src={companyInfo?.data?.Logo}
          style={{ height: "175px", width: "175px", borderRadius: "100px" }}
          className="pt-2"
          alt="Company logo"
        />
      </div>
    </>
  ) : projectName === "tgic" ? (
    <>
      <div style={{ height: "3in", minHeight: "3in", maxHeight: "3in" }}></div>
    </>
  ) : null;
}

export default PrintHeader;
