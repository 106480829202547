import React, { useState, useEffect } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallEmployees } from "../../actions/HRactions/getemployees";
import { getallProject } from "../../actions/getprojects";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { getUsersData } from "../../actions/Users/GetUsers";
import { getallRolesTable } from "../../actions/getroles";

const UserForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [screenLoader, setscreenLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [roles, setRoles] = useState("");
  const [projects, setProjects] = useState([]);
  const [access, setAccess] = useState("");

  const [selectAll, setSelectAll] = useState(false);
  const [projectNames, setProjectNames] = useState("");

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);

  const resultallproject = useSelector((state) => state.getProject);
  const RolesData = useSelector((state) => state.GetAllRoleTable.data.Data);
  const loading = useSelector((state) => state.GetAllRoleTable.loading);

  const location = useLocation();
  const rowData = location.state;

  useEffect(() => {
    dispatch(getallEmployees());
    dispatch(getallProject());
    dispatch(getallRolesTable());
  }, [dispatch]);

  const postUser = (e) => {
    if (name === "") {
      toast.info("Please Enter Name");
      return;
    }
    if (email === "") {
      toast.info("Please Enter Email");
      return;
    }
    if (password === "") {
      toast.info("Please Enter Password ");
      return;
    }
    if (confirmpassword === "") {
      toast.info("Please Enter Password");
      return;
    }

    if (password !== confirmpassword) {
      toast.info("Confirm Password Must Be same as password");
      return;
    }

    if (roles === "") {
      toast.info("Please Select Role");
      return;
    }

    if (access === "") {
      toast.info("Please Select Access ");
      return;
    }
    setscreenLoader(true);
    let arr = projects.map((projectId) => ({
      projectId: Number(projectId),
    }));
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Auth/RegisterUser`,
      data: JSON.stringify({
        fullName: name,
        email: email,
        password,
        password,
        roleId: roles,
        access: access,
        confirmPassword: confirmpassword,
        projects: arr,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setscreenLoader(false);
          toast.info(result.data.Message);
          dispatch(getUsersData());
          setAccess("");
          setName("");
          setPassword("");
          setConfirmPassword("");
          setProjects([]);
          setProjectNames("");
          setRoles("");
          setEmail("");
        } else {
          toast.info(result.data.Message);
          setscreenLoader(false);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
        setscreenLoader(false);
      }
    );
  };

  const openModel = () => {
    setOpen(true);
  };
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allProjectIds = resultallproject.data.map((x) => x.ProjectId);
      setProjects(allProjectIds);
    } else {
      setProjects([]);
    }
  };
  const handleCheckboxChange = (id) => {
    if (projects.includes(id)) {
      setProjects(projects.filter((projectId) => projectId !== id));
    } else {
      setProjects([...projects, id]);
    }
  };

  useEffect(() => {
    if (resultallproject.data && projects.length > 0) {
      const selectedNames = projects.map((projectId) => {
        const selectedProject = resultallproject.data.find(
          (project) => project.ProjectId === projectId
        );
        return selectedProject ? selectedProject.ProjectName : "";
      });
      setProjectNames(selectedNames.join(", "));
    }
  }, [projects]);

  useEffect(() => {
    if (rowData) {
      setShow(false);
      setName(rowData?.FullName);
      setEmail(rowData?.Email);
      setAccess(rowData?.Access);
      if (RolesData && rowData) {
        const selectedId = RolesData?.find(
          (role) => role?.RoleId === rowData?.RoleId
        );
        if (selectedId) {
          setRoles(selectedId?.RoleId);
        }
      }
      if (rowData && rowData.Projects) {
        const ProjectNameFill = rowData.Projects.join(", ");
        setProjectNames(ProjectNameFill);
      }

      if (resultallproject.data && rowData) {
        const selectedProjectIds = resultallproject.data
          .filter((project) => rowData.Projects?.includes(project?.ProjectName))
          .map((project) => project.ProjectId);
        setProjects(selectedProjectIds);
      }
    }
  }, [rowData, resultallproject, RolesData]);

  const updateUser = (e) => {
    if (name === "") {
      toast.info("Please Enter Name");
      return;
    }

    if (roles === "") {
      toast.info("Please Select Role");
      return;
    }

    if (access === "") {
      toast.info("Please Select Access ");
      return;
    }
    setscreenLoader(true);
    let arr = projects.map((projectId) => ({
      projectId: Number(projectId),
    }));
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}Auth/UpdateUserInfo`,
      data: JSON.stringify({
        userId: rowData?.Id,
        fullName: name,
        roleId: roles,
        access: access,
        projects: arr,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setscreenLoader(false);
          toast.info(result.data.Message);
          dispatch(getUsersData());
          setAccess("");
          setName("");
          setProjects([]);
          setRoles("");
          setEmail("");
          history.goBack();
        } else {
          toast.info(result.data.Message);
          setscreenLoader(false);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
        setscreenLoader(false);
      }
    );
  };

  return (
    <>
      <div className="col-md-12  text-left page_heading">
        <h4 className="m-0">Add Users</h4>
        {loading === true ? (
          <Loader
            type="ThreeDots"
            color="green"
            height={40}
            width={40}
          />
        ) : null}
      </div>
      {/* <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          {loading===true ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div> */}
      <div className="bg-white">
        <div className="row ">
          <div className="col-md-2 mt-md-4   p-0">
            <label className="input_label m-0">Name</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <>
              <input
                name="Name"
                value={name}
                className="input_styling"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </>
          </div>
          <div className="col-md-2 mt-md-3 "></div>

          <div className="col-md-2 mt-md-4   p-0">
            <label className="input_label m-0">Email</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <>
              <input
                name="Email"
                disabled={rowData?.Email}
                value={email}
                className="input_styling"
                pattern="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </>
          </div>
        </div>
        {show && (
          <div className="row">
            <div className="col-md-2 mt-md-4   p-0">
              <label className="input_label m-0">Password</label>
            </div>
            <div className="col-md-3 mt-md-4   p-0">
              <>
                <input
                  type="password"
                  name="Password"
                  value={password}
                  className="input_styling"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </>
            </div>
            <div className="col-md-2 mt-md-3 "></div>
            <div className="col-md-2 mt-md-4   p-0">
              <label className="input_label m-0">Confirm Password</label>
            </div>
            <div className="col-md-3 mt-md-4   p-0">
              <>
                <input
                  type="password"
                  name="Password"
                  value={confirmpassword}
                  className="input_styling"
                  placeholder="Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-2 mt-md-4   p-0">
            <label className="input_label m-0">Role</label>
          </div>
          <div className="col-md-3 mt-md-4 p-0">
            <select
              id="Role"
              value={roles}
              onChange={(e) => setRoles(e.target.value)}
              className="input_styling"
              style={{ width: "84.5%" }}
              placeholder="Role"
            >
              <option value="" disabled>
                Select
              </option>

              {RolesData?.map((type, index) => (
                <option
                  value={type.RoleId}
                  key={index}
                >
                  {type.RoleName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-md-3 "></div>

          <div className="col-md-2 mt-md-4 p-0">
            <label className="input_label m-0">Project</label>
          </div>
          <div className="col-md-3 mt-md-4 p-0">
            <input
              className="input_styling"
              placeholder="Project"
              disabled={open}
              defaultValue={projectNames}
              onClick={openModel}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-md-4   p-0">
            <label className="input_label m-0">Access</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <>
              <input
                type="text"
                className="input_styling  "
                value={access}
                onChange={(e) => setAccess(e.target.value)}
                list="acesslist"
                placeholder="Access"
              ></input>
              <span className="estaric">*</span>
              <datalist id="acesslist">
                {/* <option value="C">Create</option> */}
                <option value="R">Read</option>
                <option value="CR">Create and Read</option>
                <option value="RU">Read And Update</option>
                <option value="RD">Read and Delete</option>
                <option value="CRU">Create, Read and Update</option>
                <option value="CRUD">Create, Read, Update and Delete</option>
              </datalist>
            </>
          </div>
        </div>
        <div
          className=" float-right mt-md-4 "
          style={{ display: "flex", justifyContent: "end", marginRight: "4%" }}
        >
          <span>
            {screenLoader && (
              <Loader
                type="ThreeDots"
                color="green"
                height={40}
                width={40}
              />
            )}
          </span>
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </span>

          <span>
            <button
              className="save_btn ml-2"
              id="savedata"
              onClick={rowData ? updateUser : postUser}
              disabled={screenLoader}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>

      {open && (
        <div
          id="modalofleadtable"
          className="modalscreenUser"
        >
          <div className="modal-content-cat">
            <div className="row">
              <div className="col-12 page_heading">
                <h4 className="text-center">Select Projects</h4>
                <span
                  className="close pr-2"
                  onClick={() => setOpen(false)}
                >
                  &times;
                </span>
              </div>
            </div>
            <div className="">
              <div className=" mt-4">
                <table className="table mt-2">
                  <thead className="purple whiteText">
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </td>
                      <td>Select All</td>
                    </tr>
                  </thead>
                  <tbody>
                    {resultallproject.data !== undefined &&
                      resultallproject.data !== null
                      ? resultallproject.data?.map((x, id) => (
                        <tr key={id}>
                          <td>
                            <input
                              checked={
                                selectAll || projects?.includes(x.ProjectId)
                              }
                              type="checkbox"
                              onChange={() =>
                                handleCheckboxChange(x.ProjectId)
                              }
                            />
                          </td>
                          <td>{x.ProjectName}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UserForm;
