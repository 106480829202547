import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getAllExternalCommission } from "../../../actions/vouchersactions/getExternalCommission";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";
import ExternalCommissionPrint from "../../Reports/ReportPrints/ExternalCommissionPrint";
import { CSVLink } from "react-csv";
import ExternalCommissionModal from "./ExternalCommissionModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ExternalCommissionTable = ({
  showExternalCommissionForm,
  CommissionType,
  fDate,
  tDate,
}) => {
  const allExternalCommissions = useSelector(
    (state) => state.getExternalCommission
  );
  const [filterdata, setfilterdata] = useState([]);
  const [externalCommissions, setExternalCommissions] = useState([]);
  const [fromDate, setFromDate] = useState(fDate);
  const [toDate, setToDate] = useState(tDate);
  const [printData, setPrintData] = useState(null);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const history = useHistory();

  // print function
  const IndvidualcomponentRef = useRef();

  const IndividualprintPaymentRec = useReactToPrint({
    content: () => IndvidualcomponentRef.current,
    onAfterPrint: () => setPrintData(null),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllExternalCommission(false, CommissionType));
    setFromDate(fDate);
    setToDate(tDate);
  }, [CommissionType]);

  useEffect(() => {
    setExternalCommissions(allExternalCommissions.data);
    setfilterdata(allExternalCommissions.data);
  }, [allExternalCommissions]);

  useEffect(() => {
    if (printData) {
      IndividualprintPaymentRec();
    }
  }, [printData]);

  const onTextChange = (e) => {
    if (document.getElementById("unitno").value === "") {
      setfilterdata(externalCommissions);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(document.getElementById("unitno").value, "i");

        suggestions = externalCommissions
          .sort()
          .filter(
            (val) =>
              document.getElementById("unitno").value === "" ||
              regex.test(val?.UnitNumber)
          );
      }
      setfilterdata(suggestions);
    }
  };

  function ongo() {
    document.getElementById("go").disabled = true;
    // (fromDate === "" || toDate === "")
    if (
      (fromDate === "" && toDate !== "") ||
      (fromDate !== "" && toDate === "")
    ) {
      toast.info("Select From and To Date");
      document.getElementById("go").disabled = false;
    } else {
      dispatch(
        getAllExternalCommission(false, CommissionType, fromDate, toDate)
      );
      document.getElementById("go").disabled = false;
    }
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];

    filterdata?.forEach((val, index) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: index + 1,
        Date: day + "-" + month + "-" + year,
        "Unit No.": val?.UnitNumber,
        Address: val?.Address,
        "Customer Name": val?.CustomerName,
        "Seller Name": val?.SellerName,
        "Contarct Price": val?.ContractPrice?.toLocaleString(),
        "Commission Amount": val?.CommissionAmount?.toLocaleString(),
        Narration: val?.Narration,
      });
    });
    setCsvData(arr);
  }, [filterdata]);

  return (
    <>
      <div hidden>
        <ExternalCommissionPrint
          ref={IndvidualcomponentRef}
          Data={printData}
          CommType={CommissionType}
        />
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>

        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="unitno"
            onChange={onTextChange}
            placeholder="Unit No"
          ></input>
        </div>

        <div className="col-md-3 text-center  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="fromdate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div className="col-md-3 text-center pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="todate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button
            className="btn_Go"
            id="go"
            onClick={ongo}
          >
            Go
          </button>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <ExternalCommissionModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {allExternalCommissions.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height2 mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th style={{ minWidth: "85px" }}>Date</th>
              <th style={{ minWidth: "85px" }}>Added On</th>
              <th>Unit No.</th>
              <th>Address</th>
              <th>Customer Name</th>
              <th>Seller Name</th>
              <th>Contarct Price</th>
              <th>Commission Amount</th>
              <th>Narration</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    // onClick={() => {
                    //   showExternalCommissionForm();
                    // }}
                    onClick={() =>
                      history.push(
                        `/account/vouchers/external-commission/${
                          CommissionType === "INCOME" ? "income" : "expense"
                        }/add`
                      )
                    }
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(val?.Date).format("YYYY-MM-DD")}</td>
                      <td>
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td>{val?.UnitNumber}</td>
                      <td>{val?.Address}</td>
                      <td>{val?.CustomerName}</td>
                      <td>{val?.SellerName}</td>
                      <td>{val?.ContractPrice?.toLocaleString()}</td>
                      <td>{val?.CommissionAmount?.toLocaleString()}</td>
                      <td>{val?.Narration}</td>
                      <td>
                        <GrPrint
                          className="reportcommissiondetails"
                          onClick={() => setPrintData(val)}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="External_Commission_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
    </>
  );
};

export default ExternalCommissionTable;
