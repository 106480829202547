import React, { useEffect, useRef, useState, Component } from "react";

import gravity from "../../../assets/GravityLogo.png";
// import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { getallDepreciate } from "../../actions/assetsaction/deperication";

import moment from "moment";


import Loader from "react-loader-spinner";
// import { BsCloudUpload } from "react-icons/bs";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";
// import AvailabilityPrint from "./Availabiltyprint";
// import { icons } from "react-icons/lib";
// import axios from "axios";
const DepriciationComp = (props) => {
  const resultsalldepreciation = useSelector((state) => state.GetAssetsDepReducer);



  const [filerunits, setfilterunits] = useState([]);


  const dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });


  const onTextChange = (e) => {

    if (document.getElementById("AssetName").value === "" && document.getElementById("status").value === "") {
      setfilterunits(resultsalldepreciation.data.Assets);

    } else {
      let suggestions = [];
      {
        const regex = new RegExp(document.getElementById("AssetName").value, "i");
        const regex1 = new RegExp(document.getElementById("status").value, "i");


        suggestions = resultsalldepreciation.data.Assets
          .sort()
          .filter((val) =>
          ((document.getElementById("AssetName").value === "" || regex.test(val.AssetName)) &&
            (document.getElementById("status").value === "" || regex1.test(val.AvailabilityStatus)))

          );

      }
      setfilterunits(suggestions);
    }
  };

  useEffect(() => {


    dispatch(getallDepreciate());


  }, [dispatch]
  )

  useEffect(() => {
    if (resultsalldepreciation.data !== undefined) {
      setfilterunits(resultsalldepreciation.data.Assets);
    }
  }, [resultsalldepreciation]
  )



  return (
    <>
      <div hidden>
        <DepricationPrint
          ref={componentRef}
          printData={resultsalldepreciation}
        />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="AssetName"
                onChange={onTextChange}
                placeholder="AssetName"
              ></input>
            </div>

            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="status"
                onChange={onTextChange}
                placeholder="Status"
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {resultsalldepreciation.loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>

          <div className="row ">
            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive vouchers_table_height3 mt-4">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Asset</th>
                      <th>Category</th>
                      <th>Purchase Date</th>
                      <th>Cost</th>
                      <th>Current Value</th>
                      <th>Depriciation</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filerunits !== undefined
                      ? filerunits.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>{val.AssetName}</td>

                          {val.AssetCategory !== null &&
                            val.AssetCategory !== undefined ? (
                            <td>{val.AssetCategory.AssetCategoryName}</td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            {moment(val.PurchaseDate.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )}
                          </td>
                          <td>{parseFloat(val.Cost).toLocaleString()}</td>
                          <td>
                            {parseFloat(val.CurrentValue).toLocaleString()}
                          </td>
                          <td>
                            {parseFloat(
                              parseFloat(val.Cost) -
                              parseFloat(val.CurrentValue)
                            ).toLocaleString()}
                          </td>

                          <td>{val.AvailabilityStatus}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6  text-right pr-0 pl-0">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DepriciationComp;

class DepricationPrint extends Component {
  render() {

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <div className="watermarked">
            <img
              src={gravity}
              style={{ height: "600px", width: "600px" }}
              alt="Company logo"
            />
          </div>
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
            <hr />
            <h5 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                204 R/B Road, East Canal Road, Faisalabad.
              </i>
            </h5>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={gravity}
              style={{ height: "175px", width: "175px", borderRadius: "100px" }}
              className="pt-2"
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
        // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Depreciation</u>
            </h4>





            <div className="mt-5">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Asset</th>
                      <th>Category</th>
                      <th>PurchaseDate</th>
                      <th>Cost</th>
                      <th>CurrentValue</th>
                      <th>Depriciation</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>

                    {this.props.printData.data.Assets !== undefined ?
                      this.props.printData.data.Assets.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td >{val.AssetName}</td>

                          {val.AssetCategory !== null && val.AssetCategory !== undefined ?
                            <td >{val.AssetCategory.AssetCategoryName}</td> : <td></td>}
                          <td >{moment(val.PurchaseDate.split("T")[0]).format("DD-MMM-YYYY")}</td>
                          <td >{parseFloat(val.Cost).toLocaleString()}</td>
                          <td >{parseFloat(val.CurrentValue).toLocaleString()}</td>
                          <td >{parseFloat(parseFloat(val.Cost) - parseFloat(val.CurrentValue)).toLocaleString()}</td>






                          <td>{val.AvailabilityStatus}</td>

                        </tr>))
                      : null}

                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

