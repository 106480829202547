import moment from "moment";
import React, { Component } from "react";
// import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class LandPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
        // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Lands</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Entry Date</th>
                      <th>Project</th>
                      <th>Creditor</th>
                      <th>Land Name</th>
                      <th>Land Value</th>
                      <th>Size</th>
                      <th>Advance</th>
                      <th>Amount Paid</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData?.length !== undefined
                      ? this.props.printData?.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {val.EntryDate !== null
                              ? moment(val.EntryDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )
                              : null}
                          </td>
                          <td>
                            {val.Project !== undefined
                              ? val.Project.ProjectName
                              : null}
                          </td>
                          <td>
                            {val.LandCreditor !== undefined
                              ? val.LandCreditor.LandCreditorName
                              : null}
                          </td>
                          <td>{val.LandName}</td>
                          <td>{val.LandValue.toLocaleString()}</td>
                          <td>
                            {val.MeasureUnit !== undefined
                              ? val.MeasureValue + "" + val.MeasureUnit.Unit
                              : null}
                          </td>
                          <td>{val.Advance}</td>

                          <td>
                            {val?.LandPayment?.length
                              ? val?.LandPayment?.reduce(
                                (prev, curr) => prev + curr?.NetPaid,
                                0
                              )?.toLocaleString()
                              : 0}
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
