import React from "react";
// import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ data }) => {
  const firstColumnArray = [];
  const restColumnsArray = [];

  // Separate the arrays
  for (let i = 0; i < data.length; i++) {
    firstColumnArray.push(data[i][0]);
    restColumnsArray.push(data[i].slice(1));
  }
  if (firstColumnArray.length > 0) {
    firstColumnArray.shift();
  }

  // Separate the column arrays
  const columnArrays = [];
  if (restColumnsArray.length > 0) {
    for (let i = 0; i < restColumnsArray[0].length; i++) {
      columnArrays[i] = [restColumnsArray[0][i]];
    }

    // Populate the arrays with data from the original data
    for (let i = 1; i < restColumnsArray.length; i++) {
      for (let j = 0; j < restColumnsArray[i].length; j++) {
        columnArrays[j].push(restColumnsArray[i][j]);
      }
    }
  }
  const projectNames = columnArrays.map((row) => row[0]);
  const projectData = columnArrays.map((row) => row.slice(1));

  // Create an array of objects
  const seriesData = projectNames.map((name, index) => ({
    name,
    data: projectData[index],
  }));

  const options = {
    // dataLabels: {
    //   enabled: false,
    // },
    colors: ["#008FFB", "#FF4560"],
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      //   text: "Fiction Books Sales",
    },
    xaxis: {
      categories: firstColumnArray,
      labels: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
      title: {
        text: "Total Employees",
      },
    },
    yaxis: {
      title: {
        text: "Departments",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      offsetX: 50,
    },
  };

  return (
    <div id="chart">
      {seriesData.length > 0 ? (
        <ReactApexChart
          options={options}
          series={seriesData}
          type="bar"
          width="100%"
          height={400}
        />
      ) : (
        <div>No data to display</div>
      )}
    </div>
  );
};

export default BarChart;
