const allcontractPopulate = { data: [], loading: true };
const contracttable = { data: [], loading: true };
const contracts = { data: [], loading: true };

const contracttablePopulate = { data: [], loading: true };
const contractdetails = { data: [], loading: true };
const unitPopulate = { data: [], loading: true };

export const GetAllContractPopulateRed = (
  state = allcontractPopulate,
  action
) => {
  switch (action.type) {
    case "get_allcontractpopulate":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetUnitPopulateRed = (state = unitPopulate, action) => {
  switch (action.type) {
    case "get_unitpopulate":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetContractTable = (state = contracttable, action) => {
  switch (action.type) {
    case "get_contracttable":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetContractTablePopulate = (
  state = contracttablePopulate,
  action
) => {
  switch (action.type) {
    case "get_contractPopulateData":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export const GetContracts = (state = contracts, action) => {
  switch (action.type) {
    case "get_contracts":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetContractDetailsPopulate = (state = contractdetails, action) => {
  switch (action.type) {
    case "get_contractDetails":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
