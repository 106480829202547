import moment from "moment";
import { useEffect, useState } from "react";
// import { useRef } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
// import { useReactToPrint } from "react-to-print";
import {
  GetResceduleData,
  InstallRescedule,
  installrescedule,
} from "../../../actions/accountsactions/installmentsrescedule";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const InstallResceduleTable = (props) => {
  const resultsallcontracts = useSelector(
    (state) => state.getAllInstallRescedulePopulate
  );
  const history = useHistory();
  const rolesdata = useSelector((state) => state.GetAllRole);

  const dispatch = useDispatch();

  // const [printData, SetPrintData] = useState();
  // // print function
  // const componentRef = useRef();

  // const printRecoveryBooking = useReactToPrint({
  //     content: () => componentRef.current,
  // });
  // useEffect(() => {
  //     if (printData !== undefined) {
  //         printRecoveryBooking();
  //     }
  // }, [printData]);
  // // all projects print

  // const AllcomponentRef = useRef();

  // const AllprintPaymentRec = useReactToPrint({
  //   content: () => AllcomponentRef.current,
  // });

  //Table Data fiter

  let suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(installrescedule());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultsallcontracts.data
          .sort()
          .filter(
            (val) =>
              regex.test(val.Unit.UnitName) ||
              regex.test(val.CustomerCnicNavigation.CustomerName)
          );
      }
      suggestionlist = suggestions;
      dispatch(InstallRescedule(suggestionlist));
    }
  };
  useEffect(() => {
    dispatch(installrescedule());
  }, [dispatch]);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    resultsallcontracts.data?.forEach((val) => {
      arr.push({
        Sr: i++,
        Date: moment(val?.EntryDate).format("DD-MMM-YYYY"),
        Buyer: val.CustomerCnicNavigation.CustomerName,
        Affiliate: val.AffiliateCnicNavigation.AffiliateName,
        Manager: val.Manager.Employee.EmpName,
        Unit: val.Unit.UnitName,
        Total: val.TotalPrice?.toLocaleString(),
        Receiveable: val.CustomerReceivable?.toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [resultsallcontracts.data]);

  return (
    <>
      {/* <div hidden>
                <ProjectsPrint
                    ref={componentRef}

                    printData={printData}

                />
            </div> */}
      {/* <div hidden>
                <ContractPrint
                    ref={AllcomponentRef}

                    printData={resultsallcontracts}

                />
            </div> */}
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Unit/Buyer"
            onChange={onTextChange}
          ></input>
        </div>
        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultsallcontracts.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive vouchers_table_height2 mt-4">
            <table className="table table-borderless m-0 table-hover cur_sor">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Date</th>
                  <th> Buyer</th>
                  <th> Affiliate</th>
                  <th>Manager</th>
                  <th> Unit</th>

                  <th> Total</th>
                  <th> Receiveable</th>

                  <th className="text-center">
                    {/* <button
                                            className="save_btn"
                                            onClick={() => {
                                                props.showcontracttable();
                                            }}
                                        >
                                            New+
                </button> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultsallcontracts.data !== undefined
                  ? resultsallcontracts.data.map((val, index) => {
                    // let year = val.EntryDate.slice(0, 4);
                    // let day = val.EntryDate.slice(8, 10);
                    // let month = val.EntryDate.slice(5, 7);
                    // switch (month) {
                    //     case "01":
                    //         month = "Jan";

                    //         break;
                    //     case "02":
                    //         month = "Feb";

                    //         break;
                    //     case "03":
                    //         month = "Mar";

                    //         break;
                    //     case "04":
                    //         month = "Apr";

                    //         break;
                    //     case "05":
                    //         month = "May";

                    //         break;
                    //     case "06":
                    //         month = "Jun";

                    //         break;
                    //     case "07":
                    //         month = "Jul";

                    //         break;
                    //     case "08":
                    //         month = "Aug";

                    //         break;
                    //     case "09":
                    //         month = "Sep";

                    //         break;
                    //     case "10":
                    //         month = "Oct";
                    //         break;
                    //     case "11":
                    //         month = "Nov";
                    //         break;
                    //     case "12":
                    //         month = "Dec";
                    //         break;
                    //     default:
                    //         break;
                    // }
                    return (
                      <tr key={index}>
                        <td className="table_data">{index + 1}</td>
                        <td className="table_data">
                          {moment(val?.EntryDate).format("DD-MMM-YYYY")}
                        </td>

                        <td className="table_data">
                          {val.CustomerCnicNavigation.CustomerName}
                        </td>
                        <td className="table_data">
                          {val.AffiliateCnicNavigation.AffiliateName}
                        </td>
                        <td className="table_data">
                          {val.Manager.Employee.EmpName}
                        </td>
                        <td className="table_data">{val.Unit.UnitName}</td>

                        <td className="table_data">
                          {val.TotalPrice?.toLocaleString()}
                        </td>
                        <td className="table_data">
                          {val.CustomerReceivable?.toLocaleString()}
                        </td>
                        {/* <td>{val.InstallmentStatus}</td> */}

                        <td
                          className="text-center cur_sor"
                          onClick={() => {
                            dispatch(GetResceduleData(val));
                            // props.showcontracttable();
                            history.push(
                              "/account/contract/install-reschedule/add",
                              { resceduledata: val }
                            );
                          }}
                        >
                          {rolesdata?.data?.Access?.includes("C") ? (
                            <button className="save_btn">reschedule</button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Installment_Reschedule_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
        {/* <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { AllprintPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                </div> */}
      </div>
    </>
  );
};
