import moment from "moment";
import React, { Component } from "react";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class ManagerRecovery extends Component {
  render() {

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
        // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Recovery Report</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr </th>
                      <th>Date</th>
                      <th>Voucher Id</th>
                      <th>Manager</th>

                      <th>Unit</th>
                      <th>Advance Amount</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData.data !== undefined
                      ? this.props.printData.data.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {moment(val.Entrydate.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )}
                          </td>
                          <td>{val.RecoveryBookingVoucherId}</td>
                          <td>
                            {val.Manager !== null &&
                              val.Manager.Employee !== undefined
                              ? val.Manager.Employee.EmpName
                              : null}
                          </td>
                          <td>
                            {val.Unit !== null ? val.Unit.UnitName : null}
                          </td>

                          <td>{val.Contract.AdvanceAmount}</td>
                          <td>{val.Amount}</td>
                          <td>
                            {val.Paid !== true ? "Pending" : "Recoverd"}
                          </td>
                        </tr>
                      ))
                      : null}
                    {/* <tr>

                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td> <label className="input_label m-0">Total:</label></td>
                                            <td><input type="text " className="input_total_styling " value={parseFloat(this.props.Total).toLocaleString()} ></input></td>
                                            <td></td>

                                        </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagerRecovery;