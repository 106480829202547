import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { getaffiliatepopulatetable } from "../../../actions/inventoryactions/getaffiliates";
import { Country, State, City } from "country-state-city";

import Loader from "react-loader-spinner";
function AddAffiliateModal({ setshowAffiliateModal }) {
  const affiliatesdatastate = useSelector((state) => state.getAffliateEdit);
  const resultsproject = useSelector((state) => state.getProject);
  const [toggle, settoggle] = useState(false);
  const [allprojectname, setallprojectname] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [allCountries, setallCountries] = useState([]);
  const [allStates, setallStates] = useState([]);
  const [allCity, setallCity] = useState([]);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedStates, setselectedStates] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);

  let dispatch = useDispatch();
  useEffect(() => {
    const data = Country?.getAllCountries()?.map((country) => ({
      value: country.name,
      displayValue: `${country.name}-${country.isoCode}`,
      isoCode: country.isoCode,
    }));
    setallCountries(data);
  }, []);
  useEffect(() => {
    const data = State.getStatesOfCountry(
      selectedCountries?.split("-")[1]
    )?.map((state) => ({
      value: state.name,
      displayValue: `${state.name}-${state.isoCode}`,
    }));
    setallStates(data);
  }, [selectedCountries]);
  useEffect(() => {
    const data = City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));

    setallCity(data);
  }, [selectedCountries, selectedStates]);
  let populatedata = {};
  const [individualcheck, setindividualcheck] = useState(true);

  if (
    affiliatesdatastate.affiliatesdata !== undefined ||
    affiliatesdatastate.affiliatesdata !== null
  ) {
    populatedata = affiliatesdatastate.affiliatesdata;
  } else {
    populatedata = affiliatesdatastate;
  }

  // function emptyfields() {
  //     document.getElementById("Country").selectedIndex = 0;
  //     document.getElementById("City").selectedIndex = 0;
  //     // document.getElementById("affiliatetype").selectedIndex = 0;
  //     document.getElementById("Phone").value = "";
  //     document.getElementById("Address").value = "";

  //     document.getElementById("modalaffcreditorName").value = "";
  //     document.getElementById("CNIC").value = "";
  //     document.getElementById("Cell1").value = "";
  //     document.getElementById("Cell2").value = "";
  //     document.getElementById("Email").value = "";

  // }

  function emptyfields() {
    let modalaffCNIC = document.getElementById("modalaffCNIC")?.value;
    let Companyregistration = document.getElementById(
      "Companyregistration"
    )?.value;
    if (Companyregistration !== null && Companyregistration !== "") {
      document.getElementById("modalaffiliateprojectname").selectedIndex = 0;
      document.getElementById("modalaffcreditorName").value = "";
      document.getElementById("modalaffEmail").value = "";
      // document.getElementById("modalaffCNIC").value = "";
      document.getElementById("modalaffPhone").value = "";
      document.getElementById("modalaffCity").selectedIndex = 0;
      document.getElementById("modalaffCountry").selectedIndex = 0;
      document.getElementById("modalaffCell1").value = "";
      document.getElementById("modalaffCell2").value = "";
      document.getElementById("modalaffAddress").value = "";
      setselectedCountries(null);
    } else if (modalaffCNIC !== null && modalaffCNIC !== "") {
      document.getElementById("modalaffiliateprojectname").selectedIndex = 0;
      document.getElementById("modalaffcreditorName").value = "";
      document.getElementById("modalaffEmail").value = "";
      // document.getElementById("Companyregistration").value = "";
      document.getElementById("modalaffPhone").value = "";
      document.getElementById("modalaffCity").selectedIndex = 0;
      document.getElementById("modalaffCountry").selectedIndex = 0;
      document.getElementById("modalaffCell1").value = "";
      document.getElementById("modalaffCell2").value = "";
      document.getElementById("modalaffAddress").value = "";
      setselectedCountries(null);
    }
  }
  function addAffiliates() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");

    let regexcompany = new RegExp("^[0-9+]{7}$");
    let check =
      document.getElementById("modalaffCNIC") !== null
        ? document.getElementById("modalaffCNIC").value
        : null;
    let checkcell1 = document.getElementById("modalaffCell1").value;
    let checkcell2 = document.getElementById("modalaffCell2").value;
    let checkptcl = document.getElementById("modalaffPhone")?.value;
    let checkcompanyreg =
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration")?.value
        : null;

    if (document.getElementById("modalaffcreditorName").value === "") {
      toast.info("Name is required");
    } else if (
      document.getElementById("modalaffCNIC") !== null
        ? document.getElementById("modalaffCNIC").value === ""
        : null
    ) {
      toast.info("CNIC is required");
    } else if (
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value === ""
        : null
    ) {
      toast.info("Company registration is required");
    } else if (
      !regexp.test(check) && document.getElementById("modalaffCNIC") !== null
        ? document.getElementById("modalaffCNIC").value !== ""
        : null
    ) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (
      !regexcompany.test(checkcompanyreg) &&
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value !== ""
        : null
    ) {
      toast.info("Invalid Company Reg No. Should contain 7 digits");
    }

    // else if (document.getElementById("CNIC").value === "") { toast.info("CNIC is required") }
    // else if (!regexp.test(check)) {
    //     toast.info('Invalid CNIC. Format should be like xxxxx-xxxxxxx-x');

    // }
    else if (document.getElementById("modalaffAddress").value === "") {
      toast.info("Address is required");
    } else if (
      !regexptcl.test(checkptcl) &&
      document.getElementById("modalaffPhone").value !== ""
    ) {
      toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
    } else if (document.getElementById("modalaffCell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (
      !regexphone.test(checkcell2) &&
      document.getElementById("modalaffCell2").value !== ""
    ) {
      toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
    } else if (selectedCountries === null || selectedCountries === "Select") {
      toast.info("Select any country");
    } else if (selectedStates === null || selectedStates === "Select") {
      toast.info("Select any state");
    } else if (selectedCity === null || selectedCity === "Select") {
      toast.info("Select any city");
    }
    // else if (document.getElementById("affiliatetype").selectedIndex === 0) { toast.info("Select Affiliate Type") }
    else {
      setloader1(true);

      let Projects = [];
      allprojectname.forEach((x, index) => {
        Projects.push({
          ProjectId: x.ProjectId,
          ProjectName: x.ProjectName,
        });
      });

      var Affiliate = affiliatesdatastate;

      // Affiliate.LandCreditorId = populatedata.LandCreditorId;
      // Affiliate.AccountId = populatedata.AccountId;
      Affiliate.AffiliateName = document.getElementById(
        "modalaffcreditorName"
      ).value;
      Affiliate.AffiliateAddress =
        document.getElementById("modalaffAddress").value;
      // Affiliate.AffiliateCnic = document.getElementById("CNIC").value;
      Affiliate.AffiliateCnic =
        individualcheck === true
          ? document.getElementById("modalaffCNIC").value
          : document.getElementById("Companyregistration").value;

      Affiliate.AffiliateContactNo1 =
        document.getElementById("modalaffCell1").value;
      Affiliate.AffiliateContactNo2 =
        document.getElementById("modalaffCell2").value;
      Affiliate.AffiliatePhoneNumber =
        document.getElementById("modalaffPhone").value;
      Affiliate.AffiliateEmail = document.getElementById("modalaffEmail").value;
      Affiliate.AffiliateType =
        individualcheck === true ? "INDIVIDUAL" : "COMPANY";

      // Affiliate.AffiliateType = document.getElementById(
      //     "affiliatetype"
      // ).options[
      //     document.getElementById("affiliatetype").selectedIndex
      // ].value
      Affiliate.AffiliateCity = selectedCity;
      Affiliate.AffiliateCountry = selectedCountries;
      Affiliate.State = selectedStates;

      document.getElementById("creditorsave").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}Affiliates/Add`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          Affiliate,
          Projects,
        }),
      }).then(
        (result) => {
          document.getElementById("creditorsave").disabled = false;

          if (result.data.IsSuccess === true) {
            setloader1(false);
            dispatch(getaffiliatepopulatetable());
            toast.info(result.data.Message);
            emptyfields();
            setshowAffiliateModal(false);
            // props.cancelaffiliatestable();
            // props.setedit("noedit");
          } else {
            setloader1(false);

            toast.info(result.data.Message);
            // props.setedit("noedit");
          }
        },
        (error) => {
          setloader1(false);

          toast.info("Something went wrong!");
          document.getElementById("creditorsave").disabled = false;
          // props.setedit("noedit");
        }
      );
    }
  }
  function addtoarray() {
    let check = 0;

    allprojectname.forEach((x) => {
      if (
        parseInt(x.ProjectId) ===
        parseInt(
          document.getElementById("modalaffiliateprojectname").options[
            document.getElementById("modalaffiliateprojectname").selectedIndex
          ].dataset.id
        )
      ) {
        toast.info("Multiple Allowances cannot be added!");
        check = 1;
      } else {
        check = 0;
      }
    });
    if (
      document.getElementById("modalaffiliateprojectname").selectedIndex === 0
    ) {
      toast.info("Select Allowance");
    } else if (check === 1) {
      toast.info("Select Other Project");
    } else {
      let listofallprojectname = {
        ProjectId: parseInt(
          document.getElementById("modalaffiliateprojectname").options[
            document.getElementById("modalaffiliateprojectname").selectedIndex
          ].dataset.id
        ),
        ProjectName: document.getElementById("modalaffiliateprojectname")
          .options[
          document.getElementById("modalaffiliateprojectname").selectedIndex
        ].value,
      };

      setallprojectname([...allprojectname, listofallprojectname]);
      document.getElementById("modalaffiliateprojectname").selectedIndex = 0;
    }
  }
  const deleteinstallment = (idx) => {
    const rows = [...allprojectname];

    rows.splice(idx, 1);

    setallprojectname(rows);
  };
  useEffect(() => {
    populatedata.AffiliateType === "INDIVIDUAL"
      ? setindividualcheck(true)
      : setindividualcheck(false);
  }, [populatedata.AffiliateType]);
  useEffect(() => {
    dispatch(getallProject());
  }, [dispatch]);
  return (
    <div style={{ display: "block" }} class="modalscreen ">
      <div class="modal-content-dashboard pt-1">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center "> Add Affiliate</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowAffiliateModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>

        <div className="col-md-12 text-right">
          <span>
            {resultsproject.data.length <= 0 ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </span>
        </div>
        <div className="row ">
          <div className="col-12  mt-3">
            <>
              <div className="row ">
                <div className="col-md-4 mt-2 text-right">
                  <span className="txt input_label"> Individual</span>
                </div>
                <div className="col-md-1 mt-2 text-left">
                  <span>
                    {" "}
                    <input
                      type="radio"
                      id="Investment"
                      name="InvestmentType"
                      checked={individualcheck}
                      onClick={() => {
                        // document.getElementById("showhide").style.display = "none";
                        // document.getElementById("showhide2").style.display = "block";
                        // document.getElementById("cityshowhide").style.display = "block";
                        setindividualcheck(true);
                      }}
                    ></input>
                  </span>
                </div>
                <div className="col-md-4 mt-2 text-right">
                  <span className="txt input_label"> Company</span>
                </div>
                <div className="col-md-1 mt-2 text-left">
                  <span>
                    {" "}
                    <input
                      type="radio"
                      name="existingType"
                      id="existingproject"
                      checked={!individualcheck}
                      onClick={() => {
                        setindividualcheck(false);
                        // document.getElementById("showhide2").style.display = "none";
                        // document.getElementById("showhide").style.display = "block";
                      }}
                    ></input>
                  </span>
                </div>
              </div>

              {/* {props.isedit=="noedit"? */}
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Project Name :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <select
                    id="modalaffiliateprojectname"
                    className="input_styling  ml-md-3"
                    onChange={(e) => {
                      if (e.target.value !== "Select") {
                        // setprojectid(document.getElementById("projectname").options[document.getElementById("projectname").selectedIndex].dataset.id);
                      }
                    }}
                  >
                    <option>Select</option>
                    {resultsproject.data.map((x, index) => (
                      <option
                        data-id={x.ProjectId}
                        data-idx={index}
                        key={index}
                      >
                        {x.ProjectName}
                      </option>
                    ))}
                  </select>
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0">
                  <button className="save_btn" onClick={addtoarray}>
                    Add
                  </button>
                </div>
              </div>
              {/* :<></>} */}

              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Name :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3"
                    id="modalaffcreditorName"
                    defaultValue={populatedata.AffiliateName}
                  />
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Email</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="email"
                    className="input_styling ml-md-3 "
                    id="modalaffEmail"
                    defaultValue={populatedata.AffiliateEmail}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  {populatedata.AffiliateType === "INDIVIDUAL" ||
                  populatedata.AffiliateType === "COMPANY" ? (
                    <label className="input_label m-0">
                      {populatedata.AffiliateType === "INDIVIDUAL"
                        ? "CNIC"
                        : "Company Reg"}
                    </label>
                  ) : (
                    <label className="input_label m-0">
                      {individualcheck === true ? "CNIC" : "Company Reg"}
                    </label>
                  )}
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  {/* <input type="text" className="input_styling ml-md-3 " id="CNIC" placeholder="xxxxx-xxxxxxx-x" defaultValue={populatedata.AffiliateCnic} /><span className="estaric">*</span> */}
                  {individualcheck === true ? (
                    <>
                      <input
                        type="text"
                        className="input_styling ml-md-3 "
                        id="modalaffCNIC"
                        placeholder="xxxxx-xxxxxxx-x"
                        defaultValue={populatedata.AffiliateCnic}
                      />
                      <span className="estaric">*</span>
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        className="input_styling ml-md-3 "
                        id="Companyregistration"
                        placeholder="xxxxxxx"
                        defaultValue={populatedata.AffiliateCnic}
                      />
                      <span className="estaric">*</span>
                    </>
                  )}
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Phone No </label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3 "
                    id="modalaffPhone"
                    placeholder="xxx-xxxxxxx"
                    defaultValue={populatedata.AffiliatePhoneNumber}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Country</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <select
                    id="modalaffCountry"
                    onChange={(e) => {
                      setselectedCountries(e.target.value);
                    }}
                    className="input_styling ml-md-3"
                    name="country"
                    value={selectedCountries}
                  >
                    <option selected>Select</option>
                    {allCountries?.map((item, i) => {
                      return (
                        <>
                          <option
                            key={i}
                            value={item?.displayValue?.toUpperCase()}
                          >
                            {item?.displayValue?.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">States</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <select
                    className="input_styling ml-md-3"
                    id="modalaffState"
                    onChange={(e) => {
                      setselectedStates(e.target.value);
                    }}
                    value={selectedStates}
                  >
                    <option selected>Select</option>
                    {allStates?.map((item, i) => {
                      return (
                        <>
                          <option
                            key={i}
                            value={item?.displayValue?.toUpperCase()}
                          >
                            {item?.displayValue?.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="estaric">*</span>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">City</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <select
                    className="input_styling ml-md-3"
                    id="modalaffCity"
                    onChange={(e) => {
                      setselectedCity(e.target.value);
                    }}
                    value={selectedCity}
                  >
                    <option selected>Select</option>
                    {allCity?.map((item, i) => {
                      return (
                        <>
                          <option
                            key={i}
                            value={item?.displayValue?.toUpperCase()}
                          >
                            {item?.displayValue?.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Cell No 1:</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3"
                    id="modalaffCell1"
                    placeholder="xxxx-xxxxxxx"
                    defaultValue={populatedata.AffiliateContactNo1}
                  />
                  <span className="estaric">*</span>
                </div>
              </div>
              <div className="row ">
                {/* <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 " >Affiliate Type:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                    <select className="input_styling ml-md-3 " id="affiliatetype" >
                        {populatedata.AffiliateType !== null && populatedata.AffiliateType !== undefined ? <>
                            <option >Select</option>
                            <option selected > {populatedata.AffiliateType}</option></> :
                            <option selected>Select</option>}

                        <option>Person</option>
                        <option>Company</option>

                    </select><span className="estaric">*</span>

                </div>

                <div className="col-md-2 mt-md-4   p-0">
                </div> */}
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Cell No 2: </label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3 "
                    id="modalaffCell2"
                    placeholder="xxxx-xxxxxxx"
                    defaultValue={populatedata.AffiliateContactNo2}
                  />
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Address:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                  <textarea
                    className="input_address ml-md-3 "
                    id="modalaffAddress"
                    defaultValue={populatedata.AffiliateAddress}
                  ></textarea>
                  <span className="estaric">*</span>
                </div>
              </div>
              {/* {props.isedit=="noedit"?   */}
              <div className="mt-4">
                <div
                  style={{ height: "auto" }}
                  className="table-responsive mt-4"
                >
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Project Id </th>
                        <th>Project Name</th>
                        <th>Dell</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allprojectname.map((val, index) => (
                        <tr key={index}>
                          <td>{val.ProjectId}</td>
                          <td>{val.ProjectName}</td>
                          <td
                            onClick={() => {
                              deleteinstallment(index);
                              settoggle(!toggle);
                            }}
                          >
                            <MdDeleteForever size="1.6em" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row pr-md-2 pt-md-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                  className=" col-12 pr-0 pl-0  text-right "
                >
                  <span>
                    <button
                      className="cancel_btn ml-2  "
                      onClick={() => {
                        //  props.cancelaffiliatestable();
                        // props.setedit("noedit");
                        setshowAffiliateModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </span>
                  <span className="ml-2">
                    {loader1 && (
                      <>
                        <Loader
                          type="Oval"
                          color="black"
                          height={30}
                          width={30}
                          radius={12}
                        />
                      </>
                    )}
                  </span>

                  <span>
                    {" "}
                    <button
                      className="save_btn  ml-2"
                      id="creditorsave"
                      onClick={addAffiliates}
                      disabled={loader1}
                    >
                      <BsCloudUpload size="1.3em" />
                      Save
                    </button>
                  </span>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAffiliateModal;
