import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import ListImage from "../../ModalImages/ListImage";
import DownIcon from "../../ModalImages/DownIcon";

function TaxPayableModal() {
  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog modal-lg " role="document">
          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Tax Payable Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <ul className="modal-ul">
                <li>
                  <ListImage />
                  Cpr number and cpr date can be updated.
                </li>
                <li>
                  <DownIcon />
                  Transactions are:
                </li>
                <ul>
                  <li>
                    <ListImage />
                    Cash/Bank child account Credit with sum of taxes amount in
                    input list.
                  </li>
                  <li>
                    <ListImage />
                    Cash/Bank parent account Credit with voucher amount.
                  </li>
                  <li>
                    <ListImage />
                    Tax child account Debit with sum of taxes amount in input
                    list.
                  </li>
                  <li>
                    <ListImage />
                    Tax parent account Debit with taxAmount comes from input.
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxPayableModal;
