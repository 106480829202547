import React, { createContext, useState, useContext } from "react";

const RolesContext = createContext();

export const RolesProvider = ({ children }) => {
  const [roles, setRoles] = useState(null);

  return (
    <RolesContext.Provider value={{ roles, setRoles }}>
      {children}
    </RolesContext.Provider>
  );
};

export const useRoles = () => {
  const roles = useContext(RolesContext);
  if (roles === undefined) {
    throw new Error("roles must be used within a RolesProvider");
  }
  return roles;
};
