import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { getallcustomers } from "../../../actions/getclients";
import { Country, State, City } from "country-state-city";

import Loader from "react-loader-spinner";

export default function AddNomineeModal({ setshowModal, getAllNominee }) {
  const [statenomnieeid, setnomnieeid] = useState(0);
  const [statecity, setcity] = useState("");
  const [statecountry, setcountry] = useState();
  const resultallclients = useSelector((state) => state.getAllCustomersRed);
  const [isLoading, setIsLoading] = useState(false);
  const [allCountries, setallCountries] = useState([]);
  const [allStates, setallStates] = useState([]);
  const [allCity, setallCity] = useState([]);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedStates, setselectedStates] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  useEffect(() => {
    const data = Country?.getAllCountries()?.map((country) => ({
      value: country.name,
      displayValue: `${country.name}-${country.isoCode}`,
      isoCode: country.isoCode,
    }));
    setallCountries(data);
  }, [Country]);
  useEffect(() => {
    const data = State.getStatesOfCountry(
      selectedCountries?.split("-")[1]
    )?.map((state) => ({
      value: state.name,
      displayValue: `${state.name}-${state.isoCode}`,
    }));
    setallStates(data);
  }, [selectedCountries]);
  useEffect(() => {
    const data = City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));

    setallCity(data);
  }, [selectedCountries, selectedStates]);
  const clientName = useRef();
  const Guardian = useRef();
  const CNIC = useRef();
  const Cell1 = useRef();
  const NomineCity = useRef();
  const NomineCountry = useRef();
  const NomineState = useRef();
  const Address = useRef();

  function emptyfields() {
    clientName.current.value = "";
    Guardian.current.value = "";
    CNIC.current.value = "";
    Cell1.current.value = "";
    NomineCity.current.selectedIndex = 0;
    NomineCountry.current.selectedIndex = 0;
    NomineState.current.selectedIndex = 0;
    Address.current.value = "";
  }
  function emptyfield1() {
    document.getElementById("City").selectedIndex = 0;
    document.getElementById("Country").selectedIndex = 0;

    document.getElementById("Address").value = "";

    document.getElementById("clientName").value = "";
    document.getElementById("CNIC").value = "";
    document.getElementById("Cell1").value = "";
    document.getElementById("Guardian").value = "";
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallcustomers());
  }, [dispatch]);

  function addNominee() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");

    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");

    let check = document.getElementById("CNIC").value;
    let checkcell1 = document.getElementById("Cell1").value;

    if (document.getElementById("clientName").value === "") {
      toast.info("Name is required");
    } else if (document.getElementById("CNIC").value === "") {
      toast.info("CNIC is required");
    } else if (!regexp.test(check)) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (document.getElementById("Address").value === "") {
      toast.info("Address is required");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (selectedCountries === null || selectedCountries === "Select") {
      toast.info("Select  country");
    } else if (selectedStates === null || selectedStates === "Select") {
      toast.info("Select  State");
    } else if (selectedCity === null || selectedCity === "Select") {
      toast.info("Select any city");
    } else {
      document.getElementById("nomineesave").disabled = true;
      setIsLoading(true);

      axios({
        url: `${process.env.REACT_APP_API_URL}Nominees/Add`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          NomineeName: document.getElementById("clientName").value,
          NomineeGuardianName: document.getElementById("Guardian").value,
          NomineeCnic: document.getElementById("CNIC").value,
          NomineeCity: selectedCity,
          NomineeCountry: selectedCountries,
          State: selectedStates,
          NomineeAddress: document.getElementById("Address").value,
          NomineeCellNoOne: document.getElementById("Cell1").value,
        }),
      }).then(
        (result) => {
          setIsLoading(false);
          getAllNominee();
          document.getElementById("nomineesave").disabled = false;
          if (result.data.IsSuccess === true) {
            setnomnieeid(result.data.NomineeId);
            emptyfields();
            setshowModal(false);

            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
            setnomnieeid(result.data.NomineeId);
          }
        },
        (error) => {
          setIsLoading(false);

          toast.info("Something went wrong!");
          document.getElementById("nomineesave").disabled = false;
        }
      );
    }
  }
  //    auto suggestion
  let Clientsuggestionlist = [];

  const bClientsuggestionselected = (val) => {
    document.getElementById("jBuyernominee").value = val.Nominee.NomineeCnic;
    document.getElementById("clientName").value = val.Nominee.NomineeName;

    document.getElementById("CNIC").value = val.Nominee.NomineeCnic;
    // document.getElementById("City").value = val.Nominee.NomineeCity;
    // document.getElementById("Country").value = val.Nominee.NomineeCountry;

    setcountry(val.Nominee.NomineeCountry);
    setcity(val.Nominee.NomineeCity);
    document.getElementById("Cell1").value = val.Nominee.NomineeCellNoOne;

    document.getElementById("Address").value = val.Nominee.NomineeAddress;
    document.getElementById("Guardian").value = val.Nominee.NomineeGuardianName;

    setnomnieeid(val.Nominee.NomineeId);

    Clientsuggestionlist = [];
    ReactDOM.render(null, document.getElementById("bclientdemonominee"));
  };
  let Customersuggestionlist = [];
  const customeronTextChange = (e) => {
    if (document.getElementById("jBuyernominee").value == "") {
      const value = e.target.value;
      if (value === "") {
        ReactDOM.render(null, document.getElementById("bcustomerdemonominee"));
        emptyfield();
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = resultallclients.data
            .sort()
            .filter((v) => regex.test(v.CustomerCnic));
        }

        Customersuggestionlist = suggestions;
        // suggestionlist.push("Add New");
        if (Customersuggestionlist.length === 0) {
          ReactDOM.render(
            null,
            document.getElementById("bcustomerdemonominee")
          );
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {Customersuggestionlist.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Customersuggestionselected(item)}
                >
                  {item === undefined ? item : item.CustomerCnic}
                </li>
              ))}
            </ul>,
            document.getElementById("bcustomerdemonominee")
          );
        }
      }
    }
  };

  const onTextChangeCustomerCnic = () => {
    var inputValue = document.getElementById("jClientnominee").value;
    var options = document.getElementById("clientnominee").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallclients.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        document.getElementById("clientName").value = val.CustomerName;

        document.getElementById("CNIC").value = val.CustomerCnic2;
        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setselectedCity(val.City);
        setselectedStates(val.State);
        setselectedCountries(val.Country);
        document.getElementById("Cell1").value = val.ContactNumber1;

        document.getElementById("Address").value = val.CustomerAddress;
        document.getElementById("Guardian").value = val.GuardianName;
        break;
      }
    }
  };

  const Customersuggestionselected = (val) => {
    document.getElementById("jClientnominee").value = val.CustomerCnic;
    document.getElementById("clientName").value = val.CustomerName;

    document.getElementById("CNIC").value = val.CustomerCnic;
    // document.getElementById("City").value = val.Nominee.NomineeCity;
    // document.getElementById("Country").value = val.Nominee.NomineeCountry;
    setcountry(val.Country);
    setcity(val.City);
    document.getElementById("Cell1").value = val.ContactNumber1;

    document.getElementById("Address").value = val.CustomerAddress;
    document.getElementById("Guardian").value = val.GuardianName;

    // setnomnieeid(val.Nominee.NomineeId);

    Customersuggestionlist = [];
    ReactDOM.render(null, document.getElementById("bcustomerdemonominee"));
  };
  function emptyfield() {
    document.getElementById("Guardian").value = "";
    document.getElementById("Address").value = "";
    document.getElementById("Cell1").value = "";
    document.getElementById("CNIC").value = "";
    document.getElementById("clientName").value = "";
    // document.getElementById("City").selectedIndex = 0;
    // document.getElementById("Country").selectedIndex = 0;
    setcountry("");
    setcity("");
    setnomnieeid("");
  }
  return (
    <div style={{ display: "block" }} class="modalscreen ">
      <div class="modal-content-dashboard pt-1">
        <div className="row ">
          <div className="col-12  page_heading">
            <h4 className="  text-center "> Add Nominee</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <div className="col-md-12 text-right">
          <span>
            {resultallclients?.loading === true ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </span>
        </div>
        <div className="row ">
          <div className="col-12  mt-3">
            <>
              {/* <div className="row ">
                  <div className="col-md-12   text-left page_heading">
                      <h4 className="m-0">Add Nominee</h4>
                  </div>
              </div> */}
              <div className="row ">
                <div className="col-md-2 mt-md-4   p-0">
                  <label className="input_label m-0">Customer CNIC :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    className="input_styling ml-md-3"
                    id="jClientnominee"
                    autoComplete="off"
                    placeholder="Search By CNIC "
                    list="clientnominee"
                    onChange={onTextChangeCustomerCnic}
                  />
                  <datalist id="clientnominee">
                    {resultallclients?.data?.map((x, idx) => (
                      <option index={idx} value={x.CustomerCnic2}>
                        {x.CustomerName}
                      </option>
                    ))}
                  </datalist>
                  <span id="bcustomerdemonominee"></span>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Name :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    autoComplete="off"
                    type="text"
                    className="input_styling ml-md-3"
                    id="clientName"
                    ref={clientName}
                  />
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Guardian :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3"
                    id="Guardian"
                    ref={Guardian}
                  />
                  <span className="estaric">*</span>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">CNIC</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3 "
                    id="CNIC"
                    placeholder="xxxxx-xxxxxxx-x"
                    ref={CNIC}
                  />
                  <span className="estaric">*</span>
                </div>

                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Cell No 1:</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="text"
                    className="input_styling ml-md-3"
                    id="Cell1"
                    placeholder="xxxx-xxxxxxx"
                    ref={Cell1}
                  />
                  <span className="estaric">*</span>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Country</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <select
                    id="modalaffCountry"
                    onChange={(e) => {
                      setselectedCountries(e.target.value);
                    }}
                    className="input_styling ml-md-3"
                    name="country"
                    value={selectedCountries}
                    ref={NomineCountry}
                  >
                    <option selected>Select</option>
                    {allCountries?.map((item, i) => {
                      return (
                        <>
                          <option
                            key={i}
                            value={item?.displayValue?.toUpperCase()}
                          >
                            {item?.displayValue?.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>

                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">States</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <select
                    className="input_styling ml-md-3"
                    id="modalaffState"
                    onChange={(e) => {
                      setselectedStates(e.target.value);
                    }}
                    value={selectedStates}
                    ref={NomineState}
                  >
                    <option selected>Select</option>
                    {allStates?.map((item, i) => {
                      return (
                        <>
                          <option
                            key={i}
                            value={item?.displayValue?.toUpperCase()}
                          >
                            {item?.displayValue?.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="estaric">*</span>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">City</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <select
                    className="input_styling ml-md-3"
                    id="modalaffCity"
                    onChange={(e) => {
                      setselectedCity(e.target.value);
                    }}
                    value={selectedCity}
                    ref={NomineCity}
                  >
                    <option selected>Select</option>
                    {allCity?.map((item, i) => {
                      return (
                        <>
                          <option
                            key={i}
                            value={item?.displayValue?.toUpperCase()}
                          >
                            {item?.displayValue?.toUpperCase()}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  <span className="estaric">*</span>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>

                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Address:</label>
                </div>
                <div className="col-md-3 mt-4   p-0">
                  <textarea
                    className="input_address ml-md-3 "
                    id="Address"
                    ref={Address}
                  ></textarea>
                  <span className="estaric">*</span>
                </div>
              </div>

              <div className="row pr-md-2 pt-md-4">
                <div
                  className=" col-12 pr-0 pl-0  text-right "
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <button
                      className="cancel_btn ml-2  "
                      // onClick={() => { props.cancelcontracttable(); emptyfields(); }}
                      onClick={() => {
                        setshowModal(false);
                      }}
                    >
                      Back
                    </button>
                  </span>
                  <span className="ml-2">
                    {isLoading ? (
                      <>
                        <Loader
                          type="Oval"
                          color="black"
                          height={30}
                          width={30}
                          radius={12}
                        />
                      </>
                    ) : null}
                  </span>
                  <span>
                    <button
                      className="save_btn  ml-2"
                      id="nomineesave"
                      onClick={addNominee}
                    >
                      <BsCloudUpload size="1.3em" />
                      Save
                    </button>
                  </span>
                  <span></span>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
