import {
  Document,
  PDFViewer,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import QuickSand from "./Quicksand-SemiBold.ttf";
import { useState } from "react";

Font.register({
  family: "Quicksand sans-serif",
  src: QuickSand,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Quicksand sans-serif",
    padding: "0px 20px",
    // paddingTop: "4in",
    paddingTop: "40px",

    paddingBottom: "20.5px",
    fontSize: "12px",
    fontWeight: "light",
  },
  section: {
    border: "1px solid black",
    padding: 3,
    marginBottom: 2,
  },
  infoParent: {
    display: "flex",
    width: "100%",
    gap: 3,
    flexDirection: "row",
    paddingBottom: 1,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  infoChild: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  labelFont: {
    fontSize: "10px",
  },
  valueFont: {
    fontSize: "9px",
  },
  linePadding: {
    paddingBottom: 2,
  },
  listItem: {
    flexDirection: "row",
    marginBottom: 5,
  },
  bullet: {
    marginLeft: 5,
  },
  content: {
    flex: 1,
  },
  signatureLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
});
const tableStyles = StyleSheet.create({
  table: {
    // marginTop: "2px",
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    // borderRightWidth: 1,
    // borderBottomWidth: 1,
    borderColor: "#5b5b5b",
  },
  tableRow: {
    // margin: "5px",
    justifyContent: "space-between",

    flexDirection: "row",
    borderLeft: "1px solid black",
  },
  tableCellHeader: {
    // margin: 5,
    padding: 2,
    fontSize: 11,
    fontWeight: 700,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    textAlign: "left",
    borderTop: "1px solid black",

    // flex: 1,
    color: "#298b8b",
    borderColor: "#5b5b5b",
  },
  tableCell: {
    // margin: 5,
    padding: 2,
    fontSize: "8px",
    textAlign: "left",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "#5b5b5b",
  },
});

function ContractNewPrint2Tgic({ printData, setPrintData }) {
  // const [lastRows, setlastRows] = useState(0);
  const [tableRows, settableRow] = useState([]);
  const handleClick = () => {
    // setClose(true);
    setPrintData(null);
  };

  // useEffect(() => {
  //   const rowsPerPage = 20; // Adjust as needed
  //   const totalRows =
  //     tableRows.length; /* your API data length or any other way to get the total rows */

  //   const rowsOnLastPage = totalRows % rowsPerPage || rowsPerPage;
  //   setlastRows(rowsOnLastPage);
  // }, [tableRows]);
  useEffect(() => {
    let arr = [];
    let rowArr = [];
    if (printData.ContractInstallments?.length > 0) {
      printData.ContractInstallments?.forEach((row, id) => {
        if (printData?.ContractInstallments?.length === id + 1) {
          if (arr.length > 0) {
            arr.push(
              rowArr.length <= 30 ? (
                <Page size="LEGAL" style={styles.page}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.ContractId}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Date. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.EntryDate
                          ? new Date(printData.EntryDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerName}
                      </Text>{" "}
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerCnic2}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "extrabold" }}>
                        {" "}
                        Unit No.{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.Unit?.UnitName}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Size. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined && printData.Unit !== undefined
                          ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? printData.Unit.Marla +
                              "M " +
                              printData.Unit.Sarsai +
                              " S"
                            : printData.Unit.NetSqft.toFixed(
                                2
                              ).toLocaleString() + "SQFT"
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {printData !== undefined &&
                        printData.Unit !== undefined &&
                        printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? "Rate/Marla."
                          : "Rate/Sqft."}{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.SaleRate !== undefined
                          ? printData?.SaleRate?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null
                          ? printData?.TotalPrice?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null &&
                        printData?.CustomerReceivable !== null
                          ? (
                              printData?.TotalPrice -
                              printData?.CustomerReceivable
                            ).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.CustomerReceivable !== null
                          ? (printData?.CustomerReceivable).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                    <Text> Installment Plan</Text>
                  </View>
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                      Sr
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Description{" "}
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Balance{" "}
                    </Text>
                  </View>
                  {rowArr}
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? id + 3
                        : id + 2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.DueAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                        ? moment(
                            row.UnitReceiptDetail[
                              row.UnitReceiptDetail?.length - 1
                            ]?.UnitVoucher?.EntryDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : row.UnitReceiptDetail?.length !== 0 &&
                          row.UnitReceiptDetail[0]?.AdjustmentVoucher
                            ?.EntryDate !== null
                        ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                            "T"
                          )[0]
                        : null}{" "}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row?.ReceivedAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                    </Text>
                  </View>
                </Page>
              ) : (
                <>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(0, 30)}
                  </Page>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(30, rowArr.length)}
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? id + 3
                          : id + 2}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {moment(row.DueDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.DueAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !==
                          null
                          ? moment(
                              row.UnitReceiptDetail[
                                row.UnitReceiptDetail?.length - 1
                              ]?.UnitVoucher?.EntryDate?.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : row.UnitReceiptDetail?.length !== 0 &&
                            row.UnitReceiptDetail[0]?.AdjustmentVoucher
                              ?.EntryDate !== null
                          ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                              "T"
                            )[0]
                          : null}{" "}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row?.ReceivedAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                      </Text>
                    </View>
                  </Page>
                </>
              )
            );
          } else {
            arr.push(
              rowArr.length <= 30 ? (
                <Page size="LEGAL" style={styles.page}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.ContractId}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Date. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.EntryDate
                          ? new Date(printData.EntryDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerName}
                      </Text>{" "}
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerCnic2}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "extrabold" }}>
                        {" "}
                        Unit No.{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.Unit?.UnitName}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Size. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined && printData.Unit !== undefined
                          ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? printData.Unit.Marla +
                              "M " +
                              printData.Unit.Sarsai +
                              " S"
                            : printData.Unit.NetSqft.toFixed(
                                2
                              ).toLocaleString() + "SQFT"
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {printData !== undefined &&
                        printData.Unit !== undefined &&
                        printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? "Rate/Marla."
                          : "Rate/Sqft."}{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.SaleRate !== undefined
                          ? printData?.SaleRate?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null
                          ? printData?.TotalPrice?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null &&
                        printData?.CustomerReceivable !== null
                          ? (
                              printData?.TotalPrice -
                              printData?.CustomerReceivable
                            ).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.CustomerReceivable !== null
                          ? (printData?.CustomerReceivable).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                    <Text> Installment Plan</Text>
                  </View>
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                      Sr
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Description{" "}
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Balance{" "}
                    </Text>
                  </View>
                  <View key={id + 1} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Booking
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData !== undefined &&
                      printData.EntryDate !== undefined
                        ? moment(printData.EntryDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.AdvanceAmount !== undefined
                        ? printData.AdvanceAmount?.toLocaleString()
                        : 0}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {" "}
                      {printData !== undefined &&
                      printData.UnitVoucher?.length > 0
                        ? moment(
                            printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.AdvanceReceived?.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(
                        printData.AdvanceAmount - printData.AdvanceReceived
                      ).toLocaleString()}
                    </Text>
                  </View>
                  {printData?.RescheduledReceivedInstallment && (
                    <View key={id + 1} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        2
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? moment(
                              printData.RescheduledReceivedInstallment.DueDate?.split(
                                "T"
                              )[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {" "}
                        {printData.RescheduledReceivedInstallment
                          ? moment(
                              printData.RescheduledReceivedInstallment.DueDate?.split(
                                "T"
                              )[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {0}
                      </Text>
                    </View>
                  )}
                  {rowArr}
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {+printData.RescheduledReceivedInstallment
                        ? id + 3
                        : id + 2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.DueAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                        ? moment(
                            row.UnitReceiptDetail[
                              row.UnitReceiptDetail?.length - 1
                            ]?.UnitVoucher?.EntryDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : row.UnitReceiptDetail?.length !== 0 &&
                          row.UnitReceiptDetail[0]?.AdjustmentVoucher
                            ?.EntryDate !== null
                        ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                            "T"
                          )[0]
                        : null}{" "}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row?.ReceivedAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                    </Text>
                  </View>
                </Page>
              ) : (
                <>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    <View key={id + 1} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        1
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Booking
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData !== undefined &&
                        printData.EntryDate !== undefined
                          ? moment(printData.EntryDate.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceAmount !== undefined
                          ? printData.AdvanceAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {" "}
                        {printData !== undefined &&
                        printData.UnitVoucher?.length > 0
                          ? moment(
                              printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceReceived?.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(
                          printData.AdvanceAmount - printData.AdvanceReceived
                        ).toLocaleString()}
                      </Text>
                    </View>
                    {printData?.RescheduledReceivedInstallment && (
                      <View key={id + 1} style={tableStyles.tableRow}>
                        <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                          2
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          Installment
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {printData.RescheduledReceivedInstallment
                            ? moment(
                                printData.RescheduledReceivedInstallment.DueDate?.split(
                                  "T"
                                )[0]
                              ).format("DD-MMM-YYYY")
                            : null}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {printData.RescheduledReceivedInstallment
                            ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                            : 0}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {" "}
                          {printData.RescheduledReceivedInstallment
                            ? moment(
                                printData.RescheduledReceivedInstallment.DueDate?.split(
                                  "T"
                                )[0]
                              ).format("DD-MMM-YYYY")
                            : null}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {printData.RescheduledReceivedInstallment
                            ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                            : 0}
                        </Text>
                        <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                          {0}
                        </Text>
                      </View>
                    )}
                    {rowArr.slice(0, 30)}
                  </Page>
                  <Page size="LEGAL" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft === "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(30, rowArr.length)}
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        {printData.RescheduledReceivedInstallment
                          ? id + 3
                          : id + 2}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {moment(row.DueDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.DueAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !==
                          null
                          ? moment(
                              row.UnitReceiptDetail[
                                row.UnitReceiptDetail?.length - 1
                              ]?.UnitVoucher?.EntryDate?.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : row.UnitReceiptDetail?.length !== 0 &&
                            row.UnitReceiptDetail[0]?.AdjustmentVoucher
                              ?.EntryDate !== null
                          ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                              "T"
                            )[0]
                          : null}{" "}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row?.ReceivedAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                      </Text>
                    </View>
                  </Page>
                </>
              )
            );
          }
        } else if (id + 1 === 49 && (id + 1) % 49 === 0) {
          if (arr.length > 0) {
            arr.push(
              <Page size="LEGAL" style={styles.page}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.ContractId}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Date. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.EntryDate
                        ? new Date(printData.EntryDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerName}
                    </Text>{" "}
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerCnic2}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.Unit?.UnitName}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Size. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData.Unit !== undefined
                        ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? printData.Unit.Marla +
                            "M " +
                            printData.Unit.Sarsai +
                            " S"
                          : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                            "SQFT"
                        : null}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {" "}
                      {printData !== undefined &&
                      printData.Unit !== undefined &&
                      printData.Unit.RatePerMarlaOrsqft === "MARLA"
                        ? "Rate/Marla."
                        : "Rate/Sqft."}{" "}
                    </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.SaleRate !== undefined
                        ? printData?.SaleRate?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData?.TotalPrice !== null
                        ? printData?.TotalPrice?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.TotalPrice !== null &&
                      printData?.CustomerReceivable !== null
                        ? (
                            printData?.TotalPrice -
                            printData?.CustomerReceivable
                          ).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.CustomerReceivable !== null
                        ? (printData?.CustomerReceivable).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                </View>
                <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                  <Text> Installment Plan</Text>
                </View>
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                    Sr
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Description{" "}
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Balance{" "}
                  </Text>
                </View>
                {rowArr}
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                    {printData.RescheduledReceivedInstallment ? id + 3 : id + 2}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    Installment
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.DueAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                      ? moment(
                          row.UnitReceiptDetail[
                            row.UnitReceiptDetail?.length - 1
                          ]?.UnitVoucher?.EntryDate?.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.AdjustmentVoucher
                          ?.EntryDate !== null
                      ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                          "T"
                        )[0]
                      : null}{" "}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row?.ReceivedAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                  </Text>
                </View>
              </Page>
            );
          } else {
            arr.push(
              <Page size="LEGAL" style={styles.page}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.ContractId}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Date. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.EntryDate
                        ? new Date(printData.EntryDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerName}
                    </Text>{" "}
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.CustomerCnicNavigation?.CustomerCnic2}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.Unit?.UnitName}
                    </Text>
                  </Text>

                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Size. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData.Unit !== undefined
                        ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                          ? printData.Unit.Marla +
                            "M " +
                            printData.Unit.Sarsai +
                            " S"
                          : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                            "SQFT"
                        : null}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {" "}
                      {printData !== undefined &&
                      printData.Unit !== undefined &&
                      printData.Unit.RatePerMarlaOrsqft === "MARLA"
                        ? "Rate/Marla."
                        : "Rate/Sqft."}{" "}
                    </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData?.SaleRate !== undefined
                        ? printData?.SaleRate?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined && printData?.TotalPrice !== null
                        ? printData?.TotalPrice?.toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.TotalPrice !== null &&
                      printData?.CustomerReceivable !== null
                        ? (
                            printData?.TotalPrice -
                            printData?.CustomerReceivable
                          ).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                    <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                      {printData !== undefined &&
                      printData?.CustomerReceivable !== null
                        ? (printData?.CustomerReceivable).toLocaleString()
                        : null}
                    </Text>
                  </Text>
                </View>
                <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                  <Text> Installment Plan</Text>
                </View>
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                    Sr
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Description{" "}
                  </Text>{" "}
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Due Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Date{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Paid Amount{" "}
                  </Text>
                  <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                    Balance{" "}
                  </Text>
                </View>
                <View key={id + 1} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    Booking
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {printData !== undefined &&
                    printData.EntryDate !== undefined
                      ? moment(printData.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )
                      : null}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {printData.AdvanceAmount !== undefined
                      ? printData.AdvanceAmount?.toLocaleString()
                      : 0}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {" "}
                    {printData !== undefined &&
                    printData.UnitVoucher?.length > 0
                      ? moment(
                          printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : null}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {printData.AdvanceReceived?.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {(
                      printData.AdvanceAmount - printData.AdvanceReceived
                    ).toLocaleString()}
                  </Text>
                </View>
                {printData?.RescheduledReceivedInstallment && (
                  <View key={id + 1} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? moment(
                            printData.RescheduledReceivedInstallment.DueDate?.split(
                              "T"
                            )[0]
                          ).format("DD-MMM-YYYY")
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                        : 0}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {" "}
                      {printData.RescheduledReceivedInstallment
                        ? moment(
                            printData.RescheduledReceivedInstallment.DueDate?.split(
                              "T"
                            )[0]
                          ).format("DD-MMM-YYYY")
                        : null}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {printData.RescheduledReceivedInstallment
                        ? printData.RescheduledReceivedInstallment?.DueAmount?.toLocaleString()
                        : 0}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {0}
                    </Text>
                  </View>
                )}
                {rowArr}
                <View key={id} style={tableStyles.tableRow}>
                  <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                    {printData.RescheduledReceivedInstallment ? id + 3 : id + 2}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    Installment
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.DueAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                      ? moment(
                          row.UnitReceiptDetail[
                            row.UnitReceiptDetail?.length - 1
                          ]?.UnitVoucher?.EntryDate?.split("T")[0]
                        ).format("DD-MMM-YYYY")
                      : row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.AdjustmentVoucher
                          ?.EntryDate !== null
                      ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                          "T"
                        )[0]
                      : null}{" "}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {row?.ReceivedAmount.toLocaleString()}
                  </Text>
                  <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                    {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                  </Text>
                </View>
              </Page>
            );
          }

          rowArr = [];
        } else {
          rowArr.push(
            <View key={id} style={tableStyles.tableRow}>
              <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                {printData.RescheduledReceivedInstallment ? id + 3 : id + 2}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                Installment
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row.DueAmount.toLocaleString()}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row.UnitReceiptDetail?.length !== 0 &&
                row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                  ? moment(
                      row.UnitReceiptDetail[
                        row.UnitReceiptDetail?.length - 1
                      ]?.UnitVoucher?.EntryDate?.split("T")[0]
                    ).format("DD-MMM-YYYY")
                  : row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate !==
                      null
                  ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                      "T"
                    )[0]
                  : null}{" "}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row?.ReceivedAmount.toLocaleString()}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
              </Text>
            </View>
          );
        }
      });
    } else {
      arr.push(
        <Page size="LEGAL" style={styles.page}>
          {" "}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.ContractId}
              </Text>
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}>Date. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.EntryDate
                  ? new Date(printData.EntryDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : ""}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.CustomerCnicNavigation?.CustomerName}
              </Text>{" "}
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.CustomerCnicNavigation?.CustomerCnic2}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.Unit?.UnitName}
              </Text>
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}>Size. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined && printData.Unit !== undefined
                  ? printData.Unit.RatePerMarlaOrsqft === "MARLA"
                    ? printData.Unit.Marla + "M " + printData.Unit.Sarsai + " S"
                    : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                      "SQFT"
                  : null}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                {printData !== undefined &&
                printData.Unit !== undefined &&
                printData.Unit.RatePerMarlaOrsqft === "MARLA"
                  ? "Rate/Marla."
                  : "Rate/Sqft."}{" "}
              </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.SaleRate !== undefined
                  ? printData?.SaleRate?.toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined && printData?.TotalPrice !== null
                  ? printData?.TotalPrice?.toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined &&
                printData?.TotalPrice !== null &&
                printData?.CustomerReceivable !== null
                  ? (
                      printData?.TotalPrice - printData?.CustomerReceivable
                    ).toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Balance. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined &&
                printData?.CustomerReceivable !== null
                  ? (printData?.CustomerReceivable).toLocaleString()
                  : null}
              </Text>
            </Text>
          </View>
          <View style={{ fontWeight: "bold", marginTop: "9px" }}>
            <Text> Installment Plan</Text>
          </View>
          <View key={1} style={tableStyles.tableRow}>
            <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>Sr</Text>{" "}
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Description{" "}
            </Text>{" "}
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Due Date{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Due Amount{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Paid Date{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Paid Amount{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Balance{" "}
            </Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>Booking</Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData !== undefined && printData.EntryDate !== undefined
                ? moment(printData.EntryDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData.AdvanceAmount !== undefined
                ? printData.AdvanceAmount?.toLocaleString()
                : 0}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {" "}
              {printData !== undefined && printData.UnitVoucher?.length > 0
                ? moment(
                    printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                  ).format("DD-MMM-YYYY")
                : null}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData.AdvanceReceived?.toLocaleString()}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {(
                printData.AdvanceAmount - printData.AdvanceReceived
              ).toLocaleString()}
            </Text>
          </View>
        </Page>
      );
    }

    settableRow(arr);
  }, [printData]);

  return (
    <>
      <PDFViewer width={"100%"} height={"800px"}>
        {" "}
        <Document>{tableRows}</Document>
      </PDFViewer>
      <button
        style={{ color: "black !important", marginBottom: "20px" }}
        className="print_btn float-left"
        onClick={handleClick}
      >
        {"< Go Back"}
      </button>
    </>
  );
}

export default ContractNewPrint2Tgic;
