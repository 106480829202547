import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { RiFileEditLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getAllContractTablePopulate } from "../../../actions/accountsactions/addcontract";
import { getallunitpopulatetable } from "../../../actions/vouchersactions/getunitreceipt";
// import { UnitReceiptPrint } from "../../accpayablepages/vouchersprints/receiptprints";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import UnitReceiptDeleteVoucherModal from "./UnitReceiptDeleteVoucherModal";
import UnitReceiptTransactionDetailModal from "./UnitReceiptTransactionDetailModal";
import { CSVLink } from "react-csv";
import UnitReceiptModal from "./UnitReceiptModal";
import useDownloadCsv from "../../../actions/downloadCsvHook";
import moment from "moment";
import FullScreenLoader from "../../../FullScreenLoader/FullScreenLoader";
import UnitReceiptPrintNew from "../../accpayablepages/vouchersprints/UnitReceiptPrintNew";
import { getallunitcategory } from "../../../actions/getunit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const UnitReceiptTable = (props) => {
  const resultsunittable = useSelector((state) => state.getUnitReceiptTable);
  // const resultsCategory = useSelector((state) => state.getAllUnitCategory);

  const [TransactionData, setTransactionData] = useState();
  const resultsallcontractspopulate = useSelector(
    (state) => state.getContractTablePopulate
  );
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  const handelDeleteVouvherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };
  const history = useHistory();

  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const dispatch = useDispatch();
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [voucherId, setVoucherId] = useState(null);
  const [entryDate, setEntryDate] = useState(null);
  const [downloadCsv] = useDownloadCsv();
  const [loading, setloading] = useState(false);
  // print function
  const componentRef = useRef();
  const [allLinks, setallLinks] = useState(null);
  const [allUnits, setallUnits] = useState([]);
  const [allCustomers, setallCustomers] = useState([]);
  const [customerCnic, setcustomerCnic] = useState("");
  const [unitId, setunitId] = useState("");
  const [contractId, setContractId] = useState("");
  const [unitRecieptRowData, setunitRecieptRowData] = useState(null);
  const [loading2, setloading2] = useState(false);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [rendarComp, setrendarComp] = useState(false);
  const [showfullScreenLoader, setshowfullScreenLoader] = useState(false);

  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    account: "",
    status: "",
  });
  const printUnitvoucher = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printUnitvoucher();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallunitpopulatetable({}));
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsunittable.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.UnitVoucherId) || regex.test(val.Contract !== undefined && val.Contract !== null ? val.Contract.CustomerCnicNavigation.CustomerName : null)));

  //         }
  //         suggestionlist = suggestions;
  //         // dispatch(GetAllUnitTableData(suggestionlist));
  //         setfilterdata(suggestionlist);

  //     }
  // };

  // const onTextChange = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   setfilterObject({ ...filterObject, [name]: value });
  //   if (
  //     // document.getElementById("customerfilter").value === "" &&
  //     document.getElementById("datefilter").value === "" &&
  //     document.getElementById("voucherfilter").value === "" &&
  //     // document.getElementById("unitfilter").value === "" &&
  //     document.getElementById("submittedId")?.value === "all"
  //   ) {
  //     setfilterdata(resultsunittable.data?.data);
  //   } else {
  //     let suggestions = [];
  //     {
  //       // const regex = new RegExp(
  //       //   document.getElementById("customerfilter").value,
  //       //   "i"
  //       // );
  //       const regex1 = new RegExp(
  //         document.getElementById("voucherfilter").value,
  //         "i"
  //       );
  //       const regex2 = new RegExp(
  //         document.getElementById("datefilter").value,
  //         "i"
  //       );
  //       // const regex3 = new RegExp(
  //       //   document.getElementById("unitfilter").value,
  //       //   "i"
  //       // );

  //       suggestions = resultsunittable?.data?.data?.sort().filter(
  //         (val) =>
  //           // (document.getElementById("customerfilter").value === "" ||
  //           //   regex.test(
  //           //     val.Contract !== undefined
  //           //       ? val.Contract.CustomerCnicNavigation.CustomerName
  //           //       : null
  //           //   )) &&
  //           (document.getElementById("voucherfilter").value === "" ||
  //             regex1.test(val.UnitVoucherId)) &&
  //           (document.getElementById("datefilter").value === "" ||
  //             regex2.test(val.EntryDate)) &&
  //           // (document.getElementById("unitfilter").value === "" ||
  //           //   regex3.test(
  //           //     val.Contract !== undefined ? val.Contract.Unit.UnitName : null
  //           //   )) &&
  //           (document.getElementById("submittedId").value === "all"
  //             ? val.Submitted === false || val.Submitted === true
  //             : document.getElementById("submittedId").value === "false"
  //               ? val.Submitted === false
  //               : val.Submitted === true)
  //       );
  //     }
  //     setfilterdata(suggestions);
  //   }
  // };
  useEffect(() => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      ContractId: contractId,
      UnitName: unitId,
      CustomerCnic: customerCnic,
      Status: document.getElementById("submittedId").value,
      VoucherId: document.getElementById("voucherfilter").value,
    };
    dispatch(getallunitpopulatetable(data));
    dispatch(getAllContractTablePopulate());
    dispatch(getallunitcategory());
  }, [dispatch]);

  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";

    setTransactionData(data?.Transaction);
    setVoucherId(data?.UnitVoucherId);
    setEntryDate(data?.EntryDate);
    var credit = 0;
    var debit = 0;
    data?.Transaction?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
          ? (debit += parseFloat(val.Amount))
          : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function ongo() {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      ContractId: contractId,
      UnitId: unitId,
      CustomerCnic: customerCnic,
      Status: document.getElementById("submittedId").value,
      VoucherId: document.getElementById("voucherfilter").value,
      // UnitCategoryId:
      //   document.getElementById("categoryname")?.options[
      //     document.getElementById("categoryname").selectedIndex
      //   ]?.dataset?.id,
      PaymentType:
        document.getElementById("paymentType")?.options[
          document.getElementById("paymentType").selectedIndex
        ]?.value,
    };
    setloading(true);
    dispatch(getallunitpopulatetable(data));
  }
  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      ContractId: contractId,
      UnitId: unitId,
      CustomerCnic: customerCnic,
      Status: document.getElementById("submittedId").value,
      VoucherId: document.getElementById("voucherfilter").value,
    };
    setloading(true);
    dispatch(getallunitpopulatetable(data, link));
  };
  useEffect(() => {
    if (resultsunittable.data) {
      setallLinks(resultsunittable.data);
      setfilterdata(resultsunittable.data.data);
      setloading(false);
    }
  }, [resultsunittable]);

  useEffect(() => {
    if (resultsallcontractspopulate.data) {
      setallCustomers(resultsallcontractspopulate.data?.Buyers);
      setallUnits(resultsallcontractspopulate.data?.Units);
    }
  }, [resultsallcontractspopulate]);
  const onTextChangeBuyer = () => {
    var inputValue = document.getElementById("buyerfilterId").value;
    var options = document.getElementById("buyerfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allCustomers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;

        setcustomerCnic(val.CustomerCnicNavigation?.CustomerCnic);
        break;
      }
      setcustomerCnic("");
    }
  };

  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("unitfilterId").value;
    var options = document.getElementById("unitfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allUnits.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setunitId(val.UnitId);
        setContractId(
          val.Contract?.length > 0 ? val.Contract[0]?.ContractId : ""
        );
        break;
      }
      setunitId("");
      setContractId("");
    }
  };
  function showmodal2(data) {
    setunitRecieptRowData(data);
    document.getElementById("mymodal2").style.display = "block";
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }
  function deleteUnitRecipteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading2(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Password: DeleteVoucherData?.Password,
        Email: DeleteVoucherData?.Email,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          let data = {
            ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
            From: document.getElementById("fromdate").value,
            To: document.getElementById("todate").value,
            ContractId: contractId,
            UnitId: unitId,
            CustomerCnic: customerCnic,
            Status: document.getElementById("submittedId").value,
            VoucherId: document.getElementById("voucherfilter").value,
          };
          setloading(true);
          dispatch(getallunitpopulatetable(data));
          // dispatch(getAllContractTablePopulate());
          toast.info(result.data.Message);
          setloading2(false);
          setunitRecieptRowData(null);
          setSelectedVouchers([]);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          setloading2(false);
          // setvoucherRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
    setloading(false);
  }

  function handleCheckboxChange(i) {
    let arr = filterdata
      ?.filter((itm) => itm.checked)
      .map((itm) => itm.UnitVoucherId);
    setSelectedVouchers(arr);
    // filterdata?.forEach((itm) => {
    //   if (itm.checked) {
    //     arr.push(itm.GeneralVoucherId
    //     )
    //   }
    // })
    // setSelectedVouchers(arr)
  }
  // const onTextChange = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   setfilterObject({ ...filterObject, [name]: value });
  // };

  const [CsvData, setCsvData] = useState([]);
  const [newData, setNewData] = useState([]);
  const csvLinkRef = useRef();

  const handleDownload = (vt) => {
    downloadCsv(vt)
      .then((csvData) => {
        setNewData(csvData);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  useEffect(() => {
    if (newData.length > 0) {
      console.log("newData", newData);
      let arr = [];
      var i = 1;
      newData?.forEach((val) => {
        let year = val.EntryDate.slice(0, 4);
        let day = val.EntryDate.slice(8, 10);
        let month = val.EntryDate.slice(5, 7);
        arr.push({
          Sr: i++,
          Date: day + "-" + month + "-" + year,
          Voucher: val.UnitVoucherId,
          Customer:
            val.Contract !== undefined
              ? val.Contract.CustomerCnicNavigation.CustomerName
              : "",
          Unit: val.Contract !== undefined ? val.Contract?.Unit?.UnitName : "",
          Category:
            val.Contract !== undefined
              ? val.Contract?.UnitCategory?.CategoryName
              : "",

          Narration: val.Narration,
          Amount: parseFloat(val.Amount).toLocaleString(),
          User: val?.AddedBy?.UserName,
        });
      });

      setCsvData(arr);
    }
  }, [newData]);
  useEffect(() => {
    if (csvLinkRef.current && CsvData.length > 0) {
      csvLinkRef.current.link.click();
      setCsvData([]);
    }
  }, [CsvData]);

  const handlePrint = (VoucherId) => {
    setshowfullScreenLoader(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Vouchers/UnitReceipt/GetUnitReceiptPrintData?VoucherId=${VoucherId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        // document.getElementById("headingsave").disabled = false;
        if (result.data.Succeeded === true) {
          SetPrintData(result.data.Data);
          settoggle(!statetoggle);
          setshowfullScreenLoader(false);
        } else {
          toast.info(result.data.Message);
          setshowfullScreenLoader(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
      }
    );
  };

  return (
    <>
      {printData ? (
        <div hidden={printData ? false : true}>
          {/* <UnitReceiptPrint ref={componentRef} printData={printData} /> */}
          <UnitReceiptPrintNew
            printData={printData}
            setPrintData={SetPrintData}
          />
        </div>
      ) : (
        <>
          <div className="row pt-3">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div
              hidden
              className="col-md-2 pr-0 pl-0"
            >
              <input
                name="date"
                value={filterObject?.date}
                autoComplete="off"
                readOnly
                onMouseDown={(e) => {
                  e.target.removeAttribute("readOnly");
                }}
                onChange={(e) => {
                  setfilterObject({
                    ...filterObject,
                    [e.target.name]: e.target.value,
                  });
                }}
                type="date"
                className="input_styling "
                id="datefilter"
                placeholder="Date"
              />
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                name="voucherNumber"
                value={filterObject?.voucherNumber}
                autoComplete="off"
                readOnly
                onMouseDown={(e) => {
                  e.target.removeAttribute("readOnly");
                }}
                onChange={(e) => {
                  setfilterObject({
                    ...filterObject,
                    [e.target.name]: e.target.value,
                  });
                }}
                type="text "
                className="input_styling "
                id="voucherfilter"
                placeholder="Voucher"
              />
            </div>
            {/* <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="customerfilter"
            onChange={onTextChange}
            placeholder="Customer"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="unitfilter"
            onChange={onTextChange}
            placeholder="Unit"
          ></input>
        </div> */}
            <div className="col-md-2 pr-0 pl-0">
              <input
                className="input_styling ml-md-3"
                id="unitfilterId"
                autoComplete="off"
                defaultValue={""}
                readOnly
                onMouseDown={(e) => {
                  e.target.removeAttribute("readOnly");
                }}
                placeholder="Unit"
                list="unitfilterIdOpt"
                onChange={onTextChangeUnit}
              />
              <datalist id="unitfilterIdOpt">
                {allUnits?.map((x, idx) => {
                  if (x.Contract?.length > 0) {
                    return (
                      <option
                        key={idx}
                        index={idx}
                        value={x.UnitName}
                      >
                        {x.UnitId}
                      </option>
                    );
                  }
                })}
              </datalist>
            </div>
            {/* <div className="col-md-2 pr-0 pl-0">
          <select
            id="categoryname"
            className="input_styling  ml-md-3"
          >
            <option value={""}>Select Category</option>
            {resultsCategory?.data !== undefined
              ? resultsCategory?.data?.map((x, index) => (
                  <option
                    data-id={x.UnitCategoryId}
                    data-idx={index}
                    key={index}
                  >
                    {x.CategoryName}
                  </option>
                ))
              : null}
          </select>
        </div> */}
            <div className="col-md-2 pr-0 pl-0">
              <select
                id="paymentType"
                className="input_styling  ml-md-3"
              >
                <option value={""}>Payment Type</option>
                <option value="Cash">Cash</option>
                <option value="Bank">Bank</option>
              </select>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                className="input_styling ml-md-3"
                id="buyerfilterId"
                defaultValue={""}
                autoComplete="off"
                readOnly
                onMouseDown={(e) => {
                  e.target.removeAttribute("readonly");
                }}
                placeholder="Buyer"
                list="buyerfilterIdOpt"
                onChange={onTextChangeBuyer}
              />
              <datalist id="buyerfilterIdOpt">
                {allCustomers?.map((x, idx) => (
                  <option
                    key={idx}
                    index={idx}
                    value={x?.CustomerCnicNavigation?.CustomerName}
                  >
                    {x?.CustomerCnicNavigation?.CustomerCnic2}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="col-md-2 pr-0 pl-0 ">
              <select
                name="status"
                value={filterObject?.status}
                className="input_styling ml-md-3"
                id="submittedId"
                onChange={(e) => {
                  setfilterObject({
                    ...filterObject,
                    [e.target.name]: e.target.value,
                  });
                }}
                readOnly="readonly"
                onMouseDown={(e) => {
                  e.target.removeAttribute("readonly");
                }}
              >
                <option value={""}>All</option>
                <option value={false}>Pending</option>
                <option value={true}>Submitted</option>
              </select>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <UnitReceiptModal />
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {resultsunittable.loading || loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>

          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0"></div>
            <div className="col-md-4   pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
              ></input>
            </div>
            <div className="col-md-4  pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button
                className="btn_Go"
                onClick={ongo}
              >
                Go
              </button>
            </div>
            <div className="col-md-12 pr-0 pl-0">
              {selectedVouchers.length > 0 && (
                <div className="col-md-12 mt-2">
                  <div className="float-right">
                    {loading2 && (
                      <Loader
                        type="ThreeDots"
                        color="green"
                        height={40}
                        width={40}
                      />
                    )}
                    <span>
                      <button
                        onClick={() => {
                          showmodal2(selectedVouchers);
                        }}
                        className="cancel_btn ml-2"
                      >
                        <MdDeleteForever />
                        Delete All
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="table-responsive vouchers_table_height4 mt-2">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>{""}</th>
                  <th style={{ minWidth: "85px" }}>Date</th>
                  <th style={{ minWidth: "85px" }}>Added On</th>
                  <th style={{ minWidth: "120px" }}>Voucher</th>
                  <th>Customer</th>
                  {/* <th>Project</th> */}
                  <th>Unit</th>
                  <th>Category</th>

                  <th>Narration</th>
                  <th style={{ minWidth: "65px" }}>Amount</th>
                  <th style={{ minWidth: "140px" }}>User</th>
                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        // onClick={() => {
                        //   props.showunittable();
                        // }}
                        onClick={() =>
                          history.push(
                            "/account/vouchers/receipt-entry/unit-receipt/add"
                          )
                        }
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterdata !== undefined
                  ? filterdata.map((val, index) => {
                      let year = val.EntryDate.slice(0, 4);
                      let day = val.EntryDate.slice(8, 10);
                      let month = val.EntryDate.slice(5, 7);
                      switch (month) {
                        case "01":
                          month = "Jan";

                          break;
                        case "02":
                          month = "Feb";

                          break;
                        case "03":
                          month = "Mar";

                          break;
                        case "04":
                          month = "Apr";

                          break;
                        case "05":
                          month = "May";

                          break;
                        case "06":
                          month = "Jun";

                          break;
                        case "07":
                          month = "Jul";

                          break;
                        case "08":
                          month = "Aug";

                          break;
                        case "09":
                          month = "Sep";

                          break;
                        case "10":
                          month = "Oct";
                          break;
                        case "11":
                          month = "Nov";
                          break;
                        case "12":
                          month = "Dec";
                          break;
                        default:
                          break;
                      }
                      return (
                        <tr
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            showmodal(val);
                            // SetPrintDataModal(val.Transaction);
                          }}
                        >
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                          >
                            {index +
                              1 +
                              (allLinks?.PageNumber - 1) * allLinks?.PageSize}
                          </td>
                          <td>
                            {localStorage.getItem("UserName") === "Admin" ? (
                              <input
                                checked={val.checked ? val.checked : false}
                                key={index}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  filterdata[index].checked = e.target.checked;
                                  setrendarComp(!rendarComp);
                                  handleCheckboxChange(index);
                                }}
                                type="checkbox"
                                id={`checkedVoucher${index}`}
                              />
                            ) : null}
                          </td>
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                          >
                            {day + "-" + month + "-" + year}
                          </td>
                          <td className="table_data">
                            {val.AddedOnDate
                              ? moment(
                                  val.AddedOnDate?.slice(0, 10),
                                  "YYYY-MM-DD"
                                )?.format("DD-MMM-YYYY")
                              : "N/A"}
                          </td>
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                          >
                            {val.UnitVoucherId}
                          </td>
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   // SetPrintDataModal(val.Transaction);
                            // }}
                          >
                            {val.Contract !== undefined ? (
                              val.Contract.CustomerCnicNavigation.CustomerName
                            ) : (
                              <td></td>
                            )}
                          </td>
                          {/* {val.Project !== undefined && val.Project !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Project.ProjectName}
                        </td>
                      ) : (
                        <td></td>
                      )} */}
                          <td className="table_data">
                            {val?.Contract?.Unit?.UnitName
                              ? val?.Contract?.Unit?.UnitName
                              : "N/A"}
                          </td>
                          <td className="table_data">
                            {val?.Contract?.UnitCategory?.CategoryName
                              ? val?.Contract?.UnitCategory?.CategoryName
                              : " N/A"}
                          </td>
                          <td
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                            className="table_data"
                          >
                            {val.Narration}
                          </td>
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                          >
                            {parseFloat(val.Amount).toLocaleString()}
                          </td>
                          <td
                            className="table_data"
                            // onClick={() => {
                            //   showmodal(val.Transaction);
                            //   SetPrintDataModal(val.Transaction);
                            // }}
                          >
                            {val?.AddedBy?.UserName}
                          </td>
                          <td className="text-center ">
                            {rolesdata?.data?.Access?.includes("U") ? (
                              <RiFileEditLine
                                title="Edit"
                                size="1em"
                                color={
                                  val.Submitted === false ? "black" : "#707070"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (val.Submitted === false) {
                                    // props.setvoucherData(val);
                                    history.push({
                                      pathname:
                                        "/account/vouchers/receipt-entry/unit-receipt/add",
                                      state: { vouchData: val },
                                    });
                                  } else {
                                    toast.info("Can't edit voucher");
                                  }
                                  // props.showgeneraltable();
                                }}
                                className="cur_sor"
                              />
                            ) : null}
                            &nbsp;
                            <GrPrint
                              title="Print"
                              className="cur_sor"
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePrint(val.UnitVoucherId);
                              }}
                              size="1em"
                            />
                            &nbsp;
                            {/* {localStorage.getItem("UserName") === "Admin" ? (
                          <MdDeleteForever
                            onClick={(e) => {
                              e.stopPropagation();
                              showmodal2(val);
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null} */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          <div className="col-12  text-right pr-0 pl-0">
            <button
              disabled={allLinks?.LastPage ? false : true}
              className="print_btn float-right mx-1"
              onClick={() => getPaginationData(allLinks?.LastPage)}
            >
              Last
            </button>
            <button
              disabled={allLinks?.PreviousPage ? false : true}
              className="print_btn float-right mx-1"
              onClick={() => getPaginationData(allLinks?.PreviousPage)}
            >
              Previous
            </button>
            <button
              disabled={allLinks?.NextPage ? false : true}
              className="print_btn float-right mx-1"
              onClick={() => getPaginationData(allLinks?.NextPage)}
            >
              Next
            </button>
            <button
              disabled={allLinks?.FirstPage ? false : true}
              className="print_btn float-right pl-1 mx-1"
              onClick={() => getPaginationData(allLinks?.FirstPage)}
            >
              First
            </button>
          </div>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
            onClick={() => handleDownload("URV")}
          >
            Download CSV
          </button>
          <CSVLink
            ref={csvLinkRef}
            style={{ display: "none" }} // Hide the link element
            filename="Unit_Receipt_Csv"
            data={CsvData}
          >
            Download CSV
          </CSVLink>
          <UnitReceiptDeleteVoucherModal
            closemodal2={closemodal2}
            DeleteVoucherData={DeleteVoucherData}
            handelDeleteVouvherdataChange={handelDeleteVouvherdataChange}
            loading2={loading2}
            setunitRecieptRowData={setunitRecieptRowData}
            deleteUnitRecipteVoucher={deleteUnitRecipteVoucher}
          />
          <UnitReceiptTransactionDetailModal
            closemodal={closemodal}
            TransactionData={TransactionData}
            debitState={debitState}
            creditState={creditState}
            printDataModal={printDataModal}
            voucherId={voucherId}
            entryDate={entryDate}
          />
          {showfullScreenLoader && (
            <FullScreenLoader setshowModal={setshowfullScreenLoader} />
          )}
        </>
      )}
    </>
  );
};
