import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";

import { toast } from "react-toastify";
import moment from "moment";
// import { GrPrint } from "react-icons/gr";
// import AdminSharePayableReportPrintAll from "../ReportPrints/AdminSharePayableReportPrintAll";
import { useDispatch, useSelector } from "react-redux";
import { getPendingLandAmount } from "../../../actions/AccountReports/landinstalltracker";
import LandInstallTrackerPrint from "../ReportPrints/LandInstallTrackerPrint";
import AdminSharePayableReportPrint from "../ReportPrints/AdminSharePayableReportPrint";

const LandInstallTracker = () => {
  const [filterdata, setfilterdata] = useState([]);

  const [csvData, setcsvData] = useState([]);
  const [ModalData, setModalData] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateLoading, setDateLoading] = useState(false);
  const dispatch = useDispatch();
  const resultsgetlandpay = useSelector((state) => state.GetLandPendingAmount);
  // print function
  const AllcomponentRef = useRef();
  const AllprintRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  const IndvidualcomponentRef = useRef();

  // const IndividualPrintRec = useReactToPrint({
  //   content: () => IndvidualcomponentRef.current,
  //   onAfterPrint: () => setModalData(null),
  // });

  const onTextChange = (e) => {
    if (
      document.getElementById("creditorFilter").value === "" &&
      document.getElementById("nameFilter").value === ""
    ) {
      setfilterdata(resultsgetlandpay?.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("creditorFilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("nameFilter").value,
          "i"
        );

        suggestions = resultsgetlandpay?.data
          ?.sort()
          ?.filter(
            (val) =>
              (document.getElementById("creditorFilter").value === "" ||
                regex.test(val?.LandCreditor?.LandCreditorName)) &&
              (document.getElementById("nameFilter").value === "" ||
                regex1.test(val?.SqaNumber))
          );
      }
      setfilterdata(suggestions);
    }
  };

  //   useEffect(() => {
  //     if (ModalData) {
  //       IndividualPrintRec();
  //     }
  //   }, [ModalData]);
  // useEffect(() => {
  //   dispatch(getPendingLandAmount(fromDate, toDate));
  // }, [dispatch]);
  useEffect(() => {
    if (resultsgetlandpay.data) {
      setDateLoading(false);
      setfilterdata(resultsgetlandpay.data);
    }
  }, [resultsgetlandpay]);

  useEffect(() => {
    let arr = [];

    // Find the maximum length of LandPurchasedInstallment array
    const maxInstallmentsLength = filterdata?.reduce((max, item) => {
      return Math.max(max, item?.LandPurchasedInstallment?.length || 0);
    }, 0);

    filterdata?.forEach((item) => {
      let installmentAmount = {};

      // Add Installments properties based on the maximum length
      for (let i = 0; i < maxInstallmentsLength; i++) {
        installmentAmount[`Installment ${i + 1}`] = item
          ?.LandPurchasedInstallment?.[i]
          ? item.LandPurchasedInstallment[i]?.InstallmentAmount -
          item.LandPurchasedInstallment[i]?.PaidAmount
          : "";
        installmentAmount[`Due Date ${i + 1}`] = item
          ?.LandPurchasedInstallment?.[i]
          ? moment(item.LandPurchasedInstallment[i]?.DueDate).format(
            "DD-MMM-YYYY"
          )
          : "";
      }
      console.log("installmentAmount", installmentAmount);
      arr.push({
        ["Land Creditor"]: item?.LandCreditor?.LandCreditorName,
        ["Muraba No."]: item?.SqaNumber,
        ["Area (Marla)"]: item?.NetMarla,
        Amount: item?.LandValue,
        ["Paid Amount"]: item?.LandValuePaid,
        ["Remaining Amount"]: item?.LandValue - item?.LandValuePaid,
        ...installmentAmount,
      });
    });

    setcsvData(arr);
  }, [filterdata]);

  const savedata = () => {
    document.getElementById("go").disabled = true;
    // (fromDate === "" || toDate === "")
    if (
      fromDate === "" ||
      toDate === "" ||
      (fromDate !== "" && toDate === "")
    ) {
      toast.info("Select From and To Date");
      document.getElementById("go").disabled = false;
    } else {
      setDateLoading(true);
      dispatch(getPendingLandAmount(fromDate, toDate));
      document.getElementById("go").disabled = false;
    }
  };

  return (
    <>
      <div hidden>
        <AdminSharePayableReportPrint
          ref={IndvidualcomponentRef}
          Data={ModalData}
        />
      </div>

      <div hidden>
        <LandInstallTrackerPrint
          ref={AllcomponentRef}
          Data={filterdata}
        />
      </div>

      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row mt-0">
            <div className="col-12 mt-0">
              <div className="row ">
                <div className="col-md-3 text-center  pr-0 pl-0">
                  <label className="input_label m-0">From:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="fromdate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  ></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                  <label className="input_label m-0">To:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="todate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <button
                    className="btn_Go"
                    onClick={savedata}
                    id="go"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  {dateLoading && (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#CAF5F5", height: "1px" }}
              className="col-12 m-2"
            ></div>
            <div className="col-12">
              <div className="row ">
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0">Search:</label>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="creditorFilter"
                    onChange={onTextChange}
                    placeholder="Land Creditor"
                  ></input>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="nameFilter"
                    onChange={onTextChange}
                    placeholder="SQA #"
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive vouchers_table_height3 mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>
                    <th scope="col">Land Creditor</th>
                    <th scope="col">SQA #</th>
                    <th scope="col">Net Marla</th>

                    <th scope="col">Amount</th>
                    <th scope="col">Paid Amount</th>
                    <th scope="col">Remaining Amount</th>
                    <th scope="col">Installment | Due Date</th>

                    {/* <th scope="col">Print</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((x, id) => (
                      <>
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td>{x?.LandCreditor?.LandCreditorName}</td>
                          <td>{x?.SqaNumber}</td>
                          <td>{x?.NetMarla}</td>

                          <td>{x?.LandValue?.toLocaleString()}</td>
                          <td>{x?.LandValuePaid?.toLocaleString()}</td>
                          <td>
                            {(
                              x?.LandValue - x?.LandValuePaid
                            )?.toLocaleString()}
                          </td>
                          <td>
                            <ol
                              type="i"
                              style={{
                                // listStyleType: "none",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {x?.LandPurchasedInstallment?.map(
                                (land, idx) => (
                                  <li key={idx}>
                                    {(
                                      land?.InstallmentAmount -
                                      land?.PaidAmount
                                    )?.toLocaleString() +
                                      " | " +
                                      moment(land?.DueDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                  </li>
                                )
                              )}
                            </ol>
                          </td>

                          {/* <td>
                              <GrPrint
                                className="reportcommissiondetails"
                                onClick={() => {
                                  setModalData(x);
                                }}
                              />
                            </td> */}
                        </tr>
                      </>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              disabled={filterdata?.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                AllprintRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {filterdata?.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Land Installment Track"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandInstallTracker;
