import moment from "moment";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetAllLoanTableData,
  getloanpopulatetable,
} from "../../../actions/getlaonaction";
import { InvestmentPrint } from "../InvestmentPrint/InvestmentPrint";
import InvestmentUpdateModal from "./investmentUpdateModal";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const InvestmentTable = (props) => {
  const resultallloans = useSelector((state) => state.getLoanTable);
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [updateData, setupdateData] = useState(null);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const history = useHistory();

  // print function
  const componentRef = useRef();
  const [statelenderdata, setlenderdata] = useState([]);
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getloanpopulatetable());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultallloans.data
          .sort()
          .filter((val) => regex.test(val.LoanName));
      }
      suggestionlist = suggestions;
      dispatch(GetAllLoanTableData(suggestionlist));
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getloanpopulatetable());
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setlenderdata(data.LoanAgainstLand);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    resultallloans.data?.forEach((val) => {
      arr.push({
        Sr: i++,
        Investor: val.Lender !== undefined ? val.Lender.LenderName : null,
        "Inv Name": val.LoanName,
        "Entry Date": moment(val?.EntryDate).format("DD-MMM-YYYY"),
        "Due Date": moment(val?.DueDate).format("DD-MMM-YYYY"),
        "Invst Amt": parseFloat(val.Amount).toLocaleString(),
        "Acc Bal":
          val.Lender?.ProjectLenders[0] !== undefined
            ? val.Lender?.ProjectLenders[0]?.LongAccount?.CurrentBalance?.toLocaleString()
            : null,
        "Profit %": val.NetProfit,
        "T&C": val.TermsAndConditions !== null ? val.TermsAndConditions : null,
      });
    });
    setCsvData(arr);
  }, [resultallloans.data]);

  return (
    <>
      <div hidden>
        <InvestmentPrint
          ref={componentRef}
          printData={resultallloans}
        />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label  m-0">Search:</label>
        </div>
        <div className="col-md-3   pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder="Investment Name"
          ></input>
        </div>

        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultallloans.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive vouchers_table_height2 mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Investor</th>
                  <th>Inv Name</th>
                  <th>Entry Date</th>
                  <th>Due Date</th>
                  <th>Invst Amt</th>
                  <th>Acc Bal</th>
                  <th>Profit %</th>
                  <th>T&C</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        // onClick={() => {
                        //     props.showloantable();
                        // }}
                        onClick={() => {
                          history.push("/account/investment/Investment/add");
                        }}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultallloans.data.map((val, index) => {
                  // let year = val.EntryDate.slice(0, 4);
                  // let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  // let year1 = val.DueDate.slice(0, 4);
                  // let day1 = val.DueDate.slice(8, 10);
                  // let month1 = val.DueDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr
                      className="cur_sor"
                      key={index}
                    >
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {val.Lender !== undefined
                          ? val.Lender.LenderName
                          : null}
                      </td>

                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {val.LoanName}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {moment(val?.EntryDate).format("DD-MMM-YYYY")}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {moment(val?.DueDate).format("DD-MMM-YYYY")}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {val.Lender?.ProjectLenders[0] !== undefined
                          ? val.Lender?.ProjectLenders[0]?.LongAccount?.CurrentBalance?.toLocaleString()
                          : null}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {val.NetProfit}
                      </td>

                      <td
                        onClick={() => {
                          showmodal(val);
                        }}
                      >
                        {val.TermsAndConditions !== null
                          ? val.TermsAndConditions
                          : null}
                      </td>

                      <td className="text-center">
                        {rolesdata?.data?.Access?.includes("U") ? (
                          <FiEdit2
                            onClick={() => {
                              setupdateData(val);
                              setshowUpdateModal(true);
                            }}
                            size="1em"
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Investment_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>

      <div
        id="mymodal"
        className="modal"
      >
        <div className="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Investment Units Name</h4>
              <span
                class="close pr-2  "
                onClick={closemodal}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              {statelenderdata !== undefined && statelenderdata !== null
                ? statelenderdata.map((val, index) => (
                  <ol
                    key={index}
                    onClick={() => {
                      showmodal(val);
                    }}
                  >
                    <li>
                      {val.Unit !== undefined ? val.Unit.UnitName : null}
                    </li>
                  </ol>
                ))
                : null}
            </div>
          </div>
        </div>
      </div>

      {showUpdateModal && (
        <InvestmentUpdateModal
          setshowModal={setshowUpdateModal}
          data={updateData}
        />
      )}
    </>
  );
};
