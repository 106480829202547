import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ data, labels, colors, height, heading }) => {
  const seriesData = data;
  const options = {
    title: {
      text: `${heading ? heading : ""}`,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    chart: {
      type: "donut",
    },
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={seriesData}
        type="donut"
        height={height ? height : 320}
      />
    </div>
  );
};

export default PieChart;
