import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
// import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallSalaryTable } from "../../../actions/vouchersactions/salarypayment";
import { SalaryPaymentPrint } from "../../accpayablepages/vouchersprints/paymentprint";
import SalaryPaymentTransactionDetailModal from "./SalaryPaymentTransactionDetailModal";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const SalaryPaymentTable = (props) => {
  const resultsloanpaytable = useSelector((state) => state.GetAllSalaryTable);
  const [TransactionData, setTransactionData] = useState();
  const dispatch = useDispatch();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const rolesdata = useSelector((state) => state.GetAllRole);
  const history = useHistory();

  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  useEffect(() => {
    if (printData !== undefined) {
      printPaymentRec();
    }
  }, [printData, statetoggle]);
  //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallSalaryTable({}));
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsloanpaytable.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.SalaryVoucherId) || regex.test(val.Employee !== undefined && val.Employee !== null ? val.Employee.EmpName : null)));

  //         }
  //         suggestionlist = suggestions;
  //         dispatch(getallSalaryTable(suggestionlist));
  //         setfilterdata(suggestionlist);

  //     }
  // };
  const onTextChange = (e) => {
    if (
      document.getElementById("employeefilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === ""
    ) {
      setfilterdata(resultsloanpaytable.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("employeefilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );

        suggestions = resultsloanpaytable.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("employeefilter").value === "" ||
                regex.test(val.Employee.EmpName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.SalaryVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate))
          );
      }
      setfilterdata(suggestions);
    }
  };

  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
          ? (debit += parseFloat(val.Amount))
          : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  useEffect(() => {
    let data = {};
    dispatch(getallSalaryTable(data));
  }, [dispatch]);
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallSalaryTable(data));
  }
  useEffect(() => {
    setfilterdata(resultsloanpaytable.data);
  }, [resultsloanpaytable]);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.SalaryVoucherId,
        Employee:
          val.Employee !== undefined && val.Employee !== null
            ? val.Employee.EmpName
            : "",
        "Pay Month":
          val.Payslip !== undefined && val.Payslip !== null
            ? moment(val.Payslip.PayslipMonth.split("T")[0]).format(
              "DD-MMM-YYYY"
            )
            : "",
        Amount: parseFloat(val.Amount).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);

  return (
    <>
      <div hidden>
        <SalaryPaymentPrint
          ref={componentRef}
          printData={printData}
        />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          ></input>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          ></input>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="employeefilter"
            onChange={onTextChange}
            placeholder="Employee"
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {resultsloanpaytable.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="fromdate"
          ></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="todate"
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button
            className="btn_Go"
            onClick={ongo}
          >
            Go
          </button>
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th style={{ minWidth: "85px" }}>Date</th>
              <th style={{ minWidth: "85px" }}>Added On</th>
              <th style={{ minWidth: "120px" }}>Voucher No.</th>
              <th>Employee</th>
              <th>Pay Month</th>
              <th> Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    // onClick={() => {
                    //   props.showsalarytable();
                    // }}
                    onClick={() =>
                      history.push(
                        "/account/vouchers/payment-entry/salary-payment/add"
                      )
                    }
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.SalaryVoucherId}
                      </td>
                      {val.Employee !== undefined && val.Employee !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Employee.EmpName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {val.Payslip !== undefined && val.Payslip !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {moment(
                            val.Payslip.PayslipMonth.split("T")[0]
                          ).format("DD-MMM-YYYY")}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>
                      <td
                        className="text-center cur_sor"
                        onClick={() => {
                          SetPrintData(val);
                          settoggle(!statetoggle);
                        }}
                      >
                        <GrPrint size="1.3em" />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Salary_Payment_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>

      <SalaryPaymentTransactionDetailModal
        debitState={debitState}
        creditState={creditState}
        printDataModal={printDataModal}
        closemodal={closemodal}
        TransactionData={TransactionData}
      />
    </>
  );
};
