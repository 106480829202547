import axios from "axios";
import { toast } from "react-toastify";
export const GetAllRoles = (data) => {
  return {
    type: "get_allrole",
    payload: data,
    loader: false,
  };
};
export function getallRoles() {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Auth/Role/Get?Id=${localStorage.getItem("LoginId")}`;
    if (localStorage.getItem("ProjectId") !== null) {
      pgurl = `${
        process.env.REACT_APP_API_URL
      }Auth/Role/Get?Id=${localStorage.getItem(
        "LoginId"
      )}&ProjectId=${localStorage.getItem("ProjectId")}`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllRoles(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllRolesTable = (data) => {
  return {
    type: "get_allroletable",
    payload: data,
    loader: false,
  };
};
export function getallRolesTable() {
  return (dispatch) => {
    axios({
      // url: `${process.env.REACT_APP_API_URL}Auth/Role/GetAll`,
      url: `${process.env.REACT_APP_API_URL}Roles/getAllRole`,

      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllRolesTable(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
