import React, { createContext, useState, useContext } from "react";

// Create a context for subbtn
const SubbtnContext = createContext();

// Create a custom provider component for SubbtnContext
export const SubbtnProvider = ({ children }) => {
  const [subbtn, setSubbtn] = useState(null);
  const [tab, setTab] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  return (
    <SubbtnContext.Provider
      value={{
        subbtn,
        setSubbtn,
        tab,
        setTab,
        scrollPosition,
        setScrollPosition,
      }}
    >
      {children}
    </SubbtnContext.Provider>
  );
};

// Create a custom hook to access the subbtn array
export const useSubbtn = () => {
  const subbtn = useContext(SubbtnContext);
  if (subbtn === undefined) {
    throw new Error("useSubbtn must be used within a SubbtnProvider");
  }
  return subbtn;
};
