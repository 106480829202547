import React, { useEffect, useRef, useState } from "react";
import { TiPrinter } from "react-icons/ti";
import { useSelector, useDispatch } from "react-redux";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import RecoveryPrint from "../ReportPrints/ManagerRecoveryPrint";
import moment from "moment";

const ManagerRecovery = () => {
  const resultsgetaccpay = useSelector((state) => state.getRecoveryReport);
  const [filterdata, setfilterdata] = useState();

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  let Total = 0;
  const dispatch = useDispatch();
  //Table Data fiter

  // var suggestionlist = [];

  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getrecoveryreport());
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsgetaccpay.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.RecoveryBookingVoucherId) || regex.test(val.Manager !== null ? val.Manager.Employee.EmpName : null)));

  //         }
  //         suggestionlist = suggestions;
  //         dispatch(GetRecoveryReport(suggestionlist));
  //     }
  // };
  const onTextChange = (e) => {

    if (document.getElementById("voucherfilter").value === "" && document.getElementById("unitfilter").value === "" && document.getElementById("managerfilter").value === "" && document.getElementById("statusfilter").value === "") {
      setfilterdata(resultsgetaccpay.data);

    } else {
      let suggestions = [];
      {
        const regex = new RegExp(document.getElementById("voucherfilter").value, "i");
        const regex1 = new RegExp(document.getElementById("managerfilter").value, "i");
        const regex2 = new RegExp(document.getElementById("unitfilter").value, "i");
        const regex3 = new RegExp(document.getElementById("statusfilter").value, "i");






        suggestions = resultsgetaccpay.data
          .sort()
          .filter((val) =>
            (document.getElementById("voucherfilter").value === "" || regex.test(val.RecoveryBookingVoucherId)) &&
            (document.getElementById("managerfilter").value === "" || regex1.test(val.Manager.Employee.EmpName)) &&
            (document.getElementById("unitfilter").value === "" || regex2.test(val.Unit.UnitName)) &&
            (document.getElementById("statusfilter").value === "" || regex3.test(val.Paid !== true ? "Pending" : "Recoverd"))



          );

      }
      setfilterdata(suggestions);
    }
  };
  useEffect(() => {

    dispatch(getrecoveryreport());
  }, [dispatch]);
  useEffect(() => {
    setfilterdata(resultsgetaccpay.data);

  }, [resultsgetaccpay]
  )
  // resultsgetaccpay.data.map((val, index) => {
  //     Total += parseFloat(val.CurrentBalance);
  // })


  return (
    <>
      <div hidden>
        <RecoveryPrint
          ref={componentRef}
          // Total={Total}
          printData={resultsgetaccpay}
        />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="voucherfilter"
                onChange={onTextChange}
                placeholder="Voucher "
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="managerfilter"
                onChange={onTextChange}
                placeholder="Manager"
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="unitfilter"
                onChange={onTextChange}
                placeholder="Unit"
              ></input>
            </div>
            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="statusfilter"
                onChange={onTextChange}
                placeholder="Status"
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {filterdata !== undefined ? (
                filterdata.loading ? (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                ) : null
              ) : null}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive vouchers_table_height2 mt-3">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr </th>
                    <th>Date</th>
                    <th>Voucher Id</th>
                    <th>Manager</th>

                    <th>Unit</th>
                    <th>Advance Amount</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((val, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {moment(val.Entrydate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )}
                        </td>
                        <td>{val.RecoveryBookingVoucherId}</td>
                        <td>
                          {val.Manager !== null &&
                            val.Manager.Employee !== undefined
                            ? val.Manager.Employee.EmpName
                            : null}
                        </td>
                        <td>
                          {val.Unit !== null ? val.Unit.UnitName : null}
                        </td>

                        <td>
                          {val.Contract.AdvanceAmount !== null
                            ? val.Contract.AdvanceAmount.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {val.Amount !== null
                            ? val.Amount.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {val.Paid !== true ? "Pending" : "Recoverd"}
                        </td>
                      </tr>
                    ))
                    : null}
                  {/* <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td> <label className="input_label m-0">Total:</label></td>
                                        <td>  <input type="text " className="input_total_styling" value={parseFloat(Total).toLocaleString()} id="total" disabled></input></td>
                                        <td></td>
                                    </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagerRecovery;