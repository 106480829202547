import axios from "axios";
import moment from "moment";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getloanpopulatetable } from "../../../actions/getlaonaction";

export default function InvestmentUpdateModal({ setshowModal, data }) {
  const dispatch = useDispatch();
  const UpdateData = () => {
    if (document.getElementById("investmentDate").value === "") {
      toast.info("Please Enter Date");
      return;
    }
    if (document.getElementById("investmentNewAmount").value === "") {
      toast.info("Please Enter Amount");
      return;
    }
    let Loans = {
      LoanId: data.LoanId,
      Amount: parseInt(
        document.getElementById("investmentNewAmount").value.replace(/,/g, "")
      ),
      EntryDate: document.getElementById("investmentDate").value,
      ProjectId: data.ProjectId,
    };
    let arr = data?.LoanAgainstLands;
    document.getElementById("loansave").disabled = true;
    axios({
      method: "post",
      // url: `${process.env.REACT_APP_API_URL}Lenders/AddLoan`,
      url: `${process.env.REACT_APP_API_URL}Investment/LoanDetail`,
      data: JSON.stringify(Loans),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("loansave").disabled = false;
        if (result.data.IsSuccess === true) {
          dispatch(getloanpopulatetable());
          toast.info(result.data.Message);
          setshowModal(false);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("loansave").disabled = false;

        toast.info("Someting went wrong");
      }
    );
  };
  return (
    <div className="modal" style={{ display: "block" }}>
      <div
        className="modal-content-cat"
        style={{ display: "block", height: "fit-content" }}
      >
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Update Investment Amount</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowModal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 pl-md-4">Date:</label>
          </div>
          <div className="col-md- mt-4 ">
            <input
              type="date"
              className="input_styling ml-md-3"
              id="investmentDate"
            ></input>
          </div>
          <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0 pl-md-4">Amount:</label>
          </div>
          <div className="col-md- mt-4 ">
            <input
              type="text"
              className="input_styling  ml-md-3"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("investmentNewAmount").value =
                    parseInt(e.target.value.replace(/,/g, "")).toLocaleString();
              }}
              id="investmentNewAmount"
            ></input>
          </div>
          {/* <div className="col-md-2 mt-4 text-left p-0">
                        <label className="input_label m-0 pl-md-4">Net Amount:</label>
                    </div>
                    <div className="col-md- mt-4 ">
                        <input type="text" disabled value={(data?.Amount + parseInt(document.getElementById("investmentNewAmount")?.value?.replace(/,/g, '')))} className="input_styling  ml-md-3" id="investmentNetAmount"></input>

                    </div> */}

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="mt-4">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Entry Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data.LoanDetail !== undefined
                    ? data?.LoanDetail?.map((val, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{moment(val.EntryDate).format("DD-MMM-YYYY")}</td>

                          <td>{val.Amount?.toLocaleString()}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-md-12 p-2">
            <div className="float-right  mt-md-4 ">
              <span>
                <button
                  className="cancel_btn ml-2  "
                  onClick={() => setshowModal(false)}
                >
                  Cancel
                </button>
              </span>

              <span>
                <button
                  className="save_btn ml-2  "
                  id="loansave"
                  onClick={UpdateData}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
