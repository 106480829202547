import React from "react";
import ReactApexChart from "react-apexcharts";

const NegativeBar = ({ data, categories, colors }) => {
  const options = {
    title: {
      text: "Financial Position Overview",
    },
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          //
        },
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories,

      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val?.toLocaleString();
        },
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
  };

  const series = [
    {
      name: "Summary",
      data: data,
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width={"100%"}
        height={295}
      />
    </div>
  );
};

export default NegativeBar;
