import React, { Component } from "react";
import gravity from "../../../assets/GravityLogo.png";
class AvailabilityPrint extends Component {
    render() {

        return (
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className="row m-0 p-0">
              <div className="watermarked">
                <img
                  src={gravity}
                  style={{ height: "600px", width: "600px" }}
                  alt="Company logo"
                />
              </div>
              <div className="col-8 text-left mt-4">
                <h2 style={{ color: "#1b7a7f" }}>
                  Gravity Associates Pvt Limited
                </h2>
                <hr />
                <h5 style={{ color: "grey", marginTop: "5px" }}>
                  <i>204 R/B Road, East Canal Road, Faisalabad.</i>
                </h5>
                <div
                  style={{
                    borderTop: "2px dotted black",
                    borderBottom: "2px dotted black",
                    height: "7px",
                  }}
                ></div>
              </div>
              <div className="col-4 text-center">
                {/* IMAGE COMES HERE */}
                <img
                  src={gravity}
                  style={{
                    height: "175px",
                    width: "175px",
                    borderRadius: "100px",
                  }}
                  className="pt-2"
                  alt="Company logo"
                />
              </div>
            </div>
            <div
              className="row m-3 p-0"
              // style={{ border: "5px solid darkgrey", height: "auto" }}
            >
              <div className="col-12 text-center mt-2">
                <h4 style={{ color: "red" }}>
                  <u>Units Availability Report</u>
                </h4>

                <div className="mt-5">
                  <div class="table-responsive col-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Project</th>
                          <th>Section </th>
                          <th>Unit</th>
                          <th>Area</th>
                          <th>Dimension</th>

                          <th>Sqft</th>
                          <th>S.Rate</th>

                          <th>Total Value</th>

                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.printData.data.Units !== undefined
                          ? this.props.printData.data.Units.map(
                              (val, index) => (
                                <tr key={index}>
                                  {val.Project !== null &&
                                  val.Project !== undefined ? (
                                    <td>{val.Project.ProjectName}</td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {val.Section !== null &&
                                  val.Section !== undefined ? (
                                    <td>{val.Section.SectionName}</td>
                                  ) : (
                                    <td></td>
                                  )}
                                  <td>{val.UnitName}</td>

                                  {/* <td>{val.NetMarla}</td> */}
                                  <td>{val.NetMarla}</td>
                                  <td>
                                    {val.DimensionLength +
                                      "x" +
                                      val.DimensionWidth}
                                  </td>
                                  <td>{val.NetSqft}</td>

                                  <td>
                                    {parseFloat(
                                      val.RatePerMarla
                                    ).toLocaleString()}
                                  </td>

                                  <td>
                                    {parseFloat(val.Price).toLocaleString()}
                                  </td>

                                  <td>{val.Status}</td>
                                </tr>
                              )
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default AvailabilityPrint;