const allroles = { data: [], loading: true };
export const GetAllRoles = (state = allroles, action) => {
  switch (action.type) {
    case "get_allroles":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
