import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
// import { getallcustomers } from "../../../actions/getclients";
import { MdDeleteForever } from "react-icons/md";
import Loader from "react-loader-spinner";
import {
  getalltransferunitpopulate,
  getAllTransferUnitTable,
} from "../../../actions/accountsactions/transferunit";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const TransferUnitPage = (props) => {
  const resultallPopulateData = useSelector(
    (state) => state.GetTransferUnitPopulate
  );
  console.log("resultallPopulateData", resultallPopulateData);
  let dispatch = useDispatch();
  let history = useHistory();

  const [clientAccid, setclientAccid] = useState("");
  const [currentUnits, setCurrentUnits] = useState([]);
  const [autoAmt, setautoAmt] = useState(false);
  const [autoInstallments, setAutoInstallments] = useState(false);

  const [currentUnitId, setCurrentUnitId] = useState("");
  const [newUnitId, setNewUnitId] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmt, setRemainingAmt] = useState(0);

  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [installmentAmt, setInstallmentAmt] = useState(0);
  const [netMarla, setNetMarla] = useState(0);
  const [ratePerMarla, setRatePerMarla] = useState(0);

  const [loader2, setloader2] = useState(false);

  const [tab, settab] = useState("Custom");
  const [installmenttotal, setinstallmenttotal] = useState(0);
  const [installmentdifference, setinstallmentdifference] = useState(0);
  const [InstallmentDurationList, setInstallmentDurationList] = useState([]);
  const [render, setrender] = useState(false);
  const [autoInstallmentData, setautoInstallmentData] = useState({
    startDate: "",
    amount: "",
    noOfInstallments: "",
    interval: 1,
    dayOfMonth: 1,
    bubbleInterval: "",
    bubbleInstallment: false,
    bubbleAmt: "",
  });

  // var date = moment().format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(getalltransferunitpopulate());
  }, [dispatch]);

  useEffect(() => {
    if (autoInstallments) {
      console.log("first", Math.ceil(remainingAmt / installmentAmt));
      document.getElementById("noOfInstallments").value = Math.ceil(
        remainingAmt / installmentAmt
      );
    }
  }, [autoInstallments, remainingAmt, installmentAmt]);

  useEffect(() => {
    if (netMarla && ratePerMarla) {
      setTotalAmount(ratePerMarla * netMarla);
    }
  }, [ratePerMarla, netMarla]);

  // useEffect(() => {
  //   if (autoAmt) {
  //     setInstallmentAmt(
  //       parseInt(
  //         remainingAmt /
  //           Number(document.getElementById("noOfInstallments").value)
  //       )
  //     );
  //     document.getElementById("installmentAmount").value = parseInt(
  //       remainingAmt / Number(document.getElementById("noOfInstallments").value)
  //     ).toLocaleString();
  //   }
  // }, [autoAmt, remainingAmt]);

  useEffect(() => {
    if (advanceAmount) {
      setRemainingAmt(totalAmount - advanceAmount);
      setinstallmentdifference(totalAmount - advanceAmount);
    }
  }, [advanceAmount, totalAmount]);

  useEffect(() => {
    let total = 0;
    if (InstallmentDurationList.length !== 0) {
      InstallmentDurationList.forEach((x) => {
        total += parseFloat(x.DueAmount);
      });
    }

    setinstallmenttotal(total);
  }, [InstallmentDurationList]);

  useEffect(() => {
    if (autoAmt && autoInstallmentData.noOfInstallments) {
      setautoInstallmentData({
        ...autoInstallmentData,
        amount: Math.trunc(
          (installmentdifference - Number(installmenttotal)) /
          autoInstallmentData.noOfInstallments
        ),
      });
      setrender(!render);
    }
  }, [autoInstallmentData.noOfInstallments, autoAmt]);

  const onTextChangeContractName = () => {
    var inputValue = document.getElementById("contractName").value;
    var options = document.getElementById("contractnamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallPopulateData?.data?.Customers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        console.log("val", val);
        setCurrentUnits(val?.Units);
        setclientAccid(val.CustomerCnic);
        break;
      }
    }
  };

  const emptyfields = () => {
    setCurrentUnitId("");
    setNewUnitId("");
    setclientAccid("");
    document.getElementById("ratePerMarla").value = "";
    // document.getElementById("installmentDueDate").value = "";
    // document.getElementById("installmentAmount").value = "";
    document.getElementById("newUnit").value = "";
    document.getElementById("currentunits").value = "";
    document.getElementById("contractName").value = "";
    setTotalAmount(0);
    setRemainingAmt(0);
    setNetMarla(0);
    setAdvanceAmount(0);
    setInstallmentAmt(0);

    settab("Custom");
    setinstallmenttotal(0);
    setinstallmentdifference(0);
    setInstallmentDurationList([]);
    setrender(false);
    setautoInstallmentData({
      startDate: "",
      amount: "",
      noOfInstallments: "",
      interval: 1,
      dayOfMonth: 1,
      bubbleInterval: "",
      bubbleInstallment: false,
      bubbleAmt: "",
    });
  };

  const handleSetNetMarla = (val) => {
    let netMarla = val;
    if (netMarla && netMarla.toString().includes(".")) {
      const [integerPart, fractionalPart] = netMarla.toString().split(".");

      const repeatedFractional = fractionalPart.repeat(
        4 / fractionalPart.length
      );

      setNetMarla(parseFloat(`${integerPart}.${repeatedFractional}`));
    } else {
      setNetMarla(netMarla);
    }
  };

  const onTextChangeNewUnit = () => {
    var inputValue = document.getElementById("newUnit").value;
    var options = document.getElementById("newUnitlist").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallPopulateData.data?.NewUnits?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        setNewUnitId(val?.UnitId);
        handleSetNetMarla(val?.NetMarla);

        document.getElementById("ratePerMarla").value = parseInt(
          val?.RatePerMarla
        ).toLocaleString();
        setRatePerMarla(val?.RatePerMarla);

        break;
      }
    }
  };

  const onTextChangeCurrentUnit = () => {
    var inputValue = document.getElementById("currentunits").value;
    var options = document.getElementById("currentunitslist").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        currentUnits?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        setCurrentUnitId(val?.UnitId);
        setAdvanceAmount(val?.AdvanceAmount);
        break;
      }
    }
  };

  function generateInstallments(
    startDate,
    dayOfMonth,
    installmentAmount,
    numberOfInstallments,
    intervalMonths,
    totalAmount,
    extraInstallmentAmount,
    extraInstallmentInterval
  ) {
    // Initialize variables
    let installments = [];
    let currentDate = new Date(startDate);
    let currentDayOfMonth = currentDate.getDate();

    // If the current day is after the specified day of the month, move to the next month
    if (currentDayOfMonth > dayOfMonth) {
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Set the day of the month for the first installment
    currentDate.setDate(dayOfMonth);

    // Generate regular installments
    let j = 1;
    let k = extraInstallmentInterval;
    let totalAmt = 0;
    for (let i = 0; i <= numberOfInstallments - 1; i++) {
      // Create a new installment object with date and amount
      let installment = null;
      if (i + 1 === k) {
        installments.push({
          InstallmentNo: j,
          // date: moment(
          //   moment(startDate).add("M", Number(k)).format("YYYY-MM-DD")
          // ).format("DD-MMM-YYYY"),
          DueDate: moment(currentDate).format("DD-MMM-YYYY"),
          DueAmount: Number(extraInstallmentAmount),
        });
        j = j + 1;
        k = k + Number(extraInstallmentInterval);
        totalAmt = totalAmt + Number(extraInstallmentAmount);
      }
      if (i + 1 === Number(numberOfInstallments)) {
        if (totalAmount !== totalAmt) {
          installment = {
            InstallmentNo: j,
            DueDate: moment(currentDate).format("DD-MMM-YYYY"),
            DueAmount: Math.abs(Number(totalAmount) - totalAmt),
          };
          totalAmt = totalAmt + Number(installmentAmount);
          j = j + 1;
          installments.push(installment);
        }
      } else {
        let existingInstallment = installments.find(
          (installment) =>
            installment.DueDate === moment(currentDate).format("DD-MMM-YYYY")
        );
        if (!existingInstallment) {
          installment = {
            InstallmentNo: j,
            DueDate: moment(currentDate).format("DD-MMM-YYYY"),
            DueAmount: Number(installmentAmount),
          };
          totalAmt = totalAmt + Number(installmentAmount);
          j = j + 1;
          installments.push(installment);
        }
      }

      // Move to the next month with the specified interval
      currentDate.setMonth(currentDate.getMonth() + intervalMonths);
    }
    // Generate extra installments
    console.log("installments", installments);
    return installments;
  }

  const autoinstallments = () => {
    let amount = 0;
    if (autoAmt && autoInstallmentData.bubbleInstallment) {
      amount =
        (installmentdifference -
          Number(installmenttotal) -
          autoInstallmentData.bubbleAmt *
          (autoInstallmentData.noOfInstallments /
            autoInstallmentData.bubbleInterval)) /
        (autoInstallmentData.noOfInstallments -
          autoInstallmentData.noOfInstallments /
          autoInstallmentData.bubbleInterval);
      setautoInstallmentData({
        ...autoInstallmentData,
        amount: Math.trunc(amount),
      });
    } else {
      amount = autoInstallmentData.amount;
    }
    if (autoInstallmentData.startDate === "") {
      toast.error("Please select start date");
    } else if (autoInstallmentData.amount === "") {
      toast.error("Please enter installment amount");
    } else if (autoInstallmentData.noOfInstallments === "") {
      toast.error("Please enter number of installments");
    } else {
      if (autoInstallmentData.bubbleInstallment) {
        if (autoInstallmentData.bubbleAmt === "") {
          toast.error("Please enter amount of installment amount");
          return;
        }
        if (autoInstallmentData.bubbleInterval === "") {
          toast.error("Please enter bubble installment interval");
          return;
        }
      }
      setInstallmentDurationList([]);

      const installments = generateInstallments(
        new Date(`${autoInstallmentData.startDate}-01`),
        Number(autoInstallmentData.dayOfMonth),
        // autoInstallmentData.amount,
        Math.trunc(amount),
        autoInstallmentData.noOfInstallments,
        Number(autoInstallmentData.interval),
        installmentdifference,
        autoInstallmentData.bubbleInstallment
          ? autoInstallmentData.bubbleAmt
          : 0,
        autoInstallmentData.bubbleInstallment
          ? Number(autoInstallmentData.bubbleInterval)
          : 0
      );

      let amt = 0;
      installments.forEach((itm) => {
        amt = amt + itm.DueAmount;
      });

      if (amt !== installmentdifference) {
        toast.warning(
          `Installments amount must be equal to ${installmentdifference}`
        );
        return;
      }
      setInstallmentDurationList(installments);
    }
  };

  const custominstallments = () => {
    if (parseInt(installmentdifference) === 0) {
      toast.info("Remaining Amount is 0 , you can't create installments");
    } else if (document.getElementById("customDate").value === "") {
      toast.info("Select Date ");
    } else if (document.getElementById("customAmount").value === "") {
      toast.info("Enter custome installment amount");
    } else {
      if (parseFloat(document.getElementById("customAmount").value) > "0") {
        let sum = 0;
        InstallmentDurationList.map((x) => {
          if (
            x.DueAmount != "" ||
            x.DueAmount != null ||
            x.DueAmount != undefined
          )
            sum = sum + parseFloat(x.DueAmount);
        });

        let idv = InstallmentDurationList?.length + 1;
        let amount = parseFloat(
          document.getElementById("customAmount").value.replace(/,/g, "")
        );
        let installmentdate = document.getElementById("customDate").value;

        if (amount === "") {
          amount = "0";
        }
        sum = sum + parseFloat(amount);
        if (sum > installmentdifference) {
          toast.info(
            "Installments value is greater than total receivable amount"
          );
        } else if (sum === 0) {
          toast.info("Please enter value greater than 0!");
        } else {
          // document.getElementById("advanceval").disabled = true;
          let item = {
            InstallmentNo: idv,
            DueAmount: amount,

            DueDate: installmentdate,
          };
          setInstallmentDurationList([...InstallmentDurationList, item]);
          document.getElementById("customAmount").value = "";
        }
      } else {
        toast.info("Please enter value greater than 0!");
      }
    }
  };

  const deleteinstallment = (idx) => {
    const oldArr = [...InstallmentDurationList];
    const rows = oldArr
      ?.filter((item, index) => index !== idx)
      ?.map((ele, i) => ({ ...ele, InstallmentNo: i + 1 }));

    setInstallmentDurationList(rows);
  };

  const savecontract = () => {
    if (
      currentUnitId === "" ||
      newUnitId === "" ||
      clientAccid === "" ||
      document.getElementById("ratePerMarla").value === ""
      // ||
      // document.getElementById("installmentDueDate").value === "" ||
      // document.getElementById("installmentAmount").value === "" ||
      // document.getElementById("installmentDueDate").value === ""
    ) {
      toast.info("All fields are mandatory");
    } else if (InstallmentDurationList?.length === 0) {
      toast.info("Please add an installment");
    } else {
      setloader2(true);

      var data = {
        customerCNIC: clientAccid,
        oldUnitId: Number(currentUnitId),
        newUnitId: Number(newUnitId),
        saleRate: Number(
          document.getElementById("ratePerMarla").value.replace(/,/g, "")
        ),
        Installments: InstallmentDurationList,
        // installmentAmount: Number(
        //   document.getElementById("installmentAmount").value.replace(/,/g, "")
        // ),
        // noOfInstallment: Number(
        //   document.getElementById("noOfInstallments").value
        // ),
        // installmentDueDate: document.getElementById("installmentDueDate").value,
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Contracts/unitTransfer`,

        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            emptyfields();
            // props.formCancel();
            dispatch(getAllTransferUnitTable());
            setloader2(false);
          } else {
            toast.info(result.data.Message);
            setloader2(false);
          }
        },
        (error) => {
          toast.info(error);
          setloader2(false);
        }
      );
    }
  };

  console.log("InstallmentDurationList", InstallmentDurationList);
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Transfer Unit</h4>
          {resultallPopulateData?.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2 ">
          {" "}
          <span className="txt">Buyer Name</span>
        </div>

        <div
          style={{ display: "flex" }}
          className="col-4  text-left"
        >
          {" "}
          <span style={{ width: "88%" }}>
            {" "}
            <input
              type="text"
              id="contractName"
              autoComplete="off"
              onChange={onTextChangeContractName}
              className="input_styling"
              style={{ width: "96%" }}
              list="contractnamelist"
            ></input>
            <datalist id="contractnamelist">
              {resultallPopulateData?.data?.Customers?.map((x, idx) => (
                <option
                  index={idx}
                  value={`${x.CustomerName} (${idx + 1})`}
                >
                  {x.CustomerCnic2}
                </option>
              ))}
            </datalist>
          </span>
          <span id="clientdemo"></span>
        </div>

        {/* <div className="col-3"></div> */}
        <div className="col-2">
          <span className="txt">{"Current Unit"}</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="currentunits"
              autoComplete="off"
              onChange={onTextChangeCurrentUnit}
              className="input_styling"
              list="currentunitslist"
            ></input>
            <datalist id="currentunitslist">
              {currentUnits?.map((x, idx) => (
                <option
                  index={idx}
                  value={x?.UnitName}
                >
                  {x?.UnitId}
                </option>
              ))}
            </datalist>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2">
          <span className="txt">{"New Unit"}</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="newUnit"
              autoComplete="off"
              onChange={onTextChangeNewUnit}
              className="input_styling"
              list="newUnitlist"
            ></input>
            <datalist id="newUnitlist">
              {resultallPopulateData?.data?.NewUnits?.map((x, idx) => (
                <option
                  index={idx}
                  value={x?.UnitName}
                >
                  {x?.UnitId}
                </option>
              ))}
            </datalist>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
        <div className="col-2  ">
          <span className="txt">Rate per Marla.</span>
        </div>
        <div className="col-4 text-left">
          <span>
            {" "}
            <input
              type="text"
              id="ratePerMarla"
              autoComplete="off"
              className="input_styling"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("ratePerMarla").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                setRatePerMarla(parseInt(e.target.value.replace(/,/g, "")));
              }}
            />
          </span>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2  ">
          <span className="txt">Total Amount</span>
        </div>
        <div className="col-4 text-left">
          <span>
            {" "}
            <input
              type="text"
              id="totalAmt"
              autoComplete="off"
              className="input_styling"
              disabled
              value={totalAmount?.toLocaleString()}
            />
          </span>
        </div>
        <div className="col-2">
          <span className="txt">Advance Amount</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              id="advanceAmount"
              autoComplete="off"
              disabled
              className="input_styling"
              value={advanceAmount?.toLocaleString()}
            ></input>
          </span>

          <span id="contractdetaildemo"></span>
        </div>
      </div>

      {/* <div className="row pt-3">
        <div className="col-2  ">
          <span className="txt">Installment Amount</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="text"
              autoComplete="off"
              id="installmentAmount"
              onChange={(e) => {
                if (e.target.value.length > 0)
                  document.getElementById("installmentAmount").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                setInstallmentAmt(
                  Number(parseInt(e.target.value.replace(/,/g, "")))
                );
              }}
              className="input_styling"
            ></input>
          </span>
        </div>
        <div
          className="col-md-4  p-0 d-flex"
          style={{ gap: 2 }}
        >
          <input
            className="mb-3"
            type="checkbox"
            disabled={autoAmt}
            checked={autoInstallments}
            onChange={(e) => {
              setAutoInstallments(e.target.checked);
            }}
          />
          <p>Auto Installments</p>
        </div>
        <div className="col-2  "></div>

        <div className="col-2  ">
          <span className="txt">No. of Installments</span>
        </div>
        <div className="col-4 text-left">
          <span>
            {" "}
            <input
              type="number"
              id="noOfInstallments"
              className="input_styling"
            ></input>
          </span>
        </div>
        <div
          className="col-md-4  p-0 d-flex"
          style={{ gap: 2 }}
        >
          <input
            className="mb-3"
            type="checkbox"
            disabled={autoInstallments}
            checked={autoAmt}
            onChange={(e) => {
              setautoAmt(e.target.checked);
            }}
          />
          <p>Auto Installment Amount</p>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-2  ">
          <span className="txt">1st Installment Due Date</span>
        </div>
        <div className="col-4  text-left">
          <span>
            {" "}
            <input
              type="date"
              id="installmentDueDate"
              className="input_styling"
              // defaultValue={date}
            ></input>
          </span>
        </div>
      </div> */}

      {localStorage.getItem("ProjectOwner") === "OTHER" ? null : (
        <div className="row pt-3">
          <div className="col-md-12 text-center mt-2  p-0 d-flex">
            <button
              className={
                tab === "Custom" ? "adjust_btns_selected  " : "adjust_btns  "
              }
              onClick={() => {
                settab("Custom");
              }}
            >
              <span className="comp_btns_text">Custom Installments</span>
            </button>

            <button
              className={
                tab === "Auto"
                  ? "adjust_btns_selected ml-1 "
                  : "adjust_btns ml-1 "
              }
              onClick={() => {
                settab("Auto");
              }}
            >
              <span className="comp_btns_text">Auto Installments</span>
            </button>
          </div>

          <div className="col-md-12 mt-3  p-0">
            <div className="row ">
              {tab === "Auto" && (
                <div className="col-md-12 mt-3  p-0">
                  <div className="row ">
                    <div className="col-md-12   ">
                      <label className="input_label m-0 ">
                        <b>Auto Installments:</b>
                      </label>
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">Start:</label>
                    </div>
                    <div className="col-md-4 mt-3  p-0">
                      <input
                        type="month"
                        value={autoInstallmentData.startDate}
                        onChange={(e) => {
                          setautoInstallmentData({
                            ...autoInstallmentData,
                            startDate: e.target.value,
                          });
                        }}
                        className="input_date_styling "
                        id="startDate"
                      />
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">Install. Amt:</label>
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <input
                        disabled={autoAmt}
                        type="number"
                        value={autoInstallmentData.amount}
                        onChange={(e) => {
                          setautoInstallmentData({
                            ...autoInstallmentData,
                            amount: e.target.value,
                          });
                        }}
                        className="input_styling"
                        placeholder="Amount"
                      />
                    </div>
                    <div
                      className="col-md-2 mt-3  p-0 d-flex"
                      style={{ gap: 2 }}
                    >
                      <input
                        className="mb-3"
                        type="checkbox"
                        checked={autoAmt}
                        onChange={(e) => {
                          setautoAmt(e.target.checked);
                        }}
                      />
                      <p>Auto</p>
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">Pay. Interval:</label>
                    </div>
                    <div className="col-md-4 mt-3  p-0">
                      <input
                        type="number"
                        value={autoInstallmentData.interval}
                        onChange={(e) => {
                          setautoInstallmentData({
                            ...autoInstallmentData,
                            interval: e.target.value,
                          });
                        }}
                        className="input_styling"
                        placeholder="In Months"
                      />
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">Pay. Date</label>
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <select
                        className="input_styling"
                        value={autoInstallmentData.dayOfMonth}
                        onChange={(e) => {
                          setautoInstallmentData({
                            ...autoInstallmentData,
                            dayOfMonth: e.target.value,
                          });
                        }}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                      </select>
                    </div>
                    <div className="col-md-2 mt-3  p-0"></div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">
                        No. of Install.
                      </label>
                    </div>
                    <div className="col-md-4 mt-3 p-0">
                      <input
                        type="number"
                        className="input_styling "
                        value={autoInstallmentData.noOfInstallments}
                        onChange={(e) => {
                          setautoInstallmentData({
                            ...autoInstallmentData,
                            noOfInstallments: e.target.value,
                          });
                        }}
                        placeholder="No of Installments"
                      />
                    </div>{" "}
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">Bubble Inst.</label>
                    </div>
                    <div className="col-md-4 mt-3  p-0">
                      <input
                        type="checkbox"
                        checked={autoInstallmentData.bubbleInstallment}
                        onChange={(e) =>
                          setautoInstallmentData({
                            ...autoInstallmentData,
                            bubbleInstallment: e.target.checked,
                          })
                        }
                      />
                    </div>
                    {autoInstallmentData.bubbleInstallment && (
                      <>
                        <div className="col-md-2 mt-3  p-0">
                          <label className="input_label m-0 ">
                            Bubble Interval.
                          </label>
                        </div>
                        <div className="col-md-4 mt-3 p-0">
                          <input
                            type="number"
                            className="input_styling "
                            value={autoInstallmentData.bubbleInterval}
                            onChange={(e) => {
                              setautoInstallmentData({
                                ...autoInstallmentData,
                                bubbleInterval: e.target.value,
                              });
                            }}
                            placeholder="Bubble Installment Interval"
                          />
                        </div>{" "}
                        <div className="col-md-2 mt-3  p-0">
                          <label className="input_label m-0 ">
                            Bubble Amt.
                          </label>
                        </div>
                        <div className="col-md-4 mt-3 p-0">
                          <input
                            type="number"
                            className="input_styling "
                            value={autoInstallmentData.bubbleAmt}
                            onChange={(e) => {
                              setautoInstallmentData({
                                ...autoInstallmentData,
                                bubbleAmt: e.target.value,
                              });
                            }}
                            placeholder="Bubble Installment Amount"
                          />
                        </div>{" "}
                      </>
                    )}
                  </div>
                </div>
              )}
              {tab === "Custom" && (
                <div className="col-md-12 mt-3  p-0">
                  <div className="row ">
                    <div className="col-md-12 ">
                      <label className="input_label m-0 ">
                        <b>Custom Installments:</b>
                      </label>
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">
                        Installment Amount
                      </label>
                    </div>
                    <div className="col-md-4 mt-3  p-0">
                      <input
                        type="text"
                        className="input_styling "
                        id="customAmount"
                        placeholder="amount"
                        onChange={(e) => {
                          if (e.target.value.length > 0)
                            document.getElementById("customAmount").value =
                              parseFloat(
                                e.target.value.replace(/,/g, "")
                              ).toLocaleString();
                        }}
                      />
                    </div>
                    <div className="col-md-2 mt-3  p-0">
                      <label className="input_label m-0 ">
                        Installment Date
                      </label>
                    </div>
                    <div className="col-md-4 mt-3  p-0">
                      <input
                        type="date"
                        className="input_date_styling "
                        id="customDate"
                      />
                    </div>
                    <div className="col-md-6 mt-3 text-center p-0"></div>
                    <div className="col-md-2 mt-3 text-center p-0">
                      <label className="input_label m-0 ">Remaining Amt:</label>
                    </div>
                    <div className="col-md-4 mt-3  p-0">
                      <input
                        type="text"
                        className="input_styling"
                        id="remainingamt"
                        value={(
                          installmentdifference - Number(installmenttotal)
                        )?.toLocaleString()}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12 mt-3 text-right ">
                <button
                  className="save_btn ml-2 "
                  onClick={() => {
                    if (tab === "Auto") {
                      autoinstallments();
                    } else {
                      custominstallments();
                    }
                  }}
                  id="autoinstallbutton"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="  mt-3 overflow-auto"
              style={{ height: "145px" }}
            >
              <table class="table tableCash table-hover ">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Installment No</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {InstallmentDurationList.map((x, idx) => (
                    <tr>
                      <th scope="row">{x.InstallmentNo}</th>
                      <td>{x.DueAmount?.toLocaleString()}</td>
                      <td>{moment(x.DueDate).format("DD-MMM-YYYY")}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteinstallment(idx)}
                      >
                        <MdDeleteForever size="1.6em" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col-6 text-center">
              <b>Total:</b> {parseFloat(installmenttotal)?.toLocaleString()}
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          float: "right",
          width: "100%",
          justifyContent: "end",
          marginRight: "3%",
          marginTop: "10px",
          alignItems: "center",
          gap: 5,
        }}
      >
        {loader2 && (
          <Loader
            type="ThreeDots"
            color="green"
            height={40}
            width={40}
          />
        )}

        <button
          onClick={() => {
            history.goBack()
          }}
          className="cancel_btn"
        >
          Cancel
        </button>
        <button
          id="repursave"
          disabled={loader2}
          onClick={savecontract}
          className="save_btn"
        >
          Save
        </button>
      </div>
    </>
  );
};
