import moment from "moment";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getInstallmentReceipt } from "../../../actions/AccountReports/installmentsreceiptreports";
import { InstallRecPrint } from "../ReportPrints/InstallReceiptPrint";



const InstallmentReceipt = () => {
  const resultsgetaccpay = useSelector((state) => state.GetInstallmentReceipt);
  const [filterdata, setfilterdata] = useState([]);
  const [csvData, setcsvData] = useState([]);
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  // let Total = 0;
  const dispatch = useDispatch();

  const onTextChange = (e) => {

    if (document.getElementById("voucherfilter").value === "" && document.getElementById("unitfilter").value === "" && document.getElementById("managerfilter").value === "") {
      setfilterdata(resultsgetaccpay.data);

    } else {
      let suggestions = [];
      {
        const regex = new RegExp(document.getElementById("voucherfilter").value, "i");
        const regex1 = new RegExp(document.getElementById("managerfilter").value, "i");
        const regex2 = new RegExp(document.getElementById("unitfilter").value, "i");





        suggestions = resultsgetaccpay.data
          .sort()
          .filter((val) =>
            (document.getElementById("voucherfilter").value === "" || regex.test(val.Project.ProjectName)) &&
            (document.getElementById("managerfilter").value === "" || regex1.test(val.CustomerName)) &&
            (document.getElementById("unitfilter").value === "" || regex2.test(val.Unit))



          );

      }
      setfilterdata(suggestions);
    }
  };
  useEffect(() => {

    dispatch(getInstallmentReceipt());
  }, [dispatch]);
  useEffect(() => {
    setfilterdata(resultsgetaccpay.data);

  }, [resultsgetaccpay]
  )
  // resultsgetaccpay.data.map((val, index) => {
  //     Total += parseFloat(val.CurrentBalance);
  // })

  useEffect(() => {
    let arr = [];
    filterdata.forEach((item) => {
      arr.push({
        Date: moment(item.EntryDate.split("T")[0]).format("DD-MMM-YYYY"),
        Customer: item.CustomerName,
        PaymentType: item.PaymentType,
        Project: item.Project !== null && item.Project !== undefined ? item.Project.ProjectName : null,
        Unit: item.Unit !== null ? item.Unit : null,
        Received: item.ReceivedAmount,
        PaidAmount: item.PaidAmount,
        Status: item.Paid !== true ? "Pending" : "Paid"
      })
    })
    setcsvData(arr);
  }, [filterdata]);
  return (
    <>
      <div hidden>
        <InstallRecPrint
          ref={componentRef}
          // Total={Total}
          printData={filterdata !== undefined ? filterdata : null}
        />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="voucherfilter"
                onChange={onTextChange}
                placeholder="Project "
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="managerfilter"
                onChange={onTextChange}
                placeholder="Customer"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="unitfilter"
                onChange={onTextChange}
                placeholder="Unit"
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {resultsgetaccpay.loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive vouchers_table_height2 mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr </th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Pay.Type</th>
                    <th>Project</th>
                    <th>Unit</th>
                    <th>Received</th>
                    <th>Paid</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((val, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {moment(val.EntryDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )}
                        </td>
                        <td>{val.CustomerName}</td>

                        <td>{val.PaymentType}</td>
                        <td>
                          {val.Project !== null && val.Project !== undefined
                            ? val.Project.ProjectName
                            : null}
                        </td>
                        <td>{val.Unit !== null ? val.Unit : null}</td>

                        <td>
                          {val.ReceivedAmount !== null
                            ? val.ReceivedAmount.toLocaleString()
                            : null}
                        </td>
                        <td>
                          {val.PaidAmount !== null
                            ? val.PaidAmount.toLocaleString()
                            : null}
                        </td>
                        <td>{val.Paid !== true ? "Pending" : "Paid"}</td>
                      </tr>
                    ))
                    : null}
                  {/* <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td> <label className="input_label m-0">Total:</label></td>
                                        <td>  <input type="text " className="input_total_styling" value={parseFloat(Total).toLocaleString()} id="total" disabled></input></td>
                                        <td></td>
                                    </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              disabled={filterdata.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>

            {filterdata?.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Installments Receipt Csv"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InstallmentReceipt;