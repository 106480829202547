import { useEffect, useRef } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { RiFileEditLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { RefundVouchPrint } from "../../accpayablepages/vouchersprints/paymentprint";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { CSVLink } from "react-csv";
// import useDownloadCsv from "../../../actions/downloadCsvHook";
import moment from "moment";
import RefundPaymentTransactionDetailModal from "./RefundPaymentTransactionDetailModal";
import RefundPaymentDeleteVoucherModal from "./RefundPaymentDeleteVoucherModal";
import RefundPaymentModal from "./RefundPaymentModal";
import { getallrefund } from "../../../actions/getrefundvouch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const RefundPaymentTable = (props) => {
  const resultsgetrefund = useSelector((state) => state.getAllRefund);
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [loading, setloading] = useState(false);
  const rolesdata = useSelector((state) => state.GetAllRole);
  // const [selectedVouchers, setSelectedVouchers] = useState([]);
  // const [rendarComp, setrendarComp] = useState(false);
  const [allLinks, setallLinks] = useState(null);
  // const [data, downloadCsv] = useDownloadCsv();
  const [selectedVoucherInfo, setSelectedVoucherInfo] = useState([])
  const history = useHistory();
  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    account: "",
    status: "",
  });

  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });

  const handelDeleteVoucher = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printPaymentRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  const onTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setfilterObject({ ...filterObject, [name]: value });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallrefund(data));
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
          ? (debit += parseFloat(val.Amount))
          : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function showmodal2(data) {
    document.getElementById("mymodal2").style.display = "block";
    // handleCheckboxChange(data);
    setSelectedVoucherInfo(data)
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }
  function ongo() {
    setloading(true);
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      VoucherId: document.getElementById("voucherfilter").value,
      FilterDate: document.getElementById("datefilter").value,
      Status: document.getElementById("submittedId").value,
    };

    dispatch(getallrefund(data));
  }
  useEffect(() => {
    setfilterdata(resultsgetrefund.data);
  }, [resultsgetrefund]);

  function deletegeneralPaymentVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);

    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteGeneralVoucher?voucherId=${selectedVoucherInfo?.GeneralVoucherId}&Reason=${DeleteVoucherData.Reason}&Password=${DeleteVoucherData.Password}&Email=${DeleteVoucherData.Email}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      // data: {
      //   Email: DeleteVoucherData?.Email,
      //   Password: DeleteVoucherData?.Password,
      //   Reason: DeleteVoucherData?.Reason,
      //   voucherId: selectedVouchers,
      // },
    }).then(
      (result) => {
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          let data = {};
          dispatch(getallrefund(data));
          toast.info(result.data.Message);
          setloading(false);
          // setSelectedVouchers([]);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setloading(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
  }
  // function handleCheckboxChange(i) {
  //   setSelectedVouchers(i);
  // }
  // function handleCheckboxChange(i) {
  //   let arr = filterdata
  //     ?.filter((itm) => itm.checked)
  //     .map((itm) => itm.GeneralVoucherId);
  //   setSelectedVouchers(arr);
  // }

  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };
    setloading(true);

    dispatch(getallrefund(data, link));
  };
  useEffect(() => {
    if (resultsgetrefund.data) {
      setallLinks(resultsgetrefund.data);
      setfilterdata(resultsgetrefund.data.data);

      setloading(false);
    }
  }, [resultsgetrefund]);

  const [CsvData, setCsvData] = useState([]);
  // const [newData, setNewData] = useState([]);
  const csvLinkRef = useRef();
  const handleDownload = () => {
    const csvData = filterdata.map((val, i) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      return {
        Sr: i + 1,
        Date: `${day}-${month}-${year}`,
        AddedOn: moment(
          val.Transaction[0]?.AddedOn?.slice(0, 10),
          "YYYY:MM-DD"
        )?.format("DD-MMM-YYYY"),
        "Voucher No": val.GeneralVoucherId,
        Account: val.Transaction?.filter(
          (item) => item.TransactionType === "DEBIT"
        )
          .map((item) => item.Account.AccountTitle)
          .join(", "),
        Narration: val?.Narration,
        Amount: parseFloat(val.Amount).toLocaleString(),
        User: val?.AddedBy?.UserName,
        Submitted: val.Submitted ? "True" : "False",
      };
    });

    setCsvData(csvData);
  };

  useEffect(() => {
    if (csvLinkRef.current && CsvData.length > 0) {
      csvLinkRef.current.link.click();
      setCsvData([]);
    }
  }, [CsvData]);
  return (
    <>
      <div hidden>
        <RefundVouchPrint
          ref={componentRef}
          printData={printData}
        />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="date"
            value={filterObject?.date}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="date"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="voucherNumber"
            value={filterObject?.voucherNumber}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          />
        </div>

        <div className="col-md-2 pr-0 pl-0 ">
          <select
            autoComplete="off"
            name="status"
            value={filterObject?.status}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            className="input_styling "
            id="submittedId"
            onChange={onTextChange}
          >
            <option value={""}>All</option>
            <option value={false}>Pending</option>
            <option value={true}>Submitted</option>
          </select>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <RefundPaymentModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsgetrefund.loading || loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="fromdate"
          ></input>
        </div>
        <div className="col-md-4 pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input
            type="date"
            className="input_date ml-3"
            id="todate"
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button
            className="btn_Go"
            onClick={ongo}
          >
            Go
          </button>
        </div>
        {/* <div className="col-md-12 pr-0 pl-0">
          {selectedVouchers?.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="float-right">
                {loading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div> */}
      </div>

      <div className="table-responsive vouchers_table_height3 mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              {/* <th>{""}</th> */}
              <th style={{ minWidth: "90px" }}>Date</th>
              <th style={{ minWidth: "90px" }}>Added On</th>
              <th style={{ minWidth: "120px" }}>Voucher</th>
              <th>Account</th>
              <th>Narration</th>
              <th>Amount</th>
              <th style={{ minWidth: "95px" }}>User</th>
              <th>Submitted</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      // props.showrefundtable();
                      history.push("/account/vouchers/refund-voucher/add");
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        showmodal(val.Transaction);
                        SetPrintDataModal(val.Transaction);
                      }}
                    >
                      <td className="table_data">
                        {index +
                          1 +
                          (allLinks?.PageNumber - 1) * allLinks?.PageSize}
                      </td>
                      {/* <td>
                        {localStorage.getItem("Designation") === "Admin" ? (
                          <input
                            checked={val.checked ? val.checked : false}
                            key={index}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              e.stopPropagation();
                              filterdata[index].checked = e.target.checked;
                              setrendarComp(!rendarComp);
                              handleCheckboxChange(index);
                            }}
                            type="checkbox"
                            id={`checkedVoucher${index}`}
                          />
                        ) : null}
                      </td> */}
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {moment(
                          val?.AddedOnDate?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          // SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.GeneralVoucherId}
                      </td>
                      {val.Transaction?.length > 0 ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            // SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Transaction?.filter(
                            (item) => item.TransactionType === "DEBIT"
                          )
                            .map((item) => item.Account.AccountTitle)
                            .join(", ")}
                        </td>
                      ) : (
                        <td
                          className="table_data"
                          // onClick={() => {
                          //   showmodal(val.Transaction);
                          //   SetPrintDataModal(val.Transaction);
                          // }}
                        >
                          {val.GeneralVoucherSavedDetail?.length > 0
                            ? val.GeneralVoucherSavedDetail?.map(
                                (item) => item.Account.AccountTitle
                              ).join(", ")
                            : val.GeneralVoucherSaved?.DebitAccount
                                ?.AccountTitle}
                        </td>
                      )}
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {val?.Narration}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>
                      <td className="table_data">{val?.AddedBy?.UserName}</td>
                      <td className="table_data">
                        {val.Submitted ? "True" : "False"}
                      </td>
                      <td className="text-center ">
                        {rolesdata?.data?.Access?.includes("U") ? (
                          <RiFileEditLine
                            title="Edit"
                            size="1em"
                            color={
                              val.Submitted === false ? "black" : "#707070"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (val.Submitted === false) {
                                // props.setvoucherData(val.GeneralVoucherSaved);
                                // props.setunitData(val.unit);

                                // props.setvoucherSaveDetails(
                                //   val?.GeneralVoucherSavedDetail
                                // );
                                history.push({
                                  pathname: `/account/vouchers/refund-voucher/add`,
                                  state: {
                                    unitData: val.unit,
                                    vouchData: val.GeneralVoucherSaved,
                                    voucherSaveDetails:
                                      val?.GeneralVoucherSavedDetail,
                                  },
                                });
                              } else {
                                toast.info("Can't edit voucher");
                              }
                              // props.showgeneraltable();
                            }}
                            className="cur_sor"
                          />
                        ) : null}
                        &nbsp;
                        <GrPrint
                          className="cur_sor"
                          onClick={(e) => {
                            e.stopPropagation();
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                          size="1em"
                        />
                        &nbsp;
                        {localStorage.getItem("Designation") === "Admin" ? (
                          <MdDeleteForever
                            onClick={(e) => {
                              e.stopPropagation();
                              showmodal2(val);
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null}
                        &nbsp; &nbsp;
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="col-12  text-right pr-0 pl-0">
        <button
          disabled={allLinks?.LastPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.LastPage)}
        >
          Last
        </button>
        <button
          disabled={allLinks?.PreviousPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.PreviousPage)}
        >
          Previous
        </button>
        <button
          disabled={allLinks?.NextPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.NextPage)}
        >
          Next
        </button>
        <button
          disabled={allLinks?.FirstPage ? false : true}
          className="print_btn float-right pl-1 mx-1"
          onClick={() => getPaginationData(allLinks?.FirstPage)}
        >
          First
        </button>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
        onClick={() => handleDownload("GPV")}
      >
        Download CSV
      </button>
      <CSVLink
        ref={csvLinkRef}
        data={CsvData}
        filename="refund_vouchers.csv"
        className="hidden"
        target="_blank"
      />
      <RefundPaymentDeleteVoucherModal
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherDataChange={handelDeleteVoucher}
        deletegeneralPaymentVoucher={deletegeneralPaymentVoucher}
        loading={loading}
        closemodal2={closemodal2}
      />
      <RefundPaymentTransactionDetailModal
        TransactionData={TransactionData}
        closemodal={closemodal}
        debitState={debitState}
        creditState={creditState}
        printDataModal={printDataModal}
      />
    </>
  );
};
