import moment from "moment";
import React, { Component } from "react";
// import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class DealSoldPrint extends Component {
  render() {

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Deal Sold</u>
            </h4>
            {/* <h5>
                            <b>Entry Date:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.EntryDate.split("T")[0]

                                : null}
                        </h5>
                        <h5>
                            <b>Exp Date:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.ExpDate.split("T")[0]

                                : null}
                        </h5> */}

            <div className="row">
              <div className="col-6 mt-4">
                <ul>
                  <li>
                    <b>Project Name:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.Project.ProjectName ===
                        "THE GRAND CITY"
                        ? "THE GRAND ICONIC CITY"
                        : this.props.printData.Project.ProjectName
                      : null}
                  </li>

                  <li>
                    <b>Affiliate:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.AffiliateCnicNavigation
                        .AffiliateName
                      : null}
                  </li>
                  <li>
                    <b> Entry Date:</b>{" "}
                    {this.props.printData !== undefined
                      ? moment(
                        this.props.printData.EntryDate.split("T")[0]
                      ).format("DD-MMM-YYYY")
                      : null}
                  </li>
                  <li>
                    <b>Expiry Date:</b>{" "}
                    {this.props.printData !== undefined
                      ? moment(
                        this.props.printData.ExpDate.split("T")[0]
                      ).format("DD-MMM-YYYY")
                      : null}
                  </li>
                  <li>
                    <b>Advance%:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.AdvancePer
                      : null}
                  </li>
                </ul>
              </div>
              <div className="col-6 mt-4">
                <ul>
                  <li>
                    <b>Deal Name:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.DealName
                      : null}
                  </li>
                  <li>
                    <b>Amount:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.Amount.toLocaleString()
                      : null}
                  </li>

                  <li>
                    <b>Amount Received:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.AmountReceived.toLocaleString()
                      : null}
                  </li>

                  <li>
                    <b>Commission%:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.CommissionPer
                      : null}
                  </li>

                  <li>
                    <b>Profit Margin%:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.ProfitMarginPer
                      : null}
                  </li>
                  <li>
                    <b>Note:</b>{" "}
                    {this.props.printData !== undefined
                      ? this.props.printData.Note
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table ">
                  <thead class="purple whiteText">
                    <tr>
                      <th scope="col">Unit</th>
                      <th scope="col">Size</th>
                      <th scope="col">Sqft</th>

                      <th scope="col">OldSaleRate</th>
                      <th scope="col">NewSaleRate</th>

                      <th scope="col">TotalSaleValue</th>
                      <th scope="col">AdvanceAmount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.printData === undefined ||
                      this.props.printData.DealSoldUnits === undefined
                      ? // contractinstallment.length == 0
                      null
                      : this.props.printData.DealSoldUnits.map((x, id) => (
                        <tr key={id}>
                          <td>{x.Unit.UnitName}</td>
                          <td>{x.Unit.NetMarla + " M"}</td>
                          <td>{x.Unit.NetSqft}</td>
                          <td>{x.OldSaleRate.toLocaleString()}</td>

                          <td>{x.NewSaleRate.toLocaleString()}</td>

                          <td>{x.TotalSaleValue.toLocaleString()}</td>

                          <td>{x.AdvanceAmount.toLocaleString()}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <h5 className="text-right pt-3">
                            <b>Narration:{" "}</b>
                            {this.props.printData !== undefined ?

                                this.props.printData.Narration

                                : null}
                        </h5> */}
          </div>
        </div>
      </div>
    );
  }
}
export default DealSoldPrint;