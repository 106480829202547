import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallEmployees } from "../../actions/HRactions/getemployees";
import { getallProject } from "../../actions/getprojects";
import { getallRolesTable } from "../../actions/getroles";
import axios from "axios";
// import { MdDeleteForever } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";

import Loader from "react-loader-spinner";
import {
  useHistory,
  // useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

const MainAddRoleCom = () => {
  const showroleaddtable = () => {
    stateshowroleadd("RoleAdd");
  };
  const cancelroleaddtable = () => {
    stateshowroleadd("RoleTable");
  };
  const [showroleadd, stateshowroleadd] = useState("RoleTable");

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showroleadd === "RoleTable" ? (
                <RoleAddTable
                  showroleaddtable={showroleaddtable}
                  cancelroleaddtable={cancelroleaddtable}
                />
              ) : (
                <RoleAdd cancelroleaddtable={cancelroleaddtable} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainAddRoleCom;
export const RoleAdd = (props) => {
  const resultallemployees = useSelector((state) => state.getAllEmployees);
  const resultallproject = useSelector((state) => state.getProject);
  // const location = useLocation();
  // const value = location.state;
  let dispatch = useDispatch();
  const [projectid, setprojectid] = useState();
  const [employeemail, setemployeemail] = useState();

  const [accountsrole, setaccountsrole] = useState({});
  const [otherrole, setotherrole] = useState({});

  const [isaccount, setaccount] = useState(false);
  const [isvoucher, setvoucher] = useState(false);
  // const [ischeckedvoucher, setcheckedvoucher] = useState(false);
  const [iscontract, setcontract] = useState(false);
  const [iscof, setcof] = useState(false);
  const [istax, settax] = useState(false);
  const [isinvestment, setinvestment] = useState(false);
  const [isaccountreport, setaccountreport] = useState(false);

  const [isinventory, setinventory] = useState(false);
  const [isproject, setproject] = useState(false);
  // const [isavailability, setavailability] = useState(false);
  const [isdeal, setdeal] = useState(false);
  const [islandseller, setlandseller] = useState(false);

  const [ishr, setishr] = useState(false);

  const [ispayroll, setpayroll] = useState(false);
  const [isemployee, setemployee] = useState(false);

  const [iscrm, setiscrm] = useState(false);
  const [isteam, setteam] = useState(false);
  const [istask, settask] = useState(false);

  const [isassets, setisassets] = useState(false);
  const [isassetsadd, setisassetsadd] = useState(false);

  const [refresh, setrefresh] = useState(false);

  var suggestionlist1 = [];
  const EmployeeSelecttion = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallemployees.data
          .sort()
          .filter((v) => regex.test(v.EmpName !== null ? v.EmpName : null));
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          // <ul className="suggestbox">
          //     {suggestionlist1.map((item) => (
          //         <li className="listcss" onClick={() => suggestionselected1(item)}>
          //             {item === undefined
          //                 ? item
          //                 : item.EmpName
          //             }
          //         </li>
          //     ))}
          // </ul>,

          <datalist id="employeelist">
            {suggestionlist1?.map((item, idx) => (
              <option
                index={idx}
                value={item === undefined ? item : item.EmpName}
                onClick={() => suggestionselected1(item)}
              >
                {item === undefined ? item : item.EmpName}
              </option>
            ))}
          </datalist>,
          document.getElementById("unitdemo")
        );
      }
    }
  };
  const suggestionselected1 = (val) => {
    document.getElementById("Employee").value = val.EmpName;

    setemployeemail(val.EmpEmail);

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  var projectlist = [];
  const ProjectSelecttion = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("projectdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallproject.data
          .sort()
          .filter((v) =>
            regex.test(v.ProjectName !== null ? v.ProjectName : null)
          );
      }
      projectlist = suggestions;

      if (projectlist.length === 0) {
        ReactDOM.render(null, document.getElementById("projectdemo"));
      } else {
        ReactDOM.render(
          //   <ul className="suggestbox">
          //     {projectlist.map((item) => (
          //       <li className="listcss" onClick={() => projectselected(item)}>
          //         {item === undefined ? item : item.ProjectName}
          //       </li>
          //     ))}
          //   </ul>,
          <datalist id="projectlist">
            {projectlist?.map((item, idx) => (
              <option
                index={idx}
                value={item === undefined ? item : item.ProjectName}
                onClick={() => projectselected(item)}
              >
                {item === undefined ? item : item.ProjectName}
              </option>
            ))}
          </datalist>,
          document.getElementById("projectdemo")
        );
      }
    }
  };
  const projectselected = (val) => {
    document.getElementById("Project").value = val.ProjectName;
    setprojectid(val.ProjectId);

    projectlist = [];
    ReactDOM.render(null, document.getElementById("projectdemo"));
  };

  function savedata() {
    if (document.getElementById("Employee").value === "") {
      toast.info("Select Employee");
    } else if (
      isaccount === true &&
      document.getElementById("Project").value === ""
    ) {
      toast.info("Select Project");
    } else {
      document.getElementById("savedata").disabled = true;
      axios({
        url: `${window.$url}Auth/Role/Add`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          Email: employeemail,
          Name: document.getElementById("Employee").value,
          ProjectId: projectid,
          OtherRoles: otherrole,
          AccountRoles: accountsrole,
        }),
      }).then(
        (result) => {
          document.getElementById("savedata").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            props.cancelroleaddtable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("savedata").disabled = false;
          toast.info("Something went wrong!");
        }
      );
    }
  }

  useEffect(() => {
    dispatch(getallEmployees());
    dispatch(getallProject());
  }, [dispatch]);

  const history = useHistory();

  return (
    <>
      <div className="row ">
        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">Employee Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  "
            id="Employee"
            list="employeelist"
            placeholder="Auto Suggestion"
            onChange={EmployeeSelecttion}
          ></input>
          <span className="estaric">*</span>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">
            {isaccount === true ? "Project Name" : null}
          </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          {isaccount === true ? (
            <>
              <input
                type="text"
                className="input_styling  "
                id="Project"
                list="projectlist"
                placeholder="Auto Suggestion"
                onChange={ProjectSelecttion}
              ></input>
              <span className="estaric">*</span>
              <span id="projectdemo"></span>{" "}
            </>
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">
            <h5>Accounts:</h5>
          </label>{" "}
          <input
            type="checkbox"
            className="  ml-md-3"
            id="Accounts"
            onChange={(e) => {
              setaccount(!isaccount);
              let x = accountsrole;

              if (e.target.checked === true) {
                x["Accounts"] = true;
                setaccountsrole(x);
              } else {
                x["Accounts"] = false;
                setaccountsrole(x);
              }

              setrefresh(!refresh);
            }}
          ></input>
          {isaccount === true ? (
            <div className="row">
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Dashboard:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="DashboardAccount"
                  onChange={(e) => {
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Dashboard"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Dashboard"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Voucher:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="voucher"
                  onChange={(e) => {
                    setvoucher(!isvoucher);
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Voucher"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Voucher"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isvoucher === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Receipt Entry:</label>{" "}
                      <input
                        type="checkbox"
                        className="voucher  ml-md-3"
                        id="DashboardAccount"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Receipt Entry"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Receipt Entry"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Payment Entry :</label>{" "}
                      <input
                        type="checkbox"
                        className=" voucher ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Payment Entry"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Payment Entry"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Recovery Booking:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className=" voucher  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Recovery Booking"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Recovery Booking"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Journal Entry :</label>{" "}
                      <input
                        type="checkbox"
                        className=" voucher ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Journal Entry"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Journal Entry"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        General Adjustment:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className=" voucher ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["General Adjustment"] = true;
                            setaccountsrole(x);
                          } else {
                            x["General Adjustment"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Installments:</label>{" "}
                      <input
                        type="checkbox"
                        className="voucher  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Installments"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Installments"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Contract:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Contract"
                  onChange={(e) => {
                    setcontract(!iscontract);
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Contract"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Contract"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {iscontract === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0"> Add Contract :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="DashboardAccount"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Add Contract"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Add Contract"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Install Reschedule :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Install Reschedule"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Install Reschedule"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Customer:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Customer"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Customer"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Transfer Contract:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Transfer Contract"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Transfer Contract"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Chart of Account:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setcof(!iscof);
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Chart of Account"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Chart of Account"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {iscof === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Add Chart of Acc :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="DashboardAccount"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Add Chart of Acc"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Add Chart of Acc"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Add Account :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Add Account"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Add Account"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Tax:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    settax(!istax);
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Tax"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Tax"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {istax === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Add Tax :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="DashboardAccount"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Add Tax"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Add Tax"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Tax Payable :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Tax Payable"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Tax Payable"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Tax Receivable :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Tax Receivable"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Tax Receivable"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Investment:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setinvestment(!isinvestment);
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Investment"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Investment"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isinvestment === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Investor :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="DashboardAccount"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Investor"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Investor"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        {" "}
                        Add Investment :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Add Investment"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Add Investment"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Reports:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setaccountreport(!isaccountreport);
                    let x = accountsrole;

                    if (e.target.checked === true) {
                      x["Reports"] = true;
                      setaccountsrole(x);
                    } else {
                      x["Reports"] = false;
                      setaccountsrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isaccountreport === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Trail Balance :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="DashboardAccount"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Trail Balance"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Trail Balance"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        General Journal :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["General Journal"] = true;
                            setaccountsrole(x);
                          } else {
                            x["General Journal"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        General Ledger :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["General Ledger"] = true;
                            setaccountsrole(x);
                          } else {
                            x["General Ledger"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Balance Sheet :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Balance Sheet"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Balance Sheet"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Income Statement :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Income Statement"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Income Statement"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Cash Book :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Cash Book"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Cash Book"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Manager Recovery :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Manager Recovery"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Manager Recovery"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Recovery Receipt :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Recovery Receipt"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Recovery Receipt"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Account Payable:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Account Payable"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Account Payable"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Account Receivable :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Account Receivable"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Account Receivable"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Install Payment :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Install Payment"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Install Payment"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Install Receipt :
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Install Receipt"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Install Receipt"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Commission Pay Bought:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Commission Pay Bought"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Commission Pay Bought"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Commission Rec Bought:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Commission Rec Bought"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Commission Rec Bought"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Commission Pay Sold:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Commission Pay Sold"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Commission Pay Sold"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">
                        Projects Report:
                      </label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = accountsrole;

                          if (e.target.checked === true) {
                            x["Projects Report"] = true;
                            setaccountsrole(x);
                          } else {
                            x["Projects Report"] = false;
                            setaccountsrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">
            <h5>Inventory:</h5>
          </label>{" "}
          <input
            type="checkbox"
            className="  ml-md-3"
            id="Inventory"
            onChange={(e) => {
              setinventory(!isinventory);
              let x = otherrole;

              if (e.target.checked === true) {
                x["Inventory"] = true;
                setotherrole(x);
              } else {
                x["Inventory"] = false;
                setotherrole(x);
              }

              setrefresh(!refresh);
            }}
          ></input>
          {isinventory === true ? (
            <div className="row">
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Dashboard:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Dashboardinventory"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Inventory Dashboard"] = true;
                      setotherrole(x);
                    } else {
                      x["Inventory Dashboard"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Project:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Project"
                  onChange={(e) => {
                    setproject(!isproject);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Project"] = true;
                      setotherrole(x);
                    } else {
                      x["Project"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isproject === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Add Project:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardinventory"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Add Project"] = true;
                            setotherrole(x);
                          } else {
                            x["Add Project"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Section:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Project"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Section"] = true;
                            setotherrole(x);
                          } else {
                            x["Section"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Units:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Deals"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Units"] = true;
                            setotherrole(x);
                          } else {
                            x["Units"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">New Purchase:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Availability"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["New Purchase"] = true;
                            setotherrole(x);
                          } else {
                            x["New Purchase"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">RePurchase:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Land"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["RePuchase"] = true;
                            setotherrole(x);
                          } else {
                            x["RePuchase"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Deals:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Deals"
                  onChange={(e) => {
                    setdeal(!isdeal);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Deals"] = true;
                      setotherrole(x);
                    } else {
                      x["Deals"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isdeal === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Deal Bought:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardinventory"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Deal Bought"] = true;
                            setotherrole(x);
                          } else {
                            x["Deal Bought"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Deal Sold:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Project"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Deal Sold"] = true;
                            setotherrole(x);
                          } else {
                            x["Deal Sold"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Affiliate:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Deals"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Affiliate"] = true;
                            setotherrole(x);
                          } else {
                            x["Affiliate"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Availability:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Availability"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Inventory Availability"] = true;
                      setotherrole(x);
                    } else {
                      x["Inventory Availability"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Land&Seller:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Land"
                  onChange={(e) => {
                    setlandseller(!islandseller);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["LandandSeller"] = true;
                      setotherrole(x);
                    } else {
                      x["LandandSeller"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {islandseller === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Land:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardinventory"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Land"] = true;
                            setotherrole(x);
                          } else {
                            x["Land"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Land Seller:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Project"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["LandSeller"] = true;
                            setotherrole(x);
                          } else {
                            x["LandSeller"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Seller:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Deals"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Seller"] = true;
                            setotherrole(x);
                          } else {
                            x["Seller"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">
            <h5>HR:</h5>
          </label>{" "}
          <input
            type="checkbox"
            className="  ml-md-3"
            id="Hr"
            onChange={(e) => {
              setishr(!ishr);
              let x = otherrole;

              if (e.target.checked === true) {
                x["HR"] = true;
                setotherrole(x);
              } else {
                x["HR"] = false;
                setotherrole(x);
              }

              setrefresh(!refresh);
            }}
          ></input>
          {ishr === true ? (
            <div className="row">
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Dashboard:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Dashboardhr"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Hr Dashboard"] = true;
                      setotherrole(x);
                    } else {
                      x["Hr Dashboard"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Attendence:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Attendence"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Attendence"] = true;
                      setotherrole(x);
                    } else {
                      x["Attendence"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Pay Roll:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setpayroll(!ispayroll);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Pay Roll"] = true;
                      setotherrole(x);
                    } else {
                      x["Pay Roll"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {ispayroll === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Assign Salary :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardhr"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Assign Salary"] = true;
                            setotherrole(x);
                          } else {
                            x["Assign Salary"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Advance/Loan:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Attendence"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["AdvanceLoan"] = true;
                            setotherrole(x);
                          } else {
                            x["AdvanceLoan"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Pay Slip:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Pay Slip"] = true;
                            setotherrole(x);
                          } else {
                            x["Pay Slip"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Employees:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setemployee(!isemployee);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Employees"] = true;
                      setotherrole(x);
                    } else {
                      x["Employees"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isemployee === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Add Employee:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardhr"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Add Employee"] = true;
                            setotherrole(x);
                          } else {
                            x["Add Employee"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Designation:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Attendence"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Designation"] = true;
                            setotherrole(x);
                          } else {
                            x["Designation"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label p-0 m-0">
            <h5>CRM:</h5>
          </label>{" "}
          <input
            type="checkbox"
            className="  ml-md-3"
            id="CRM"
            onChange={(e) => {
              setiscrm(!iscrm);
              let x = otherrole;

              if (e.target.checked === true) {
                x["CRM"] = true;
                setotherrole(x);
              } else {
                x["CRM"] = false;
                setotherrole(x);
              }

              setrefresh(!refresh);
            }}
          ></input>
          {iscrm === true ? (
            <div className="row">
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Dashboard:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="DashboardAccount"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["CRM Dashboard"] = true;
                      setotherrole(x);
                    } else {
                      x["CRM Dashboard"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Leads:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="DashboardAccount"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Leads"] = true;
                      setotherrole(x);
                    } else {
                      x["Leads"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Clients:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Clients"] = true;
                      setotherrole(x);
                    } else {
                      x["Clients"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Tasks:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    settask(!istask);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Tasks"] = true;
                      setotherrole(x);
                    } else {
                      x["Tasks"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {istask === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Meeting:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardhr"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Meeting"] = true;
                            setotherrole(x);
                          } else {
                            x["Meeting"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Call:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Attendence"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Call"] = true;
                            setotherrole(x);
                          } else {
                            x["Call"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Other Task:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Other Task"] = true;
                            setotherrole(x);
                          } else {
                            x["Other Task"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Teams:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setteam(!isteam);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Teams"] = true;
                      setotherrole(x);
                    } else {
                      x["Teams"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isteam === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Team :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardhr"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Add Team"] = true;
                            setotherrole(x);
                          } else {
                            x["Add Team"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Member:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Attendence"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Member"] = true;
                            setotherrole(x);
                          } else {
                            x["Member"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Hierarchy:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Hierarchy"] = true;
                            setotherrole(x);
                          } else {
                            x["Hierarchy"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Availability:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Crm Availability"] = true;
                      setotherrole(x);
                    } else {
                      x["Crm Availability"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">
            <h5>Asset</h5>
          </label>{" "}
          <input
            type="checkbox"
            className="  ml-md-3"
            id="Assets"
            onChange={(e) => {
              setisassets(!isassets);
              let x = otherrole;

              if (e.target.checked === true) {
                x["Asset Module"] = true;
                setotherrole(x);
              } else {
                x["Asset Module"] = false;
                setotherrole(x);
              }

              setrefresh(!refresh);
            }}
          ></input>
          {isassets === true ? (
            <div className="row">
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Dashboard:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="DashboardAccount"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Asset Dashboard"] = true;
                      setotherrole(x);
                    } else {
                      x["Asset Dashboard"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Assets:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    setisassetsadd(!isassetsadd);
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Assets"] = true;
                      setotherrole(x);
                    } else {
                      x["Assets"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
                {isassetsadd === true ? (
                  <div className="row">
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0"> Add Asset :</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Dashboardhr"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Add Asset"] = true;
                            setotherrole(x);
                          } else {
                            x["Add Asset"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Sell:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Attendence"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Sell"] = true;
                            setotherrole(x);
                          } else {
                            x["Sell"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>{" "}
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Buyer:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Buyer"] = true;
                            setotherrole(x);
                          } else {
                            x["Buyer"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                    <div className="col-md-12  p-0">
                      <label className="input_label m-0">Seller:</label>{" "}
                      <input
                        type="checkbox"
                        className="  ml-md-3"
                        id="Accounts"
                        onChange={(e) => {
                          let x = otherrole;

                          if (e.target.checked === true) {
                            x["Seller"] = true;
                            setotherrole(x);
                          } else {
                            x["Seller"] = false;
                            setotherrole(x);
                          }

                          setrefresh(!refresh);
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Maintainance:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Maintainance"] = true;
                      setotherrole(x);
                    } else {
                      x["Maintainance"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>{" "}
              <div className="col-md-12  p-0">
                <label className="input_label m-0">
                  <b>Depriciation:</b>
                </label>{" "}
                <input
                  type="checkbox"
                  className="  ml-md-3"
                  id="Accounts"
                  onChange={(e) => {
                    let x = otherrole;

                    if (e.target.checked === true) {
                      x["Depriciation"] = true;
                      setotherrole(x);
                    } else {
                      x["Depriciation"] = false;
                      setotherrole(x);
                    }

                    setrefresh(!refresh);
                  }}
                ></input>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row pr-md-2 pt-md-4">
        <div className=" col-12 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => history.push("/Roles")}
            >
              Cancel
            </button>
          </span>
          <span>
            <button className="save_btn  ml-2" id="savedata" onClick={savedata}>
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
export const RoleAddTable = (props) => {
  const rolesdatastate = useSelector((state) => state.GetAllRoleTable);

  const [allroleData, setallroleData] = useState([]);
  const [filerunits, setfilterunits] = useState();
  const [searchValue, setSearchValue] = useState("");

  let dispatch = useDispatch();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(rolesdatastate.data.Data);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter((val) =>
            regex.test(val.RoleName !== null ? val.RoleName : null)
          );
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };

  const openmodalallroles = (x) => {
    setallroleData(x);
    document.getElementById("modalofleadtable").style.display = "block";
  };

  useEffect(() => {
    dispatch(getallRolesTable());
  }, [dispatch]);

  useEffect(() => {
    if (rolesdatastate !== undefined) {
      setfilterunits(rolesdatastate.data.Data);
    }
  }, [rolesdatastate]);
  const history = useHistory();

  return (
    <>
      <div className="row  ">
        <div className="dash_background col-12 p-0">
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3   pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                placeholder="Name"
                onChange={onTextChange}
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {rolesdatastate.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>

          <div className="table-responsive mt-4">
            <table className="table table-borderless  table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Role Name</th>
                  <th>Permission Type</th>
                  <th>Description</th>
                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => history.push("/Roles/addform")}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filerunits) &&
                  filerunits?.map((val, index) => (
                    <tr key={index}>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallroles(val);
                        }}
                      >
                        {index + 1}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallroles(val);
                        }}
                      >
                        {val.RoleName !== undefined && val.RoleName !== null
                          ? val.RoleName
                          : null}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallroles(val);
                        }}
                      >
                        {val.PermissionType !== undefined
                          ? val.PermissionType
                          : null}
                      </td>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallroles(val);
                        }}
                      >
                        {val.Description !== undefined &&
                          val.Description !== null
                          ? val.Description
                          : null}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        <FiEdit2
                          title="Edit"
                          size="1.1em"
                          className="cur_sor"
                          onClick={() => {
                            history.push("/Roles/addform", val);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* model for show information*/}
      <div id="modalofleadtable" class="modalscreen ">
        <div class="modal-content-cat">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Roles Detail</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadtable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3   pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                placeholder="Role Name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {rolesdatastate.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 p-0">
              <div
                className="table-responsive_modal mt-4 table_height-voucher"
                style={{ height: "auto" }}
              >
                <table className="table table-borderless m-0 ">
                  <thead class="purple whiteText">
                    <tr>
                      <th scope="col">Sr</th>
                      <th className="text-center" scope="col">
                        Role{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allroleData !== undefined &&
                      allroleData?.RoleScreenPermissions !== undefined
                      ? allroleData?.RoleScreenPermissions.map((x, id) => {
                        if (
                          x.Name.toLowerCase().includes(
                            searchValue.toLowerCase()
                          )
                        ) {
                          return (
                            <tr key={id}>
                              <td>{id + 1}</td>
                              <td className="text-center">{x.Name}</td>
                            </tr>
                          );
                        }
                        return null;
                      })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
