import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallEmployees } from "../../actions/HRactions/getemployees";
import { getallProject } from "../../actions/getprojects";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Loader from "react-loader-spinner";
import { getallRoles } from "../../actions/populateRoles";
import { toast } from "react-toastify";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
export const RoleAddForm = (props) => {
  const resultallemployees = useSelector((state) => state.getAllEmployees);
  // const resultallproject = useSelector((state) => state.getProject);
  const allRoleId = useSelector((state) => state.getRoles);

  let dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const editVal = location.state;
  const [employeemail, setemployeemail] = useState();
  console.log(employeemail);
  const [isLoading, setIsLoading] = useState(false);
  const [isaccount, setaccount] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [hrOpen, setHrOpen] = useState(false);
  const [crmOpen, setCrmOpen] = useState(false);
  const [assetOpen, setAssetOpen] = useState(false);
  const [otherOpen, setOtherOpen] = useState(false);

  const [isinventory, setinventory] = useState(false);
  const [renderComp, setrenderComp] = useState(false);
  const [isHr, setisHr] = useState(false);
  const [isCrm, setisCrm] = useState(false);
  const [isAsset, setisAsset] = useState(false);
  const [isOther, setisOther] = useState(false);

  const [permission, setPermission] = useState("");
  const [decsrip, setDecsrip] = useState("");
  const [showList, setSHowList] = useState(false);

  const [accountRsIdx, setAccountRsIdx] = useState("");
  const [inventoryRsIdx, setInventoryRsIdx] = useState("");
  const [hrRsIdx, setHrRsIdx] = useState("");
  const [crmRsIdx, setCrmRsIdx] = useState("");
  const [assetRsIdx, setAssetRsIdx] = useState("");

  const checkboxStyle = {
    width: "18px",
    height: "18px",
    marginTop: 3,
    cursor: "pointer",
  };
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: 5,
  };
  const [accountDataList, setaccountDataList] = useState([
    {
      name: "Dashboard",
      check: false,
    },
    {
      name: "Vouchers",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Receipt Entry",
          check: false,
          open: false,
          subMenu: [
            {
              name: "General Receipt",
              check: false,
            },
            {
              name: "Unit Receipt",
              check: false,
            },
            {
              name: "Deal Sold Rec",
              check: false,
            },
            {
              name: "Recovery Receipt",
              check: false,
            },
            {
              name: "Invest Receipt",
              check: false,
            },
            {
              name: "Com Receipt",
              check: false,
            },
          ],
        },
        {
          name: "Payment Entry",
          check: false,
          open: false,
          subMenu: [
            {
              name: "General Payment",
              check: false,
            },
            {
              name: "Land Payment",
              check: false,
            },
            {
              name: "Salary Payment",
              check: false,
            },
            {
              name: "Invest Payment",
              check: false,
            },
            {
              name: "Deal Payment",
              check: false,
            },
            {
              name: "Com Payment",
              check: false,
            },
          ],
        },
        {
          name: "Recovery Booking",
          check: false,
        },
        {
          name: "Journal Entry",
          check: false,
        },
        {
          name: "General Adjustment",
          check: false,
        },
        {
          name: "Installments",
          check: false,
          subMenu: [
            {
              name: "Receipt Installments",
              check: false,
            },
            {
              name: "Payment Installments",
              check: false,
            },
          ],
        },
        {
          name: "External Commission",
          check: false,
        },
        {
          name: "Unsubmitted Vouchers",
          check: false,
        },
        {
          name: "Submitted Vouchers",
          check: false,
        },
        {
          name: "Payment Entry",
          check: false,
        },
        {
          name: "Recovery Booking",
          check: false,
        },
        {
          name: "Journal Entry",
          check: false,
        },
        {
          name: "Notifications",
          check: false,
        },
      ],
    },
    {
      name: "Chart of Account",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Add Chart of Acc",
          check: false,
        },
        {
          name: "Account",
          check: false,
        },
      ],
    },
    {
      name: "Contract",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Contracts",
          check: false,
        },
        {
          name: "Install Reschedule",
          check: false,
        },
        {
          name: "Customer",
          check: false,
        },
        {
          name: "Transfer Contract",
          check: false,
        },
        {
          name: "Transfer Unit",
          check: false,
        },
      ],
    },
    {
      name: "Tax",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Add Tax",
          check: false,
        },
        {
          name: "Tax Payable",
          check: false,
        },
        {
          name: "Tax Receivable",
          check: false,
        },
      ],
    },
    {
      name: "Investment",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Investor",
          check: false,
        },
        {
          name: "Investment",
          check: false,
        },
      ],
    },
    {
      name: "Reports",
      open: false,
      check: false,
      subBtn: [
        {
          name: "Trail Balance",
          check: false,
        },

        {
          name: "Account Receivable",
          check: false,
        },
        {
          name: "Account Payable",
          check: false,
        },
        {
          name: "General Journal",
          check: false,
        },
        {
          name: "General Ledger",
          check: false,
        },
        {
          name: "Land Installment Tracker",
          check: false,
        },
        {
          name: "Balance Sheet",
          check: false,
        },
        {
          name: "Income Statement",
          check: false,
        },
        {
          name: "Cash Book",
          check: false,
        },
        {
          name: "Recovery Receipt",
          check: false,
        },
        {
          name: "Install Payment",
          check: false,
        },
        {
          name: "Install Receipt",
          check: false,
        },
        {
          name: "Manager Recovery",
          check: false,
        },
        {
          name: "Commission Payment",
          check: false,
        },
        {
          name: "Commission Receipt",
          check: false,
        },
        {
          name: "Commission Pay Sold",
          check: false,
        },
        {
          name: "Admin Share Payable",
          check: false,
        },
        {
          name: "Reserved Commission Payable",
          check: false,
        },
        {
          name: "Project Report",
          check: false,
        },
      ],
    },
  ]);
  const [inventoryDataList, setinventoryDataList] = useState([
    {
      name: "Inventory Dashboard",
      check: false,
    },
    {
      name: "Project",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Add Project",
          check: false,
        },
        {
          name: "Section",
          check: false,
        },
        {
          name: "Units",
          check: false,
        },
        {
          name: "New Purchase",
          check: false,
        },
        {
          name: "Re Purchase",
          check: false,
        },
      ],
    },
    // {
    //     name: "Deal Bought",
    //     check: false,
    // },

    {
      name: "Deals",
      check: false,
      open: false,

      subBtn: [
        {
          name: "Deal Bought",
          check: false,
        },
        {
          name: "Deal Sold",
          check: false,
        },
        {
          name: "Affiliates",
          check: false,
        },
      ],
    },
    {
      name: "Inventory Availability",
      check: false,
      open: false,

      subBtn: [
        {
          name: "Inventory Availability",
          check: false,
        },
        {
          name: "Booked Units",
          check: false,
        },
      ],
    },
    {
      name: "LandandSeller",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Land",
          check: false,
        },
        {
          name: "LandSeller",
          check: false,
        },
        {
          name: "Seller",
          check: false,
        },
      ],
    },
  ]);
  const [hrDataList, sethrDataList] = useState([
    {
      name: "Hr Dashboard",
      check: false,
    },
    {
      name: "Attendence",
      check: false,
    },
    {
      name: "Pay Roll",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Assign Salary",
          check: false,
        },
        {
          name: "AdvanceLoan",
          check: false,
          open: false,
          subMenu: [
            {
              name: "Advance",
              check: false,
            },
            {
              name: "Loan",
              check: false,
            },
          ],
        },
        {
          name: "Pay Slip",
          check: false,
        },
      ],
    },
    {
      name: "Employees",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Add Employee",
          check: false,
        },
        {
          name: "Designation",
          check: false,
        },
      ],
    },
  ]);
  const [crmDataList, setcrmDataList] = useState([
    {
      name: "CRM Dashboard",
      check: false,
    },
    {
      name: "Leads",
      check: false,
    },
    {
      name: "Clients",
      check: false,
    },
    {
      name: "Tasks",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Meeting",
          check: false,
        },
        {
          name: "Call",
          check: false,
        },
        {
          name: "Other Task",
          check: false,
        },
      ],
    },
    {
      name: "Teams",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Add Team",
          check: false,
        },
        {
          name: "Member",
          check: false,
        },
        {
          name: "Hierarchy",
          check: false,
        },
      ],
    },
    {
      name: "Crm Availability",
      check: false,
    },
  ]);

  const [AssetDataList, setassetDataList] = useState([
    {
      name: "Asset Dashboard",
      check: false,
    },
    {
      name: "Assets",
      check: false,
      open: false,
      subBtn: [
        {
          name: "Add Asset",
          check: false,
        },
        {
          name: "Sell",
          check: false,
        },
        {
          name: "Buyer",
          check: false,
        },
        {
          name: "Seller",
          check: false,
        },
      ],
    },
    {
      name: "Maintainance",
      check: false,
    },
    {
      name: "Depriciation",
      check: false,
    },
  ]);
  const [otherList, setOtherList] = useState([
    {
      name: "Users",
      check: false,
    },
    {
      name: "Change Request",
      check: false,
    },
    {
      name: "Roles",
      check: false,
    },
    {
      name: "Revert Back Vouchers",
      check: false,
    },
  ]);

  var suggestionlist1 = [];
  const EmployeeSelecttion = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultallemployees.data
          .sort()
          .filter((v) => regex.test(v.EmpName !== null ? v.EmpName : null));
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <datalist id="employeelist">
            {suggestionlist1?.map((item, idx) => (
              <option
                index={idx}
                value={item === undefined ? item : item.EmpName}
                // onClick={() => suggestionselected1(item)}
                onChange={setemployeemail(item.EmpEmail)}
              >
                {item === undefined ? item : item.EmpName}
              </option>
            ))}
          </datalist>,
          document.getElementById("unitdemo")
        );
      }
    }
  };
  // const suggestionselected1 = (val) => {
  //   document.getElementById("Employee").value = val.EmpName;

  //   setemployeemail(val.EmpEmail);

  //   suggestionlist1 = [];
  //   ReactDOM.render(null, document.getElementById("unitdemo"));
  // };

  function savedata() {
    if (document.getElementById("Employee").value === "") {
      toast.info("Select Role");
    }
    if (decsrip === "") {
      toast.info("Type Description");
    } else {
      let arr = [];
      if (isaccount) {
        arr.push({
          // roleName: "Accounts",
          aspNetRoleId: "b39349db-60bf-4267-a0cd-4ff296a7cb53",
        });
        accountDataList?.forEach((item, idx) => {
          if (item.check === true) {
            arr.push({ aspNetRoleId: item.id });
            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                arr.push({ aspNetRoleId: btn.id });
              }
              btn.subMenu?.forEach((subMenus, idx) => {
                if (subMenus.check) {
                  arr.push({ aspNetRoleId: subMenus.id });
                }
              });
            });

            // item.check = false;
          }
        });
      }

      if (isinventory) {
        arr.push({
          // roleName: "Inventory",
          aspNetRoleId: "129979f5-2f29-4a51-b4d8-68e15461ba84",
        });
        inventoryDataList?.forEach((item, idx) => {
          if (item.check === true) {
            arr.push({ aspNetRoleId: item.id });
            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                arr.push({ aspNetRoleId: btn.id });
              }
            });
            // item.check = false;
          }
        });
      }
      if (isHr) {
        arr.push({
          // roleName: "Hr",
          aspNetRoleId: "7bec99e1-762a-4b94-96b4-d376e8ab77ed",
        });
        hrDataList?.forEach((item, idx) => {
          if (item.check === true) {
            arr.push({ aspNetRoleId: item.id });

            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                arr.push({ aspNetRoleId: btn.id });
              }
              btn.subMenu?.forEach((subMenus, idx) => {
                if (subMenus.check) {
                  arr.push({ aspNetRoleId: subMenus.id });
                }
              });
            });
            // item.check = false;
          }
        });
      }
      if (isCrm) {
        arr.push({
          aspNetRoleId: "af8d9338-cf14-46d3-a510-fdaad0ce7d75",
        });
        crmDataList?.forEach((item, idx) => {
          if (item.check === true) {
            arr.push({ aspNetRoleId: item.id });

            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                arr.push({ aspNetRoleId: btn.id });
              }
            });
            // item.check = false;
          }
        });
      }
      if (isAsset) {
        arr.push({
          aspNetRoleId: "5f222f4b-2f57-4674-8f13-9d63dfac9a94",
        });
        AssetDataList?.forEach((item, idx) => {
          if (item.check === true) {
            arr.push({ aspNetRoleId: item.id });

            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                arr.push({ aspNetRoleId: btn.id });
              }
            });
            // item.check = false;
          }
        });
      }
      if (isOther) {
        otherList?.forEach((item, idx) => {
          if (item.check === true) {
            arr.push({ aspNetRoleId: item.id });

            // item.check = false;
          }
        });
      }
      //
      setIsLoading(true);
      axios({
        url: `${process.env.REACT_APP_API_URL}Roles/Add`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          name: document.getElementById("Employee").value,
          description: decsrip,
          permissionType: permission,
          roleScreens: arr,
        }),
      }).then(
        (result) => {
          document.getElementById("savedata").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            setTimeout(() => {
              setIsLoading(false);
              history.goBack();
            }, 2000);
          } else {
            toast.info(result.data.Message);

            setIsLoading(false);
          }
        },
        (error) => {
          document.getElementById("savedata").disabled = false;
          toast.info("Something went wrong!");
          setIsLoading(false);
        }
      );
    }
  }

  const updateIdsRecursively = (dataList) => {
    return dataList.map((item) => {
      if (allRoleId) {
        const matchingObject = allRoleId.data.find(
          (newItem) => newItem.Name === item.name
        );
        const updatedItem = matchingObject
          ? { ...item, id: matchingObject.Id }
          : item;

        if (updatedItem.subBtn) {
          updatedItem.subBtn = updateIdsRecursively(updatedItem.subBtn);
        }
        if (updatedItem?.subMenu) {
          updatedItem.subMenu = updateIdsRecursively(updatedItem.subMenu);
        }

        return updatedItem;
      }
    });
  };
  const updateRoleScreenIdRecursively = (dataList) => {
    return dataList.map((item) => {
      if (editVal?.RoleScreenPermissions) {
        const matchingObject = editVal?.RoleScreenPermissions.find(
          (newItem) => newItem.Name === item.name
        );
        const updatedItem = matchingObject
          ? { ...item, roleId: matchingObject.RoleScreenId }
          : item;

        if (updatedItem.subBtn) {
          updatedItem.subBtn = updateRoleScreenIdRecursively(
            updatedItem.subBtn
          );
        }
        if (updatedItem?.subMenu) {
          updatedItem.subMenu = updateRoleScreenIdRecursively(
            updatedItem.subMenu
          );
        }

        return updatedItem;
      }
    });
  };
  useEffect(() => {
    const updatedAccountsList = updateIdsRecursively(accountDataList);
    const updatedInventoryList = updateIdsRecursively(inventoryDataList);
    const updatedCrmList = updateIdsRecursively(crmDataList);
    const updatedHrList = updateIdsRecursively(hrDataList);
    const updatedAssetList = updateIdsRecursively(AssetDataList);
    const updatedOtherList = updateIdsRecursively(otherList);

    setaccountDataList(updatedAccountsList);
    setinventoryDataList(updatedInventoryList);
    sethrDataList(updatedHrList);
    setcrmDataList(updatedCrmList);
    setassetDataList(updatedAssetList);
    setOtherList(updatedOtherList);
  }, [allRoleId]);
  useEffect(() => {
    if (editVal?.RoleScreenPermissions?.length > 0) {
      const updatedAccountsList =
        updateRoleScreenIdRecursively(accountDataList);
      const updatedInventoryList =
        updateRoleScreenIdRecursively(inventoryDataList);
      const updatedCrmList = updateRoleScreenIdRecursively(crmDataList);
      const updatedHrList = updateRoleScreenIdRecursively(hrDataList);
      const updatedAssetList = updateRoleScreenIdRecursively(AssetDataList);

      setaccountDataList(updatedAccountsList);
      setinventoryDataList(updatedInventoryList);
      sethrDataList(updatedHrList);
      setcrmDataList(updatedCrmList);
      setassetDataList(updatedAssetList);
    }
  }, [editVal]);
  useEffect(() => {
    dispatch(getallEmployees());
    dispatch(getallProject());
    dispatch(getallRoles());
  }, [dispatch]);


  useEffect(() => {
    if (editVal) {
      document.getElementById("Employee").value = editVal.RoleName;
      setPermission(editVal?.PermissionType);
      setDecsrip(editVal.Description);
      if (editVal.PermissionType === "Custom") {
        setSHowList(true);
      } else {
        setSHowList(false);
      }
      const rolesArray = editVal?.RoleScreenPermissions?.map(
        (userRole) => userRole.Name
      );
      accountDataList?.forEach((item, idx) => {
        if (rolesArray?.includes("Accounts")) {
          setaccount(true);
        }

        if (rolesArray?.includes(item.name)) {
          item.check = true;
        }

        item.subBtn?.forEach((item2, id) => {
          if (rolesArray?.includes(item2.name)) {
            item2.check = true;
          }

          item2.subMenu?.forEach((item3, id) => {
            if (rolesArray?.includes(item3.name)) {
              item3.check = true;
            }
          });
        });
      });
      inventoryDataList?.forEach((item, idx) => {
        if (rolesArray?.includes("Inventory")) {
          setinventory(true);
        }
        if (rolesArray?.includes(item.name)) {
          item.check = true;
        }
        item?.subBtn?.forEach((item2, id) => {
          if (rolesArray?.includes(item2.name)) {
            item2.check = true;
          }
        });
      });
      hrDataList?.forEach((item, idx) => {
        if (rolesArray?.includes("HR")) {
          setisHr(true);
        }
        if (rolesArray?.includes(item.name)) {
          item.check = true;
        }
        item?.subBtn?.forEach((item2, id) => {
          if (rolesArray?.includes(item2.name)) {
            item2.check = true;
          }
          item2.subMenu?.forEach((item3, id) => {
            if (rolesArray?.includes(item3.name)) {
              item3.check = true;
            }
          });
        });
        setrenderComp(!renderComp);
      });
      crmDataList?.forEach((item, idx) => {
        if (rolesArray?.includes("CRM")) {
          setisCrm(true);
        }
        if (rolesArray?.includes(item.name)) {
          item.check = true;
        }
        item?.subBtn?.forEach((item2, id) => {
          if (rolesArray?.includes(item2.name)) {
            item2.check = true;
          }
        });
        setrenderComp(!renderComp);
      });
      AssetDataList?.forEach((item, idx) => {
        if (rolesArray?.includes("Assets")) {
          setisAsset(true);
        }
        if (rolesArray?.includes(item.name)) {
          item.check = true;
        }
        item?.subBtn?.forEach((item2, id) => {
          if (rolesArray?.includes(item2.name)) {
            item2.check = true;
          }
        });
        setrenderComp(!renderComp);
      });
      otherList?.forEach((item, idx) => {
        if (rolesArray?.includes(item.name)) {
          item.check = true;
          setisOther(true);
        }

        setrenderComp(!renderComp);
      });
    }
  }, [editVal && AssetDataList]);

  useEffect(() => {
    if (editVal) {
      editVal?.RoleScreenPermissions?.map((item) => {
        if (item?.AspNetRoleId === "b39349db-60bf-4267-a0cd-4ff296a7cb53") {
          setAccountRsIdx(item.RoleScreenId);
        }
        if (item?.AspNetRoleId === "129979f5-2f29-4a51-b4d8-68e15461ba84") {
          setInventoryRsIdx(item.RoleScreenId);
        }
        if (item?.AspNetRoleId === "7bec99e1-762a-4b94-96b4-d376e8ab77ed") {
          setHrRsIdx(item.RoleScreenId);
        }
        if (item?.AspNetRoleId === "af8d9338-cf14-46d3-a510-fdaad0ce7d75") {
          setCrmRsIdx(item.RoleScreenId);
        }
        if (item?.AspNetRoleId === "5f222f4b-2f57-4674-8f13-9d63dfac9a94") {
          setAssetRsIdx(item.RoleScreenId);
        }
      });
    }
  }, [editVal]);

  function updatedata() {
    if (document.getElementById("Employee").value === "") {
      toast.info("Select Employee");
    }
    if (decsrip === "") {
      toast.info("Type Description");
    } else {
      let arr = [];

      if (isaccount) {
        arr.push({
          // roleName: "Accounts",
          aspNetRoleId: "b39349db-60bf-4267-a0cd-4ff296a7cb53",
          roleScreenId: accountRsIdx || null,
        });
        accountDataList?.forEach((item, idx) => {
          if (item.check === true) {
            if (item.roleId) {
              arr.push({ aspNetRoleId: item.id, roleScreenId: item.roleId });
            } else arr.push({ aspNetRoleId: item.id });
            item.subBtn?.forEach((btn, idx) => {
              if (btn.check === true) {
                if (btn.roleId) {
                  arr.push({
                    aspNetRoleId: btn.id,
                    roleScreenId: btn.roleId,
                  });
                } else arr.push({ aspNetRoleId: btn.id });
              }
              btn.subMenu?.forEach((subMenus, idx) => {
                if (subMenus.check === true) {
                  if (subMenus.roleId) {
                    arr.push({
                      aspNetRoleId: subMenus.id,
                      roleScreenId: subMenus.roleId,
                    });
                  } else arr.push({ aspNetRoleId: subMenus.id });
                }
              });
            });
            // item.check = false;
          }
        });
      }

      // accountDataList?.forEach((item, idx) => {
      //   if (item.check === true) {
      //     editVal.RoleScreenPermissions?.map((item2) => {
      //       if (item2?.AspNetRoleId === item.id) {
      //         arr.push({
      //           aspNetRoleId: item.id,
      //           roleScreenId: item2.RoleScreenId,
      //           abc: "hehe",
      //         });
      //       }
      //     });
      //     // arr.push({ aspNetRoleId: item.id });
      //     item.subBtn?.forEach((btn, idx) => {
      //       if (btn.check  === true) {
      //         editVal.RoleScreenPermissions?.map((item3) => {
      //           if (item3?.AspNetRoleId === btn.id) {
      //             arr.push({
      //               aspNetRoleId: btn.id,
      //               roleScreenId: item3.RoleScreenId,
      //               def: "Hahahah",
      //             });
      //           }
      //         });
      //         // arr.push({ aspNetRoleId: btn.id });
      //       }
      //       btn.subMenu?.forEach((subMenus, idx) => {
      //         if (subMenus.check  === true) {
      //           editVal.RoleScreenPermissions?.map((item4) => {
      //             if (item4?.AspNetRoleId === subMenus.id) {
      //               arr.push({
      //                 aspNetRoleId: subMenus.id,
      //                 roleScreenId: item4.RoleScreenId,
      //                 ghi: "Huhuhuh",
      //               });
      //             }
      //           });
      //           // arr.push({ aspNetRoleId: subMenus.id });
      //         }
      //       });
      //     });
      //     // item.check = false;
      //   }
      //   });
      // }

      if (isinventory) {
        arr.push({
          // roleName: "Inventory",
          aspNetRoleId: "129979f5-2f29-4a51-b4d8-68e15461ba84",
          roleScreenId: inventoryRsIdx || null,
        });
        inventoryDataList?.forEach((item, idx) => {
          if (item.check === true) {
            if (item.roleId) {
              arr.push({ aspNetRoleId: item.id, roleScreenId: item.roleId });
            } else arr.push({ aspNetRoleId: item.id });
            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                if (btn.roleId) {
                  arr.push({
                    aspNetRoleId: btn.id,
                    roleScreenId: btn.roleId,
                  });
                } else arr.push({ aspNetRoleId: btn.id });
              }
            });
            // item.check = false;
          }
        });
      }
      if (isHr) {
        arr.push({
          // roleName: "Hr",
          aspNetRoleId: "7bec99e1-762a-4b94-96b4-d376e8ab77ed",
          roleScreenId: hrRsIdx || null,
        });
        hrDataList?.forEach((item, idx) => {
          if (item.check === true) {
            if (item.roleId) {
              arr.push({ aspNetRoleId: item.id, roleScreenId: item.roleId });
            } else arr.push({ aspNetRoleId: item.id });
            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                if (btn.roleId) {
                  arr.push({
                    aspNetRoleId: btn.id,
                    roleScreenId: btn.roleId,
                  });
                } else arr.push({ aspNetRoleId: btn.id });
              }
              btn.subMenu?.forEach((subMenus, idx) => {
                if (subMenus.check) {
                  if (subMenus.roleId) {
                    arr.push({
                      aspNetRoleId: subMenus.id,
                      roleScreenId: subMenus.roleId,
                    });
                  } else arr.push({ aspNetRoleId: subMenus.id });
                }
              });
            });
            // item.check = false;
          }
        });
      }

      if (isCrm) {
        arr.push({
          aspNetRoleId: "af8d9338-cf14-46d3-a510-fdaad0ce7d75",
          roleScreenId: crmRsIdx || null,
        });
        crmDataList?.forEach((item, idx) => {
          if (item.check === true) {
            if (item.roleId) {
              arr.push({ aspNetRoleId: item.id, roleScreenId: item.roleId });
            } else arr.push({ aspNetRoleId: item.id });

            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                if (btn.roleId) {
                  arr.push({
                    aspNetRoleId: btn.id,
                    roleScreenId: btn.roleId,
                  });
                } else arr.push({ aspNetRoleId: btn.id });
              }
            });
            // item.check = false;
          }
        });
      }
      if (isAsset) {
        arr.push({
          aspNetRoleId: "5f222f4b-2f57-4674-8f13-9d63dfac9a94",
          roleScreenId: assetRsIdx || null,
        });
        AssetDataList?.forEach((item, idx) => {
          if (item.check === true) {
            if (item.roleId) {
              arr.push({ aspNetRoleId: item.id, roleScreenId: item.roleId });
            } else arr.push({ aspNetRoleId: item.id });

            item.subBtn?.forEach((btn, idx) => {
              if (btn.check) {
                if (btn.roleId) {
                  arr.push({
                    aspNetRoleId: btn.id,
                    roleScreenId: btn.roleId,
                  });
                } else arr.push({ aspNetRoleId: btn.id });
              }
            });
            // item.check = false;
          }
        });
      }
      if (isOther) {
        otherList?.forEach((item, idx) => {
          if (item.check === true) {
            if (item.roleId) {
              arr.push({ aspNetRoleId: item.id, roleScreenId: item.roleId });
            } else arr.push({ aspNetRoleId: item.id });
          }
        });
      }
      document.getElementById("savedata").disabled = true;
      setIsLoading(true);

      axios({
        url: `${process.env.REACT_APP_API_URL}Roles/update`,
        method: "put",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          RoleId: editVal.RoleId,
          name: document.getElementById("Employee").value,
          description: decsrip,
          permissionType: permission,
          roleScreens: arr,
        }),
      }).then(
        (result) => {
          document.getElementById("savedata").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            setTimeout(() => {
              setIsLoading(false);
              setAccountRsIdx("");
              setInventoryRsIdx("");
              setAssetRsIdx("");
              setCrmRsIdx("");
              setHrRsIdx("");
              history.goBack();
            }, 3000);
          } else {
            toast.info(result.data.Message);

            setIsLoading(false);
          }
        },
        (error) => {
          document.getElementById("savedata").disabled = false;
          toast.info("Something went wrong!");
          setIsLoading(false);
        }
      );
    }
  }

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setPermission(selectedValue);
    if (selectedValue === "All") {
      setSHowList(false);
    } else if (selectedValue === "Custom") {
      setSHowList(true);
    } else {
      setSHowList(false);
    }
  };
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0"> {editVal ? "Update" : "Add"} Roles</h4>
        </div>
        <div className="row "></div>
        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">Role Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  "
            id="Employee"
            list="employeelist"
            placeholder="Role Name"
            onChange={EmployeeSelecttion}
          ></input>
          <span className="estaric">*</span>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>

        <div className="col-md-2 mt-md-4   p-0">
          <label className="input_label m-0">Permission</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <>
            <select
              name="Permission"
              value={permission}
              onChange={handleChange}
              className="input_styling"
            >
              <option
                value=""
                disabled
              >
                {" "}
                Select
              </option>
              <option value="All">All</option>
              <option value="Custom">Custom</option>
            </select>
          </>
        </div>
        <div className="col-md-2 mt-md-4 p-0">
          <label className="input_label m-0">Description</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <textarea
            name="description"
            value={decsrip}
            onChange={(e) => setDecsrip(e.target.value)}
            className="input_address"
            style={{ width: "85%" }}
            placeholder="Enter Description"
          />
        </div>
      </div>
      {showList && (
        <div
          className="row "
          style={{ maxHeight: "600px", overflowY: "auto" }}
        >
          <div className="col-md-2 mt-md-4   p-0">
            <div style={rowStyle}>
              <span>
                {accountOpen ? (
                  <AiOutlineDown
                    title="see details"
                    onClick={() => {
                      setAccountOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <AiOutlineRight
                    title="see details"
                    onClick={() => {
                      setAccountOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              <label className="input_label m-0">
                <h5>Accounts:</h5>
              </label>{" "}
              <input
                checked={isaccount}
                type="checkbox"
                style={checkboxStyle}
                className="ml-md-3"
                id="Accounts"
                onChange={(e) => {
                  setaccount(e.target.checked);
                  setAccountOpen(e.target.checked);
                  if (e.target.checked === false) {
                    accountDataList?.forEach((item) => {
                      item.check = false;
                      item?.subBtn?.forEach((submenu) => {
                        submenu.check = false;
                        submenu.subMenu?.forEach((submenu) => {
                          submenu.check = false;
                        });
                      });
                    });
                  }
                }}
              ></input>
            </div>
            {accountOpen &&
              accountDataList?.map((item, idx) => {
                return (
                  <div className="row">
                    <ul
                      style={{ listStyleType: "none" }}
                      className="col-md-12 m-0"
                    >
                      <li>
                        <div style={rowStyle}>
                          {item.subBtn ? (
                            <span>
                              {item.open ? (
                                <AiOutlineDown
                                  title="see details"
                                  onClick={() => {
                                    item.open = false;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiOutlineRight
                                  title="see details"
                                  onClick={() => {
                                    item.open = true;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          <label htmlFor="">
                            <b>{item.name}: </b>
                          </label>
                          <input
                            type="checkbox"
                            style={checkboxStyle}
                            className="m-1"
                            checked={item.check}
                            onChange={(e) => {
                              item.check = e.target.checked;
                              item.open = e.target.checked;
                              setaccount(true);
                              setrenderComp(!renderComp);
                              if (e.target.checked === false) {
                                item.subBtn?.forEach((submenu) => {
                                  submenu.check = false;
                                  submenu.subMenu?.forEach((submenu) => {
                                    submenu.check = false;
                                  });
                                });
                              }
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                    {item.open &&
                      item.subBtn?.map((btn, i) => {
                        return (
                          <>
                            <ul
                              style={{ listStyleType: "none" }}
                              className="col-md-12 m-2 mt-0"
                            >
                              <li style={{ marginLeft: "5px" }}>
                                <div style={rowStyle}>
                                  {btn?.subMenu ? (
                                    <span>
                                      {btn.open ? (
                                        <AiOutlineDown
                                          title="see details"
                                          onClick={() => {
                                            btn.open = false;
                                            setrenderComp(!renderComp);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <AiOutlineRight
                                          title="see details"
                                          onClick={() => {
                                            btn.open = true;
                                            setrenderComp(!renderComp);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <label htmlFor="">
                                    {"  "}
                                    {btn.name === "Account Receiveable"
                                      ? "Account Receivable"
                                      : btn.name}
                                    :{" "}
                                  </label>
                                  <input
                                    className="m-1"
                                    checked={btn.check}
                                    type="checkbox"
                                    style={checkboxStyle}
                                    onChange={(e) => {
                                      btn.check = e.target.checked;
                                      btn.open = e.target.checked;
                                      item.check = true;
                                      setaccount(true);

                                      setrenderComp(!renderComp);
                                      if (e.target.checked === false) {
                                        btn.subMenu.forEach((submenu) => {
                                          submenu.check = false;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </li>
                            </ul>
                            {btn.open &&
                              btn?.subMenu?.map((subMenus) => {
                                return (
                                  <ul
                                    style={{ listStyleType: "none" }}
                                    className="col-md-12 ml-4 mt-0"
                                  >
                                    <li>
                                      <div style={rowStyle}>
                                        <label htmlFor="">
                                          {"  "}
                                          {subMenus.name || "N/A"}
                                        </label>
                                        <input
                                          className="m-1"
                                          checked={subMenus.check}
                                          type="checkbox"
                                          style={checkboxStyle}
                                          onChange={(e) => {
                                            subMenus.check = e.target.checked;
                                            btn.check = true;
                                            item.check = true;
                                            setaccount(true);
                                            setrenderComp(!renderComp);
                                          }}
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                          </>
                        );
                      })}
                  </div>
                );
              })}
          </div>

          <div className="col-md-2 mt-md-4 p-0">
            <div style={rowStyle}>
              <span>
                {inventoryOpen ? (
                  <AiOutlineDown
                    title="see details"
                    onClick={() => {
                      setInventoryOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <AiOutlineRight
                    title="see details"
                    onClick={() => {
                      setInventoryOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              <label className="input_label m-0">
                <h5>Inventory:</h5>
              </label>{" "}
              <input
                checked={isinventory}
                type="checkbox"
                style={checkboxStyle}
                className="  ml-md-3"
                id="Inventory"
                onChange={(e) => {
                  setinventory(e.target.checked);
                  setInventoryOpen(e.target.checked);
                  if (e.target.checked === false) {
                    inventoryDataList?.forEach((item) => {
                      item.check = false;
                      item?.subBtn?.forEach((submenu) => {
                        submenu.check = false;
                      });
                    });
                  }
                }}
              ></input>
            </div>
            {inventoryOpen &&
              inventoryDataList?.map((item, idx) => {
                return (
                  <div className="row">
                    <ul
                      style={{ listStyleType: "none" }}
                      className="col-md-12 m-0"
                    >
                      <li>
                        <div style={rowStyle}>
                          {item.subBtn ? (
                            <span>
                              {item.open ? (
                                <AiOutlineDown
                                  title="see details"
                                  onClick={() => {
                                    item.open = false;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiOutlineRight
                                  title="see details"
                                  onClick={() => {
                                    item.open = true;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          <label htmlFor="">
                            <b>{item.name}: </b>
                          </label>
                          <input
                            type="checkbox"
                            style={checkboxStyle}
                            className="m-1"
                            checked={item.check}
                            onChange={(e) => {
                              item.check = e.target.checked;
                              item.open = e.target.checked;
                              setinventory(true);
                              setrenderComp(!renderComp);
                              if (e.target.checked === false) {
                                item.subBtn?.forEach((submenu) => {
                                  submenu.check = false;
                                  submenu.subMenu?.forEach((submenu) => {
                                    submenu.check = false;
                                  });
                                });
                              }
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                    {item.open &&
                      item.subBtn?.map((btn, i) => {
                        return (
                          <ul
                            style={{ listStyleType: "none" }}
                            className="col-md-12 m-2 mt-0"
                          >
                            <li style={{ marginLeft: "5px" }}>
                              <div style={rowStyle}>
                                <label htmlFor=""> {btn.name}: </label>
                                <input
                                  className="m-1"
                                  checked={btn.check}
                                  type="checkbox"
                                  style={checkboxStyle}
                                  onChange={(e) => {
                                    btn.check = e.target.checked;
                                    item.check = true;
                                    setinventory(true);
                                    setrenderComp(!renderComp);
                                  }}
                                />
                              </div>
                            </li>
                          </ul>
                        );
                      })}
                  </div>
                );
              })}
          </div>
          <div className="col-md-2 mt-md-4   p-0">
            <div style={rowStyle}>
              <span>
                {hrOpen ? (
                  <AiOutlineDown
                    title="see details"
                    onClick={() => {
                      setHrOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <AiOutlineRight
                    title="see details"
                    onClick={() => {
                      setHrOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              <label className="input_label m-0">
                <h5>Hr:</h5>
              </label>{" "}
              <input
                checked={isHr}
                type="checkbox"
                style={checkboxStyle}
                className="  ml-md-3"
                id="Hr"
                onChange={(e) => {
                  setisHr(e.target.checked);
                  setHrOpen(e.target.checked);
                  if (e.target.checked === false) {
                    hrDataList?.forEach((item) => {
                      item.check = false;
                      item?.subBtn?.forEach((submenu) => {
                        submenu.check = false;
                        submenu.subMenu?.forEach((submenu) => {
                          submenu.check = false;
                        });
                      });
                    });
                  }
                }}
              ></input>
            </div>
            {hrOpen &&
              hrDataList?.map((item, idx) => {
                return (
                  <div className="row">
                    <ul
                      style={{ listStyleType: "none" }}
                      className="col-md-12 m-0"
                    >
                      <li>
                        <div style={rowStyle}>
                          {item.subBtn ? (
                            <span>
                              {item.open ? (
                                <AiOutlineDown
                                  title="see details"
                                  onClick={() => {
                                    item.open = false;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiOutlineRight
                                  title="see details"
                                  onClick={() => {
                                    item.open = true;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          <label htmlFor="">
                            <b>{item.name}: </b>
                          </label>
                          <input
                            type="checkbox"
                            style={checkboxStyle}
                            className="m-1"
                            checked={item.check}
                            onChange={(e) => {
                              item.check = e.target.checked;
                              item.open = e.target.checked;
                              setisHr(true);
                              setrenderComp(!renderComp);
                              if (e.target.checked === false) {
                                item.subBtn?.forEach((submenu) => {
                                  submenu.check = false;
                                  submenu.subMenu?.forEach((submenu) => {
                                    submenu.check = false;
                                  });
                                });
                              }
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                    {item.open &&
                      item.subBtn?.map((btn, i) => {
                        return (
                          <>
                            <ul
                              style={{ listStyleType: "none" }}
                              className="col-md-12 m-2 mt-0"
                            >
                              <li style={{ marginLeft: "5px" }}>
                                <div style={rowStyle}>
                                  {btn?.subMenu ? (
                                    <span>
                                      {btn.open ? (
                                        <AiOutlineDown
                                          title="see details"
                                          onClick={() => {
                                            btn.open = false;
                                            setrenderComp(!renderComp);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <AiOutlineRight
                                          title="see details"
                                          onClick={() => {
                                            btn.open = true;
                                            setrenderComp(!renderComp);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <label htmlFor=""> {btn.name}: </label>
                                  <input
                                    className="m-1"
                                    checked={btn.check}
                                    type="checkbox"
                                    style={checkboxStyle}
                                    onChange={(e) => {
                                      btn.check = e.target.checked;
                                      btn.open = e.target.checked;
                                      item.check = true;
                                      setisHr(true);
                                      setrenderComp(!renderComp);
                                      if (e.target.checked === false) {
                                        btn.subMenu.forEach((submenu) => {
                                          submenu.check = false;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </li>
                            </ul>
                            {btn.open &&
                              btn?.subMenu?.map((subMenus) => {
                                return (
                                  <ul
                                    style={{ listStyleType: "none" }}
                                    className="col-md-12 m-2 mt-0"
                                  >
                                    <li style={{ marginLeft: "25px" }}>
                                      <div style={rowStyle}>
                                        <label htmlFor="">
                                          {"  "}
                                          {subMenus.name || "N/A"}
                                        </label>
                                        <input
                                          className="m-1"
                                          checked={subMenus.check}
                                          type="checkbox"
                                          style={checkboxStyle}
                                          onChange={(e) => {
                                            subMenus.check = e.target.checked;
                                            btn.check = true;
                                            item.check = true;
                                            setisHr(true);
                                            setrenderComp(!renderComp);
                                          }}
                                        />
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                          </>
                        );
                      })}
                  </div>
                );
              })}
          </div>
          <div className="col-md-2 mt-md-4   p-0">
            <div style={rowStyle}>
              <span>
                {crmOpen ? (
                  <AiOutlineDown
                    title="see details"
                    onClick={() => {
                      setCrmOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <AiOutlineRight
                    title="see details"
                    onClick={() => {
                      setCrmOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              <label className="input_label m-0">
                <h5>Crm:</h5>
              </label>{" "}
              <input
                checked={isCrm}
                type="checkbox"
                style={checkboxStyle}
                className="  ml-md-3"
                id="Crm"
                onChange={(e) => {
                  setisCrm(e.target.checked);
                  setCrmOpen(e.target.checked);
                  if (e.target.checked === false) {
                    crmDataList?.forEach((item) => {
                      item.check = false;
                      item?.subBtn?.forEach((submenu) => {
                        submenu.check = false;
                      });
                    });
                  }
                }}
              ></input>
            </div>
            {crmOpen &&
              crmDataList?.map((item, idx) => {
                return (
                  <div className="row">
                    <ul
                      style={{ listStyleType: "none" }}
                      className="col-md-12 m-0"
                    >
                      <li>
                        <div style={rowStyle}>
                          {item.subBtn ? (
                            <span>
                              {item.open ? (
                                <AiOutlineDown
                                  title="see details"
                                  onClick={() => {
                                    item.open = false;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiOutlineRight
                                  title="see details"
                                  onClick={() => {
                                    item.open = true;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          <label htmlFor="">
                            <b>{item.name}: </b>
                          </label>
                          <input
                            type="checkbox"
                            style={checkboxStyle}
                            className="m-1"
                            checked={item.check}
                            onChange={(e) => {
                              item.check = e.target.checked;
                              item.open = e.target.checked;
                              setisCrm(true);
                              setrenderComp(!renderComp);
                              if (e.target.checked === false) {
                                item.subBtn?.forEach((submenu) => {
                                  submenu.check = false;
                                  submenu.subMenu?.forEach((submenu) => {
                                    submenu.check = false;
                                  });
                                });
                              }
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                    {item.open &&
                      item.subBtn?.map((btn, i) => {
                        return (
                          <ul
                            style={{ listStyleType: "none" }}
                            className="col-md-12 m-2 mt-0"
                          >
                            <li style={{ marginLeft: "5px" }}>
                              <div style={rowStyle}>
                                <label htmlFor=""> {btn.name}: </label>
                                <input
                                  className="m-1"
                                  checked={btn.check}
                                  type="checkbox"
                                  style={checkboxStyle}
                                  onChange={(e) => {
                                    btn.check = e.target.checked;
                                    item.check = true;
                                    setisCrm(true);
                                    setrenderComp(!renderComp);
                                  }}
                                />
                              </div>
                            </li>
                          </ul>
                        );
                      })}
                  </div>
                );
              })}
          </div>
          <div className="col-md-2 mt-md-4   p-0">
            <div style={rowStyle}>
              <span>
                {assetOpen ? (
                  <AiOutlineDown
                    title="see details"
                    onClick={() => {
                      setAssetOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <AiOutlineRight
                    title="see details"
                    onClick={() => {
                      setAssetOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              <label className="input_label m-0">
                <h5>Asset:</h5>
              </label>{" "}
              <input
                checked={isAsset}
                type="checkbox"
                style={checkboxStyle}
                className="  ml-md-3"
                id="Asset"
                onChange={(e) => {
                  setisAsset(e.target.checked);
                  setAssetOpen(e.target.checked);
                  if (e.target.checked === false) {
                    AssetDataList?.forEach((item) => {
                      item.check = false;
                      item?.subBtn?.forEach((submenu) => {
                        submenu.check = false;
                      });
                    });
                  }
                }}
              ></input>
            </div>
            {assetOpen &&
              AssetDataList?.map((item, idx) => {
                return (
                  <div className="row">
                    <ul
                      style={{ listStyleType: "none" }}
                      className="col-md-12 m-0"
                    >
                      <li>
                        <div style={rowStyle}>
                          {item.subBtn ? (
                            <span>
                              {item.open ? (
                                <AiOutlineDown
                                  title="see details"
                                  onClick={() => {
                                    item.open = false;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiOutlineRight
                                  title="see details"
                                  onClick={() => {
                                    item.open = true;
                                    setrenderComp(!renderComp);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          <label htmlFor="">
                            <b>{item.name}: </b>
                          </label>
                          <input
                            type="checkbox"
                            style={checkboxStyle}
                            className="m-1"
                            checked={item.check}
                            onChange={(e) => {
                              item.check = e.target.checked;
                              item.open = e.target.checked;

                              setisAsset(true);
                              setrenderComp(!renderComp);
                              if (e.target.checked === false) {
                                item.subBtn?.forEach((submenu) => {
                                  submenu.check = false;
                                  submenu.subMenu?.forEach((submenu) => {
                                    submenu.check = false;
                                  });
                                });
                              }
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                    {item.open &&
                      item.subBtn?.map((btn, i) => {
                        return (
                          <ul
                            style={{ listStyleType: "none" }}
                            className="col-md-12 m-2 mt-0"
                          >
                            <li style={{ marginLeft: "5px" }}>
                              <div style={rowStyle}>
                                <label htmlFor=""> {btn.name}: </label>
                                <input
                                  className="m-1"
                                  checked={btn.check}
                                  type="checkbox"
                                  style={checkboxStyle}
                                  onChange={(e) => {
                                    btn.check = e.target.checked;
                                    item.check = true;
                                    setisAsset(true);
                                    setrenderComp(!renderComp);
                                  }}
                                />
                              </div>
                            </li>
                          </ul>
                        );
                      })}
                  </div>
                );
              })}
          </div>
          <div className="col-md-2 mt-md-4   p-0">
            <div style={rowStyle}>
              <span>
                {otherOpen ? (
                  <AiOutlineDown
                    title="see details"
                    onClick={() => {
                      setOtherOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <AiOutlineRight
                    title="see details"
                    onClick={() => {
                      setOtherOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </span>
              <label className="input_label m-0">
                <h5>Others:</h5>
              </label>{" "}
              <input
                checked={isOther}
                type="checkbox"
                style={checkboxStyle}
                className="  ml-md-3"
                id="Other"
                onChange={(e) => {
                  setisOther(e.target.checked);
                  setOtherOpen(e.target.checked);
                  if (e.target.checked === false) {
                    otherList?.forEach((item) => {
                      item.check = false;
                    });
                  }
                }}
              ></input>
            </div>
            {otherOpen &&
              otherList?.map((item, idx) => {
                return (
                  <div className="row">
                    <ul
                      style={{ listStyleType: "none" }}
                      className="col-md-12 m-0"
                    >
                      <li style={{ marginLeft: "5px" }}>
                        <div style={rowStyle}>
                          <label htmlFor="">
                            <b>{item.name}: </b>
                          </label>
                          <input
                            type="checkbox"
                            style={checkboxStyle}
                            className="m-1"
                            checked={item.check}
                            onChange={(e) => {
                              item.check = e.target.checked;
                              item.open = e.target.checked;

                              setisOther(true);
                              setrenderComp(!renderComp);
                              if (e.target.checked === false) {
                                item.subBtn?.forEach((submenu) => {
                                  submenu.check = false;
                                  submenu.subMenu?.forEach((submenu) => {
                                    submenu.check = false;
                                  });
                                });
                              }
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className="row pr-md-2 pt-md-4">
        <div className=" col-12 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                // location.state = {};
                history.push("/Roles");
              }}
            >
              Cancel
            </button>
          </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="savedata"
              disabled={isLoading}
              onClick={editVal ? updatedata : savedata}
            >
              <BsCloudUpload size="1.3em" />
              {editVal ? "Update" : "Save"}
              {/* {isLoading ? "Loading..." : "Save"} */}
            </button>
            <span>
              {isLoading === true ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : (
                ""
              )}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};
