import {
  Document,
  Font,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
import moment from "moment";
import QuickSand from "./Quicksand-SemiBold.ttf";

Font.register({
  family: "Quicksand sans-serif",
  src: QuickSand,
});
function UnitReceiptPrintNew({ printData, setPrintData }) {
  var converter = require("number-to-words");
  console.log("printData", printData);
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Quicksand sans-serif",
      fontWeight: "light",
      padding: "0px 20px",
      paddingTop: "2.5in",
    },

    flexRow: {
      display: "flex",
      width: "100%",
      gap: 3,
      flexDirection: "row",
      paddingBottom: 1,
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginTop: "10px",
    },

    infoTextFontSize1: {
      fontWeight: "extrabold",
      fontSize: "12px",
    },
    infoTextFontSize2: {
      fontSize: "9px",
      fontWeight: "thin",
    },
    infoTextGap: {
      rowGap: 6,
    },

    signature: {
      alignItems: "center",
      fontSize: "12px",
      fontWeight: "bold",
      marginTop: 10,
    },
  });
  const tableStyles = StyleSheet.create({
    table: {
      // marginTop: "2px",
      display: "table",
      width: "100%",
      // borderStyle: "solid",
      // borderLeftWidth: 1,
      // borderTopWidth: 1,
      // borderRightWidth: 1,
      // borderBottomWidth: 1,
      // borderColor: "#5b5b5b",
    },
    tableRow: {
      // margin: "5px",
      justifyContent: "space-between",

      flexDirection: "row",
      borderLeft: "1px solid grey",
    },
    tableCellHeader: {
      // margin: 5,
      padding: 2,
      fontSize: 11,
      fontWeight: 700,
      textAlign: "center",
      borderTop: "1px solid grey",
      borderBottom: "1px solid grey",
      borderRight: "1px solid grey",
      // flex: 1,
      color: "#298b8b",
      // borderColor: "#5b5b5b",
    },
    tableCell: {
      // margin: 5,
      padding: 2,
      fontSize: "8px",
      textAlign: "center",
      borderBottom: "1px solid grey",
      borderRight: "1px solid grey",
    },
  });
  const handleClick = () => {
    // setClose(true);
    setPrintData(null);
  };
  return (
    <>
      <PDFViewer
        width={"100%"}
        height={"800px"}
      >
        {" "}
        <Document>
          <Page size="LEGAL">
            <View style={styles.page}>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                >
                  Unit Receipt
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {localStorage.getItem("ProjectName")}
                </Text>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.infoTextGap}>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Voucher Date: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData !== undefined
                        ? moment(printData?.EntryDate?.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      Name{" "}
                      {printData?.Contract?.ContractJointBuyers.length > 0
                        ? 1
                        : null}
                      :{" "}
                    </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {
                        printData?.Contract?.CustomerCnicNavigation
                          ?.CustomerName
                      }
                    </Text>
                  </Text>{" "}
                  {printData?.Contract?.ContractJointBuyers.length >
                    0 ? null : (
                    <Text>
                      <Text style={styles.infoTextFontSize1}>
                        CNIC{" "}
                        {printData?.Contract?.ContractJointBuyers.length > 0
                          ? 1
                          : null}
                        :{" "}
                      </Text>
                      <Text style={styles.infoTextFontSize2}>
                        {
                          printData?.Contract?.CustomerCnicNavigation
                            ?.CustomerCnic2
                        }
                      </Text>
                    </Text>
                  )}
                  {printData?.Contract?.ContractJointBuyers.length > 0 ? (
                    <Text>
                      <Text style={styles.infoTextFontSize1}>Name 2: </Text>
                      <Text style={styles.infoTextFontSize2}>
                        {
                          printData?.Contract?.ContractJointBuyers[0]
                            ?.CustomerCnicNavigation?.CustomerName
                        }
                      </Text>
                    </Text>
                  ) : null}{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      {printData?.Contract?.UnitCategory
                        ? "Category Name"
                        : "Unit Name"}
                      :{" "}
                    </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Contract?.Unit?.UnitName ||
                        printData?.Contract?.UnitCategory?.CategoryName}
                    </Text>
                  </Text>
                </View>
                <View style={styles.infoTextGap}>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Total Price: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Contract?.TotalPrice?.toLocaleString()}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Received: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {Number(
                        Number(printData?.Contract?.TotalPrice) -
                        Number(printData?.Contract?.CustomerReceivable)
                      ).toLocaleString()}
                    </Text>
                  </Text>{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Receivable: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Contract?.CustomerReceivable?.toLocaleString()}
                    </Text>
                  </Text>{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Payment Type: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.PaymentType}
                    </Text>
                  </Text>{" "}
                </View>
              </View>
              <View style={[tableStyles.tableRow, { marginTop: 10 }]}>
                <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                  Sr
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Date{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 3 }]}>
                  Voucher No.{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Customer{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  {printData?.Contract?.UnitCategory ? "Category" : "Unit"}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 4 }]}>
                  Narration{" "}
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Amount{" "}
                </Text>
              </View>
              <View style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {moment(printData?.EntryDate?.split("T")[0])?.format(
                    "DD-MMM-YYYY"
                  )}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 3 }]}>
                  {printData?.UnitVoucherId}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData?.Contract?.CustomerCnicNavigation?.CustomerName}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData?.Contract?.Unit?.UnitName ||
                    printData?.Contract?.UnitCategory?.CategoryName}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 4 }]}>
                  {printData?.Narration}
                </Text>{" "}
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData?.Amount?.toLocaleString()}
                </Text>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.infoTextGap}>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Total Amount: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {parseFloat(printData?.Amount)?.toLocaleString()}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      Amount in words:{" "}
                    </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Amount
                        ? converter
                          .toWords(parseFloat(printData.Amount))
                          ?.toUpperCase()
                        : ""}
                    </Text>
                  </Text>{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      Received with thanks.
                    </Text>
                    {/* <Text style={styles.infoTextFontSize2}>
                      {
                        printData?.Contract?.CustomerCnicNavigation
                          ?.CustomerName
                      }
                    </Text> */}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <View style={styles.signature}>
                  <Text>_______________________</Text>
                  <Text>Received By</Text>
                </View>
                <View style={styles.signature}>
                  <Text>_______________________</Text>
                  <Text>Authorized By</Text>
                </View>
              </View>
            </View>
            <View style={styles.page}>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                >
                  Unit Receipt
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {localStorage.getItem("ProjectName")}
                </Text>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.infoTextGap}>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Voucher Date: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData !== undefined
                        ? moment(printData?.EntryDate?.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )
                        : null}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      Name{" "}
                      {printData?.Contract?.ContractJointBuyers.length > 0
                        ? 1
                        : null}
                      :{" "}
                    </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {
                        printData?.Contract?.CustomerCnicNavigation
                          ?.CustomerName
                      }
                    </Text>
                  </Text>{" "}
                  {printData?.Contract?.ContractJointBuyers.length >
                    0 ? null : (
                    <Text>
                      <Text style={styles.infoTextFontSize1}>
                        CNIC{" "}
                        {printData?.Contract?.ContractJointBuyers.length > 0
                          ? 1
                          : null}
                        :{" "}
                      </Text>
                      <Text style={styles.infoTextFontSize2}>
                        {
                          printData?.Contract?.CustomerCnicNavigation
                            ?.CustomerCnic2
                        }
                      </Text>
                    </Text>
                  )}
                  {printData?.Contract?.ContractJointBuyers.length > 0 ? (
                    <Text>
                      <Text style={styles.infoTextFontSize1}>Name 2: </Text>
                      <Text style={styles.infoTextFontSize2}>
                        {printData !== undefined
                          ? moment(printData?.EntryDate?.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                          : null}
                      </Text>
                    </Text>
                  ) : null}{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      {printData?.Contract?.UnitCategory
                        ? "Category Name"
                        : "Unit Name"}
                      :{" "}
                    </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Contract?.Unit?.UnitName ||
                        printData?.Contract?.UnitCategory?.CategoryName}
                    </Text>
                  </Text>
                </View>
                <View style={styles.infoTextGap}>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Total Price: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Contract?.TotalPrice?.toLocaleString()}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Received: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {Number(
                        Number(printData?.Contract?.TotalPrice) -
                        Number(printData?.Contract?.CustomerReceivable)
                      ).toLocaleString()}
                    </Text>
                  </Text>{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Receivable: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Contract?.CustomerReceivable?.toLocaleString()}
                    </Text>
                  </Text>{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Payment Type: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.PaymentType}
                    </Text>
                  </Text>{" "}
                </View>
              </View>
              <View style={[tableStyles.tableRow, { marginTop: 10 }]}>
                <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                  Sr
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Date{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 3 }]}>
                  Voucher No.{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Customer{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  {printData?.Contract?.UnitCategory ? "Category" : "Unit"}{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 4 }]}>
                  Narration{" "}
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Amount{" "}
                </Text>
              </View>
              <View style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {moment(printData?.EntryDate?.split("T")[0])?.format(
                    "DD-MMM-YYYY"
                  )}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 3 }]}>
                  {printData?.UnitVoucherId}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData?.Contract?.CustomerCnicNavigation?.CustomerName}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData?.Contract?.Unit?.UnitName ||
                    printData?.Contract?.UnitCategory?.CategoryName}{" "}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 4 }]}>
                  {printData?.Narration}
                </Text>{" "}
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData?.Amount?.toLocaleString()}
                </Text>
              </View>
              <View style={styles.flexRow}>
                <View style={styles.infoTextGap}>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>Total Amount: </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {parseFloat(printData?.Amount)?.toLocaleString()}
                    </Text>
                  </Text>
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      Amount in words:{" "}
                    </Text>
                    <Text style={styles.infoTextFontSize2}>
                      {printData?.Amount
                        ? converter
                          .toWords(parseFloat(printData.Amount))
                          ?.toUpperCase()
                        : ""}
                    </Text>
                  </Text>{" "}
                  <Text>
                    <Text style={styles.infoTextFontSize1}>
                      Received with thanks.
                    </Text>
                    {/* <Text style={styles.infoTextFontSize2}>
                      {
                        printData?.Contract?.CustomerCnicNavigation
                          ?.CustomerName
                      }
                    </Text> */}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <View style={styles.signature}>
                  <Text>_______________________</Text>
                  <Text>Received By</Text>
                </View>
                <View style={styles.signature}>
                  <Text>_______________________</Text>
                  <Text>Authorized By</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      <button
        style={{ color: "black !important", marginBottom: "20px" }}
        className="print_btn float-left"
        onClick={handleClick}
      >
        {"< Go Back"}
      </button>
    </>
  );
}

export default UnitReceiptPrintNew;
