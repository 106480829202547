import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import SaveImage from "../../ModalImages/SaveImage";
import ListImage from "../../ModalImages/ListImage";
import DownIcon from "../../ModalImages/DownIcon";

function RecoveryBookingModal() {
  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Recovery Booking Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <ul className="modal-ul">
                <li>
                  <h5>
                    <SaveImage />
                    Save and submit
                  </h5>
                  <ul>
                    <li>
                      <ListImage />
                      Update contract’s advance received and customer
                      receivable.
                    </li>
                    <li>
                      <DownIcon />
                      Transactions are:
                      <ul>
                        <li>
                          <ListImage />
                          Debit account = Manager accounts debit with NetPaid.
                        </li>
                        <li>
                          <ListImage />
                          Credit account = Customer’s account credit with
                          NetPaid.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecoveryBookingModal;
