import React, { useState, useEffect } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  getallAllowances,
  getallDesignation,
} from "../../actions/HRactions/designationaction";

import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";

import { FiEdit2 } from "react-icons/fi";

import Loader from "react-loader-spinner";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
const MainDesignationCom = ({ type }) => {
  // const showdesignationtable = () => {
  //   stateshowdesignation("Designation");
  // };
  // const canceldesignationtable = () => {
  //   stateshowdesignation("DesignationTable");
  //   setallallowances([]);
  //   setstate({});
  // };

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {type === "DesignationTable" ? (
                <DesignationTable />
              ) : (
                <Designation />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainDesignationCom;
export const Designation = () => {
  const [state, setstate] = useState({
    DesignationId: 0,
    DesignationName: null,
    BasicSalary: null,
    NoOfPaidLeaves: null,
  });
  const [allallowances, setallallowances] = useState([]);
  // const [editstate, setedit] = useState(false);
  const resultsallallowances = useSelector((state) => state.getallAllowances);
  const location = useLocation();
  const history = useHistory();
  const rowData = location.state;
  useEffect(() => {
    if (rowData) {
      setallallowances(rowData?.allallowances);
      // setedit(rowData?.editstate);
      setstate(rowData?.state);
    }
  }, [rowData]);
  let dispatch = useDispatch();

  const [toggle, settoggle] = useState(false);

  function addtoarray() {
    let check = 0;

    allallowances.forEach((x) => {
      if (
        parseInt(x.AllowanceId) ===
        parseInt(
          document.getElementById("Allowance").options[
            document.getElementById("Allowance").selectedIndex
          ].dataset.id
        )
      ) {
        toast.info("Multiple Allowances cannot be added!");
        check = 1;
      } else {
        check = 0;
      }
    });
    if (document.getElementById("Allowance").selectedIndex === 0) {
      toast.info("Select Allowance");
    } else if (document.getElementById("Amount").value === "") {
      toast.info("Enter Amount");
    } else if (check === 1) {
      toast.info("Select Other Allowance");
    } else {
      let listofallallowances = {
        AllowanceId: parseInt(
          document.getElementById("Allowance").options[
            document.getElementById("Allowance").selectedIndex
          ].dataset.id
        ),
        Allowance: {
          AllowanceName:
            document.getElementById("Allowance").options[
              document.getElementById("Allowance").selectedIndex
            ].value,
        },
        DesignationId: 0,
        AllowanceAmount: parseFloat(
          document.getElementById("Amount").value.replace(/,/g, "")
        ),
      };

      setallallowances([...allallowances, listofallallowances]);
      document.getElementById("Allowance").selectedIndex = 0;
      document.getElementById("Amount").value = "";
    }
  }
  const deleteinstallment = (idx) => {
    const rows = [...allallowances];

    rows.splice(idx, 1);

    setallallowances(rows);
  };
  function modalclose() {
    document.getElementById("catModal").style.display = "none";
  }
  function addAllowance() {
    document.getElementById("allowancesave").disabled = true;
    axios({
      url: `${process.env.REACT_APP_API_URL}Allowances/Add`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify({
        AllowanceId: 0,
        AllowanceName: document.getElementById("allowancename").value,
      }),
    }).then(
      (result) => {
        document.getElementById("allowancesave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("allowancename").value = "";
          toast.info(result.data.Message);
          modalclose();

          dispatch(getallAllowances());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("allowancesave").disabled = false;
        toast.info("Something went wrong!");
      }
    );
  }

  const savedata = () => {
    if (document.getElementById("Designation").value === "") {
      toast.info("Designation is Required");
    } else if (document.getElementById("Salary").value === "") {
      toast.info("Enter Salary Amount");
    } else {
      let Designation = state;
      // let DesignationAllowances = props.allallowances;
      let DesignationAllowances = [];
      allallowances.forEach((x, index) => {
        DesignationAllowances.push({
          AllowanceId: x.AllowanceId,

          DesignationId: x.DesignationId,
          AllowanceAmount: parseFloat(
            document
              .getElementById(`allowanceamountfield${index}`)
              .value.replace(/,/g, "")
          ),
        });
      });

      Designation.DesignationId = state.DesignationId;
      Designation.DesignationName =
        document.getElementById("Designation").value;
      Designation.BasicSalary = parseFloat(
        document.getElementById("Salary").value.replace(/,/g, "")
      );
      Designation.NoOfPaidLeaves = document.getElementById("Leaves").value;

      document.getElementById("designationsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Designations/AddDesignation`,
        data: JSON.stringify({
          Designation,
          DesignationAllowances,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("designationsave").disabled = false;
          if (result.data.IsSuccess === true) {
            // document.getElementById("areavalue").value = "";
            // document.getElementById("Landvalue").value = "";
            // document.getElementById("Advance").value = "";
            // document.getElementById("LandName").value = "";

            // document.getElementById("Creditor").selectedIndex = 0;
            // // document.getElementById("Generateallallowances").checked = false;
            // document.getElementById("areasize").selectedIndex = 0;
            // document.getElementById("projectname").selectedIndex = 0;
            // document.getElementById("Generateallallowances").selectedIndex = 0;
            // document.getElementById("DueDate").value = "";
            // document.getElementById("InstallmentAmount").value = "";
            // document.getElementById("perofsold").value = ""
            // setallallowances([]);
            // setchecktotal(0);
            // setstate({
            //     EntryDate: null,
            //     LandCreditorId: 0,
            //     ProjectId: 0,
            //     LandValue: null,
            //     AdvancePaid: 0,
            //     Advance: null,
            //     LandValuePaid: 0,
            //     FullPaid: false,
            //     MeasureUnitId: null,
            //     MeasureValue: null,
            //     LandName: null,
            //     PaymentPlan: null,
            //     SalePaymentPer: null,

            // });

            toast.info(result.data.Message);
            // props.canceldesignationtable();
            history.goBack();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("designationsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallAllowances());
  }, []);

  return (
    <>
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Add Designation</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Designation Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="Designation"
            defaultValue={state !== undefined ? state.DesignationName : null}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Basic Salary</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="Salary"
            defaultValue={
              state !== undefined && state.BasicSalary !== null
                ? state.BasicSalary.toLocaleString()
                : null
            }
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Salary").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">No.of Paid Leaves</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="number"
            className="input_styling  ml-md-3"
            id="Leaves"
            defaultValue={state !== undefined ? state.NoOfPaidLeaves : null}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Allowance Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            className="input_styling  ml-md-3"
            id="Allowance"
            onChange={(e) => {
              e.target.value === "Add New"
                ? (document.getElementById("catModal").style.display = "block")
                : (document.getElementById("catModal").style.display = "none");
            }}
          >
            <option>Select</option>
            <option>Add New</option>

            {resultsallallowances.data.AllowancesList !== undefined
              ? resultsallallowances.data.AllowancesList.map((val, index) => (
                <option key={index} data-id={val.AllowanceId}>
                  {val.AllowanceName}
                </option>
              ))
              : null}
          </select>
        </div>

        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Allowance Amount</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="Amount"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Amount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
        </div>
        <div className="col-md-2 mt-md-4   p-0">
          <button className="save_btn" onClick={addtoarray}>
            Add
          </button>
        </div>
      </div>
      <div className="mt-4">
        <div className="table-responsive mt-4" style={{ maxHeight: "500px" }}>
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th>Allowance Name </th>

                <th>Allowance Advance</th>
                <th>Dell</th>
              </tr>
            </thead>
            <tbody>
              {allallowances.map((val, index) => (
                <tr key={index}>
                  <td>{val.Allowance.AllowanceName}</td>

                  {/* <td>{val.AllowanceAmount}</td> */}
                  <td>
                    <input
                      type="text"
                      key={`allowanceamountfield${index}`}
                      id={`allowanceamountfield${index}`}
                      className="input_styling"
                      defaultValue={val.AllowanceAmount.toLocaleString()}
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          document.getElementById(
                            `allowanceamountfield${index}`
                          ).value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                      }}
                    />
                  </td>

                  <td
                    onClick={() => {
                      deleteinstallment(index);
                      settoggle(!toggle);
                    }}
                  >
                    <MdDeleteForever size="1.6em" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className=" float-right mt-md-4 ">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2  "
            id="designationsave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
      {/* <!-- The Modal for cat --> */}
      <div id="catModal" className="modal">
        <div className="modal-content-cat">
          <div className="modal_heading">
            <span className="head_text pl-3">Add Allowance</span>
            <span className="close pr-3" onClick={modalclose}>
              &times;
            </span>
          </div>
          <div className="row pt-5 pl-3">
            <div className="col-md-4  text-left p-0">
              <label className="input_label m-0 ">Allowance Name:</label>
            </div>

            <div className="col-md-8  p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="allowancename"
              ></input>
            </div>
          </div>

          <div className="row pr-md-2 pt-md-4">
            <div className=" col-11 pr-0 pl-0  text-right ">
              <span>
                <button
                  className="save_btn mr-md-3"
                  id="allowancesave"
                  onClick={addAllowance}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* modal close */}
    </>
  );
};

export const DesignationTable = () => {
  const designationdatastate = useSelector((state) => state.getAllDesignation);

  const [allDesignationData, setallDesignationData] = useState([]);
  const [filerunits, setfilterunits] = useState();
  const history = useHistory();
  const rolesdata = useSelector((state) => state.GetAllRole);

  let dispatch = useDispatch();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(designationdatastate.data.Designations);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter((val) =>
            regex.test(
              val.DesignationName !== null ? val.DesignationName : null
            )
          );
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };

  const openmodalallowancetable = (x) => {
    setallDesignationData(x);

    document.getElementById("modalofleadtable").style.display = "block";
  };

  useEffect(() => {
    dispatch(getallDesignation());
  }, [dispatch]);
  useEffect(() => {
    setfilterunits(designationdatastate.data.Designations);
  }, [designationdatastate]);
  return (
    <>
      <div className="row  ">
        <div className="dash_background col-12 p-0">
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3   pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                placeholder="Designation"
                onChange={onTextChange}
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {designationdatastate.loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>

          <div className="table-responsive vouchers_table_height2 mt-4">
            <table className="table table-borderless  table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>

                  <th>Designation</th>

                  <th>Basic Salary</th>
                  <th>Paid Leaves</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") && (
                      <button
                        className="save_btn"
                        onClick={() => {
                          // props.showdesignationtable();
                          history.push("/hr/employees/designation/add");
                        }}
                      >
                        New+
                      </button>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                    <tr key={index + 1}>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {index + 1}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.DesignationName !== undefined
                          ? val.DesignationName
                          : null}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.BasicSalary !== undefined
                          ? val.BasicSalary.toLocaleString()
                          : null}
                      </td>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.NoOfPaidLeaves !== undefined
                          ? val.NoOfPaidLeaves
                          : null}
                      </td>
                      <td className="text-center">
                        {rolesdata?.data?.Access?.includes("U") && (
                          <FiEdit2
                            title="Edit"
                            size="1.1em"
                            className="cur_sor"
                            onClick={() => {
                              history.push("/hr/employees/designation/add", {
                                state: val,
                                allallowances: val.DesignationAllowances,
                                editstate: true,
                              });
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* model for show information*/}
      <div
        id="modalofleadtable"
        class="modalscreen "
      >
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Allowance Detail</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadtable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            <div className="table-responsive mt-4">
              <table class="table mt-2">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Allowance Name</th>
                    <th scope="col">Allowance Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {allDesignationData !== undefined &&
                    allDesignationData.DesignationAllowances !== undefined
                    ? // allDesignationData.length == 0
                    allDesignationData.DesignationAllowances.map((x, id) => (
                      <tr key={id}>
                        <td>{x.Allowance.AllowanceName}</td>
                        <td>{x.AllowanceAmount.toLocaleString()}</td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </div>

            {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
