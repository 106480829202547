import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { getallSection } from "../../../actions/getsections";
import { getallsellers } from "../../../actions/inventoryactions/addseller";
import { getallnewpurchase } from "../../../actions/inventoryactions/newpurchase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const NewPurchaseForm = (props) => {
  let dispatch = useDispatch();
  const history = useHistory();

  const [statehidden, sethidden] = useState(false);
  const [statehidden2, sethidden2] = useState(true);

  const [state, setstate] = useState({
    UnitFileId: 0,
    UnitName: null,
    SizeNetSqft: null,
    SizeNetMarla: null,
    RatePerMarlaOrSqft: null,
    PurchaseRate: null,
    PurchaseValue: null,
    SaleRate: null,
    SaleValue: null,
    SoldValue: null,
    Sold: null,
    AdvancePayment: null,
    InstallmentsPaid: null,
    ProfitValue: null,
    TransferFee: null,
    RemainingAmount: null,
    NoOfInstallmentsRemaining: null,
    UnitDescription: null,
    EntryDate: null,
  });
  // const [unitpurchasestate, unitsetpurchaseunit] = useState({
  //   EntryDate: null,
  //   SellerId: 0,
  // });
  const savedata = () => {
    if (document.getElementById("unitname").value === "") {
      toast.info("UnitName is required");
    }
    // else if (document.getElementById("netsqft").value === "" || document.getElementById("netsqft").value === "") { toast.info("Netsqft is required ") }
    // else if (document.getElementById("netmarlas").value === "" || document.getElementById("netmarlas").value === "") { toast.info("Netmarlas is required") }
    else if (document.getElementById("totalcost").value === "") {
      toast.info("Total Purchase rate is required");
    } else if (document.getElementById("plotNet").value === "") {
      toast.info("Total sale rate is required");
    } else if (document.getElementById("RemainingAmount").value === "") {
      toast.info(
        " Remaining Amount is required (Purchase Value - Down Payment - Instalments Paid"
      );
    } else {
      let Unit = state;

      Unit.UnitFileId = 0;

      Unit.UnitName = document.getElementById("unitname").value;

      Unit.SizeNetMarla =
        document.getElementById("radiomarla").checked === true
          ? parseFloat(
            document.getElementById("netmarlas").value.replace(/,/g, "")
          )
          : null;
      Unit.SizeNetSqft =
        document.getElementById("radiomarla").checked !== true
          ? parseFloat(
            document.getElementById("netsqft").value.replace(/,/g, "")
          )
          : null;

      Unit.RatePerMarlaOrSqft =
        document.getElementById("radiomarla").checked === true
          ? "Marla"
          : "Sqft";
      Unit.PurchaseRate = parseFloat(
        document.getElementById("Costpermarla").value.replace(/,/g, "")
      );
      Unit.PurchaseValue = parseFloat(
        document.getElementById("totalcost").value.replace(/,/g, "")
      );
      Unit.SaleRate = parseFloat(
        document.getElementById("plotRate").value.replace(/,/g, "")
      );
      Unit.SaleValue = parseFloat(
        document.getElementById("plotNet").value.replace(/,/g, "")
      );
      Unit.SoldValue = null;
      Unit.Sold = false;
      Unit.InstallmentsPaid = parseFloat(
        document.getElementById("InstallPaidAmt").value.replace(/,/g, "")
      );
      Unit.AdvancePayment = parseFloat(
        document.getElementById("DownPayment").value.replace(/,/g, "")
      );
      Unit.ProfitValue = parseFloat(
        document.getElementById("Profitpercent").value.replace(/,/g, "")
      );
      Unit.TransferFee = parseFloat(
        document.getElementById("Transferfee").value.replace(/,/g, "")
      );
      Unit.RemainingAmount = parseFloat(
        document.getElementById("RemainingAmount").value.replace(/,/g, "")
      );

      Unit.NoOfInstallmentsRemaining = parseFloat(
        document.getElementById("noofinstall").value
      );
      Unit.InstallmentPerMonth = parseFloat(
        document.getElementById("Installpermonth").value.replace(/,/g, "")
      );
      Unit.EntryDate = document.getElementById("Date").value;
      Unit.UnitDescription = document.getElementById("Description").value;

      document.getElementById("unitsave").disabled = true;

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}UnitPurchase/AddFile`,
        data: JSON.stringify(Unit),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("unitsave").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);

            dispatch(getallnewpurchase());

            // props.cancelunittable();

            history.goBack();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("unitsave").disabled = false;
        }
      );
    }
  };

  function salerateonchange(e) {
    // let currentval = e.target.value.replace(/,/g, '');
    let currentval = e.target.value.replace(/,/g, "");
    if (currentval.length > 0) {
      document.getElementById("plotRate").value = parseInt(
        e.target.value.replace(/,/g, "")
      ).toLocaleString();
      // document.getElementById("plotRate").value = parseFloat(e.target.value)

      if (document.getElementById("radiomarla").checked === true) {
        // let marla = document.getElementById("netmarlas") !== null ? parseFloat(document.getElementById("netmarlas").value.replace(/,/g, '')) : 0;
        let marla =
          document.getElementById("netmarlas") !== null
            ? parseFloat(document.getElementById("netmarlas").value)
            : 0;

        let total = parseFloat(marla) * parseFloat(currentval);
        // parseFloat(currentval.replace(/,/g, ''));
        document.getElementById("plotNet").value =
          parseFloat(total).toLocaleString();
        document.getElementById("Profitpercent").value = parseFloat(
          total - parseFloat(document.getElementById("totalcost").value)
        ).toLocaleString();
      } else {
        // let netsqft = document.getElementById("netsqft") !== null ? parseFloat(document.getElementById("netsqft").value.replace(/,/g, '')) : null;
        let netsqft =
          document.getElementById("netsqft") !== null
            ? parseFloat(document.getElementById("netsqft").value)
            : null;

        // document.getElementById("plotNet").value = parseFloat(parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ''))).toLocaleString();
        // document.getElementById("Profitpercent").value = parseInt(parseFloat(parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ''))) - parseInt(document.getElementById("totalcost").value.replace(/,/g, ''))).toLocaleString();
        document.getElementById("plotNet").value = parseFloat(
          parseFloat(netsqft) * parseFloat(currentval)
        );
        document.getElementById("Profitpercent").value = parseInt(
          parseFloat(parseFloat(netsqft) * parseFloat(currentval)) -
          parseInt(document.getElementById("totalcost").value)
        );
      }
    } else {
      document.getElementById("plotNet").value = "";
      document.getElementById("Profitpercent").value = "";
    }
  }
  function radioonchange(e) {
    // let currentval = document.getElementById("plotRate").value;
    if (e.target.checked === true) {
      sethidden(false);
      sethidden2(true);
      document.getElementById("plotRate").value = "";
      document.getElementById("plotNet").value = "";
      document.getElementById("totalcost").value = "";
      document.getElementById("Costpermarla").value = "";
      document.getElementById("netmarlas").value = "";
      document.getElementById("netsqft").value = "";

      // let marla = document.getElementById("marlas") !== null ? document.getElementById("marlas").value : 0;
      // let sarsai = document.getElementById("sarsai") !== null ? document.getElementById("sarsai").value : 0;
      // let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      //

      // let total =
      //     parseFloat(marlasarsai) *
      //     parseFloat(currentval);
      // document.getElementById("plotNet").value = Math.ceil(total);
    }
    //  else {
    //     let netsqft = document.getElementById("netsqft").value;
    //     document.getElementById("plotNet").value = parseFloat(netsqft) * parseFloat(currentval);
    // }
  }
  function SQradioonchange(e) {
    // let currentval = document.getElementById("plotRate").value;
    if (e.target.checked === true) {
      sethidden(true);
      sethidden2(false);
      // let netsqft = document.getElementById("netsqft").value;
      // document.getElementById("plotNet").value = parseFloat(netsqft) * parseFloat(currentval);
      document.getElementById("plotRate").value = "";
      document.getElementById("plotNet").value = "";
      document.getElementById("totalcost").value = "";
      document.getElementById("Costpermarla").value = "";
      document.getElementById("netmarlas").value = "";
      document.getElementById("netsqft").value = "";
    }
    // else {

    //     let marla = document.getElementById("marlas") !== null ? document.getElementById("marlas").value : 0;
    //     let sarsai = document.getElementById("sarsai") !== null ? document.getElementById("sarsai").value : 0;
    //     let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
    //

    //     let total =
    //         parseFloat(marlasarsai) *
    //         parseFloat(currentval);
    //     document.getElementById("plotNet").value = Math.ceil(total);
    // }
  }
  function costrateonchange(e) {
    let currentval = e.target.value.replace(/,/g, "");
    if (currentval.length > 0) {
      document.getElementById("Costpermarla").value = parseInt(
        e.target.value.replace(/,/g, "")
      ).toLocaleString();
      // document.getElementById("Costpermarla").value = parseFloat(e.target.value)

      if (document.getElementById("radiomarla").checked === true) {
        // let marla = document.getElementById("netmarlas") !== null ? parseFloat(document.getElementById("netmarlas").value.replace(/,/g, '')) : 0;
        let marla =
          document.getElementById("netmarlas") !== null
            ? parseFloat(document.getElementById("netmarlas").value)
            : 0;

        let total = parseFloat(marla) * parseFloat(currentval);
        // parseFloat(currentval.replace(/,/g, ''));
        document.getElementById("totalcost").value =
          parseFloat(total).toLocaleString();
      } else {
        // let netsqft = document.getElementById("netsqft") !== null ? parseFloat(document.getElementById("netsqft").value.replace(/,/g, '')) : null;
        let netsqft =
          document.getElementById("netsqft") !== null
            ? parseFloat(document.getElementById("netsqft").value)
            : null;
        // document.getElementById("totalcost").value = parseFloat(parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ''))).toLocaleString();
        document.getElementById("totalcost").value = parseFloat(
          parseFloat(netsqft) * parseFloat(currentval)
        );
      }
    } else {
      document.getElementById("totalcost").value = "";
    }
  }

  useEffect(() => {
    dispatch(getallProject());

    dispatch(getallSection());
    dispatch(getallnewpurchase());

    dispatch(getallsellers());
  }, [dispatch]);

  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Purchase Unit</h4>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Unit Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input type="text" className="input_styling ml-md-3" id="unitname" />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Date</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="Date"
            defaultValue={moment().format("DD-MMM-YYYY")}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Rate Per:</label>
        </div>
        <div className="col-md-1 mt-4 text-center  p-0">
          <span>Marla</span>
          <input
            type="radio"
            name="radiomarla"
            className="ml-2"
            id="radiomarla"
            onChange={(e) => {
              radioonchange(e);
            }}
          />
        </div>
        <div className="col-md-1 text-center  mt-4  p-0">
          <span>Sq.Ft</span>
          <input
            type="radio"
            name="radiomarla"
            className="ml-2"
            id="radiosqft"
            onChange={(e) => {
              SQradioonchange(e);
            }}
          />
        </div>
        <div className="col-md-3 mt-4  text-left p-0"></div>
        <div hidden={statehidden} className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Net Marla:</label>
        </div>
        <div hidden={statehidden} className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3"
            id="netmarlas"
          // onChange={(e) => { if (e.target.value.length > 0) document.getElementById("netmarlas").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
          />
          <span className="estaric">*</span>
        </div>
        <div hidden={statehidden2} className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0 ">Net Sq/ft:</label>
        </div>
        <div hidden={statehidden2} className="col-md-3 mt-4  p-0">
          <input
            type="number"
            className="input_styling ml-md-3"
            id="netsqft"
          // onChange={(e) => { if (e.target.value.length > 0) document.getElementById("netsqft").value = parseInt(e.target.value.replace(/,/g, '')).toLocaleString() }}
          />
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Purchase Rate:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Costpermarla"
            onChange={(e) => {
              costrateonchange(e);
            }}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Total Purchase Amt:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="totalcost"
            disabled
          />
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Sale Rate:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="plotRate"
            onChange={(e) => {
              salerateonchange(e);
            }}
          />
          <span className="estaric">*</span>
        </div>

        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Total Sale Amt:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="plotNet"
            disabled
          />
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Down Payment:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="DownPayment"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("DownPayment").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Install. Paid Amt:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="InstallPaidAmt"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("RemainingAmount").value = parseFloat(
                  parseFloat(
                    document
                      .getElementById("totalcost")
                      .value.replace(/,/g, "") -
                    parseFloat(
                      document
                        .getElementById("DownPayment")
                        .value.replace(/,/g, "")
                    ) -
                    parseFloat(e.target.value.replace(/,/g, ""))
                  )
                ).toLocaleString();
                document.getElementById("InstallPaidAmt").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              } else {
                document.getElementById("RemainingAmount").value = "";
              }
              // if (e.target.value.length > 0) {
              //     document.getElementById("RemainingAmount").value = parseFloat(parseFloat((document.getElementById("totalcost").value) - parseFloat(document.getElementById("DownPayment").value) - parseFloat(e.target.value)))
              //     document.getElementById("InstallPaidAmt").value = parseFloat(e.target.value)
              // } else {
              //     document.getElementById("RemainingAmount").value = "";
              // }
            }}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Profit Amount :</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Profitpercent"
            disabled
          />
        </div>
        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Transfer fee:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Transferfee"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Transferfee").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Remaining Amount :</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="text"
            disabled
            className="input_styling ml-md-3 "
            id="RemainingAmount"
            placeholder="Pur. Val - Down Pay. - Instal. Paid"
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>

        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Install. per month:</label>
        </div>
        <div className="col-md-2 mt-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Installpermonth"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("noofinstall").value = Math.ceil(
                  parseFloat(
                    document
                      .getElementById("RemainingAmount")
                      .value.replace(/,/g, "")
                  ) / parseFloat(e.target.value.replace(/,/g, ""))
                );
                document.getElementById("Installpermonth").value = parseFloat(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              } else {
                document.getElementById("noofinstall").value = "";
              }
              // if (e.target.value.length > 0) {
              //     document.getElementById("noofinstall").value = Math.ceil(parseFloat(document.getElementById("RemainingAmount").value) / parseFloat(e.target.value));
              //     document.getElementById("Installpermonth").value = parseFloat(e.target.value)

              // } else {
              //     document.getElementById("noofinstall").value = ""
              // }
            }}
          />
        </div>
        <div className="col-md-1 mt-4 p-0">
          <input type="text" className="input_styling  " id="noofinstall" />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Description :</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Description"
          ></textarea>
        </div>
        <div className="col-md-2 mt-4 p-0"></div>
      </div>
      <div className="row pr-md-2 pt-md-4">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div className=" col-6 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </span>
          <span>
            <button className="save_btn  ml-2" id="unitsave" onClick={savedata}>
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
