import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// import { useAuth } from "../../context/useAuth";
import { useHistory } from "react-router-dom";

function RequireAuth({ children }) {
  // const { user } = useAuth();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(localStorage.getItem("LoginId"));

  let history = useHistory();
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setUser(localStorage.getItem("LoginId"));
  }, [user]);

  useEffect(() => {
    if (!user || !token) {
      history.replace("/");
    }
  }, [user, token, history]);

  if (!user || !token) {
    return null;
  }

  return children;
}

export default RequireAuth;

RequireAuth.propTypes = {
  children: PropTypes.any.isRequired,
};
