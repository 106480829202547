import dashboardPic from "../assets/dashboard.svg";
import payablePic from "../assets/payable.svg";
import ledgerPic from "../assets/ledger.svg";
import chartPic from "../assets/chart.svg";
import taxPic from "../assets/tax.svg";
import loanPic from "../assets/loan.svg";
import reportPic from "../assets/report.svg";
import projectPic from "../assets/project.svg";
import homePic from "../assets/home.svg";
import availablePic from "../assets/available.svg";
import employeePic from "../assets/employee.svg";
import payrollPic from "../assets/payroll.svg";
import brokerPic from "../assets/broker.svg";
import leadPic from "../assets/lead.svg";
import taskPic from "../assets/tasks.svg";
import personPic from "../assets/person.svg";
import depriciationPic from "../assets/depriciation.svg";
import maintainPic from "../assets/maintenence.svg";
import assestsPic from "../assets/assets.svg";

export const accountsPathsArr = [
  {
    text: "Dashboard",
    icon: (
      <img
        src={dashboardPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/account/dashboard",
    rolename: "Dashboard",
  },
  {
    text: "Vouchers",
    icon: (
      <img
        src={payablePic}
        alt="Vouchers"
        className="ml-3 Iconpayable"
      />
    ),
    rolename: "Vouchers",
    subbtn: [
      {
        text: "Receipts",
        url: "/account/vouchers/receipt-entry/general-receipt",
        rolename: "Receipt Entry",
        route: "receipt-entry",
        subTab: [
          {
            name: "General Receipt",
            url: "/account/vouchers/receipt-entry/general-receipt",
            info: "General Receipt",
            route: "general-receipt",
          },
          {
            name: "Unit Receipt",
            info: "Unit Receipt",
            url: "/account/vouchers/receipt-entry/unit-receipt",
            route: "unit-receipt",
          },
          {
            name: "Deal Sold Rec",
            info: "Deal Sold Rec",
            url: "/account/vouchers/receipt-entry/deal-sold-rec",
            route: "deal-sold-rec",
          },
          {
            name: "Recovery Receipt",
            info: "Recovery Receipt",
            url: "/account/vouchers/receipt-entry/recovery-receipt",
            route: "recovery-receipt",
          },
          {
            name: "Invest Receipt",
            url: "/account/vouchers/receipt-entry/invest-receipt",
            info: "Invest Receipt",
            route: "invest-receipt",
          },
          {
            name: "Com Receipt",
            url: "/account/vouchers/receipt-entry/com-receipt",
            info: "Com Receipt",
            route: "com-receipt",
          },
        ],
      },
      {
        text: "Payments",
        url: "/account/vouchers/payment-entry/general-payment",
        rolename: "Payment Entry",
        route: "payment-entry",
        subTab: [
          {
            name: "General Payment",
            url: "/account/vouchers/payment-entry/general-payment",
            info: "General Payment",
            route: "general-payment",
          },
          {
            name: "Land Payment",
            info: "Land Payment",
            url: "/account/vouchers/payment-entry/land-payment",
            route: "land-payment",
          },
          {
            name: "Salary Payment",
            info: "Salary Payment",
            url: "/account/vouchers/payment-entry/salary-payment",
            route: "salary-payment",
          },
          {
            name: "Invest Payment",
            info: "Invest Payment",
            url: "/account/vouchers/payment-entry/invest-payment",
            route: "invest-payment",
          },
          {
            name: "Deal Payment",
            url: "/account/vouchers/payment-entry/deal-payment",
            info: "Deal Payment",
            route: "deal-payment",
          },
          {
            name: "Com Payment",
            url: "/account/vouchers/payment-entry/com-payment",
            info: "Com Payment",
            route: "com-payment",
          },
        ],
      },
      {
        text: "Refunds",
        url: "/account/vouchers/refund-voucher",
        rolename: "Recovery Booking",
      },
      // {
      //   text: "Recovery Booking",
      //   url: "/account/vouchers/recovery-booking",
      //   rolename: "Recovery Booking",
      // },

      {
        text: "Journal Entry",
        url: "/account/vouchers/journal-entry",
        rolename: "Journal Entry",
      },
      {
        text: "Adjustments",
        url: "/account/vouchers/general-adjustment",
        rolename: "General Adjustment",
      },

      {
        text: "Installments",
        url: "/account/vouchers/installments/receipt-installments",
        rolename: "Installments",
        route: "installments",
        subTab: [
          {
            name: "Receipt Installments",
            url: "/account/vouchers/installments/receipt-installments",
            info: "Receipt Installments",
            route: "receipt-installments",
          },
          {
            name: "Payment Installments",
            url: "/account/vouchers/installments/payment-installments",
            info: "Payment Installments",
            route: "payment-installments",
          },
        ],
      },
      {
        text: "External Comm",
        url: "/account/vouchers/external-commission/income",
        rolename: "External Commission",
        route: "external-commission",
        subTab: [
          {
            name: "Income",
            url: "/account/vouchers/external-commission/income",
            info: "Income",
            route: "income",
          },
          {
            name: "Expense",
            url: "/account/vouchers/external-commission/expense",
            info: "Expense",
            route: "expense",
          },
        ],
      },
      {
        text: "Draft Vouch",
        url: "/account/vouchers/unsubmitted-vouchers",
        rolename: "Unsubmitted Vouchers",
      },
      {
        text: "Saved Vouch",
        url: "/account/vouchers/submitted-vouchers",
        rolename: "Submitted Vouchers",
      },
      {
        text: "Notifications",
        url: "/account/vouchers/notifications",
        rolename: "Notifications",
      },
    ],
  },

  {
    text: "Contract",
    icon: (
      <img
        src={ledgerPic}
        alt="Ledger"
        className="ml-3 Iconpayable"
      />
    ),
    rolename: "Contract",
    subbtn: [
      {
        text: "Contracts",
        url: "/account/contract/contracts/with-unit",
        rolename: "Contract",
        route: "contracts",
        subTab: [
          {
            name: "Contract",
            url: "/account/contract/contracts/with-unit",
            info: "Contract With Unit",
            route: "with-unit",
          },
          {
            name: "Contract",
            url: "/account/contract/contracts/without-unit",
            info: "Contract Without Unit",
            route: "without-unit",
          },
        ],
      },
      {
        text: "Install Resch",
        url: "/account/contract/install-reschedule",
        rolename: "Install Reschedule",
      },
      {
        text: "Customers",
        url: "/account/contract/customers",
        rolename: "Customer",
      },
      {
        text: "Trans Contract",
        url: "/account/contract/transfer-contract",
        rolename: "Transfer Contract",
      },
      {
        text: "Transfer Unit",
        url: "/account/contract/transfer-unit",
        rolename: "Transfer Unit",
      },
    ],
  },
  {
    text: "Chart of Acc.",
    icon: (
      <img
        src={chartPic}
        alt="Chart"
        className="ml-3 Iconpayable"
      />
    ),
    rolename: "Chart of Account",
    subbtn: [
      {
        text: "Chart of Acc",
        url: "/accounts/chart-of-acc/Chart",
        rolename: "Chart of Account",
      },
      {
        text: "Account",
        url: "/accounts/chart-of-acc/account",
        rolename: "Accounts",
      },
    ],
  },
  {
    text: "Tax",
    icon: (
      <img
        src={taxPic}
        alt="Tax"
        className="ml-3 Icontax"
      />
    ),
    rolename: "Tax",
    subbtn: [
      {
        text: "Tax",
        url: "/account/tax/Tax",
        rolename: "Tax",
      },
      {
        text: "Tax Payable",
        url: "/account/tax/tax-payable",
        rolename: "Tax Payable",
      },
      {
        text: "Tax Receivable",
        url: "/account/tax/tax-receivable",
        rolename: "Tax Receivable",
      },
    ],
  },
  {
    text: "Investment",
    icon: (
      <img
        src={loanPic}
        alt="Investment"
        className="ml-3 Iconloan"
      />
    ),
    rolename: "Investment",
    subbtn: [
      {
        text: "Investor",
        url: "/account/investment/Investor",
        rolename: "Investor",
      },
      {
        text: "Investment",
        url: "/account/investment/Investment",
        rolename: "Investment",
      },
    ],
  },
  {
    text: "Reports",
    icon: (
      <img
        src={reportPic}
        alt="Reports"
        className="ml-3 Iconpayable"
      />
    ),
    rolename: "Reports",
    subbtn: [
      {
        text: "Trail Blnc",
        url: "/account/reports/trail-balance",
        rolename: "Trail Balance",
      },
      {
        text: "Journal",
        url: "/account/reports/general-journal",
        rolename: "General Journal",
      },
      {
        text: "Ledger",
        url: "/account/reports/general-ledger",
        rolename: "General Ledger",
      },
      {
        text: "Land Inst. Trac",
        url: "/account/reports/land-installment-tracker",
        rolename: "Land Installment Tracker",
      },
      {
        text: "Balance Sheet",
        url: "/account/reports/balance-sheet",
        rolename: "Balance Sheet",
      },
      {
        text: "Income State",
        url: "/account/reports/income-statement",
        rolename: "Income Statement",
      },
      {
        text: "Cash Book",
        url: "/account/reports/cash-book",
        rolename: "Cash Book",
      },
      {
        text: "Manager Recov",
        url: "/account/reports/manager-recovery",
        rolename: "Manager Recovery",
      },
      {
        text: "Recov Receipt",
        url: "/account/reports/recovery-receipt",
        rolename: "Recovery Receipt",
      },
      {
        text: "Acc Payable",
        url: "/account/reports/account-payable",
        rolename: "Account Payable",
      },
      {
        text: "Acc Receivable",
        url: "/account/reports/account-receivable",
        rolename: "Account Receivable",
      },
      {
        text: "Install Receipt",
        url: "/account/reports/install-receipt",
        rolename: "Install Receipt",
      },
      {
        text: "Install Payment",
        url: "/account/reports/install-payment",
        rolename: "Install Payment",
      },
      {
        text: "Comm Pay",
        url: "/account/reports/commission-payment",
        rolename: "Commission Payment",
      },
      {
        text: "Comm Receipt",
        url: "/account/reports/commission-receipt",
        rolename: "Commission Receipt",
      },
      {
        text: "Admin Pay",
        url: "/account/reports/admin-share-payable",
        rolename: "Admin Share Payable",
      },
      {
        text: `Res Comm Pay`,
        url: "/account/reports/reserved-commission-payable",
        rolename: "Reserved Commission Payable",
      },
      {
        text: "Project Report",
        url: "/account/reports/project-report",
        rolename: "Project Report",
      },
    ],
  },
];
export const inventoryArray = [
  {
    text: "Dashboard",
    icon: (
      <img
        src={dashboardPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/inventory/dashboard",
    rolename: "Inventory Dashboard",
  },
  {
    text: "Projects",
    icon: (
      <img
        src={projectPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    // url: "/inventory/projects",
    rolename: "Project",
    subbtn: [
      {
        text: "Project",
        url: "/inventory/projects/Projects",
        rolename: "Add Project",
      },
      {
        text: "Section",
        url: "/inventory/projects/section",
        rolename: "Section",
      },
      {
        text: "Units",
        url: "/inventory/projects/units",
        rolename: "Units",
      },
      {
        text: "New Purchase",
        url: "/inventory/projects/newpurchase",
        rolename: "New Purchase",
      },
      {
        text: "Re Purchase",
        url: "/inventory/projects/repurchase",
        rolename: "RePuchase",
      },
    ],
  },
  {
    text: "Deals",
    icon: (
      <img
        src={homePic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Deals",
    subbtn: [
      {
        text: "Deal Bought",
        url: "/inventory/deals/dealbought",
        rolename: "Deal Bought",
      },
      {
        text: "Deal Sold",
        url: "/inventory/deals/dealsold",
        rolename: "Deal Sold",
      },
      {
        text: "Affiliates",
        url: "/inventory/deals/affliates",
        rolename: "Affiliates",
      },
    ],
  },
  {
    text: "Availability",
    icon: (
      <img
        src={availablePic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Crm Availability",
    subbtn: [
      {
        text: "Availability",
        url: "/inventory/availability/availability",
        rolename: "Inventory Availability",
      },
      {
        text: "Booked Units",
        url: "/inventory/availability/units",
        rolename: "Booked Units",
      },
    ],
  },
  // {
  //   text: "Availability",
  //   icon: (
  //     <img src={availablePic} alt="Dashboard" className="ml-3 Icondashboard" />
  //   ),
  //   url: "/inventory/availability",
  //   rolename: "Inventory Availability",
  // },
  {
    text: "Land & Seller",
    icon: (
      <img
        src={homePic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "LandandSeller",
    subbtn: [
      {
        text: "Land",
        url: "/inventory/land-and-seller/land",
        rolename: "Land",
      },
      {
        text: "Land Seller",
        url: "/inventory/land-and-seller/sellerland",
        rolename: "LandSeller",
      },
      {
        text: "Seller",
        url: "/inventory/land-and-seller/sellers",
        rolename: "Seller",
      },
    ],
  },
];
export const hrArray = [
  {
    text: "Dashboard",
    icon: (
      <img
        src={dashboardPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/hr/dashboard",
    rolename: "Hr Dashboard",
  },
  {
    text: "Attendence",
    icon: (
      <img
        src={ledgerPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Attendence",
    subbtn: [
      {
        text: "Attendence",
        url: "/hr/attendence",
        rolename: "Attendence",
      },
    ],
  },
  {
    text: "Payroll",
    icon: (
      <img
        src={payrollPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/hr/payroll",
    rolename: "Pay Roll",
    subbtn: [
      {
        text: "Assign Salary",
        url: "/hr/payroll/assignsalary",
        rolename: "Assign Salary",
      },
      {
        text: "Advance/Loan",
        url: "/hr/payroll/advanceloan/advance-table",
        rolename: "AdvanceLoan",
        route: "advanceloan",
        subTab: [
          {
            name: "Advance",
            url: "/hr/payroll/advanceloan/advance-table",
            info: "Advance",
            route: "advance-table",
          },
          {
            name: "Loan",
            info: "Loan",
            url: "/hr/payroll/advanceloan/loan-table",
            route: "loan-table",
          },
        ],
      },
      {
        text: "Pay Slip",
        url: "/hr/payroll/payslip",
        rolename: "Pay Slip",
      },
    ],
  },
  {
    text: "Employees",
    icon: (
      <img
        src={employeePic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Employees",
    subbtn: [
      {
        text: "Employee",
        url: "/hr/employees/employee",
        rolename: "Add Employee",
      },
      {
        text: "Designation",
        url: "/hr/employees/designation",
        rolename: "Designation",
      },
    ],
  },
];
export const crmArray = [
  {
    text: "Dashboard",
    icon: (
      <img
        src={dashboardPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/crm/dashboard",
    rolename: "CRM Dashboard",
  },
  {
    text: "Leads",
    icon: (
      <img
        src={leadPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Leads",
    subbtn: [
      {
        text: "Leads",
        url: "/crm/leads",
        rolename: "Leads",
      },
    ],
  },
  {
    text: "Clients",
    icon: (
      <img
        src={personPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Clients",
    subbtn: [
      {
        text: "Clients",
        url: "/crm/client/clients",
        rolename: "Clients",
      },
    ],
  },
  {
    text: "Tasks",
    icon: (
      <img
        src={taskPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Tasks",
    subbtn: [
      {
        text: "Meeting",
        url: "/crm/tasks/meeting",
        rolename: "Meeting",
      },
      {
        text: "Call",
        url: "/crm/tasks/call",
        rolename: "Call",
      },
      {
        text: "Other Tasks",
        url: "/crm/tasks/othertask",
        rolename: "Other Task",
      },
    ],
  },
  {
    text: "Teams",
    icon: (
      <img
        src={brokerPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Teams",
    subbtn: [
      {
        text: "Teams",
        url: "/crm/teams/teams",
        rolename: "Add Team",
      },
      {
        text: "Member",
        url: "/crm/teams/member",
        rolename: "Member",
      },
      {
        text: "Hierarchy",
        url: "/crm/teams/hierarchy",
        rolename: "Hierarchy",
      },
    ],
  },
  {
    text: "Availability",
    icon: (
      <img
        src={availablePic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    // url: "/crm/availability",
    rolename: "Crm Availability",
    subbtn: [
      {
        text: "Availability",
        url: "/crm/availability/availability",
        rolename: "Crm Availability",
      },
      {
        text: "Booked Units",
        url: "/crm/availability/units",
        rolename: "Crm Units",
      },
    ],
  },
];
export const assetsArray = [
  {
    text: "Dashboard",
    icon: (
      <img
        src={dashboardPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/asset/dashboard",
    rolename: "Asset Dashboard",
  },
  {
    text: "Assets",
    icon: (
      <img
        src={assestsPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    rolename: "Assets",
    subbtn: [
      {
        text: "Asset",
        url: "/assets/asset",
        rolename: "Add Asset",
      },
      {
        text: "Sell",
        url: "/assets/sale",
        rolename: "Sell",
      },
      {
        text: "Buyer",
        url: "/assets/buyer",
        rolename: "Buyer",
      },
      {
        text: "Seller",
        url: "/assets/seller",
        rolename: "Seller",
      },
    ],
  },
  {
    text: "Maintainance",
    icon: (
      <img
        src={maintainPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/asset/maintainance",
    rolename: "Maintainance",
  },
  {
    text: "Depriciation",
    icon: (
      <img
        src={depriciationPic}
        alt="Dashboard"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/asset/depriciation",
    rolename: "Depriciation",
  },
];
export const revertVouchers = [
  {
    text: "Revert Vouchers",
    icon: (
      <img
        src={dashboardPic}
        alt="revert-back-vouchers"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/revert-back-vouchers",
    rolename: "Revert Back Vouchers",
  },
];
export const Roles = [
  {
    text: "Roles",
    icon: (
      <img
        src={dashboardPic}
        alt="Roles"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/Roles",
    rolename: "Roles",
  },
];
export const Users = [
  {
    text: "Users",
    icon: (
      <img
        src={dashboardPic}
        alt="Users"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/Users",
    rolename: "Users",
  },
];
export const ChangeRequest = [
  {
    text: "Change Request",
    icon: (
      <img
        src={dashboardPic}
        alt="ChangeRequest"
        className="ml-3 Icondashboard"
      />
    ),
    url: "/change-request",
    rolename: "Change Request",
  },
];
