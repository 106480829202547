import React, { Component } from "react";
// import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
export class AllSectionPrint extends Component {
  render() {

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div className="row m-3 p-0">
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Sections </u>
            </h4>

            <div className="mt-3">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Section Name</th>
                      <th>Section Type</th>
                      <th>Project Name</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th>Adv%</th>
                      <th>Pay Time</th>
                      <th>Marketing Exp%</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData.data !== undefined
                      ? this.props.printData.data.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.SectionName}</td>
                          <td>{val.SectionType.Type}</td>
                          {val.Project !== null &&
                            val.Project !== undefined ? (
                            <td>{val.Project.ProjectName}</td>
                          ) : (
                            <td></td>
                          )}
                          {val.Category !== null &&
                            val.Category !== undefined ? (
                            <td>{val.Category.CategoryName}</td>
                          ) : (
                            <td></td>
                          )}
                          {val.SubCategory !== null &&
                            val.SubCategory !== undefined ? (
                            <td>{val.SubCategory.SubcategoryName}</td>
                          ) : (
                            <td></td>
                          )}

                          {val.AdvanceAmountPer !== null &&
                            val.AdvanceAmountPer !== undefined ? (
                            <td>{val.AdvanceAmountPer}</td>
                          ) : (
                            <td></td>
                          )}
                          <td>{val.Installments + " Months"}</td>

                          <td>{val.MarketingExpensePercentage}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}