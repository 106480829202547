import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import { TiPrinter } from 'react-icons/ti';
// import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRevertVouchers } from '../../../actions/vouchersactions/GetRevertBackVouchers';

function RevertBackVouchers() {
    // const [apiLoading, setapiloading] = useState(false);
    const dispatch = useDispatch();
    const [allData, setallData] = useState([]);
    const VouchersData = useSelector((state) => state.getAllRevertBackVouchers);

    useEffect(() => {
        dispatch(getAllRevertVouchers());
    }, [dispatch]);
    useEffect(() => {
        if (VouchersData?.data) {
            setallData(VouchersData?.data);
        }

    }, [VouchersData]);
    const onTextChange = (e) => {

        if (
            // document.getElementById("accountfilter").value === "" &&
            document.getElementById("datefilter").value === "" &&
            document.getElementById("voucherfilter").value === ""
        ) {
            setallData(VouchersData?.data?.Vouchers);

        } else {
            let suggestions = [];
            {
                // const regex = new RegExp(document.getElementById("accountfilter").value, "i");
                const regex1 = new RegExp(document.getElementById("voucherfilter").value, "i");
                const regex2 = new RegExp(document.getElementById("datefilter").value, "i");





                suggestions = VouchersData?.data?.Vouchers
                    .sort()
                    .filter((val) =>
                        // (document.getElementById("accountfilter").value === "" || regex.test(val.VoucherName)) &&
                        (document.getElementById("voucherfilter").value === "" || regex1.test(val.VoucherId)) &&
                        (document.getElementById("datefilter").value === "" || regex2.test(val.AddedBy))




                    );

            }


            setallData(suggestions);
        }
    };
    return (
        <div className="row">
            <div className="dash_background col-12 p-0">
                <div className="row ">
                    <div className="col-md-12">
                        <>

                            <div className="row pt-3">
                                <div className="col-md-1 pr-0 pl-0">


                                    <label className="input_label m-0">Search:</label>
                                </div>
                                {/* <div className="col-md-3 pr-0 pl-0">

                                    <input type="text " className="input_styling " id="datefilter" onChange={onTextChange} placeholder="Added By"></input>

                                </div> */}
                                <div className="col-md-3 pr-0 pl-0">


                                    <input type="text " className="input_styling " id="voucherfilter" onChange={onTextChange} placeholder="Voucher"></input>
                                </div>
                                <div className="col-md-3 pr-0 pl-0">

                                    <input type="text " className="input_styling " id="accountfilter" onChange={onTextChange} placeholder="Name"></input>

                                </div>

                                <div className="col-md-1 pr-0 pl-0">

                                    {/* 
{resultsgetgeneralrec?.loading ? <Loader type="ThreeDots"
  color="green"
  height={40}
  width={40} /> : null} */}
                                </div>

                            </div>
                            {/* <div className="row pt-3 ">
                                <div className="col-md-1 pr-0 pl-0">



                                </div>
 
                                <div className="col-md-3   pr-0 pl-0">
                                    <label className="input_label m-0">From:</label>
                                    <input type="date" className="input_date ml-3" id="fromdate"></input>
                                </div>
                                <div className="col-md-3  pr-0 pl-0">
                                    <label className="input_label m-0">To:</label>
                                    <input type="date" className="input_date ml-3" id="todate"></input>
                                </div>

                                <div className="col-md-1 pr-0 pl-0">
                                    <button className="btn_Go"
                                    //  onClick={ongo}
                                    >Go</button>
                                </div>
                                <div className="col-md-1 pr-0 pl-0">


                                    {apiLoading && <Loader type="ThreeDots"
                                        color="green"
                                        height={40}
                                        width={40} />}
                                </div>
                            </div> */}


                            <div className="table-responsive mt-4">
                                <table className="table table-borderless m-0">
                                    <thead>
                                        <tr >
                                            <th>Sr</th>
                                            <th>Date</th>
                                            <th>Voucher No.</th>
                                            <th>Voucher Name</th>
                                            <th>Status</th>
                                            <th>Narration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData?.map((itm, idx) => {
                                            return <tr>
                                                <td>{idx + 1}</td>
                                                <td>{moment(itm.AddedOnDate).format("DD-MMM-YYYY")}</td>
                                                <td>{itm.EntryId}</td>
                                                <td>{itm.EntryType}</td>
                                                <td>{itm.Executed === true ? "Executed" : "Pending"}</td>

                                                <td>{itm.Description}</td>

                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RevertBackVouchers;
