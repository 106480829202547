import React, { useState, useEffect } from "react";

// import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  getallAssetsCat,
  getallAssetsSubCat,
  getallDepricationMethod,
  getallTableAssets,
} from "../../actions/assetsaction/addassets";
import axios from "axios";
import { getallsellers } from "../../actions/inventoryactions/addseller";
import { toast } from "react-toastify";
// import { MdDeleteForever } from "react-icons/md";

// import { FiEdit2 } from "react-icons/fi";

import Loader from "react-loader-spinner";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const MainAssetsCom = () => {
  const showassetstable = () => {
    stateshowassets("AssetsAdd");
  };
  const cancelassetstable = () => {
    stateshowassets("AssetsTable");
  };
  const [showassets, stateshowassets] = useState("AssetsTable");

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showassets === "AssetsTable" ? (
                <AssetsTable
                  showassetstable={showassetstable}
                  cancelassetstable={cancelassetstable}
                />
              ) : (
                <AssestsAdd cancelassetstable={cancelassetstable} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainAssetsCom;
export const AssestsAdd = () => {
  const history = useHistory();

  const resultcat = useSelector((state) => state.GetAssetsCat);
  const resulttype = useSelector((state) => state.GetdepricationMethod);

  const resultsubcat = useSelector((state) => state.GetAssetsSubcat);
  const resultallsellers = useSelector((state) => state.getAllSellers);

  const [stateshowhide, setshowide] = useState(true);
  const [StateSellerId, SetSellerId] = useState(0);

  let dispatch = useDispatch();
  const [subcat, setsubcat] = useState({
    AssetCategoryId: 0,
    AssetSubCategoryName: "",
  });
  function addSubCategory() {
    let subcatdata = subcat;

    subcatdata.AssetCategoryId =
      document.getElementById("catselectmodal").options[
        document.getElementById("catselectmodal").selectedIndex
      ].dataset.id;
    subcatdata.AssetSubCategoryName =
      document.getElementById("subcatnamemodal").value;
    axios({
      url: `${process.env.REACT_APP_API_URL}Assets/Subcategory/Add`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify(subcatdata),
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          document.getElementById("subcatnamemodal").value = "";
          document.getElementById("catselectmodal").selectedIndex = 0;
          toast.info(result.data.Message);
          dispatch(getallAssetsSubCat());
          setsubcat({
            AssetCategoryId: 0,

            AssetSubCategoryName: "",
          });
          modalclosesubcat();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  // function deleteSectionSubCat(id) {

  //     axios({
  //         url: `${process.env.REACT_APP_API_URL}Projects/Subcategory/Del?SubcategoryId=${id}`,
  //         method: "Delete",
  //         headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             "content-type": "application/json",
  //         },
  //     }).then(
  //         (result) => {
  //
  //             if (result.data.IsSuccess === true) {
  //                 dispatch(getallSectionSubCat());

  //                 toast.info(result.data.Message);

  //             }
  //             else {
  //                 toast.info(result.data.Message);
  //             }

  //         },
  //         (error) => {
  //
  //             toast.info("Something went wrong!");

  //         }
  //     );

  // }
  function modalclosesubcat() {
    document.getElementById("subcatModal").style.display = "none";
  }
  function addAssetsCat() {
    document.getElementById("catsave").disabled = true;
    axios({
      url: `${process.env.REACT_APP_API_URL}Assets/Category/Add`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify({
        AssetCategoryName: document.getElementById("catname").value,
        RelevantHead:
          document.getElementById("cataccount").options[
            document.getElementById("cataccount").selectedIndex
          ].value,
      }),
    }).then(
      (result) => {
        document.getElementById("catsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("catname").value = "";
          document.getElementById("cataccount").selectedIndex = 0;

          toast.info(result.data.Message);
          modalclose();

          dispatch(getallAssetsCat());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("catsave").disabled = false;
        toast.info("Something went wrong!");
      }
    );
  }
  // function deleteSectionType(id) {

  //     axios({
  //         url: `${process.env.REACT_APP_API_URL}Projects/SectionType/Del?SectionTypeId=${id}`,
  //         method: "Delete",
  //         headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             "content-type": "application/json",
  //         },
  //     }).then(
  //         (result) => {
  //
  //             if (result.data.IsSuccess === true) {
  //                 dispatch(getallSectionType());

  //                 toast.info(result.data.Message);

  //             }
  //             else {
  //                 toast.info(result.data.Message);
  //             }

  //         },
  //         (error) => {
  //
  //             toast.info("Something went wrong!");

  //         }
  //     );

  // }
  function modalclose() {
    document.getElementById("catModal").style.display = "none";
  }

  // var assetsuggestionlist = [];

  // const SellerSelecttion = (e) => {
  //   const value = e.target.value;
  //   if (value==="") {
  //     ReactDOM.render(null, document.getElementById("teamdemo"));
  //   } else {
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");
  //       suggestions = resultallsellers.data
  //         .sort()
  //         .filter((v) =>
  //           regex.test(v.SellerName !== null ? v.SellerName : null)
  //         );
  //     }
  //     assetsuggestionlist = suggestions;

  //     if (assetsuggestionlist.length === 0) {
  //       ReactDOM.render(null, document.getElementById("teamdemo"));
  //     } else {
  //       ReactDOM.render(
  //         <ul className="suggestbox">
  //           {assetsuggestionlist.map((item) => (
  //             <li
  //               className="listcss"
  //               onClick={() => sellersuggestionselected(item)}
  //             >
  //               {item === undefined ? item : item.SellerName}
  //             </li>
  //           ))}
  //         </ul>,
  //         document.getElementById("teamdemo")
  //       );
  //     }
  //   }
  // };
  const onTextChangeSeller = () => {
    var inputValue = document.getElementById("purchase").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultallsellers.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        SetSellerId(val.SellerId);

        break;
      }
    }
  };
  // const sellersuggestionselected = (val) => {
  //   document.getElementById("purchase").value = val.SellerName;

  //   SetSellerId(val.SellerId);

  //   assetsuggestionlist = [];
  //   ReactDOM.render(null, document.getElementById("teamdemo"));
  // };
  const [state, setstate] = useState({
    AssetId: 0,
    AssetName: null,
    EntryDate: 0,
    PurchaseDate: 0,
    SeriolNo: 0,
    Brand: 0,
    Model: null,
    AssetCategoryId: null,
    AssetSubCategoryId: null,
    SellerId: null,
    Location: null,
    Cost: null,
    DepreciableAsset: null,
  });
  const [state2, setstate2] = useState({
    AssetDepreciationDetailId: 0,
    AssetId: 0,
    DepreciableCost: null,
    AssetLife: null,
    SalvageValue: null,
    DepreciationMethodId: null,
  });
  const savedata = () => {
    if (
      document.getElementById("name").value === "" ||
      document.getElementById("subcat").options[
        document.getElementById("subcat").selectedIndex
      ].value === "Select" ||
      document.getElementById("sectiontype").options[
        document.getElementById("sectiontype").selectedIndex
      ].value === "Select" ||
      document.getElementById("subcat").options[
        document.getElementById("subcat").selectedIndex
      ].value === "Select" ||
      document.getElementById("cost").value === ""
    ) {
      toast.info("All fields are required");
    } else if (
      document.getElementById("radiobutton").checked === true &&
      (document.getElementById("depcost").value === "" ||
        document.getElementById("DeprecatioId").selectedIndex === 0 ||
        document.getElementById("Salvage").value === "")
    ) {
      toast.info("All fields are required ");
    } else {
      let Asset = state;
      Asset.AssetId = 0;
      Asset.AssetName = document.getElementById("name").value;
      Asset.AssetCategoryId =
        document.getElementById("sectiontype").options[
          document.getElementById("sectiontype").selectedIndex
        ].dataset.id;
      Asset.AssetSubCategoryId =
        document.getElementById("subcat").options[
          document.getElementById("subcat").selectedIndex
        ].dataset.id;
      Asset.SeriolNo = document.getElementById("serial").value;
      Asset.EntryDate = document.getElementById("Entry").value;
      Asset.PurchaseDate = document.getElementById("purchasedate").value;
      Asset.Brand = document.getElementById("brand").value;
      Asset.Model = document.getElementById("modal").value;
      Asset.SellerId = parseInt(StateSellerId);
      Asset.Location = document.getElementById("Location").value;
      Asset.Cost = parseInt(
        document.getElementById("cost").value.replace(/,/g, "")
      );
      Asset.DepreciableAsset =
        document.getElementById("radiobutton").checked === true ? true : false;

      let DepreciationDetail = state2;
      DepreciationDetail.AssetId = 0;
      DepreciationDetail.AssetDepreciationDetailId = 0;
      DepreciationDetail.DepreciableCost = parseInt(
        document.getElementById("depcost").value.replace(/,/g, "")
      );
      DepreciationDetail.AssetLife = document.getElementById("life").value;
      DepreciationDetail.SalvageValue = parseInt(
        document.getElementById("Salvage").value.replace(/,/g, "")
      );
      DepreciationDetail.DepreciationMethodId =
        document.getElementById("DeprecatioId").options[
          document.getElementById("DeprecatioId").selectedIndex
        ].dataset.id;

      document.getElementById("assetsave").disabled = true;

      // setloader(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Assets/Add`,
        data: JSON.stringify({ Asset, DepreciationDetail }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            // dispatch(getallSection());

            toast.info(result.data.Message);
            // props.cancelassetstable();
            history.goBack();
            // setloader(false);
          } else {
            toast.info(result.data.Message);
            document.getElementById("assetsave").disabled = false;
            // setloader(false);
          }
        },
        (error) => {
          document.getElementById("assetsave").disabled = false;

          // setloader(false);
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getallAssetsCat());
    dispatch(getallAssetsSubCat());
    dispatch(getallDepricationMethod());
    dispatch(getallsellers());
  }, [dispatch]);
  return (
    <>
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Add Asset</h4>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Entry Date :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="Entry"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Asset Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="name"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Purchase Date :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="purchasedate"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Serial No</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="serial"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Brand :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="brand"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Model</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="modal"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Category :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3 "
            id="sectiontype"
            onChange={(e) => {
              e.target.value === "Add New"
                ? (document.getElementById("catModal").style.display = "block")
                : (document.getElementById("catModal").style.display = "none");
            }}
          >
            <option>Select</option>
            <option>Add New</option>
            {resultcat.data.map((val, index) => (
              <option key={index} data-id={val.AssetCategoryId}>
                {val.AssetCategoryName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Sub Category</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            className="input_styling ml-md-3"
            id="subcat"
            onChange={(e) => {
              e.target.value === "Add New"
                ? (document.getElementById("subcatModal").style.display =
                  "block")
                : (document.getElementById("subcatModal").style.display =
                  "none");
            }}
          >
            <option>Select</option>
            <option>Add New</option>
            {resultsubcat.data.map((val, index) => (
              <option key={index} data-id={val.AssetSubCategoryId}>
                {val.AssetSubCategoryName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Purchase From :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            list="employeenamelist"
            className="input_styling  ml-md-3"
            id="purchase"
            onChange={onTextChangeSeller}
            placeholder="Suggestion List"
          ></input>
          <span className="estaric">*</span>
          <datalist id="employeenamelist">
            {resultallsellers?.data?.map((x, idx) => (
              <option index={idx} value={x.SellerName}>
                {x.SellerId}
              </option>
            ))}
          </datalist>
          <span id="teamdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Location :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="Location"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cost</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="cost"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("cost").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Depreciable Asset :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="checkbox"
            className=" ml-md-3"
            id="radiobutton"
            onChange={() => {
              setshowide(!stateshowhide);
            }}
          ></input>
        </div>
      </div>
      <div className="row " hidden={stateshowhide}>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Depreciable Cost :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="depcost"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("depcost").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Asset Life</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="life"
            placeholder="e.g 1 for one month"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row " hidden={stateshowhide}>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Salvage Value :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="Salvage"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("Salvage").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Deprecation Method</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select className="input_styling ml-md-3" id="DeprecatioId">
            <option>Select</option>
            {resulttype.data.map((val, index) => (
              <option key={index} data-id={val.AssetDepreciationMethodId}>
                {val.DepreciationMethodName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={
              // props.cancelassetstable
              () => history.goBack()
            }
          >
            Cancel
          </button>
        </span>

        <span>
          <button className="save_btn ml-2  " id="assetsave" onClick={savedata}>
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
      <div id="catModal" class="modalscreen ">
        <div className="modal-content-screen1 pt-1">
          <div className=" page_heading">
            <span className="head_text pl-3">Add Type </span>
            <span className="close pr-3" onClick={modalclose}>
              &times;
            </span>
          </div>

          <div className="row pt-5 pl-3">
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">Type Name:</label>
            </div>

            <div className="col-md-3  p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="catname"
              ></input>
              <span className="estaric">*</span>
            </div>
            <div className="col-md-2 mt-md-4   p-0"></div>
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">Account:</label>
            </div>

            <div className="col-md-3  p-0">
              <select className="input_styling ml-md-3" id="cataccount">
                <option>Select</option>
                <option>PROPERTY, PLANT AND EQUIPMENT</option>
                <option>INTANGIBLE ASSETS</option>
              </select>
              <span className="estaric">*</span>
            </div>
          </div>
          <div className="row pt-2 pl-3">
            <div className=" col-12 pr-0 pl-0  text-right ">
              <span>
                <button
                  className="save_btn mr-md-3"
                  id="catsave"
                  onClick={addAssetsCat}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>
          <div className="row  pt-md-4">
            <div className=" col-12 pr-0 pl-0 ">
              <div className="table-responsive mt-4">
                {/* <table className="table table-borderless m-0">
                                    <thead>
                                        <th>
                                            Type Id
                                        </th>
                                        <th>
                                            Type
                                        </th>
                                        <th>Del</th>
                                    </thead>
                                    <tbody>
                                        {resulttype.data.map((val, index) => (
                                            parseInt(projectnameid) === parseInt(val.ProjectId) ?
                                                <tr key={index}>
                                                    <td  >{val.SectionTypeId}</td>

                                                    <td >{val.Type}</td>
                                                    <td style={{ cursor: "pointer" }} onClick={() => deleteSectionType(val.SectionTypeId)}><MdDeleteForever size="1.6em" /></td>

                                                </tr> : null
                                        ))}
                                    </tbody>
                                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="subcatModal" class="modalscreen ">
        <div className="modal-content-screen1 pt-1">
          <div className=" page_heading">
            <span className="head_text pl-3">Add SubCategory</span>
            <span className="close pr-3" onClick={modalclosesubcat}>
              &times;
            </span>
          </div>

          <div className="row pt-5 pl-3">
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">Select Category:</label>
            </div>

            <div className="col-md-4  p-0">
              <select className="input_styling ml-md-3" id="catselectmodal">
                <option>Select</option>
                {resultcat.data.map((val, index) => (
                  <option key={index} data-id={val.AssetCategoryId}>
                    {val.AssetCategoryName}
                  </option>
                ))}
              </select>
              <span className="estaric">*</span>
            </div>
            <div className="col-md-2  text-left p-0">
              <label className="input_label m-0 ">SubCategory Name:</label>
            </div>

            <div className="col-md-4  p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="subcatnamemodal"
              ></input>
              <span className="estaric">*</span>
            </div>
          </div>
          <div className="row">
            <div className="  col-12 pr-3 pl-0 mt-3 text-right ">
              <span>
                <button
                  className="save_btn mr-md-3"
                  id="catsave"
                  onClick={addSubCategory}
                >
                  <BsCloudUpload size="1.3em" />
                  Save
                </button>
              </span>
            </div>
          </div>

          <div className="row  pt-md-4">
            <div className=" col-12 pr-0 pl-0 ">
              <div className="table-responsive mt-4">
                {/* <table className="table table-borderless m-0">
                                    <thead>
                                        <th>
                                            Sub Category
                                        </th>
                                        <th>
                                            Sub Category Name
                                        </th>
                                        <th>Del</th>
                                    </thead>
                                    <tbody>
                                        {resultsubcat.data.map((val, index) => (
                                            <tr key={index}>
                                                <td  >{val.SubcategoryId}</td>

                                                <td >{val.SubcategoryName}</td>
                                                <td style={{ cursor: "pointer" }} onClick={() => deleteSectionSubCat(val.SubcategoryId)}><MdDeleteForever size="1.6em" /></td>

                                            </tr>
                                        ))} 
                                    </tbody>
                                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const AssetsTable = () => {
  const assetsdatastate = useSelector((state) => state.GetTableAssetsReducer);

  const [allAssetsData, setallAssetData] = useState([]);
  const [filerunits, setfilterunits] = useState();
  const history = useHistory();
  const rolesdata = useSelector((state) => state.GetAllRole);

  let dispatch = useDispatch();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(assetsdatastate.data.Assets);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter((val) =>
            regex.test(val.AssetName !== null ? val.AssetName : null)
          );
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };

  const openmodalallowancetable = (x) => {
    setallAssetData(x);

    document.getElementById("modalofleadtable").style.display = "block";
  };

  useEffect(() => {
    dispatch(getallTableAssets());
  }, [dispatch]);
  useEffect(() => {
    setfilterunits(assetsdatastate.data.Assets);
  }, [assetsdatastate]);
  return (
    <>
      <div className="row  ">
        <div className="dash_background col-12 p-0">
          {/* < div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Salaries</h4>
                        </div>
                    </div> */}
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3   pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                placeholder="Asset"
                onChange={onTextChange}
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {assetsdatastate.loading ? (
                <Loader
                  type="ThreeDots"
                  color="green"
                  height={40}
                  width={40}
                />
              ) : null}
            </div>
          </div>

          <div className="table-responsive vouchers_table_height2 mt-4">
            <table className="table table-borderless  table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>

                  <th>AssetName</th>

                  <th> EntryDate</th>
                  <th>PurchaseDate</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Cost</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") && (
                      <button
                        className="save_btn"
                        // onClick={() => {
                        //   props.showassetstable();
                        // }}
                        onClick={() => history.push("/assets/asset/add")}
                      >
                        New+
                      </button>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                    <tr key={index + 1}>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {index + 1}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.AssetName !== undefined && val.AssetName !== null
                          ? val.AssetName
                          : null}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.EntryDate !== null
                          ? moment(val.EntryDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                          : null}
                      </td>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.PurchaseDate !== null
                          ? moment(val.PurchaseDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                          : null}
                      </td>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.Brand !== undefined ? val.Brand : null}
                      </td>
                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.Model !== undefined ? val.Model : null}
                      </td>

                      <td
                        className="cur_sor"
                        onClick={() => {
                          openmodalallowancetable(val);
                        }}
                      >
                        {val.Cost !== undefined ? val.Cost : null}
                      </td>
                      <td></td>
                      {/* <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { props.setallallowances(val.EmployeeSalaryAllowances); props.setstate(val); props.setempid(val.Employee.EmployeeId); props.showdesignationtable(); props.setedit(true); }} /></td> */}
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* model for show information*/}
      <div
        id="modalofleadtable"
        className="modal"
      >
        <div className="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Asset Details</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadtable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Asset Name:</b> {allAssetsData.AssetName}
                </li>
                <li>
                  <b>Entry Date:</b>{" "}
                  {allAssetsData.EntryDate !== undefined
                    ? moment(allAssetsData.EntryDate.split("T")[0]).format(
                      "DD-MMM-YYYY"
                    )
                    : null}
                </li>
                <li>
                  <b>Purchase Date:</b>{" "}
                  {allAssetsData.PurchaseDate !== undefined
                    ? moment(allAssetsData.PurchaseDate.split("T")[0]).format(
                      "DD-MMM-YYYY"
                    )
                    : null}
                </li>
                <li>
                  <b>Brand:</b> {allAssetsData.Brand}
                </li>
                <li>
                  <b>Modal:</b> {allAssetsData.Model}
                </li>
                <li>
                  <b>Cost:</b>{" "}
                  {allAssetsData.Cost !== undefined
                    ? allAssetsData.Cost.toLocaleString()
                    : null}
                </li>
                <li>
                  <b>Depreciable Cost:</b>{" "}
                  {allAssetsData.AssetDepreciationDetail !== undefined &&
                    allAssetsData.AssetDepreciationDetail.length !== 0 &&
                    allAssetsData.AssetDepreciationDetail[0].DepreciableCost !==
                    undefined
                    ? allAssetsData.AssetDepreciationDetail[0].DepreciableCost.toLocaleString()
                    : null}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Serial No:</b> {allAssetsData.SeriolNo}
                </li>
                <li>
                  <b>Seller Name:</b>{" "}
                  {allAssetsData.Seller !== undefined
                    ? allAssetsData.Seller.SellerName
                    : null}
                </li>
                <li>
                  <b>Location:</b> {allAssetsData.Location}
                </li>
                <li>
                  <b>Asset Category:</b>{" "}
                  {allAssetsData.AssetCategory !== undefined
                    ? allAssetsData.AssetCategory.AssetCategoryName
                    : null}
                </li>
                <li>
                  <b>Asset SubCategory:</b>{" "}
                  {allAssetsData.AssetSubCategory !== undefined
                    ? allAssetsData.AssetSubCategory.AssetSubCategoryName
                    : null}
                </li>
                <li>
                  <b>Asset Life:</b>{" "}
                  {allAssetsData.AssetDepreciationDetail !== undefined &&
                    allAssetsData.AssetDepreciationDetail.length !== 0
                    ? allAssetsData.AssetDepreciationDetail[0].AssetLife +
                    " Months"
                    : null}
                </li>
                <li>
                  <b>Salvage Value:</b>{" "}
                  {allAssetsData.AssetDepreciationDetail !== undefined &&
                    allAssetsData.AssetDepreciationDetail.length !== 0
                    ? allAssetsData.AssetDepreciationDetail[0].SalvageValue
                    : null}
                </li>
                <li>
                  <b>Depreciation Method:</b>{" "}
                  {allAssetsData.AssetDepreciationDetail !== undefined &&
                    allAssetsData.AssetDepreciationDetail.length !== 0
                    ? allAssetsData.AssetDepreciationDetail[0]
                      .DepreciationMethod.DepreciationMethodName
                    : null}
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
        </div>
      </div>
    </>
  );
};
