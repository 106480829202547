import axios from "axios";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

export const AddInvestor = (props) => {
  const lenderdatastate = useSelector((state) => state.getLendersdatastate);
  const history = useHistory();
  const location = useLocation();
  let populatedata = location.state || {};

  // if (
  //   lenderdatastate.lenderdata !== undefined ||
  //   lenderdatastate.lenderdata !== null
  // ) {
  //   populatedata = lenderdatastate.lenderdata;
  // } else {
  //   populatedata = lenderdatastate;
  // }

  function emptyfields() {
    document.getElementById("City").selectedIndex = 0;
    document.getElementById("Country").selectedIndex = 0;

    document.getElementById("Phone").value = "";
    document.getElementById("Address").value = "";
    document.getElementById("occupation").value = "";
    document.getElementById("lenderName").value = "";
    document.getElementById("CNIC").value = "";
    document.getElementById("Cell1").value = "";
    document.getElementById("Cell2").value = "";
    document.getElementById("Email").value = "";
  }
  function addlender() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");

    let check = document.getElementById("CNIC").value;
    let checkcell1 = document.getElementById("Cell1").value;
    let checkcell2 = document.getElementById("Cell2").value;
    let checkptcl = document.getElementById("Phone").value;

    if (document.getElementById("lenderName").value === "") {
      toast.info("Name is required");
    } else if (document.getElementById("CNIC").value === "") {
      toast.info("CNIC is required");
    } else if (!regexp.test(check)) {
      toast.info("Enter CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (
      !regexptcl.test(checkptcl) &&
      document.getElementById("Phone").value !== ""
    ) {
      toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (
      !regexphone.test(checkcell2) &&
      document.getElementById("Cell2").value !== ""
    ) {
      toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
    } else if (document.getElementById("Address").value === "") {
      toast.info("Address is required");
    }

    // else if (document.getElementById("occupation").value === "") { toast.info("Occupation  is required") }
    else if (
      document.getElementById("City").options[
        document.getElementById("City").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select any city");
    } else if (
      document.getElementById("Country").options[
        document.getElementById("Country").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select any country");
    } else {
      var LenderDetails = lenderdatastate;

      LenderDetails.LenderId = populatedata?.LenderId;
      // LenderDetails.AccountId = populatedata?.AccountId;
      LenderDetails.LenderName = document.getElementById("lenderName").value;
      LenderDetails.LenderAddress = document.getElementById("Address").value;
      LenderDetails.LenderCnic = document.getElementById("CNIC").value;
      LenderDetails.ContactNumber1 = document.getElementById("Cell1").value;
      LenderDetails.ContactNumber2 = document.getElementById("Cell2").value;
      LenderDetails.PhoneNumber = document.getElementById("Phone").value;
      LenderDetails.Email = document.getElementById("Email").value;
      LenderDetails.Occupation = document.getElementById("occupation").value;
      LenderDetails.City =
        document.getElementById("City").options[
          document.getElementById("City").selectedIndex
        ].value;
      LenderDetails.Country =
        document.getElementById("Country").options[
          document.getElementById("Country").selectedIndex
        ].value;

      document.getElementById("lendersave").disabled = true;
      axios({
        // url: `${process.env.REACT_APP_API_URL}Lenders/AddLender`,
        url: `${process.env.REACT_APP_API_URL}Investors/Add`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(LenderDetails),
      }).then(
        (result) => {
          document.getElementById("lendersave").disabled = false;

          if (result.data.IsSuccess === true) {
            emptyfields();

            toast.info(result.data.Message);
            history.goBack();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Something went wrong!");
          document.getElementById("lendersave").disabled = false;
        }
      );
    }
  }

  return (
    <>
      <div className="row ">
        <div className="col-md-12   text-left page_heading">
          <h4 className="m-0">Add Investor</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="lenderName"
            value={populatedata?.LenderName}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Email</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="email"
            className="input_styling ml-md-3 "
            id="Email"
            value={populatedata?.Email}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Occupation:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="occupation"
            value={populatedata?.Occupation}
          />
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">CNIC</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="CNIC"
            value={populatedata?.LenderCnic}
            placeholder="xxxxx-xxxxxxx-x"
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">City</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select className="input_styling ml-md-3" id="City">
            <option selected>Select</option>
            {populatedata?.City !== null && populatedata?.City !== undefined ? (
              <option selected> {populatedata?.City}</option>
            ) : (
              <option selected>Select</option>
            )}

            <option value="Faisalabad">Faisalabad</option>
            <option value="Ferozewala">Ferozewala</option>
            <option value="Fateh Jhang">Fateh Jang</option>
            <option value="Ghakhar Mandi">Ghakhar Mandi</option>
            <option value="Gojra">Gojra</option>
            <option value="Gujranwala">Gujranwala</option>
            <option value="Gujrat">Gujrat</option>
            <option value="Gujar Khan">Gujar Khan</option>
            <option value="Hafizabad">Hafizabad</option>
            <option value="Haroonabad">Haroonabad</option>
            <option value="Hasilpur">Hasilpur</option>
            <option value="Haveli Lakha">Haveli Lakha</option>
            <option value="Jatoi">Jatoi</option>
            <option value="Jalalpur">Jalalpur</option>
            <option value="Jattan">Jattan</option>
            <option value="Jampur">Jampur</option>
            <option value="Jaranwala">Jaranwala</option>
            <option value="Jhang">Jhang</option>
            <option value="Jhelum">Jhelum</option>
            <option value="Kalabagh">Kalabagh</option>
            <option value="Karor Lal Esan">Karor Lal Esan</option>
            <option value="Kasur">Kasur</option>
            <option value="Kamalia">Kamalia</option>
            <option value="Kamoke">Kamoke</option>
            <option value="Khanewal">Khanewal</option>
            <option value="Khanpur">Khanpur</option>
            <option value="Kharian">Kharian</option>
            <option value="Khushab">Khushab</option>
            <option value="Kot Addu">Kot Addu</option>
            <option value="Jauharabad">Jauharabad</option>
            <option value="Lahore">Lahore</option>
            <option value="Lalamusa">Lalamusa</option>
            <option value="Layyah">Layyah</option>
            <option value="Liaquat Pur">Liaquat Pur</option>
            <option value="Lodhran">Lodhran</option>
            <option value="Malakwal">Malakwal</option>
            <option value="Mamoori">Mamoori</option>
            <option value="Mailsi">Mailsi</option>
            <option value="Mandi Bahauddin">Mandi Bahauddin</option>
            <option value="Mian Channu">Mian Channu</option>
            <option value="Mianwali">Mianwali</option>
            <option value="Multan">Multan</option>
            <option value="Murree">Murree</option>
            <option value="Muridke">Muridke</option>
            <option value="Mianwali Bangla">Mianwali Bangla</option>
            <option value="Muzaffargarh">Muzaffargarh</option>
            <option value="Narowal">Narowal</option>
            <option value="Nankana Sahib">Nankana Sahib</option>
            <option value="Okara">Okara</option>
            <option value="Renala Khurd">Renala Khurd</option>
            <option value="Pakpattan">Pakpattan</option>
            <option value="Pattoki">Pattoki</option>
            <option value="Pir Mahal">Pir Mahal</option>
            <option value="Qaimpur">Qaimpur</option>
            <option value="Qila Didar Singh">Qila Didar Singh</option>
            <option value="Rabwah">Rabwah</option>
            <option value="Raiwind">Raiwind</option>
            <option value="Rajanpur">Rajanpur</option>
            <option value="Rahim Yar Khan">Rahim Yar Khan</option>
            <option value="Rawalpindi">Rawalpindi</option>
            <option value="Sadiqabad">Sadiqabad</option>
            <option value="Safdarabad">Safdarabad</option>
            <option value="Sahiwal">Sahiwal</option>
            <option value="Sangla Hill">Sangla Hill</option>
            <option value="Sarai Alamgir">Sarai Alamgir</option>
            <option value="Sargodha">Sargodha</option>
            <option value="Shakargarh">Shakargarh</option>
            <option value="Sheikhupura">Sheikhupura</option>
            <option value="Sialkot">Sialkot</option>
            <option value="Sohawa">Sohawa</option>
            <option value="Soianwala">Soianwala</option>
            <option value="Siranwali">Siranwali</option>
            <option value="Talagang">Talagang</option>
            <option value="Taxila">Taxila</option>
            <option value="Toba Tek Singh">Toba Tek Singh</option>
            <option value="Vehari">Vehari</option>
            <option value="Wah Cantonment">Wah Cantonment</option>
            <option value="Wazirabad">Wazirabad</option>
            <option value="" disabled>
              Sindh Cities
            </option>
            <option value="Badin">Badin</option>
            <option value="Bhirkan">Bhirkan</option>
            <option value="Rajo Khanani">Rajo Khanani</option>
            <option value="Chak">Chak</option>
            <option value="Dadu">Dadu</option>
            <option value="Digri">Digri</option>
            <option value="Diplo">Diplo</option>
            <option value="Dokri">Dokri</option>
            <option value="Ghotki">Ghotki</option>
            <option value="Haala">Haala</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Islamkot">Islamkot</option>
            <option value="Jacobabad">Jacobabad</option>
            <option value="Jamshoro">Jamshoro</option>
            <option value="Jungshahi">Jungshahi</option>
            <option value="Kandhkot">Kandhkot</option>
            <option value="Kandiaro">Kandiaro</option>
            <option value="Karachi">Karachi</option>
            <option value="Kashmore">Kashmore</option>
            <option value="Keti Bandar">Keti Bandar</option>
            <option value="Khairpur">Khairpur</option>
            <option value="Kotri">Kotri</option>
            <option value="Larkana">Larkana</option>
            <option value="Matiari">Matiari</option>
            <option value="Mehar">Mehar</option>
            <option value="Mirpur Khas">Mirpur Khas</option>
            <option value="Mithani">Mithani</option>
            <option value="Mithi">Mithi</option>
            <option value="Mehrabpur">Mehrabpur</option>
            <option value="Moro">Moro</option>
            <option value="Nagarparkar">Nagarparkar</option>
            <option value="Naudero">Naudero</option>
            <option value="Naushahro Feroze">Naushahro Feroze</option>
            <option value="Naushara">Naushara</option>
            <option value="Nawabshah">Nawabshah</option>
            <option value="Nazimabad">Nazimabad</option>
            <option value="Qambar">Qambar</option>
            <option value="Qasimabad">Qasimabad</option>
            <option value="Ranipur">Ranipur</option>
            <option value="Ratodero">Ratodero</option>
            <option value="Rohri">Rohri</option>
            <option value="Sakrand">Sakrand</option>
            <option value="Sanghar">Sanghar</option>
            <option value="Shahbandar">Shahbandar</option>
            <option value="Shahdadkot">Shahdadkot</option>
            <option value="Shahdadpur">Shahdadpur</option>
            <option value="Shahpur Chakar">Shahpur Chakar</option>
            <option value="Shikarpaur">Shikarpaur</option>
            <option value="Sukkur">Sukkur</option>
            <option value="Tangwani">Tangwani</option>
            <option value="Tando Adam Khan">Tando Adam Khan</option>
            <option value="Tando Allahyar">Tando Allahyar</option>
            <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
            <option value="Thatta">Thatta</option>
            <option value="Umerkot">Umerkot</option>
            <option value="Warah">Warah</option>
            <option value="" disabled>
              Khyber Cities
            </option>
            <option value="Abbottabad">Abbottabad</option>
            <option value="Adezai">Adezai</option>
            <option value="Alpuri">Alpuri</option>
            <option value="Akora Khattak">Akora Khattak</option>
            <option value="Ayubia">Ayubia</option>
            <option value="Banda Daud Shah">Banda Daud Shah</option>
            <option value="Bannu">Bannu</option>
            <option value="Batkhela">Batkhela</option>
            <option value="Battagram">Battagram</option>
            <option value="Birote">Birote</option>
            <option value="Chakdara">Chakdara</option>
            <option value="Charsadda">Charsadda</option>
            <option value="Chitral">Chitral</option>
            <option value="Daggar">Daggar</option>
            <option value="Dargai">Dargai</option>
            <option value="Darya Khan">Darya Khan</option>
            <option value="Dera Ismail Khan">Dera Ismail Khan</option>
            <option value="Doaba">Doaba</option>
            <option value="Dir">Dir</option>
            <option value="Drosh">Drosh</option>
            <option value="Hangu">Hangu</option>
            <option value="Haripur">Haripur</option>
            <option value="Karak">Karak</option>
            <option value="Kohat">Kohat</option>
            <option value="Kulachi">Kulachi</option>
            <option value="Lakki Marwat">Lakki Marwat</option>
            <option value="Latamber">Latamber</option>
            <option value="Madyan">Madyan</option>
            <option value="Mansehra">Mansehra</option>
            <option value="Mardan">Mardan</option>
            <option value="Mastuj">Mastuj</option>
            <option value="Mingora">Mingora</option>
            <option value="Nowshera">Nowshera</option>
            <option value="Paharpur">Paharpur</option>
            <option value="Pabbi">Pabbi</option>
            <option value="Peshawar">Peshawar</option>
            <option value="Saidu Sharif">Saidu Sharif</option>
            <option value="Shorkot">Shorkot</option>
            <option value="Shewa Adda">Shewa Adda</option>
            <option value="Swabi">Swabi</option>
            <option value="Swat">Swat</option>
            <option value="Tangi">Tangi</option>
            <option value="Tank">Tank</option>
            <option value="Thall">Thall</option>
            <option value="Timergara">Timergara</option>
            <option value="Tordher">Tordher</option>
            <option value="" disabled>
              Balochistan Cities
            </option>
            <option value="Awaran">Awaran</option>
            <option value="Barkhan">Barkhan</option>
            <option value="Chagai">Chagai</option>
            <option value="Dera Bugti">Dera Bugti</option>
            <option value="Gwadar">Gwadar</option>
            <option value="Harnai">Harnai</option>
            <option value="Jafarabad">Jafarabad</option>
            <option value="Jhal Magsi">Jhal Magsi</option>
            <option value="Kacchi">Kacchi</option>
            <option value="Kalat">Kalat</option>
            <option value="Kech">Kech</option>
            <option value="Kharan">Kharan</option>
            <option value="Khuzdar">Khuzdar</option>
            <option value="Killa Abdullah">Killa Abdullah</option>
            <option value="Killa Saifullah">Killa Saifullah</option>
            <option value="Kohlu">Kohlu</option>
            <option value="Lasbela">Lasbela</option>
            <option value="Lehri">Lehri</option>
            <option value="Loralai">Loralai</option>
            <option value="Mastung">Mastung</option>
            <option value="Musakhel">Musakhel</option>
            <option value="Nasirabad">Nasirabad</option>
            <option value="Nushki">Nushki</option>
            <option value="Panjgur">Panjgur</option>
            <option value="Pishin Valley">Pishin Valley</option>
            <option value="Quetta">Quetta</option>
            <option value="Sherani">Sherani</option>
            <option value="Sibi">Sibi</option>
            <option value="Sohbatpur">Sohbatpur</option>
            <option value="Washuk">Washuk</option>
            <option value="Zhob">Zhob</option>
            <option value="Ziarat">Ziarat</option>
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Country</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select id="Country" className="input_styling ml-md-3" name="country">
            <option selected>Select</option>
            {populatedata?.Country !== null &&
            populatedata?.Country !== undefined ? (
              <option selected> {populatedata?.Country}</option>
            ) : (
              <option value="Pakistan">Pakistan</option>
            )}

            {/* <option value="Pakistan">Pakistan</option> */}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Phone No </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Phone"
            placeholder="xxx-xxxxxxx"
            value={populatedata?.PhoneNumber}
          />
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Cell No 1:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Cell1"
            placeholder="xxxx-xxxxxxx"
            value={populatedata?.ContactNumber1}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cell No 2: </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Cell2"
            placeholder="xxxx-xxxxxxx"
            value={populatedata?.ContactNumber2}
          />
        </div>

        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Address:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Address"
            value={populatedata?.LenderAddress}
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div className=" col-12 pr-0 pl-0  text-right ">
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                // props.cancellendertable();

                history.goBack();
                emptyfields();
              }}
            >
              Cancel
            </button>
          </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="lendersave"
              onClick={addlender}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
