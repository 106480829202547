import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { getallunitreceipt } from "../../../actions/vouchersactions/getunitreceipt";
import { getallDealSoldreceipt } from "../../../actions/vouchersactions/dealsoldreceipt";
import { getallrecoveryreceipt } from "../../../actions/vouchersactions/getrecoveryreceipt";
import { getallLoanReceipt } from "../../../actions/vouchersactions/getloanreceipt";
import { getallcommissionreceipt } from "../../../actions/vouchersactions/getcomreceipt";
import { getallLandPayment } from "../../../actions/vouchersactions/getlandpayment";
import { getallDealBoughtPay } from "../../../actions/vouchersactions/dealboughtpay";
import { getallLoanPayment } from "../../../actions/vouchersactions/getloanpayment";
import { getallcomissionpaymentpopulate } from "../../../actions/vouchersactions/getcompayment";
import { useEffect } from "react";
import { getallacounts } from "../../../actions/getheading";
import { getallProject } from "../../../actions/getprojects";
import axios from "axios";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const GeneralAdjustmentForm = () => {
  const resultsproject = useSelector((state) => state.getProject);
  const resultsallaccount = useSelector((state) => state.getAllAccountsReducer);
  const [accountid, setaccountid] = useState(null);
  const [individualcheck, setindividualcheck] = useState(true);
  const [showDetailModal, setshowDetailModal] = useState(false);
  const history = useHistory();

  // receipt populate
  const unitReceiptPopulate = useSelector((state) => state.getAllUnitPopulate);
  const dealSoldReceiptPopulate = useSelector(
    (state) => state.getDealSoldReceipt
  );
  const recoveryReceiptPopulate = useSelector(
    (state) => state.getRecoveryReceipt
  );
  const loanReceiptPopulate = useSelector(
    (state) => state.getLoanReceiptPopulate
  );
  const CommissionRecData = useSelector((state) => state.getComReceipt);

  const [unitReceiptList, setUnitReceiptList] = useState();
  const [unitComReceiptList, setUnitComReceiptList] = useState();
  const [isreceiptright, setisreceiptright] = useState(false);
  const [ispaymentright, setispaymentright] = useState(false);

  // payment populate
  const LoanReceiptPaymentPopulate = useSelector(
    (state) => state.getLoanPaymentPopulate
  );
  const commissionPaymentPopulate = useSelector(
    (state) => state.getCommissionPayment
  );
  const dealBoughtPaymentPopulate = useSelector(
    (state) => state.getDealBoughtPay
  );
  const landPaymentPopulate = useSelector(
    (state) => state.getLandPaymentPopulate
  );
  const [unitPaymentList, setUnitPaymentList] = useState();
  let vouchernumber = `AE-${Math.floor(Date.now())}`;
  // receipt
  const [recHumanEntityId, setRecHumanEntityId] = useState();
  const [recHumanEntityCnic, setRecHumanEntityCnic] = useState();
  const [recContractId, setRecContractId] = useState();
  const [recNecessaryId, setRecNecessaryId] = useState();
  // payment
  const [payHumanEntityId, setPayHumanEntityId] = useState();
  const [payHumanEntityCnic, setPayHumanEntityCnic] = useState();
  const [payContractId, setPayContractId] = useState();
  const [payNecessaryId, setPayNecessaryId] = useState();
  const [isRecValueMatch, setIsRecValueMatch] = useState();
  const [isPayValueMatch, setIsPayRecValueMatch] = useState();

  // receipt onchange
  function onReceiptChange(e) {
    if (e.target.value === "Select") {
      // toast.info("Select any receipt from dropdown");
      document.getElementById("Receiptname").value = "";

      document.getElementById("Receiptunit").value = "";

      document.getElementById("Receiptamount").value = "";
    } else if (e.target.value === "Unit Receipt") {
      dispatch(getallunitreceipt());
      setIsRecValueMatch("Unit Receipt");
      document.getElementById("Receiptname").value = "";

      document.getElementById("Receiptunit").value = "";

      document.getElementById("Receiptamount").value = "";
    } else if (e.target.value === "Deal Sold Receipt") {
      dispatch(getallDealSoldreceipt());
      setIsRecValueMatch("Deal Sold Receipt");
      document.getElementById("Receiptname").value = "";

      document.getElementById("Receiptunit").value = "";

      document.getElementById("Receiptamount").value = "";
    } else if (e.target.value === "Recovery Receipt") {
      dispatch(getallrecoveryreceipt());
      setIsRecValueMatch("Recovery Receipt");
      document.getElementById("Receiptname").value = "";

      document.getElementById("Receiptunit").value = "";

      document.getElementById("Receiptamount").value = "";
    } else if (e.target.value === "Investment Receipt") {
      dispatch(getallLoanReceipt());
      setIsRecValueMatch("Investment Receipt");
      document.getElementById("Receiptname").value = "";

      document.getElementById("Receiptunit").value = "";

      document.getElementById("Receiptamount").value = "";
    } else if (e.target.value === "Commission Receipt") {
      dispatch(getallcommissionreceipt());
      setIsRecValueMatch("Commission Receipt");
      document.getElementById("Receiptname").value = "";
      document.getElementById("Paymentname").value = "";
      document.getElementById("Receiptunit").value = "";
      document.getElementById("Paymentunit").value = "";
      document.getElementById("Receiptamount").value = "";
    } else {
      toast.info("Select any other receipt from dropdown");
      setIsRecValueMatch();
    }
  }
  // payment onchange
  function onPaymentChange(e) {
    if (e.target.value === "Select") {
      // toast.info("Select any payment from dropdown")
      document.getElementById("Paymentunit").value = "";
      document.getElementById("Paymentname").value = "";
      document.getElementById("Paymentamount").value = "";
    } else if (e.target.value === "Land Payment") {
      dispatch(getallLandPayment());
      setIsPayRecValueMatch("Land Payment");
      document.getElementById("Paymentunit").value = "";
      document.getElementById("Paymentname").value = "";
      document.getElementById("Paymentamount").value = "";
    } else if (e.target.value === "Deal Bought Payment") {
      dispatch(getallDealBoughtPay());
      setIsPayRecValueMatch("Deal Bought Payment");
      document.getElementById("Paymentunit").value = "";
      document.getElementById("Paymentname").value = "";
      document.getElementById("Paymentamount").value = "";
    } else if (e.target.value === "Investment Payment") {
      dispatch(getallLoanPayment());
      setIsPayRecValueMatch("Investment Payment");
      document.getElementById("Paymentunit").value = "";
      document.getElementById("Paymentname").value = "";
      document.getElementById("Paymentamount").value = "";
    } else if (e.target.value === "Commission Payment") {
      dispatch(getallcomissionpaymentpopulate());
      setIsPayRecValueMatch("Commission Payment");
      document.getElementById("Paymentunit").value = "";
      document.getElementById("Paymentname").value = "";
      document.getElementById("Paymentamount").value = "";
    } else {
      toast.info("Select any other payment from dropdown");
      setIsPayRecValueMatch();
    }
  }
  // receipt name on change

  let recieptSuggestionList = [];

  const receiptNameChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    } else {
      // for unit receipt
      if (isRecValueMatch === "Unit Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitReceiptPopulate.data
            .sort()
            .filter((v) =>
              regex.test(v.CustomerName !== null ? v.CustomerName : null)
            );
        }
        recieptSuggestionList = suggestions;

        if (recieptSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {recieptSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => receiptsuggestionselected(item)}
                >
                  {item === undefined ? item : item.CustomerName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptnamedemo")
          );
        }
      }
      // for deal sold receipt
      else if (isRecValueMatch === "Deal Sold Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = dealSoldReceiptPopulate.data
            .sort()
            .filter((v) =>
              regex.test(v.AffiliateName !== null ? v.AffiliateName : null)
            );
        }
        recieptSuggestionList = suggestions;

        if (recieptSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {recieptSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => receiptsuggestionselected(item)}
                >
                  {item === undefined ? item : item.AffiliateName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptnamedemo")
          );
        }
      }
      // for recovery receipt
      else if (isRecValueMatch === "Recovery Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = recoveryReceiptPopulate.data
            .sort()
            .filter((v) =>
              regex.test(v.Employee !== undefined ? v.Employee.EmpName : null)
            );
        }
        recieptSuggestionList = suggestions;

        if (recieptSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {recieptSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => receiptsuggestionselected(item)}
                >
                  {item === undefined ? item : item.Employee.EmpName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptnamedemo")
          );
        }
      }
      // for loan rceipt
      else if (isRecValueMatch === "Investment Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = loanReceiptPopulate.data
            .sort()
            .filter((v) => regex.test(v.LoanName !== null ? v.LoanName : null));
        }
        recieptSuggestionList = suggestions;

        if (recieptSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {recieptSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => receiptsuggestionselected(item)}
                >
                  {item === undefined ? item : item.LoanName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptnamedemo")
          );
        }
      }
      // for Commission Receipt
      else if (isRecValueMatch === "Commission Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = CommissionRecData.data
            .sort()
            .filter((v) =>
              regex.test(v.SellerName !== null ? v.SellerName : null)
            );
        }
        recieptSuggestionList = suggestions;

        if (recieptSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {recieptSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => receiptsuggestionselected(item)}
                >
                  {item === undefined ? item : item.SellerName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptnamedemo")
          );
        }
      }
    }
  };

  function onTextChangeReceiptNameAccounts() {
    var inputValue = document.getElementById("Receiptname").value;
    var options = document.getElementById("mylist2").childNodes;

    if (isRecValueMatch === "Unit Receipt") {
      let obj;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitReceiptPopulate?.data?.Data?.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              obj = x;
            }
          });
          setUnitReceiptList(obj?.Contract);
          setRecHumanEntityCnic(obj?.CustomerCnic);
          setRecHumanEntityId();
          break;
        }
      }
    } else if (isRecValueMatch === "Deal Sold Receipt") {
      let obj;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          dealSoldReceiptPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              obj = x;
            }
          });
          setUnitReceiptList(obj.DealSold);
          setRecHumanEntityCnic(obj.AffiliateCnic);
          setRecHumanEntityId();
          break;
        }
      }
    } else if (isRecValueMatch === "Recovery Receipt") {
      let obj;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          recoveryReceiptPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              obj = x;
            }
          });
          setUnitReceiptList(obj.RecoveryBookingVoucher);
          setRecHumanEntityId(obj.ManagerId);
          setRecHumanEntityCnic();
          break;
        }
      }
    } else if (isRecValueMatch === "Investment Receipt") {
      let obj;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          loanReceiptPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              obj = x;
            }
          });
          setRecHumanEntityId(obj.Lender?.LenderId);
          setRecHumanEntityCnic();
          document.getElementById("Receiptunit").value = obj.Lender.LenderName;
          document.getElementById("Receiptamount").value = parseFloat(
            parseFloat(obj.Amount) - parseFloat(obj.ReceivedAmount)
          ).toLocaleString();
          setRecNecessaryId(obj.LoanId);
          break;
        }
      }
    } else if (isRecValueMatch === "Commission Receipt") {
      let obj;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          CommissionRecData.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              obj = x;
            }
          });
          setUnitReceiptList(obj.DealBought);
          setRecHumanEntityId(obj.SellerId);
          setRecHumanEntityCnic();
          break;
        }
      }
    }
  }

  const receiptsuggestionselected = (val) => {
    if (isRecValueMatch === "Unit Receipt") {
      // for unit receipt
      document.getElementById("Receiptname").value = val.CustomerName;
      setUnitReceiptList(val.Contract);
      setRecHumanEntityCnic(val.CustomerCnic);
      setRecHumanEntityId();
      recieptSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    }
    // for deal sold receipt
    else if (isRecValueMatch === "Deal Sold Receipt") {
      document.getElementById("Receiptname").value = val.AffiliateName;
      setUnitReceiptList(val.DealSold);
      setRecHumanEntityCnic(val.AffiliateCnic);
      setRecHumanEntityId();
      recieptSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    }
    // for recovery receipt
    else if (isRecValueMatch === "Recovery Receipt") {
      document.getElementById("Receiptname").value = val.Employee.EmpName;
      setUnitReceiptList(val.RecoveryBookingVoucher);
      setRecHumanEntityId(val.ManagerId);
      setRecHumanEntityCnic();
      recieptSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    }
    // for loan receipt
    else if (isRecValueMatch === "Investment Receipt") {
      document.getElementById("Receiptname").value = val.LoanName;
      // setUnitReceiptList(val.DealSold)
      setRecHumanEntityId(val.Lender.LenderId);
      setRecHumanEntityCnic();
      document.getElementById("Receiptunit").value = val.Lender.LenderName;
      document.getElementById("Receiptamount").value = parseFloat(
        parseFloat(val.Amount) - parseFloat(val.ReceivedAmount)
      ).toLocaleString();
      setRecNecessaryId(val.LoanId);

      recieptSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    }
    // for Commission Receipt
    else if (isRecValueMatch === "Commission Receipt") {
      document.getElementById("Receiptname").value = val.SellerName;
      setUnitReceiptList(val.DealBought);
      setRecHumanEntityId(val.SellerId);
      setRecHumanEntityCnic();
      recieptSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    } else {
      recieptSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptnamedemo"));
    }
  };
  // receipt unit on change function
  let ReceiptUnitSuggestionList = [];

  const receiptUnitNameChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
    } else {
      if (isRecValueMatch === "Unit Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitReceiptList
            .sort()
            .filter((v) =>
              regex.test(v.Unit !== null ? v.Unit.UnitName : null)
            );
        }
        ReceiptUnitSuggestionList = suggestions;

        if (ReceiptUnitSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {ReceiptUnitSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Receiptunitsuggestionselected(item)}
                >
                  {item === undefined ? item : item.Unit.UnitName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptunitnamedemo")
          );
        }
      } else if (isRecValueMatch === "Deal Sold Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitReceiptList
            .sort()
            .filter((v) => regex.test(v.DealName !== null ? v.DealName : null));
        }
        ReceiptUnitSuggestionList = suggestions;

        if (ReceiptUnitSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {ReceiptUnitSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Receiptunitsuggestionselected(item)}
                >
                  {item === undefined ? item : item.DealName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptunitnamedemo")
          );
        }
      }
      // for receovery recepit
      else if (isRecValueMatch === "Recovery Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitReceiptList
            .sort()
            .filter((v) =>
              regex.test(v.Unit !== undefined ? v.Unit.UnitName : null)
            );
        }
        ReceiptUnitSuggestionList = suggestions;

        if (ReceiptUnitSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {ReceiptUnitSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Receiptunitsuggestionselected(item)}
                >
                  {item === undefined ? item : item.Unit.UnitName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptunitnamedemo")
          );
        }
      }
      // for Investment Receipt
      else if (isRecValueMatch === "Investment Receipt") {
        // let suggestions = [];
        // if (value.length > 0) {
        //     const regex = new RegExp(`${value}`, "i");
        //     suggestions = unitReceiptList
        //         .sort()
        //         .filter((v) =>
        //             regex.test(v.DealName !== null ? v.DealName : null)
        //         );
        // }
        // ReceiptUnitSuggestionList = suggestions;
        //
        // if (ReceiptUnitSuggestionList.length === 0) {
        //     ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
        // } else {
        //     ReactDOM.render(
        //         <ul className="suggestbox">
        //             {ReceiptUnitSuggestionList.map((item) => (
        //                 <li className="listcss" onClick={() => Receiptunitsuggestionselected(item)}>
        //                     {item === undefined
        //                         ? item
        //                         : item.DealName
        //                     }
        //                 </li>
        //             ))}
        //         </ul>,
        //         document.getElementById("receiptunitnamedemo")
        //     );
        // }
      }
      // forCommission Receipt
      else if (isRecValueMatch === "Commission Receipt") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitReceiptList
            .sort()
            .filter((v) => regex.test(v.DealName !== null ? v.DealName : null));
        }
        ReceiptUnitSuggestionList = suggestions;

        if (ReceiptUnitSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {ReceiptUnitSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Receiptunitsuggestionselected(item)}
                >
                  {item === undefined ? item : item.DealName}
                </li>
              ))}
            </ul>,
            document.getElementById("receiptunitnamedemo")
          );
        }
      }
    }
  };

  const onTextChangeReceiptUnitName = () => {
    var inputValue = document.getElementById("Receiptunit").value;
    var options = document.getElementById("mylist3").childNodes;

    if (isRecValueMatch === "Unit Receipt") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitReceiptList.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("Receiptamount").value = parseFloat(
            val.CustomerReceivable
          ).toLocaleString();
          setRecContractId(val.ContractId);
          setRecNecessaryId(val.UnitId);
          break;
        }
      }
    } else if (isRecValueMatch === "Deal Sold Receipt") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitReceiptList.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("Receiptamount").value = parseFloat(
            parseFloat(val.Amount) - parseFloat(val.AmountReceived)
          ).toLocaleString();
          setRecNecessaryId(val.DealSoldId);
          break;
        }
      }
    } else if (isRecValueMatch === "Recovery Receipt") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitReceiptList.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("Receiptamount").value = parseFloat(
            parseFloat(val?.Amount) - parseFloat(val?.RecoveryPaidAmount)
          ).toLocaleString();
          setRecNecessaryId(val?.Unit?.UnitId);
          break;
        }
      }
    } else if (isRecValueMatch === "Commission Receipt") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitReceiptList.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          setUnitComReceiptList(val.Units);
          setRecNecessaryId(val.DealBoughtId);
          break;
        }
      }
    }
  };

  const Receiptunitsuggestionselected = (val) => {
    if (isRecValueMatch === "Unit Receipt") {
      // for unit receipt
      document.getElementById("Receiptunit").value = val.Unit.UnitName;
      document.getElementById("Receiptamount").value = parseFloat(
        val.CustomerReceivable
      ).toLocaleString();
      // setUnitReceiptList(val.Contract);
      setRecContractId(val.ContractId);
      setRecNecessaryId(val.UnitId);

      ReceiptUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
    }
    // for deal sold receipt
    else if (isRecValueMatch === "Deal Sold Receipt") {
      document.getElementById("Receiptunit").value = val.DealName;
      document.getElementById("Receiptamount").value = parseFloat(
        parseFloat(val.Amount) - parseFloat(val.AmountReceived)
      ).toLocaleString();
      // setUnitReceiptList(val.Contract);
      // setRecContractId(val.ContractId);
      setRecNecessaryId(val.DealSoldId);

      ReceiptUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
    }
    // for recovery receipt
    else if (isRecValueMatch === "Recovery Receipt") {
      document.getElementById("Receiptunit").value = val.Unit.UnitName;
      document.getElementById("Receiptamount").value = parseFloat(
        parseFloat(val.Amount) - parseFloat(val.RecoveryPaidAmount)
      ).toLocaleString();
      // setUnitReceiptList(val.Contract);
      // setRecContractId(val.ContractId);
      setRecNecessaryId(val.Unit.UnitId);

      ReceiptUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
    }
    // for Investment Receipt
    else if (isRecValueMatch === "Investment Receipt") {
      // document.getElementById("Receiptunit").value = val.DealName
      // document.getElementById("Receiptamount").value = val.Amount
      // // setUnitReceiptList(val.Contract);
      // // setRecContractId(val.ContractId);
      // setRecNecessaryId(val.DealSoldId);

      ReceiptUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
    }
    //  for Commission Receipt
    else if (isRecValueMatch === "Commission Receipt") {
      document.getElementById("Receiptunit").value = val.DealName;
      // document.getElementById("Receiptamount").value = parseFloat(val.DealAmount)- (val.AmountPaid)
      setUnitComReceiptList(val.Units);
      // setUnitReceiptList(val.Contract);
      // setRecContractId(val.Units[0].Contract[0].ContractId);
      setRecNecessaryId(val.DealBoughtId);

      ReceiptUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("receiptunitnamedemo"));
    }
  };

  //   receipt unit on change function ends
  //   payment name select

  let PaymentSuggestionList = [];

  const paymentNameChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("paymentnamedemo"));
    } else {
      if (isPayValueMatch === "Commission Payment") {
        if (individualcheck === true) {
          let suggestions = [];
          if (value.length > 0) {
            const regex = new RegExp(`${value}`, "i");
            suggestions = commissionPaymentPopulate.data.Data.sort().filter(
              (v) =>
                regex.test(v.AffiliateName !== null ? v.AffiliateName : null)
            );
          }
          PaymentSuggestionList = suggestions;

          if (PaymentSuggestionList.length === 0) {
            ReactDOM.render(null, document.getElementById("paymentnamedemo"));
          } else {
            ReactDOM.render(
              <ul className="suggestbox">
                {PaymentSuggestionList.map((item) => (
                  <li
                    className="listcss"
                    onClick={() => paymentsuggestionselected(item)}
                  >
                    {item === undefined ? item : item.AffiliateName}
                  </li>
                ))}
              </ul>,
              document.getElementById("paymentnamedemo")
            );
          }
        } else {
          let suggestions = [];
          if (value.length > 0) {
            const regex = new RegExp(`${value}`, "i");
            suggestions =
              commissionPaymentPopulate.data.Employees.sort().filter((v) =>
                regex.test(v.EmpName !== null ? v.EmpName : null)
              );
          }
          PaymentSuggestionList = suggestions;

          if (PaymentSuggestionList.length === 0) {
            ReactDOM.render(null, document.getElementById("paymentnamedemo"));
          } else {
            ReactDOM.render(
              <ul className="suggestbox">
                {PaymentSuggestionList.map((item) => (
                  <li
                    className="listcss"
                    onClick={() => paymentsuggestionselected(item)}
                  >
                    {item === undefined ? item : item.EmpName}
                  </li>
                ))}
              </ul>,
              document.getElementById("paymentnamedemo")
            );
          }
        }
      }
      // land payment
      else if (isPayValueMatch === "Land Payment") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = landPaymentPopulate.data
            .sort()
            .filter((v) =>
              regex.test(
                v.LandCreditorName !== null ? v.LandCreditorName : null
              )
            );
        }
        PaymentSuggestionList = suggestions;

        if (PaymentSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("paymentnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {PaymentSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => paymentsuggestionselected(item)}
                >
                  {item === undefined ? item : item.LandCreditorName}
                </li>
              ))}
            </ul>,
            document.getElementById("paymentnamedemo")
          );
        }
      }
      //  investment payment
      else if (isPayValueMatch === "Investment Payment") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = LoanReceiptPaymentPopulate.data
            .sort()
            .filter((v) => regex.test(v.LoanName !== null ? v.LoanName : null));
        }
        PaymentSuggestionList = suggestions;

        if (PaymentSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("paymentnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {PaymentSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => paymentsuggestionselected(item)}
                >
                  {item === undefined ? item : item.LoanName}
                </li>
              ))}
            </ul>,
            document.getElementById("paymentnamedemo")
          );
        }
      }
      //  Deal Payment
      else if (isPayValueMatch === "Deal Bought Payment") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = dealBoughtPaymentPopulate.data
            .sort()
            .filter((v) =>
              regex.test(v.SellerName !== null ? v.SellerName : null)
            );
        }
        PaymentSuggestionList = suggestions;

        if (PaymentSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("paymentnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {PaymentSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => paymentsuggestionselected(item)}
                >
                  {item === undefined ? item : item.SellerName}
                </li>
              ))}
            </ul>,
            document.getElementById("paymentnamedemo")
          );
        }
      }
    }
  };

  const onTextChangePaymentName = () => {
    var inputValue = document.getElementById("Paymentname").value;
    var options = document.getElementById("mylist6").childNodes;

    if (isPayValueMatch === "Commission Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          if (individualcheck === true) {
            commissionPaymentPopulate?.data?.Data?.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            setUnitPaymentList(val.Contract);
            setPayHumanEntityCnic(val.AffiliateCnic);
            setPayHumanEntityId();
          } else {
            commissionPaymentPopulate?.data?.Employees?.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            setUnitPaymentList(val.SalesTeamCommissionPayables);
            setPayHumanEntityCnic();
            setPayHumanEntityId(val.EmployeeId);
          }

          break;
        }
      }
    } else if (isPayValueMatch === "Land Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          landPaymentPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          setUnitPaymentList(val.LandPurchased);
          setPayHumanEntityId(val.LandCreditorId);
          setPayHumanEntityCnic();
          break;
        }
      }
    } else if (isPayValueMatch === "Investment Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          LoanReceiptPaymentPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          setUnitPaymentList(val.Contract);
          setPayHumanEntityId(val.LenderId);
          setPayHumanEntityCnic();
          document.getElementById("Paymentunit").value = val.Lender.LenderName;
          document.getElementById("Paymentamount").value = parseFloat(
            parseFloat(val.Amount) -
            parseFloat(val.ReturnedAmount).toLocaleString()
          );
          setPayNecessaryId(val.LoanId);

          break;
        }
      }
    } else if (isPayValueMatch === "Deal Bought Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          dealBoughtPaymentPopulate.data.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          setUnitPaymentList(val.DealBought);
          setPayHumanEntityId(val.SellerId);
          setPayHumanEntityCnic();

          break;
        }
      }
    }
  };

  const paymentsuggestionselected = (val) => {
    if (isPayValueMatch === "Commission Payment") {
      if (individualcheck === true) {
        document.getElementById("Paymentname").value = val.AffiliateName;
        setUnitPaymentList(val.Contract);
        setPayHumanEntityCnic(val.AffiliateCnic);
        setPayHumanEntityId();
        PaymentSuggestionList = [];
        ReactDOM.render(null, document.getElementById("paymentnamedemo"));
      } else {
        document.getElementById("Paymentname").value = val.EmpName;
        setUnitPaymentList(val.SalesTeamCommissionPayables);
        setPayHumanEntityCnic();
        setPayHumanEntityId(val.EmployeeId);
        PaymentSuggestionList = [];
        ReactDOM.render(null, document.getElementById("paymentnamedemo"));
      }
    }
    // for land payment
    else if (isPayValueMatch === "Land Payment") {
      document.getElementById("Paymentname").value = val.LandCreditorName;
      setUnitPaymentList(val.LandPurchased);
      setPayHumanEntityId(val.LandCreditorId);
      setPayHumanEntityCnic();
      PaymentSuggestionList = [];
      ReactDOM.render(null, document.getElementById("paymentnamedemo"));
    }
    // investment payment
    else if (isPayValueMatch === "Investment Payment") {
      document.getElementById("Paymentname").value = val.LoanName;
      setUnitPaymentList(val.Contract);
      setPayHumanEntityId(val.LenderId);
      setPayHumanEntityCnic();
      document.getElementById("Paymentunit").value = val.Lender.LenderName;
      document.getElementById("Paymentamount").value = parseFloat(
        parseFloat(val.Amount) - parseFloat(val.ReturnedAmount).toLocaleString()
      );
      // setUnitReceiptList(val.Contract);
      setPayNecessaryId(val.LoanId);

      PaymentSuggestionList = [];
      ReactDOM.render(null, document.getElementById("paymentnamedemo"));
    }
    // Deal Payment
    else if (isPayValueMatch === "Deal Bought Payment") {
      document.getElementById("Paymentname").value = val.SellerName;
      setUnitPaymentList(val.DealBought);
      setPayHumanEntityId(val.SellerId);
      setPayHumanEntityCnic();

      PaymentSuggestionList = [];
      ReactDOM.render(null, document.getElementById("paymentnamedemo"));
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallacounts());
  }, [dispatch]);

  // payment unit on change function
  let paymentUnitSuggestionList = [];

  const paymentUnitNameChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
    } else {
      if (isPayValueMatch === "Commission Payment") {
        if (individualcheck === true) {
          let suggestions = [];
          if (value.length > 0) {
            const regex = new RegExp(`${value}`, "i");
            suggestions = unitPaymentList
              .sort()
              .filter((v) =>
                regex.test(v.Unit !== null ? v.Unit.UnitName : null)
              );
          }
          paymentUnitSuggestionList = suggestions;

          if (paymentUnitSuggestionList.length === 0) {
            ReactDOM.render(
              null,
              document.getElementById("paymentunitnamedemo")
            );
          } else {
            ReactDOM.render(
              <ul className="suggestbox">
                {paymentUnitSuggestionList.map((item) => (
                  <li
                    className="listcss"
                    onClick={() => Paymentunitsuggestionselected(item)}
                  >
                    {item === undefined ? item : item.Unit.UnitName}
                  </li>
                ))}
              </ul>,
              document.getElementById("paymentunitnamedemo")
            );
          }
        } else {
          let suggestions = [];
          if (value.length > 0) {
            const regex = new RegExp(`${value}`, "i");
            suggestions = unitPaymentList
              .sort()
              .filter((v) =>
                regex.test(
                  v.Contract !== null ? v.Contract.Unit.UnitName : null
                )
              );
          }
          paymentUnitSuggestionList = suggestions;

          if (paymentUnitSuggestionList.length === 0) {
            ReactDOM.render(
              null,
              document.getElementById("paymentunitnamedemo")
            );
          } else {
            ReactDOM.render(
              <ul className="suggestbox">
                {paymentUnitSuggestionList.map((item) => (
                  <li
                    className="listcss"
                    onClick={() => Paymentunitsuggestionselected(item)}
                  >
                    {item === undefined ? item : item.Contract.Unit.UnitName}
                  </li>
                ))}
              </ul>,
              document.getElementById("paymentunitnamedemo")
            );
          }
        }
      }
      // land payment
      else if (isPayValueMatch === "Land Payment") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitPaymentList
            .sort()
            .filter((v) =>
              regex.test(v.LandName !== undefined ? v.LandName : null)
            );
        }
        paymentUnitSuggestionList = suggestions;

        if (paymentUnitSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {paymentUnitSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Paymentunitsuggestionselected(item)}
                >
                  {item === undefined ? item : item.LandName}
                </li>
              ))}
            </ul>,
            document.getElementById("paymentunitnamedemo")
          );
        }
      }
      // investment payment
      else if (isPayValueMatch === "Investment Payment") {
        // let suggestions = [];
        // if (value.length > 0) {
        //     const regex = new RegExp(`${value}`, "i");
        //     suggestions = unitPaymentList
        //         .sort()
        //         .filter((v) =>
        //             regex.test(v.Unit !== null ? v.Unit.UnitName : null)
        //         );
        // }
        // paymentUnitSuggestionList = suggestions;
        //
        // if (paymentUnitSuggestionList.length === 0) {
        //     ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
        // } else {
        //     ReactDOM.render(
        //         <ul className="suggestbox">
        //             {paymentUnitSuggestionList.map((item) => (
        //                 <li className="listcss" onClick={() => Paymentunitsuggestionselected(item)}>
        //                     {item === undefined
        //                         ? item
        //                         : item.Unit.UnitName
        //                     }
        //                 </li>
        //             ))}
        //         </ul>,
        //         document.getElementById("paymentunitnamedemo")
        //     );
        // }
      }
      // Deal Payment
      else if (isPayValueMatch === "Deal Bought Payment") {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = unitPaymentList
            .sort()
            .filter((v) => regex.test(v.DealName !== null ? v.DealName : null));
        }
        paymentUnitSuggestionList = suggestions;

        if (paymentUnitSuggestionList.length === 0) {
          ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
        } else {
          ReactDOM.render(
            <ul className="suggestbox">
              {paymentUnitSuggestionList.map((item) => (
                <li
                  className="listcss"
                  onClick={() => Paymentunitsuggestionselected(item)}
                >
                  {item === undefined ? item : item.DealName}
                </li>
              ))}
            </ul>,
            document.getElementById("paymentunitnamedemo")
          );
        }
      }
    }
  };

  const onTextChangePaymentUnitName = () => {
    var inputValue = document.getElementById("Paymentunit").value;
    var options = document.getElementById("mylist7").childNodes;

    if (isPayValueMatch === "Commission Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          if (individualcheck) {
            unitPaymentList?.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            var tamt = 0;
            val.Unit.DealBoughtId === null
              ? (tamt =
                parseFloat(val.ComissionAmount) +
                parseFloat(val.AdditionalMarginAmount) -
                parseFloat(val.CommissionAndMarginPaid))
              : (tamt =
                parseFloat(val.ComissionAmount) -
                parseFloat(val.CommissionAndMarginPaid));
            document.getElementById("Paymentamount").value =
              parseFloat(tamt).toLocaleString();
            setPayContractId(val.ContractId);
            setPayNecessaryId(val.UnitId);
          } else {
            unitPaymentList?.forEach((x, idx) => {
              if (idx == options[i].attributes.index.value) {
                val = x;
              }
            });
            var tamt = 0;
            tamt =
              parseFloat(val.CommissionPayable) -
              parseFloat(val.CommissionAmountPaid);

            document.getElementById("Paymentamount").value =
              parseFloat(tamt).toLocaleString();
            setPayContractId(val.Contract.ContractId);
            setPayNecessaryId(val.Contract.Unit.UnitId);
          }

          break;
        }
      }
    } else if (isPayValueMatch === "Land Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitPaymentList.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("Paymentamount").value = parseFloat(
            parseFloat(val.LandValue) - parseFloat(val.LandValuePaid)
          ).toLocaleString();
          setPayNecessaryId(val.LandPurchasedId);
          break;
        }
      }
    } else if (isPayValueMatch === "Deal Bought Payment") {
      let val;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
          unitPaymentList.forEach((x, idx) => {
            if (idx == options[i].attributes.index.value) {
              val = x;
            }
          });
          document.getElementById("Paymentamount").value = parseFloat(
            parseFloat(val.TotalAmount) - parseFloat(val.AmountPaid)
          ).toLocaleString();
          // setUnitReceiptList(val.Contract);
          // setPayContractId(val.ContractId);
          setPayNecessaryId(val.DealBoughtId);

          break;
        }
      }
    }
  };

  const Paymentunitsuggestionselected = (val) => {
    if (isPayValueMatch === "Commission Payment") {
      if (individualcheck === true) {
        var tamt = 0;
        val.Unit.DealBoughtId === null
          ? (tamt =
            parseFloat(val.ComissionAmount) +
            parseFloat(val.AdditionalMarginAmount) -
            parseFloat(val.CommissionAndMarginPaid))
          : (tamt =
            parseFloat(val.ComissionAmount) -
            parseFloat(val.CommissionAndMarginPaid));
        document.getElementById("Paymentamount").value =
          parseFloat(tamt).toLocaleString();

        document.getElementById("Paymentunit").value = val.Unit.UnitName;

        // document.getElementById("Paymentamount").value = val.ComissionAmount-val.ComissionPaid
        // setUnitReceiptList(val.Contract);
        setPayContractId(val.ContractId);
        setPayNecessaryId(val.UnitId);

        paymentUnitSuggestionList = [];
        ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
      } else {
        var tamt = 0;
        tamt =
          parseFloat(val.CommissionPayable) -
          parseFloat(val.CommissionAmountPaid);

        document.getElementById("Paymentamount").value =
          parseFloat(tamt).toLocaleString();

        document.getElementById("Paymentunit").value =
          val.Contract.Unit.UnitName;

        // document.getElementById("Paymentamount").value = val.ComissionAmount-val.ComissionPaid
        // setUnitReceiptList(val.Contract);
        setPayContractId(val.Contract.ContractId);
        setPayNecessaryId(val.Contract.Unit.UnitId);

        paymentUnitSuggestionList = [];
        ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
      }
    }
    // for land payment
    else if (isPayValueMatch === "Land Payment") {
      document.getElementById("Paymentunit").value = val.LandName;
      document.getElementById("Paymentamount").value = parseFloat(
        parseFloat(val.LandValue) - parseFloat(val.LandValuePaid)
      ).toLocaleString();
      // setUnitReceiptList(val.Contract);
      // setPayContractId(val.LandCreditorId);
      setPayNecessaryId(val.LandPurchasedId);

      paymentUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
    }
    // for investment paymet
    else if (isPayValueMatch === "Investment Payment") {
      // document.getElementById("Paymentunit").value = val.Unit.UnitName
      // document.getElementById("Paymentamount").value = val.ComissionAmount-val.ComissionPaid
      // // setUnitReceiptList(val.Contract);
      // setPayContractId(val.ContractId);
      // setPayNecessaryId(val.UnitId);

      paymentUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
    }
    // for Deal Payment
    else if (isPayValueMatch === "Deal Bought Payment") {
      document.getElementById("Paymentunit").value = val.DealName;
      document.getElementById("Paymentamount").value = parseFloat(
        parseFloat(val.TotalAmount) - parseFloat(val.AmountPaid)
      ).toLocaleString();
      // setUnitReceiptList(val.Contract);
      // setPayContractId(val.ContractId);
      setPayNecessaryId(val.DealBoughtId);

      paymentUnitSuggestionList = [];
      ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
    }
  };
  //   payment unit on change function ends
  let comReceiptSuggestionList = [];

  const comReceiptUnitChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("paymentunitnamedemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions =
          unitComReceiptList !== undefined
            ? unitComReceiptList
              .sort()
              .filter((v) =>
                regex.test(v.UnitName !== null ? v.UnitName : null)
              )
            : "";
      }
      comReceiptSuggestionList = suggestions;

      if (comReceiptSuggestionList.length === 0) {
        ReactDOM.render(null, document.getElementById("comReceiptnamedemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {comReceiptSuggestionList.map((item) => (
              <li
                className="listcss"
                onClick={() => comReceiptsuggestionselected(item)}
              >
                {item === undefined ? item : item.UnitName}
              </li>
            ))}
          </ul>,
          document.getElementById("comReceiptnamedemo")
        );
      }
    }
  };

  const onTextChangeComReceiptUnit = () => {
    var inputValue = document.getElementById("comReceiptname").value;
    var options = document.getElementById("mylist4").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        unitComReceiptList.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        document.getElementById("Receiptamount").value = parseFloat(
          parseFloat(val.Contract[0].AdditionalMarginAmount) +
          parseFloat(val.Contract[0].CompanyCommissionAmount) -
          parseFloat(val.Contract[0].CompnayCommissionAndMarginReceived)
        ).toLocaleString();
        setRecContractId(val.Contract[0].ContractId);
        break;
      }
    }
  };

  const comReceiptsuggestionselected = (val) => {
    document.getElementById("comReceiptname").value = val.UnitName;
    document.getElementById("Receiptamount").value = parseFloat(
      parseFloat(val.Contract[0].AdditionalMarginAmount) +
      parseFloat(val.Contract[0].CompanyCommissionAmount) -
      parseFloat(val.Contract[0].CompnayCommissionAndMarginReceived)
    ).toLocaleString();
    setRecContractId(val.Contract[0].ContractId);

    comReceiptSuggestionList = [];
    ReactDOM.render(null, document.getElementById("comReceiptnamedemo"));
  };
  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("Select Project");
    } else if (
      document.getElementById("Receipt").selectedIndex === 0 &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Select Receipt  Voucher");
    } else if (
      document.getElementById("Payment").selectedIndex === 0 &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Select Payment Voucher");
    } else if (
      document.getElementById("Receiptname").value === "" &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Select Receipt  Account");
    } else if (
      document.getElementById("Paymentname").value === "" &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Select Payment Account");
    } else if (
      document.getElementById("Receiptunit").value === "" &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Select Receipt  Unit");
    } else if (
      document.getElementById("Paymentunit").value === "" &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Select Payment Unit");
    } else if (
      document.getElementById("Adjustmentamt").value === "" &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Enter Adjustment Amount");
    } else if (
      document.getElementById("narration").value === "" &&
      document.getElementById("writeoff").checked === false
    ) {
      toast.info("Enter Narration");
    }
    // else if (parseFloat(document.getElementById("Adjustmentamt").value) > parseFloat(document.getElementById("Receiptamount").value) && parseFloat(document.getElementById("Adjustmentamt").value) > parseFloat(document.getElementById("Paymentamount").value)) {
    //     toast.info(" Adjustment Amount should be less than receipt and payment amount");

    // }
    else if (
      document.getElementById("writeoff").checked === true &&
      document.getElementById("Account").value === ""
    ) {
      toast.info("Select Account");
    } else {
      document.getElementById("unitsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Adjustments/Add`,
        data: JSON.stringify({
          VoucherId: document.getElementById("Voucher").value,
          EntryDate: document.getElementById("Date").value,
          ProjectId: parseInt(
            document.getElementById("project").options[
              document.getElementById("project").selectedIndex
            ].dataset.id
          ),
          Amount: parseFloat(
            document.getElementById("Adjustmentamt").value.replace(/,/g, "")
          ),
          Narration: document.getElementById("narration").value,
          ReceiptSide: {
            Type: document.getElementById("Receipt").options[
              document.getElementById("Receipt").selectedIndex
            ].value,
            HumanEntityId: recHumanEntityId,
            HumanEntityCNIC: recHumanEntityCnic,
            NecessoryId: recNecessaryId,
            ContractId: recContractId,
            WriteOff:
              isreceiptright === true
                ? document.getElementById("writeoff").checked
                : false,
            AccountId: accountid,
          },
          PaymentSide: {
            Type: document.getElementById("Payment").options[
              document.getElementById("Payment").selectedIndex
            ].value,
            HumanEntityId: payHumanEntityId,
            HumanEntityCNIC: payHumanEntityCnic,
            NecessoryId: payNecessaryId,
            ContractId: payContractId,
            WriteOff:
              ispaymentright === true
                ? document.getElementById("writeoff").checked
                : false,
            AccountId: accountid,
          },
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("unitsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("project").selectedIndex = 0;
            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("unitsave").disabled = false;
        }
      );
    }
  };
  let suggestionlistaccounts = [];
  const onTextChange = (e, id) => {
    var accountnames = [];
    resultsallaccount.data.forEach((name) => {
      accountnames.push(name.AccountTitle.concat("-", name.AccountId));
    });
    const value = e.target.value;
    if (value === "Add New") {
      ReactDOM.render(null, document.getElementById("dropdown"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = accountnames.sort().filter((v) => regex.test(v));
      }

      suggestionlistaccounts = suggestions;
      // this.suggestionlistaccounts.push("Add New");
      if (suggestionlistaccounts.length === 0) {
        ReactDOM.render(null, document.getElementById("dropdown"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlistaccounts.map((item) => (
              <li
                className="listcss"
                onClick={() => suggestionselectedaccounts(item, id)}
              >
                {item}
              </li>
            ))}
          </ul>,
          document.getElementById("dropdown")
        );
      }
    }
  };
  const suggestionselectedaccounts = (val, id) => {
    let res = val.split("-");

    document.getElementById("Account").value = res[0];
    setaccountid(res[1]);
    suggestionlistaccounts = [];
    ReactDOM.render(null, document.getElementById("dropdown"));
  };
  function onTextChangeReceiptAccounts() {
    var inputValue = document.getElementById("Account").value;
    var options;
    if (isreceiptright === true) {
      options = document.getElementById("mylist")?.childNodes;
    } else {
      options = document.getElementById("mylist5")?.childNodes;
    }

    for (var i = 0; i < options?.length; i++) {
      if (options[i].value === inputValue) {
        setaccountid(options[i].attributes.accountid.value);
        break;
      }
    }
  }

  return (
    <>
      <div className="row ">
        <div className="col-md-3 mt-4 text-left p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0"></div>
        <div className="col-md-3 mt-4    p-0">
          <select
            className="input_styling "
            id="project"
          >
            <option>Select Project</option>
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
                parseInt(val.ProjectId) ? (
                <option
                  key={index}
                  data-id={val.ProjectId}
                >
                  {val.ProjectName}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-3 mt-4 text-left p-0"></div>
      </div>

      <div className="row ">
        <div className="col-md-5 mt-4  p-0">
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label className="input_label m-0 ">Date:</label>
            </div>
            <div className="col-md-6 mt-4   p-0">
              <input
                type="date"
                className="input_date_styling "
                id="Date"
              ></input>
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
        <div className="col-md-2 mt-4  p-0 "></div>
        <div className="col-md-5 mt-4  p-0">
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label className="input_label m-0">Voucher:</label>
            </div>
            <div className="col-md-6 mt-4    p-0">
              <input
                type="text"
                disabled
                value={vouchernumber}
                className="input_styling "
                id="Voucher"
              ></input>
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-1 mt-4  p-0"></div>
        <div className="col-md-3 mt-4 text-center p-0">
          <h3 className=" ">
            Receipt<span className="estaric">*</span>
          </h3>
        </div>

        <div className="col-md-3 mt-4 text-center p-0">
          Write Off{" "}
          <input
            type="checkbox"
            id="writeoff"
            onChange={(e) => {
              if (e.target.checked === true) {
                if (document.getElementById("Payment").selectedIndex !== 0) {
                  setisreceiptright(true);
                } else if (
                  document.getElementById("Receipt").selectedIndex !== 0
                ) {
                  setispaymentright(true);
                } else {
                  setispaymentright(false);
                  setisreceiptright(false);
                }
              } else {
                setisreceiptright(false);
                setispaymentright(false);
              }
            }}
          ></input>
        </div>
        <div className="col-md-1 mt-4  p-0"></div>
        <div className="col-md-3 mt-4 text-center p-0">
          <h3 className="">
            Payment<span className="estaric">*</span>{" "}
          </h3>
        </div>
      </div>
      {/* <div className="row " hidden={!isreceiptright}>
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0">Account:</label>
                  </div>
                  <div className="col-md-3 mt-4  p-0">
                      <input
                          type="textarea"
                          className="input_styling "
                          id="Account"
                          onChange={onTextChange}
                      ></input>
                      <span id="dropdown"></span>
                  </div>
  
  
              </div> */}
      <div className="row ">
        <div className="col-md-5   p-0">
          {document.getElementById("Payment") !== null ? (
            document.getElementById("Payment").selectedIndex !== 0 ? (
              <div className="row ">
                <div className="col-md-4 mt-4 text-left p-0">
                  <label
                    className="input_label m-0"
                    hidden={!isreceiptright}
                  >
                    Account:
                  </label>
                </div>
                <div className="col-md-6 mt-4  p-0">
                  <input
                    type="textarea"
                    className="input_styling"
                    id="Account"
                    list="mylist"
                    onChange={onTextChangeReceiptAccounts}
                    hidden={!isreceiptright}
                  ></input>
                  <datalist id="mylist">
                    {resultsallaccount?.data?.map((x, idx) => (
                      <option
                        accountid={x.AccountId}
                        value={x.AccountTitle}
                      >
                        {x.AccountId}
                      </option>
                    ))}
                  </datalist>
                  <span id="dropdown"></span>
                  <span
                    className="estaric"
                    hidden={!isreceiptright}
                  >
                    *
                  </span>
                </div>
              </div>
            ) : null
          ) : null}
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0 "
                hidden={isreceiptright}
              >
                Receipt:
              </label>
            </div>
            <div className="col-md-6 mt-4   p-0">
              <select
                type="text "
                className="input_styling "
                id="Receipt"
                onChange={onReceiptChange}
                hidden={isreceiptright}
              >
                <option>Select</option>
                <option>Unit Receipt</option>
                <option>Deal Sold Receipt</option>
                <option>Recovery Receipt</option>
                <option>Investment Receipt</option>
                <option>Commission Receipt</option>
              </select>
              <span
                className="estaric"
                hidden={isreceiptright}
              >
                *
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0 "
                hidden={isreceiptright}
              >
                {isRecValueMatch === "Investment Receipt"
                  ? "Invest Name"
                  : "Account:"}
              </label>
            </div>
            <div className="col-md-6 mt-4   p-0">
              <input
                type="text "
                className="input_styling "
                id="Receiptname"
                onChange={onTextChangeReceiptNameAccounts}
                list="mylist2"
                hidden={isreceiptright}
              ></input>
              <datalist id="mylist2">
                {isRecValueMatch === "Unit Receipt" ? (
                  unitReceiptPopulate?.data?.Data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.CustomerName}
                    >
                      {x.CustomerCnic2}
                    </option>
                  ))
                ) : isRecValueMatch === "Deal Sold Receipt" ? (
                  dealSoldReceiptPopulate?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.AffiliateName}
                    >
                      {x.AffiliateCnic}
                    </option>
                  ))
                ) : isRecValueMatch === "Recovery Receipt" ? (
                  recoveryReceiptPopulate?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.Employee?.EmpName}
                    >
                      {x.ManagerId}
                    </option>
                  ))
                ) : isRecValueMatch === "Investment Receipt" ? (
                  loanReceiptPopulate?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.LoanName}
                    >
                      {x.Lender?.LenderId}
                    </option>
                  ))
                ) : isRecValueMatch === "Commission Receipt" ? (
                  CommissionRecData?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.SellerName}
                    >
                      {x.SellerId}
                    </option>
                  ))
                ) : (
                  <option>{"Please Wait!"}</option>
                )}
              </datalist>
              <span
                className="estaric"
                hidden={isreceiptright}
              >
                *
              </span>
              <span id="receiptnamedemo"></span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0 "
                hidden={isreceiptright}
              >
                {isRecValueMatch === "Deal Sold Receipt"
                  ? "Deal Name"
                  : isRecValueMatch === "Investment Receipt"
                    ? "Investor"
                    : isRecValueMatch === "Commission Receipt"
                      ? "Deal Name"
                      : " Unit"}
              </label>
            </div>
            <div className="col-md-6 mt-4   p-0">
              <input
                type="text "
                className="input_styling "
                id="Receiptunit"
                onChange={onTextChangeReceiptUnitName}
                hidden={isreceiptright}
                list="mylist3"
              ></input>
              <datalist id="mylist3">
                {isRecValueMatch === "Unit Receipt" ? (
                  unitReceiptList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.Unit?.UnitName}
                    >
                      {x.UnitId}
                    </option>
                  ))
                ) : isRecValueMatch === "Deal Sold Receipt" ? (
                  unitReceiptList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.DealName}
                    >
                      {x.DealSoldId}
                    </option>
                  ))
                ) : isRecValueMatch === "Recovery Receipt" ? (
                  unitReceiptList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.Unit?.UnitName}
                    >
                      {x.Unit?.UnitId}
                    </option>
                  ))
                ) : isRecValueMatch === "Commission Receipt" ? (
                  unitReceiptList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.DealName}
                    >
                      {x.DealBoughtId}
                    </option>
                  ))
                ) : (
                  <option>{"Please Wait!"}</option>
                )}
              </datalist>
              <span id="receiptunitnamedemo"></span>
              <span
                className="estaric"
                hidden={isreceiptright}
              >
                *
              </span>
            </div>
          </div>
          {isRecValueMatch === "Commission Receipt" ? (
            <div className="row ">
              <div className="col-md-4 mt-4 text-left p-0">
                <label
                  className="input_label m-0 "
                  hidden={isreceiptright}
                >
                  {" "}
                  Unit
                </label>
              </div>
              <div className="col-md-6 mt-4   p-0">
                <input
                  type="text "
                  className="input_styling "
                  id="comReceiptname"
                  onChange={onTextChangeComReceiptUnit}
                  hidden={isreceiptright}
                  list="mylist4"
                ></input>
                <datalist id="mylist4">
                  {unitComReceiptList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.UnitName}
                    >
                      {x?.Contract[0]?.ContractId}
                    </option>
                  ))}
                </datalist>
                <span id="comReceiptnamedemo"></span>
                <span
                  className="estaric"
                  hidden={isreceiptright}
                >
                  *
                </span>
              </div>
            </div>
          ) : null}
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0 "
                hidden={isreceiptright}
              >
                {" "}
                R/A:
              </label>
            </div>
            <div className="col-md-6 mt-4   p-0">
              <input
                type="text"
                className="input_styling "
                disabled
                id="Receiptamount"
                hidden={isreceiptright}
              ></input>
              <span
                className="estaric"
                hidden={isreceiptright}
              >
                *
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-2 mt-4  p-0 "></div>
        <div className="col-md-5   p-0 ">
          {document.getElementById("Receipt") !== null ? (
            document.getElementById("Receipt").selectedIndex !== 0 ? (
              <div className="row ">
                <div className="col-md-4 mt-4 text-left p-0">
                  <label
                    className="input_label m-0"
                    hidden={!ispaymentright}
                  >
                    Account:
                  </label>
                </div>
                <div className="col-md-6 mt-4  p-0">
                  <input
                    type="textarea"
                    className="input_styling"
                    id="Account"
                    onChange={onTextChangeReceiptAccounts}
                    hidden={!ispaymentright}
                    list="mylist5"
                  ></input>
                  <datalist id="mylist5">
                    {resultsallaccount?.data?.map((x, idx) => (
                      <option
                        accountid={x.AccountId}
                        value={x.AccountTitle}
                      >
                        {x.AccountId}
                      </option>
                    ))}
                  </datalist>
                  <span id="dropdown"></span>
                  <span
                    className="estaric"
                    hidden={!ispaymentright}
                  >
                    *
                  </span>
                </div>
              </div>
            ) : null
          ) : null}
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0"
                hidden={ispaymentright}
              >
                Payment :
              </label>
            </div>
            <div className="col-md-6 mt-4    p-0">
              <select
                type="text "
                className="input_styling "
                id="Payment"
                onChange={onPaymentChange}
                hidden={ispaymentright}
              >
                <option>Select</option>
                <option>Land Payment</option>
                <option>Deal Bought Payment</option>
                <option>Investment Payment</option>
                <option>Commission Payment</option>
              </select>
              <span
                className="estaric"
                hidden={ispaymentright}
              >
                *
              </span>
            </div>
          </div>
          {isPayValueMatch === "Commission Payment" ? (
            <div className="row ">
              <div className="col-md-4 mt-2 text-right">
                <span className="txt input_label"> Affliate</span>
              </div>
              <div className="col-md-1 mt-2 text-left">
                <span>
                  {" "}
                  <input
                    type="radio"
                    id="affiliatetype"
                    name="affiliateType"
                    checked={individualcheck}
                    onClick={() => {
                      setindividualcheck(true);
                    }}
                  ></input>
                </span>
              </div>
              <div className="col-md-4 mt-2 text-right">
                <span className="txt input_label"> Employee</span>
              </div>
              <div className="col-md-1 mt-2 text-left">
                <span>
                  {" "}
                  <input
                    type="radio"
                    name="employeeType"
                    id="employeeproject"
                    checked={!individualcheck}
                    onClick={() => {
                      setindividualcheck(false);
                    }}
                  ></input>
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0 ">
              <label
                className="input_label m-0"
                hidden={ispaymentright}
              >
                {isPayValueMatch === "Investment Payment"
                  ? "Invest Name"
                  : "Account:"}{" "}
              </label>
            </div>
            <div className="col-md-6 mt-4    p-0">
              <input
                type="text "
                className="input_styling "
                id="Paymentname"
                onChange={onTextChangePaymentName}
                hidden={ispaymentright}
                list="mylist6"
              ></input>
              <datalist id="mylist6">
                {isPayValueMatch === "Commission Payment" ? (
                  individualcheck ? (
                    commissionPaymentPopulate?.data?.Data?.map((x, idx) => (
                      <option
                        index={idx}
                        value={x.AffiliateName}
                      >
                        {x.AffiliateCnic}
                      </option>
                    ))
                  ) : (
                    commissionPaymentPopulate?.data?.Employees?.map(
                      (x, idx) => (
                        <option
                          index={idx}
                          value={x.EmpName}
                        >
                          {x.EmployeeId}
                        </option>
                      )
                    )
                  )
                ) : isPayValueMatch === "Land Payment" ? (
                  landPaymentPopulate?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.LandCreditorName}
                    >
                      {x.LandCreditorId}
                    </option>
                  ))
                ) : isPayValueMatch === "Investment Payment" ? (
                  LoanReceiptPaymentPopulate?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.LoanName}
                    >
                      {x.LoanId}
                    </option>
                  ))
                ) : isPayValueMatch === "Deal Bought Payment" ? (
                  dealBoughtPaymentPopulate?.data?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.SellerName}
                    >
                      {x.SellerId}
                    </option>
                  ))
                ) : (
                  <option>{"Please Wait!"}</option>
                )}
              </datalist>

              <span id="paymentnamedemo"></span>
              <span
                className="estaric"
                hidden={ispaymentright}
              >
                *
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0"
                hidden={ispaymentright}
              >
                {isPayValueMatch === "Land Payment"
                  ? "Land Name"
                  : isPayValueMatch === "Investment Payment"
                    ? "Investor"
                    : isPayValueMatch === "Deal Bought Payment"
                      ? "Deal Name"
                      : "Unit"}{" "}
                :
              </label>
            </div>
            <div className="col-md-6 mt-4    p-0">
              <input
                type="text "
                className="input_styling "
                id="Paymentunit"
                onChange={onTextChangePaymentUnitName}
                hidden={ispaymentright}
                list="mylist7"
              ></input>
              <datalist id="mylist7">
                {isPayValueMatch === "Commission Payment" ? (
                  individualcheck ? (
                    unitPaymentList?.map((x, idx) => (
                      <option
                        index={idx}
                        value={x.Unit?.UnitName}
                      >
                        {x.UnitId}
                      </option>
                    ))
                  ) : (
                    unitPaymentList?.map((x, idx) => (
                      <option
                        index={idx}
                        value={x.Contract?.Unit?.UnitName}
                      >
                        {x.Contract?.Unit?.UnitId}
                      </option>
                    ))
                  )
                ) : isPayValueMatch === "Land Payment" ? (
                  unitPaymentList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.LandName}
                    >
                      {x.LandPurchasedId}
                    </option>
                  ))
                ) : isPayValueMatch === "Deal Bought Payment" ? (
                  unitPaymentList?.map((x, idx) => (
                    <option
                      index={idx}
                      value={x.DealName}
                    >
                      {x.DealBoughtId}
                    </option>
                  ))
                ) : (
                  <option>{"Please Wait!"}</option>
                )}
              </datalist>
              <span id="paymentunitnamedemo"></span>
              <span
                className="estaric"
                hidden={ispaymentright}
              >
                *
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label
                className="input_label m-0"
                hidden={ispaymentright}
              >
                P/A :
              </label>
            </div>
            <div
              className="col-md-6 mt-4    p-0"
              hidden={ispaymentright}
            >
              <input
                type="text"
                className="input_styling "
                disabled
                id="Paymentamount"
              ></input>
              <span
                className="estaric"
                hidden={ispaymentright}
              >
                *
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row ">
                  <div className="col-md-2 mt-4 text-left p-0" >
                      <label className="input_label m-0 " hidden={isreceiptright} >Receipt:</label>
                  </div>
                  <div className="col-md-3 mt-4   p-0">
                      <select type="text " className="input_styling " id="Receipt" onChange={onReceiptChange} hidden={isreceiptright}>
                          <option>Select</option>
                          <option>Unit Receipt</option>
                          <option>Deal Sold Receipt</option>
                          <option>Recovery Receipt</option>
                          <option>Investment Receipt</option>
                          <option>Commission Receipt</option>
  
  
                      </select>
                  </div>
                  <div className="col-md-2 mt-4  p-0">
                  </div>
  
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0">Payment :</label>
                  </div>
                  <div className="col-md-3 mt-4    p-0">
  
                      <select type="text " className="input_styling " id="Payment" onChange={onPaymentChange}>
                          <option>Select</option>
                          <option>Land Payment</option>
                          <option>Deal Bought Payment</option>
                          <option>Investment Payment</option>
                          <option>Commission Payment</option>
                      </select>
  
                  </div>
              </div>
              <div className="row ">
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0 " hidden={isreceiptright} >{isRecValueMatch === "Investment Receipt" ? "Invest Name" : "Account:"}</label>
                  </div>
                  <div className="col-md-3 mt-4   p-0">
                      <input type="text " className="input_styling " id="Receiptname" onChange={receiptNameChange} hidden={isreceiptright}></input>
                      <span id="receiptnamedemo"></span>
                  </div>
                  <div className="col-md-2 mt-4  p-0">
                  </div>
  
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0" >{isPayValueMatch === "Investment Payment" ? "Invest Name" : " Particular:"} </label>
                  </div>
                  <div className="col-md-3 mt-4    p-0">
  
                      <input type="text " className="input_styling " id="Paymentname" onChange={paymentNameChange} ></input>
                      <span id="paymentnamedemo"></span>
                  </div>
              </div>
              <div className="row ">
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0 " hidden={isreceiptright}>{isRecValueMatch === "Deal Sold Receipt" ? "Deal Name" : isRecValueMatch === "Investment Receipt" ? "Investor" : isRecValueMatch === "Commission Receipt" ? "Deal Name" : " Unit"}</label>
                  </div>
                  <div className="col-md-3 mt-4   p-0">
                      <input type="text " className="input_styling " id="Receiptunit" onChange={receiptUnitNameChange} hidden={isreceiptright}></input>
                      <span id="receiptunitnamedemo"></span>
                  </div>
                  <div className="col-md-2 mt-4  p-0">
                  </div>
  
                  <div className="col-md-2 mt-4 text-left p-0" >
                      <label className="input_label m-0" >{isPayValueMatch === "Land Payment" ? "Land Name" : isPayValueMatch === "Investment Payment" ? "Investor" : isPayValueMatch === "Deal Bought Payment" ? "Deal Name" : "Unit"} :</label>
                  </div>
                  <div className="col-md-3 mt-4    p-0">
  
                      <input type="text " className="input_styling " id="Paymentunit" onChange={paymentUnitNameChange}></input>
                      <span id="paymentunitnamedemo"></span>
                  </div>
              </div>
              {isRecValueMatch === "Commission Receipt" ?
                  <div className="row ">
                      <div className="col-md-2 mt-4 text-left p-0">
                          <label className="input_label m-0 " hidden={isreceiptright}> Unit</label>
                      </div>
                      <div className="col-md-3 mt-4   p-0">
                          <input type="text " className="input_styling " id="comReceiptname" onChange={comReceiptUnitChange} hidden={isreceiptright}></input>
                          <span id="comReceiptnamedemo"></span>
                      </div>
  
                  </div> : null}
              <div className="row " >
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0 " hidden={isreceiptright} > R/A:</label>
                  </div>
                  <div className="col-md-3 mt-4   p-0">
                      <input type="number" className="input_styling " disabled id="Receiptamount" hidden={isreceiptright}></input>
                  </div>
                  <div className="col-md-2 mt-4  p-0">
                  </div>
  
                  <div className="col-md-2 mt-4 text-left p-0">
                      <label className="input_label m-0">P/A :</label>
                  </div>
                  <div className="col-md-3 mt-4    p-0">
  
                      <input type="number" className="input_styling " disabled id="Paymentamount"></input>
  
                  </div>
              </div>*/}
      <div className="row ">
        <div className="col-md-5  p-0">
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label className="input_label m-0">Adjustment Amt :</label>
            </div>
            <div className="col-md-6 mt-4    p-0">
              <input
                type="text"
                className="input_styling "
                id="Adjustmentamt"
                onChange={(e) => {
                  if (e.target.value.length > 0)
                    document.getElementById("Adjustmentamt").value = parseInt(
                      e.target.value.replace(/,/g, "")
                    ).toLocaleString();
                }}
              ></input>
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
        <div className="col-md-2   p-0"></div>

        <div className="col-md-5   p-0">
          <div className="row ">
            <div className="col-md-4 mt-4 text-left p-0">
              <label className="input_label m-0">Narration:</label>
            </div>
            <div className="col-md-6 mt-4    p-0">
              <textarea
                className="input_address "
                id="narration"
              ></textarea>
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row "> 
  
  
                  <div className="col-md-2 mt-4  p-0">
                  </div>
  
  
  
  
              </div> */}

      <div className=" float-right mt-md-4">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill
              size="1em"
              className="mr-1"
            />
            Detail
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => {
              // props.cancelunittable;
              history.goBack();
            }}
          >
            Cancel
          </button>
        </span>
        <span>
          <button
            className="save_btn ml-2  "
            id="unitsave"
            onClick={savedata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="General Adjustment"
        />
      ) : null}
    </>
  );
};
