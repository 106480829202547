import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";

import {
  getallTeamTable,
  getallTeamPopulate,
} from "../../actions/Crmactions/addteamaction";
import axios from "axios";
// import { MdDeleteForever } from "react-icons/md";

import { toast } from "react-toastify";
import { FiEdit2 } from "react-icons/fi";

import Loader from "react-loader-spinner";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
const MainTeamCom = () => {
  const showteamtable = () => {
    stateshowteams("TeamAdd");
  };
  const cancelteamtable = () => {
    stateshowteams("TeamTable");

    setadvanceedit([]);
  };
  const [showteams, stateshowteams] = useState("TeamTable");
  const [stateadvanceedit, setadvanceedit] = useState([]);
  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showteams === "TeamTable" ? (
                <TeamTable
                  showteamtable={showteamtable}
                  cancelteamtable={cancelteamtable}
                  setadvanceedit={setadvanceedit}
                />
              ) : (
                <AddTeam
                  stateadvanceedit={stateadvanceedit}
                  cancelteamtable={cancelteamtable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainTeamCom;
export const AddTeam = () => {
  const populateteams = useSelector((state) => state.GetAllTeamsPopulate);
  let dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  const [StateMangerId, SetMangerId] = useState();
  const [stateadvanceedit, setadvanceedit] = useState([]);

  // var managersuggestionlist = [];
  useEffect(() => {
    if (data) setadvanceedit(data);
  }, [data]);
  // const ManagerSelecttion = (e) => {
  //   const value = e.target.value;
  //   if (value==="") {
  //     ReactDOM.render(null, document.getElementById("managerdemo"));
  //   } else {
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");
  //       suggestions = populateteams.data.Managers.sort().filter((v) =>
  //         regex.test(v.EmpName !== null ? v.EmpName : null)
  //       );
  //     }
  //     managersuggestionlist = suggestions;

  //     if (managersuggestionlist.length === 0) {
  //       ReactDOM.render(null, document.getElementById("managerdemo"));
  //     } else {
  //       ReactDOM.render(
  //         <ul className="suggestbox">
  //           {managersuggestionlist.map((item) => (
  //             <li
  //               className="listcss"
  //               onClick={() => managersuggestionselected(item)}
  //             >
  //               {item === undefined ? item : item.EmpName}
  //             </li>
  //           ))}
  //         </ul>,
  //         document.getElementById("managerdemo")
  //       );
  //     }
  //   }
  // };
  // const managersuggestionselected = (val) => {
  //   document.getElementById("Manager").value = val.EmpName;

  //   SetMangerId(val.EmployeeId);

  //   managersuggestionlist = [];
  //   ReactDOM.render(null, document.getElementById("managerdemo"));
  // };
  const [state, setstate] = useState({
    CrmteamId: 0,
    TeamName: null,
    ManagerId: 0,
  });
  const savedata = () => {
    if (document.getElementById("TeamName").value === "") {
      toast.info("Select Team Name");
    } else if (document.getElementById("Manager").value === "") {
      toast.info("Select Manager");
    } else {
      let team = state;

      team.ManagerId =
        stateadvanceedit !== undefined && stateadvanceedit.Manager !== undefined
          ? stateadvanceedit.Manager.EmployeeId
          : StateMangerId;
      team.CrmteamId =
        stateadvanceedit !== undefined ? stateadvanceedit.CrmteamId : 0;
      // team.ManagerId = StateMangerId;
      // team.CrmteamId = 0;
      team.TeamName = document.getElementById("TeamName").value;

      document.getElementById("teamsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}CRMTeams/Add`,
        data: JSON.stringify(team),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("teamsave").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            // props.setadvanceedit([]);
            // props.cancelteamtable();
            history.goBack();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("teamsave").disabled = false;
        }
      );
    }
  };
  const onTextChangeEmployeeName = () => {
    var inputValue = document.getElementById("inittiate").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        populateteams.data.Managers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        SetMangerId(val.EmployeeId);

        break;
      }
    }
  };
  useEffect(() => {
    dispatch(getallTeamPopulate());
  }, []);

  return (
    <>
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Add Team</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Team Name</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="TeamName"
            defaultValue={
              stateadvanceedit !== undefined ? stateadvanceedit.TeamName : 0
            }
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Team Manager.</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            list="employeenamelist"
            className="input_styling  ml-md-3"
            id="Manager"
            placeholder="suggestion list"
            defaultValue={
              stateadvanceedit !== undefined &&
                stateadvanceedit.Manager !== undefined
                ? stateadvanceedit.Manager.EmpName
                : null
            }
            onChange={onTextChangeEmployeeName}
          ></input>
          <span className="estaric">*</span>
          <datalist id="employeenamelist">
            {populateteams?.data?.Managers?.map((x, idx) => (
              <option index={idx} value={x.EmpName}>
                {x.EmployeeId}
              </option>
            ))}
          </datalist>
          <span id="managerdemo"></span>
        </div>
      </div>
      <div className=" float-right mt-md-4 ">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
        </span>

        <span>
          <button className="save_btn ml-2  " id="teamsave" onClick={savedata}>
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};

export const TeamTable = () => {
  const tabledatastate = useSelector((state) => state.GetAllTeamsTable);
  const history = useHistory();
  const rolesdata = useSelector((state) => state.GetAllRole);

  const [filterloans, setfilterloans] = useState();

  let dispatch = useDispatch();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterloans(tabledatastate.data.Teams);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filterloans
          .sort()
          .filter((val) =>
            regex.test(val.TeamName !== null ? val.TeamName : null)
          );
      }
      suggestionlist = suggestions;
      setfilterloans(suggestionlist);
    }
  };

  useEffect(() => {
    dispatch(getallTeamTable());
  }, [dispatch]);
  useEffect(() => {
    setfilterloans(tabledatastate.data.Teams);
  }, [tabledatastate]);
  return (
    <>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label  m-0">Search:</label>
        </div>
        <div className="col-md-3   pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Team"
            onChange={onTextChange}
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {tabledatastate.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height2 mt-4">
        <table className="table table-borderless  table-hover m-0">
          <thead>
            <tr>
              <th>Sr</th>

              <th>Team</th>

              <th>Manager</th>

              <th className="text-center">
                <button
                  className="save_btn"
                  // onClick={() => {
                  //   props.showteamtable();
                  // }}
                  onClick={() => history.push("/crm/teams/teams/add")}
                >
                  New+
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {filterloans !== undefined
              ? filterloans.map((val, index) => (
                <tr key={index + 1}>
                  <td className="cur_sor">{index + 1}</td>
                  <td className="cur_sor">
                    {val.TeamName !== undefined ? val.TeamName : null}
                  </td>
                  <td className="cur_sor">
                    {val.Manager !== undefined ? val.Manager.EmpName : null}
                  </td>

                  <td className="text-center cur_sor ">
                    <FiEdit2
                      title="Edit"
                      size="1.1em"
                      className="cur_sor"
                      // onClick={() => {
                      //   props.setadvanceedit(val);
                      //   props.showteamtable();
                      // }}
                      onClick={() => {
                        history.push("/crm/teams/teams", {
                          state: val,
                        });
                      }}
                    />
                  </td>
                </tr>
              ))
              : null}
          </tbody>
        </table>
      </div>
    </>
  );
};
