// import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
// import { useRef } from "react";
// import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
// import { useReactToPrint } from "react-to-print";
// import MainTransferPrint from "../TransferPrint/MainTransferPrint";
// import { TransferIndvidualPrint } from "../TransferPrint/TransferIndvidualPrint";
// import { TransferOfficalPrint } from "../TransferPrint/TransferOfficalPrint";
// import { CSVLink } from "react-csv";
// import TransferUnitModal from "./TransferUnitModal";
import { getAllTransferUnitTable } from "../../../actions/accountsactions/transferunit";
import { GrRevert } from "react-icons/gr";
// import { toast } from "react-toastify";
// import axios from "axios";
import RevertTransferUnitDialog from "./RevertTransferUnitDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const TransferUnitTable = (props) => {
  const resultsallunits = useSelector((state) => state.GetTransferUnitTable);
  const dispatch = useDispatch();
  const history = useHistory();

  const [filerunits, setfilterunits] = useState();
  const rolesdata = useSelector((state) => state.GetAllRole);
  const [selectedRow, setselectedRow] = useState(null);

  const onTextChange = (e) => {
    if (document.getElementById("sectionfilter").value === "") {
      setfilterunits(resultsallunits.data);
    } else {
      let suggestions = [];
      {
        const regex1 = new RegExp(
          document.getElementById("sectionfilter").value,
          "i"
        );

        suggestions = resultsallunits.data
          .sort()
          .filter(
            (val) =>
              document.getElementById("sectionfilter").value === "" ||
              regex1.test(val.CustomerName)
          );
      }
      setfilterunits(suggestions);
    }
  };

  useEffect(() => {
    let data = {};
    dispatch(getAllTransferUnitTable(data));
  }, [dispatch]);

  useEffect(() => {
    setfilterunits(resultsallunits.data);
  }, [resultsallunits]);

  return (
    <>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>

        <div className="col-md-4 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Buyer"
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {resultsallunits.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive vouchers_table_height2 mt-3">
            <table className="table table-borderless m-0 table-hover cur_sor">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th> Buyer</th>

                  <th> Old Unit</th>
                  <th> New Unit</th>

                  <th> Sale Rate</th>
                  <th> Installment Amount</th>
                  <th> Adjusted Amount</th>
                  <th> No Of Installments</th>
                  <th>Due Date</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        onClick={() => {
                          // props.changecomponent();
                          history.push("/account/contract/transfer-unit/add");
                        }}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => {
                    let year = val?.FirstInstallmentDueDate?.slice(0, 4);
                    let day = val?.FirstInstallmentDueDate?.slice(8, 10);
                    let month = val?.FirstInstallmentDueDate?.slice(5, 7);
                    switch (month) {
                      case "01":
                        month = "Jan";

                        break;
                      case "02":
                        month = "Feb";

                        break;
                      case "03":
                        month = "Mar";

                        break;
                      case "04":
                        month = "Apr";

                        break;
                      case "05":
                        month = "May";

                        break;
                      case "06":
                        month = "Jun";

                        break;
                      case "07":
                        month = "Jul";

                        break;
                      case "08":
                        month = "Aug";

                        break;
                      case "09":
                        month = "Sep";

                        break;
                      case "10":
                        month = "Oct";
                        break;
                      case "11":
                        month = "Nov";
                        break;
                      case "12":
                        month = "Dec";
                        break;
                      default:
                        break;
                    }
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{val.CustomerName}</td>

                        <td>{val?.OldUnitName || "N/A"}</td>
                        <td>{val?.NewUnitName || "N/A"}</td>
                        <td>{val?.SaleRate?.toLocaleString()}</td>
                        <td>{val?.InstallmentAmount?.toLocaleString()}</td>
                        <td>{val?.AdjustAmount?.toLocaleString()}</td>
                        <td>{val?.NoOfInstallments}</td>
                        <td>
                          {val.FirstInstallmentDueDate !== undefined
                            ? day + "-" + month + "-" + year
                            : null}
                        </td>
                        <td
                          title="Revert"
                          className="text-center"
                        >
                          {localStorage.getItem("Designation") === "Admin" ? (
                            <GrRevert
                              title="Revert"
                              className="cur_sor"
                              size="1em"
                              onClick={() => {
                                setselectedRow(val);
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        {selectedRow && (
          <RevertTransferUnitDialog
            setselectedRow={setselectedRow}
            selectedRow={selectedRow}
          />
        )}
      </div>
    </>
  );
};
