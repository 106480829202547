const allgeneralrec = { data: [], loading: true };
export const GetAllGeneralRec = (state = allgeneralrec, action) => {
    switch (action.type) {

        case "get_allgeneralrec":
            return {
                data: action?.payload,
                loading: action.loading
            }
        default:
            return state;
    }


}
