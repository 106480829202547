import { combineReducers } from "redux";

import GetHeadingReducer, {
  GetAllAccountsReducer,
  GetAllRecentlyAccReducer,
} from "./getheading";
import { GetAllBanksReducer, GetAllCashReducer } from "./getbanks";
import { GetAllProjectCatReducer } from "./getprojectcat";
import { GetAllProject } from "./getprojects";
import {
  GetsectioncatReducer,
  GetsectionsubcatReducer,
  GetsectiontypeReducer,
} from "./getsectioncat";
import { GetAllSections } from "./getsections";
import { GetAllGeneralRec } from "./getgeneralrec";
import { GetAllGeneralPay } from "./getgeneralpay";
import { GetAllJournalEntry } from "./getjournalentry";
import {
  GetAllMeasureUnit,
  GetAllDimensionUnit,
  GetAllUnits,
  GetAllUnitCategory,
  GetAllUnitTags,
} from "./getunit";
import { GetAllClientsReducer, GetClientsdataReducer } from "./getclients";
import { GetAllCreditorReducer, GetCreditordataReducer } from "./getcreditors";
import {
  GetAllUnitReceiptRed,
  GetAllUnitReceiptTableRed,
} from "./voucherreciept/getunitreceipt";
import {
  GetRecoveryBookingRed,
  GetAllRecoveryBookingTableRed,
} from "./voucherreciept/getrecoverybooking";
import { GetallManagersReducer } from "./getmanager";
import { GetLendersdataReducer, GetAllLendersReducer } from "./getlenders";
import { GetAllAccReceivable } from "./chartofacc/accreceivablereducer";
import { GetAllAccPayable } from "./chartofacc/accpayablereducer";
import {
  GetRecoveryReceiptRed,
  GetAllRecoveryReceiptTableRed,
} from "./voucherreciept/getrecoveryreciept";
import {
  GetAllLoanReceiptTableRed,
  GetLoanReceiptRed,
} from "./voucherreciept/getlaonreceipt";
import { GetAllLoanTableRed } from "./getloanreducer";
import {
  GetAllLoanPaymentTableRed,
  GetLoanPaymentRed,
} from "./voucherreciept/getloanpayment";
import {
  GetAllReceiptInstallTableRed,
  GetReceiptInstallRed,
} from "./voucherreciept/getreceiptinstallment";
import { GetAllPaymentInstallTableRed } from "./voucherreciept/getpaymentintall";
import {
  GetAllLandByIdReducer,
  GetAllLandReducer,
} from "./inventoryreducers/addlandreducer";
import { GetAllAddDeals } from "./inventoryreducers/getadddeal";
import {
  GetAllLandPaymentPopulateRed,
  GetAllLandTableRed,
} from "./voucherreciept/getlandpayment";
import {
  GetAllEmployeesReducer,
  GetEmployeedataReducer,
  GetEmpDesignation,
} from "./HRreducers/getemployees";
import { GetProjectDataReducer } from "./projectselectionred";
import { GetChartOfAcc } from "./chartofacc/getchartofacc";
import { GetTrailBalance } from "./AccountReports/trailbalance";
import { GetAllCustomersRed, GetCustomersdataReducer } from "./getclients";
import {
  GetAllAffiliatesRed,
  GetAffliateEidtReducer,
} from "./inventoryreducers/getaffiliates";
import { GetAllDealSoldRed } from "./inventoryreducers/dealsold";
import {
  GetAllSellerReducer,
  GetSellerdataReducer,
} from "./inventoryreducers/addseller";
import { GetAllDealBoughtRed } from "./inventoryreducers/dealbought";
import {
  GetAllContractPopulateRed,
  GetContractDetailsPopulate,
  GetContractTable,
  GetContractTablePopulate,
  GetContracts,
  GetUnitPopulateRed,
} from "./accountsreducers/addcontract";
import {
  GetCommissionPaymentRed,
  GetAllCommissionPaymentTableRed,
} from "./voucherreciept/getcompayment";
import {
  GetDealBoughtPayRed,
  GetAllDealBoughtPayTableRed,
} from "./voucherreciept/dealboughtpay";
import {
  GetDealSoldReceiptRed,
  GetAllDealSoldReceiptTableRed,
} from "./voucherreciept/dealsoldreceipt";
import { GetAccBalanceSummary } from "./accdashboard/balancesummary";
import {
  GetAllInstallRescedulePopulateRed,
  GetresceduledataReducer,
} from "./accountsreducers/installmentsrescedule";
import { GetBalanceSheet } from "./AccountReports/balancesheet";
import { GetProfitLoss } from "./AccountReports/profitandloss";
import {
  GetComReceiptRed,
  GetAllComReceiptTableRed,
} from "./voucherreciept/getcomreceipt";
import { GetAllGeneralAdjTableRed } from "./voucherreciept/generaladjustment";
import { GetAllTaxReducer, GetTaxDataReducer } from "./accountsreducers/addtax";
import {
  GetAllTaxPayableReducer,
  GetAllTaxPayableVoucherReducer,
} from "./accountsreducers/taxpayable";
import {
  GetAllTaxReceivableReducer,
  GetAllTaxReceiveableVoucherReducer,
} from "./accountsreducers/taxreceivable";
import { GetRecoveryReport } from "./AccountReports/recoveryreport";
import {
  GetAllNewPurchase,
  GetAddfilePopulate,
} from "./inventoryreducers/newpurchase";
import {
  GetRePurchaseTable,
  GetAllRePurchasePopulateRed,
} from "./inventoryreducers/repurchaseunit";
import {
  GetAllPopulateLeadRed,
  GetAllTableLeadRed,
  GetAllLeadTaskPopulateRed,
  GetAllLeadMeetingPopulateRed,
  GetAllLeadCallPopulateRed,
  GetAllLeadTasktableRed,
  GetAllLeadMeetingTabelRed,
  GetAllLeadCallTableRed,
} from "./crmreducer/leadreducer";
import {
  GetAllCRMAvailabilityRed,
  GetAllLeadsRed,
} from "./crmreducer/availabilityreducer";
import {
  GetAllDesignationReducer,
  GetAllAllowancesReducer,
  GetAllDeductionReducer,
} from "./HRreducers/designationred";
import {
  GetAllSalariesTable,
  GetAllSalariesPopulate,
} from "./HRreducers/assignsalary";
import {
  GetAllLoanTable,
  GetAllLoanPopulate,
  GetAllAdvanceTable,
} from "./HRreducers/advanceloanred";
import { GetAllHierarchyRed } from "./crmreducer/hierarchyred";
import {
  GetAllTeamsTable,
  GetAllTeamsPopulate,
  GetAllMemberPopulate,
  GetAllMemberTable,
} from "./crmreducer/addteamsred";
import {
  GetAssetsCatReducer,
  GetdepricationMethodReducer,
  GetAssetsSubcatReducer,
  GetTableAssetsReducer,
} from "./assetsreducer/addassets";
import {
  GetAssetBuyerdataReducer,
  GetAllAssetBuyerReducer,
} from "./assetsreducer/assetbuyer";
import { GetAllPaySlipTable } from "./HRreducers/payslipred";
import { GetAssetsDepReducer } from "./assetsreducer/depriciation";
import {
  GetAllTransferContractPopulateRed,
  GetTransferContractTable,
} from "./accountsreducers/transfercontract";
import {
  GetAllSalaryTableRed,
  GetAllSalaryPaymentPopulateRed,
} from "./voucherreciept/salarypayment";
import { GetAssetsSellReducer } from "./assetsreducer/assetssell";
import { GetAssetsMaintenenceReducer } from "./assetsreducer/maintenence";
import { GetAllRole, GetAllRoleTable } from "./getrole";
import {
  GetInstallmentReceiptRed,
  GetInstallmentPaymentRed,
} from "./AccountReports/installmentsreport";
import { GetComPaymentReportRed } from "./AccountReports/comissionpayment";
import { GetComReceiptReportRed } from "./AccountReports/comissionreceipt";
import { GetComPaymentSoldReportRed } from "./AccountReports/dealsoldcompay";
import { GetProjectsReportRed } from "./AccountReports/projectsreport";
import { GetAllUnSubmittedVouchers } from "./GetUnSubmittedVoucherReducer";
import { GetAllRevertBackVouchers } from "./GetRevertBackVoucherReducer";
import { GetAllRevertBackUserVouchers } from "./GetRevertBackUserVouchersReducer";
import { GetAllSubmittedVouchers } from "./GetSubmittedVoucherReducer";
import { GetExternalCommissionRed } from "./voucherreciept/getexternalcommission";
import { GetAllNotification } from "./GetAllNotificationReducer";
import { GetCompany } from "./GetCompanyInfoReducer";
import { GetBookedUnits } from "./BookedUnits/BookedUnits";
import { GetAllRoles } from "./populateRoles";
import { GetFetchUserData } from "./Users/getUser";
import { GetAllRefund } from "./getrefundvouch";
import { GetAllRefundPopulate } from "./getrefundpopulate";
import { GetLandPendingAmountRed } from "./AccountReports/landinstalltracker";
import { GetCancelPlotPaymentRed } from "./AccountReports/cancelplotpayment";
import {
  GetAllTransferUnitPopulateRed,
  GetTransferUnitTable,
} from "./accountsreducers/transferunit";
import { GetRepurchasedUnitsSummaryreport } from "./AccountReports/RepurchasedUnitsSummary";
const MainReducer = combineReducers({
  getHeadingReducer: GetHeadingReducer,
  getAllAccountsReducer: GetAllAccountsReducer,
  getRecentlyAccRed: GetAllRecentlyAccReducer,
  getAllBanksReducer: GetAllBanksReducer,
  getProjectCat: GetAllProjectCatReducer,
  getProject: GetAllProject,
  getsectionsubcat: GetsectionsubcatReducer,
  getsectiontype: GetsectiontypeReducer,
  getsectioncat: GetsectioncatReducer,
  getAllSections: GetAllSections,
  getAllGeneralRec: GetAllGeneralRec,
  getAllGeneralPay: GetAllGeneralPay,
  getAllJournalEntry: GetAllJournalEntry,
  getAllDimensionUnit: GetAllDimensionUnit,
  getAllMeasureUnit: GetAllMeasureUnit,
  getAllUnits: GetAllUnits,
  getAllUnitCategory: GetAllUnitCategory,
  getAllUnitTags: GetAllUnitTags,

  getAllClients: GetAllClientsReducer,
  getAllCreditors: GetAllCreditorReducer,
  getClientsdatastate: GetClientsdataReducer,
  getCreditordatastate: GetCreditordataReducer,
  getSellerdatastate: GetSellerdataReducer,
  getAllSellers: GetAllSellerReducer,
  getAllUnitPopulate: GetAllUnitReceiptRed,
  getUnitReceiptTable: GetAllUnitReceiptTableRed,
  getAllManagers: GetallManagersReducer,
  getRecoveryBookingPopulate: GetRecoveryBookingRed,
  getRecoveryBookingTable: GetAllRecoveryBookingTableRed,
  getAllLenders: GetAllLendersReducer,
  getLendersdatastate: GetLendersdataReducer,
  getAccReceivable: GetAllAccReceivable,
  getAccPayable: GetAllAccPayable,
  getRecoveryReceipt: GetRecoveryReceiptRed,
  getAllRReceiptTable: GetAllRecoveryReceiptTableRed,
  getAllLoanReceiptTable: GetAllLoanReceiptTableRed,
  getLoanReceiptPopulate: GetLoanReceiptRed,
  getLoanTable: GetAllLoanTableRed,
  getLoanPaymentPopulate: GetLoanPaymentRed,
  getLoanPaymentTable: GetAllLoanPaymentTableRed,
  getReceiptInstallTable: GetAllReceiptInstallTableRed,
  getReceiptInstallPopulate: GetReceiptInstallRed,
  getPaymentInstallTable: GetAllPaymentInstallTableRed,
  getAllLand: GetAllLandReducer,
  getAddDeals: GetAllAddDeals,
  getLandTable: GetAllLandTableRed,
  getLandPaymentPopulate: GetAllLandPaymentPopulateRed,
  getEmployeedatastate: GetEmployeedataReducer,
  getAllEmployees: GetAllEmployeesReducer,
  getProjectDataRed: GetProjectDataReducer,
  getChartOfAcc: GetChartOfAcc,
  getTrailBalance: GetTrailBalance,
  GetRepurchasedUnitsSummaryreport: GetRepurchasedUnitsSummaryreport,
  getAllCustomersRed: GetAllCustomersRed,
  getCustomersdata: GetCustomersdataReducer,
  getAffliateEdit: GetAffliateEidtReducer,
  getAffiliatesRed: GetAllAffiliatesRed,
  getAllDealSoldRed: GetAllDealSoldRed,
  getDealBoughtRed: GetAllDealBoughtRed,
  getContractPopulate: GetAllContractPopulateRed,
  getContractTable: GetContractTable,
  getContracts: GetContracts,
  getUnitPopulate: GetUnitPopulateRed,
  getCommissionPayment: GetCommissionPaymentRed,
  getAllCommissionPaymentTable: GetAllCommissionPaymentTableRed,
  getDealBoughtPayTable: GetAllDealBoughtPayTableRed,
  getDealBoughtPay: GetDealBoughtPayRed,
  getDealSoldReceipt: GetDealSoldReceiptRed,
  getAllDealSoldReceiptTable: GetAllDealSoldReceiptTableRed,
  getAccBalanceSummary: GetAccBalanceSummary,
  getAllInstallRescedulePopulate: GetAllInstallRescedulePopulateRed,
  getresceduledata: GetresceduledataReducer,
  getBalanceSheet: GetBalanceSheet,
  getProfitLoss: GetProfitLoss,
  getAllComReceiptTable: GetAllComReceiptTableRed,
  getComReceipt: GetComReceiptRed,
  getGeneralAdjTable: GetAllGeneralAdjTableRed,
  getTaxData: GetTaxDataReducer,
  getAllTaxTable: GetAllTaxReducer,
  getAllCash: GetAllCashReducer,
  getAllTaxPayable: GetAllTaxPayableReducer,
  getAllTaxPayableVoucher: GetAllTaxPayableVoucherReducer,
  GetAllTaxReceiveableVoucher: GetAllTaxReceiveableVoucherReducer,
  GetAllTaxReceivable: GetAllTaxReceivableReducer,
  getRecoveryReport: GetRecoveryReport,
  getAllNewPurchase: GetAllNewPurchase,
  getAddfilePopulate: GetAddfilePopulate,
  getRePurchaseTable: GetRePurchaseTable,
  getRePurchasePopulate: GetAllRePurchasePopulateRed,
  getEmpDesignation: GetEmpDesignation,
  getTableLeads: GetAllTableLeadRed,
  getPopulateLeads: GetAllPopulateLeadRed,
  getLeadTaskPopulate: GetAllLeadTaskPopulateRed,
  getLeadMeetingPopulate: GetAllLeadMeetingPopulateRed,
  getLeadCallPopulate: GetAllLeadCallPopulateRed,
  getLeadTasktable: GetAllLeadTasktableRed,
  getLeadMeetingTabe: GetAllLeadMeetingTabelRed,
  getLeadCallTable: GetAllLeadCallTableRed,
  getCRMAvailability: GetAllCRMAvailabilityRed,
  getallLeadsred: GetAllLeadsRed,
  getallAllowances: GetAllAllowancesReducer,
  getAllDesignation: GetAllDesignationReducer,
  getAllSalariesPopulate: GetAllSalariesPopulate,
  getAllSalariesTable: GetAllSalariesTable,
  GetAllLoanPopulate: GetAllLoanPopulate,
  GetAllLoanTable: GetAllLoanTable,
  GetAllAdvanceTable: GetAllAdvanceTable,
  GetAllHierarchy: GetAllHierarchyRed,
  GetAllTeamsPopulate: GetAllTeamsPopulate,
  GetAllTeamsTable: GetAllTeamsTable,
  GetAllMemberPopulate: GetAllMemberPopulate,
  GetAllMemberTable: GetAllMemberTable,
  GetAssetsSubcat: GetAssetsSubcatReducer,
  GetAssetsCat: GetAssetsCatReducer,
  GetdepricationMethod: GetdepricationMethodReducer,
  GetAllAssetBuyerReducer: GetAllAssetBuyerReducer,
  GetAssetBuyerdataReducer: GetAssetBuyerdataReducer,
  GetTableAssetsReducer: GetTableAssetsReducer,
  GetAllPaySlipTable: GetAllPaySlipTable,
  GetAllDeductionReducer: GetAllDeductionReducer,
  GetAssetsDepReducer: GetAssetsDepReducer,
  GetTransferContractTable: GetTransferContractTable,
  GetTransferUnitTable: GetTransferUnitTable,

  GetTransferContractPopulate: GetAllTransferContractPopulateRed,
  GetTransferUnitPopulate: GetAllTransferUnitPopulateRed,

  GetAllSalaryPaymentPopulate: GetAllSalaryPaymentPopulateRed,
  GetAllSalaryTable: GetAllSalaryTableRed,
  GetAssetsMaintenence: GetAssetsMaintenenceReducer,
  GetAssetsSell: GetAssetsSellReducer,
  GetAllRole: GetAllRole,
  GetAllRoleTable: GetAllRoleTable,
  GetInstallmentPayment: GetInstallmentPaymentRed,
  GetInstallmentReceipt: GetInstallmentReceiptRed,
  GetComPaymentReport: GetComPaymentReportRed,
  GetComReceiptReport: GetComReceiptReportRed,
  GetComPaymentSoldReport: GetComPaymentSoldReportRed,
  GetProjectsReportRed: GetProjectsReportRed,
  getAllUnSubmittedVouchers: GetAllUnSubmittedVouchers,
  getAllRevertBackVouchers: GetAllRevertBackVouchers,
  getAllRevertBackUserVouchers: GetAllRevertBackUserVouchers,
  getAllSubmittedVouchers: GetAllSubmittedVouchers,
  getallsNotification: GetAllNotification,
  getContractTablePopulate: GetContractTablePopulate,
  getContractDetails: GetContractDetailsPopulate,
  getExternalCommission: GetExternalCommissionRed,
  getCompany: GetCompany,
  getBookedUnits: GetBookedUnits,
  getRoles: GetAllRoles,
  getFetchUserData: GetFetchUserData,
  getAllRefund: GetAllRefund,
  getAllRefundPopulate: GetAllRefundPopulate,
  getAllLandById: GetAllLandByIdReducer,
  GetLandPendingAmount: GetLandPendingAmountRed,
  GetCancelPlotPayment: GetCancelPlotPaymentRed,
});
const AllReducers = (state, action) => {
  if (action.type === "CLEAR_REDUX") {
    state = undefined;
  }
  return MainReducer(state, action);
};
export default AllReducers;
