import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallProject } from "../../../actions/getprojects";
import { getallLandPayment } from "../../../actions/vouchersactions/getlandpayment";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import TaxModal from "../../TaxModal/TaxModal";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const LandPaymentForm = (props) => {
  const [vouchernumber, setvouchernumber] = useState(
    `LP-${Math.floor(Date.now())}`
  );
  const landPaymentData = useSelector((state) => state.getLandPaymentPopulate);
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const [showDetailModal, setshowDetailModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const val = location.state;  

  const resultsproject = useSelector((state) => state.getProject);
  const [stateLandPurchasedlist, setLandPurchasedlist] = useState({});
  const [stateLandNamelist, setLandName] = useState();
  const [stateamount, setamount] = useState("0");
  const [taxtotal, settaxtotal] = useState("0");
  const [allvalues, setallvalues] = useState([]);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const [checkedStates, setCheckedStates] = useState([]);
  const [advCheck, setadvCheck] = useState(false);

  const [Installments, setInstallments] = useState([]);
  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("Select Project");
    } else if (document.getElementById("creditor").selectedIndex === 0) {
      toast.info("Select Creditor");
    } else if (document.getElementById("landname").selectedIndex === 0) {
      toast.info("Select Land Name");
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else if (
      document.getElementById("total").value === "" ||
      document.getElementById("netPaid").value === ""
    ) {
      toast.info("Total and Netpaid can't be empty ");
    } else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = null;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }

      let LandPayment = {
        EntryDate: document.getElementById("Date").value,
        LandCreditorId: parseInt(
          document.getElementById("creditor").options[
            document.getElementById("creditor").selectedIndex
          ].dataset.id
        ),
        ProjectId: parseInt(
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id
        ),
        LandName:
          document.getElementById("landname").options[
            document.getElementById("landname").selectedIndex
          ].value,
        BankId: paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        // ContractId: stateunitlist.LandContractId,
        // NetPaid: parseFloat(document.getElementById("netPaid").value),
        NetPaid: parseFloat(
          document.getElementById("netPaid").value.replace(/,/g, "")
        ),

        LandPaymentVoucherId: document.getElementById("Voucher").value,
        LandPurchasedId: stateLandNamelist.LandPurchasedId,
        ChequeNumber: checkNumber,
        Narration: document.getElementById("narration").value,

        PaymentType: paymentType,
      };
      let TaxList = [];

      allvalues?.map((val, i) => {
        if (val.Checked === true) {
          TaxList.push({
            TaxId: Number(val.TaxId),
            TaxPer: Number(val.TaxPer),
            // TaxAmount: val.TaxAmount,
            // Checked: true,
            // TaxSubmitedDetailId: val.TaxSubmitedDetailId
            //   ? val.TaxSubmitedDetailId
            //   : 0,
            // ProjectId: localStorage.getItem("ProjectId"),
            // ReferenceId: "",
            // ReferenceType: "",
          });
        }
        return true;
      });
      document.getElementById("landsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Vouchers/LandPayment/Add`,
        data: JSON.stringify({
          LandPayment,

          Installments,
          TaxList,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("landsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("creditor").selectedIndex = 0;
            document.getElementById("landname").selectedIndex = 0;
            document.getElementById("chequeno").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;

            document.getElementById("total").value = "";
            document.getElementById("narration").value = "";

            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("netPaid").value = "";
            // document.getElementById("installmentcheck").checked = false;
            setLandName();

            setLandPurchasedlist({});
            if (document.getElementById("advCheck") !== null)
              document.getElementById("advCheck").checked = false;

            toast.info(result.data.Message);
            dispatch(getrecoveryreport());
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("landsave").disabled = false;
        }
      );
    }
  };
  const handleDisableBtn = () => {
    setdisableBtn(true);
  };
  useEffect(() => {
    if (val?.LandPaymentVoucherId) {
      let sum = 0;
      let PreviousSelectedTax = val?.VoucherTaxSavedDetail.map((data) => {
        sum = sum + data.TaxAmount;
        let obj = { ...data, Checked: true };
        return obj;
      });
      settaxtotal(sum);

      PreviousSelectedTax = PreviousSelectedTax?.map((value) => {
        let obj = value.Tax.TaxId;
        return { ...value, TaxId: obj };
      });
      setallvalues(PreviousSelectedTax);

      setdisableBtn(false);

      if (val?.PaymentType === "CASH") {
        // document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("vouchertype").options[1].selected = true;
        document.getElementById("bankdiv").style.display = "none";
        document.getElementById("cashdiv").style.display = "block";
      } else {
        document.getElementById("vouchertype").options[2].selected = true;
        document.getElementById("bankdiv").style.display = "block";
        document.getElementById("cashdiv").style.display = "none";
        document.getElementById("chequeno").value = val?.ChequeNumber;
        document.getElementById("banks").value = val?.BankId;
      }
      setamount(val?.NetPaid?.toString());

      document.getElementById("Date").value = val?.EntryDate?.slice(0, 10);
      document.getElementById("project").value = val?.Project?.ProjectName;
      document.getElementById("creditor").value =
        val?.LandCreditor?.LandCreditorName;

      setInstallments(val?.LandPaymentSavedInstallment);
      setvouchernumber(val?.LandPaymentVoucherId);
      let totalTax = 0;
      val?.VoucherTaxSubmitedDetail?.forEach((itm, idx) => {
        totalTax = totalTax + itm.TaxAmount;
      });
      document.getElementById("total").value = val?.NetPaid?.toString();
      document.getElementById("netPaid").value = val?.NetPaid?.toString();
      document.getElementById("taxnet").value = val?.NetPaid?.toString();
      settaxtotal(totalTax?.toString());
      const landPList = landPaymentData.data.find(
        (item) => item.LandCreditorId === val?.LandCreditor?.LandCreditorId
      );
      setLandPurchasedlist(landPList);

      // document.getElementById("unit").disabled = true;
      document.getElementById("narration").value = val?.Narration;
      // setNarration(val?.Narration);
      document.getElementById("landname").value = val?.LandName;
    }
  }, [val, resultsproject.data, landPaymentData.data, resultsallbanks.data]);
  useEffect(() => {
    if (
      val?.LandPaymentVoucherId &&
      stateLandPurchasedlist?.LandPurchased?.length > 0
    ) {
      const landList = stateLandPurchasedlist?.LandPurchased?.find(
        (item) => item.LandPurchasedId === val?.LandPurchasedId
      );
      setLandName(landList);
    }
  }, [stateLandPurchasedlist]);

  const submitdata = () => {
    setdisableBtn(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Vouchers/LandPayment/Submit?voucherId=${vouchernumber}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setdisableBtn(!disableBtn);

          document.getElementById("project").selectedIndex = 0;
          document.getElementById("creditor").selectedIndex = 0;
          document.getElementById("landname").selectedIndex = 0;
          document.getElementById("chequeno").value = "";
          document.getElementById("vouchertype").selectedIndex = 0;

          document.getElementById("total").value = "";
          document.getElementById("narration").value = "";

          document.getElementById("banks").selectedIndex = 0;
          document.getElementById("netPaid").value = "";
          // document.getElementById("installmentcheck").checked = false;
          setLandName();

          setLandPurchasedlist({});
          if (document.getElementById("advCheck") !== null)
            document.getElementById("advCheck").checked = false;

          toast.info(result.data.Message);
          dispatch(getrecoveryreport());
          // props.cancellandtable();
        } else {
          toast.info(result.data.Message);
          setdisableBtn(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        setdisableBtn(false);
      }
    );
  };
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallLandPayment());
    dispatch(getallProject());
    dispatch(getallbanks());
    dispatch(getallcash());
    dispatch(getallTaxTable());
  }, [dispatch]);
  const installmentExists = (id) =>
    val?.LandPaymentSavedInstallment?.some(
      (vouchInstallment) => id === vouchInstallment.InstallmentId
    );
  console.log("stateLandNamelist", stateLandNamelist);
  const getInitialCheckedState = () => {
    console.log("firstfirstfirstfirstfirstfirstfirst");
    const initialState = {};
    stateLandNamelist?.LandPurchasedInstallment?.forEach((installment) => {
      initialState[installment.LandPurchasedInstallmentId] =
        val?.LandPaymentSavedInstallment?.some(
          (vouchInstallment) =>
            vouchInstallment.InstallmentId ===
            installment.LandPurchasedInstallmentId
        );
    });
    setCheckedStates(initialState);
    return initialState;
  };
  useEffect(() => {
    if (val) getInitialCheckedState();
  }, [val, stateLandNamelist]);
  console.log("checkedStates", checkedStates);
  useEffect(() => {
    if (val) {
      let amt = 0;
      val?.SavedInstallment?.forEach((itm1) => {
        amt += itm1?.Amount;
      });
      if (val?.NetPaid !== amt) {
        setadvCheck(true);
      }
    }
  }, [val]);
  return (
    <>
      {landPaymentData.loading ? (
        <Loader
          type="ThreeDots"
          color="green"
          height={40}
          width={40}
        />
      ) : null}

      <div className="row ">
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <select
            onChange={handleDisableBtn}
            className="input_styling ml-md-3 "
            id="project"
          >
            <option>Select</option>
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
              parseInt(val.ProjectId) ? (
                <option
                  key={index}
                  data-id={val.ProjectId}
                >
                  {val.ProjectName}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Creditor:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            className="input_styling ml-md-3 "
            id="creditor"
            onChange={() => {
              handleDisableBtn();
              setLandPurchasedlist(
                landPaymentData.data[
                  document.getElementById("creditor").options[
                    document.getElementById("creditor").selectedIndex
                  ].dataset.idx
                ]
              );
              setLandName();
              document.getElementById("landname").selectedIndex = 0;
            }}
          >
            <option>Select</option>
            {landPaymentData.data.map((val, index) => (
              <option
                data-idx={index}
                data-id={val.LandCreditorId}
              >
                {val.LandCreditorName}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Land Name:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <select
            className="input_styling ml-md-3 "
            id="landname"
            onChange={() => {
              handleDisableBtn();
              setLandName(
                stateLandPurchasedlist.LandPurchased[
                  document.getElementById("landname").options[
                    document.getElementById("landname").selectedIndex
                  ].dataset.idx
                ]
              );
            }}
          >
            <option>Select</option>
            {stateLandPurchasedlist !== undefined &&
            stateLandPurchasedlist.LandPurchased !== undefined
              ? stateLandPurchasedlist.LandPurchased.map((x, index) => (
                  <option
                    key={index}
                    data-idx={index}
                  >
                    {x.LandName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            type="date"
            className="input_styling ml-md-3"
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
            onChange={handleDisableBtn}
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            id="vouchertype"
            onChange={(e) => {
              handleDisableBtn();
              if (e.target.value === "Bank Voucher") {
                document.getElementById("bankdiv").style.display = "block";
                document.getElementById("cashdiv").style.display = "none";
              } else if (e.target.value === "Cash Voucher") {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "block";
              } else {
                document.getElementById("bankdiv").style.display = "none";
                document.getElementById("cashdiv").style.display = "none";
              }
            }}
            type="text"
            className="input_styling ml-md-3 "
          >
            <option>Select</option>
            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <input
            type="text"
            disabled
            value={vouchernumber}
            className="input_styling ml-md-3"
            id="Voucher"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <span id="cashdiv">
        <div className="row  ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Cash:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <select
              id="cashs"
              className="input_styling  ml-md-3"
            >
              <option>Select</option>
              {resultsallcash.data.map((x) => (
                <option
                  selected
                  data-id={x.AccountId}
                >
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>

      <span
        id="bankdiv"
        style={{ display: "none" }}
      >
        <div className="row  ">
          <div className="col-md-4  p-0"></div>
          <div className="col-md-1 mt-4  p-0">
            <label className="input_label m-0 pl-md-4">Bank:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <select
              onChange={handleDisableBtn}
              id="banks"
              className="input_styling  ml-md-3"
            >
              <option>Select</option>
              {resultsallbanks.data.map((x) => (
                <option
                  data-id={x.AccountId}
                  value={x.AccountId}
                >
                  {x.AccountTitle}
                </option>
              ))}
            </select>
            <span className="estaric">*</span>
          </div>

          <div className="col-md-1 mt-4 p-0">
            <label className="input_label m-0 pl-md-4">Cheque No:</label>
          </div>
          <div className="col-md-3 mt-4  ">
            <input
              onChange={handleDisableBtn}
              id="chequeno"
              type="number"
              placeholder="Cheque No"
              className="input_styling ml-md-3"
            ></input>
            <span className="estaric">*</span>
          </div>
        </div>
      </span>
      <div className="row">
        <div className="col-md-8 mt-4 p-0"></div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Narration:</label>
        </div>
        <div className="col-md-3 mt-4  ">
          <textarea
            className="input_address  ml-md-3"
            id="narration"
            onChange={handleDisableBtn}
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="mt-4">
        <div className=" mt-4">
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th>InstallmentId</th>
                <th>Amount</th>
                <th>DueDate</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {stateLandNamelist !== undefined &&
              stateLandNamelist?.LandPurchasedInstallment?.length !== 0 ? (
                parseFloat(stateLandNamelist.Advance) -
                  parseFloat(stateLandNamelist.AdvancePaid) !==
                0 ? (
                  <tr>
                    <td>Advance</td>
                    <td>
                      {(
                        parseFloat(stateLandNamelist.Advance) -
                        parseFloat(stateLandNamelist.AdvancePaid)
                      ).toLocaleString()}
                    </td>
                    <td></td>
                    <td className="text-center">
                      <input
                        id="advCheck"
                        type="checkbox"
                        name="check"
                        checked={advCheck}
                        onClick={(e) => {
                          handleDisableBtn();
                          setadvCheck(!advCheck);
                          let amount = 0;
                          let installmentlist = [];
                          if (e.target.checked === true) {
                            installmentlist.push({
                              InstallmentId: 0,
                              Particular: "Advance payment",
                              Narration: "advance received",
                              Amount: (
                                parseFloat(stateLandNamelist.Advance) -
                                parseFloat(stateLandNamelist.AdvancePaid)
                              ).toLocaleString(),
                            });

                            amount =
                              parseFloat(stateLandNamelist.Advance) -
                              parseFloat(stateLandNamelist.AdvancePaid);
                          } else {
                            amount = 0;
                          }
                          stateLandNamelist.LandPurchasedInstallment.map(
                            (val) => {
                              if (val.checked === true) {
                                amount +=
                                  parseFloat(val.InstallmentAmount) -
                                  parseFloat(val.PaidAmount);
                                installmentlist.push(val);
                              }
                              return true;
                            }
                          );
                          document.getElementById("total").value =
                            amount.toLocaleString();
                          document.getElementById("netPaid").value =
                            amount.toLocaleString();

                          setInstallments([...installmentlist]);
                        }}
                      />
                    </td>
                  </tr>
                ) : null
              ) : null}

              {stateLandNamelist !== undefined &&
              stateLandNamelist.LandPurchasedInstallment !== undefined
                ? stateLandNamelist.LandPurchasedInstallment.map((val) =>
                    parseFloat(val.InstallmentAmount) -
                      parseFloat(val.PaidAmount) !==
                    0 ? (
                      <tr>
                        <td> {val.LandPurchasedInstallmentId}</td>
                        <td>
                          {" "}
                          {(
                            parseFloat(val.InstallmentAmount) -
                            parseFloat(val.PaidAmount)
                          ).toLocaleString()}
                        </td>
                        <td>
                          {moment(val.DueDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )}
                        </td>
                        <td className="text-center">
                          <input
                            id="installmentcheck"
                            type="checkbox"
                            name="check"
                            checked={
                              !!checkedStates[val?.LandPurchasedInstallmentId]
                            }
                            onClick={(e) => {
                              handleDisableBtn();
                              const isChecked = e.target.checked;

                              // Update the checked state for the specific checkbox
                              setCheckedStates((prevStates) => ({
                                ...prevStates,
                                [val.LandPurchasedInstallmentId]: isChecked,
                              }));
                              if (e.target.checked === true) {
                                val.checked = true;
                                e.target.checked = true;
                              } else {
                                val.checked = false;
                                e.target.checked = false;
                              }
                              let amount = 0;
                              let installmentlist = [];

                              if (
                                document.getElementById("advCheck") !== null &&
                                document.getElementById("advCheck").checked ===
                                  true
                              ) {
                                installmentlist.push({
                                  InstallmentId: 0,
                                  Particular: "Advance payment",
                                  Narration: "advance received",
                                  Amount: (
                                    parseFloat(stateLandNamelist.Advance) -
                                    parseFloat(stateLandNamelist.AdvancePaid)
                                  ).toLocaleString(),
                                });
                                amount =
                                  parseFloat(stateLandNamelist.Advance) -
                                  parseFloat(stateLandNamelist.AdvancePaid);
                              } else {
                                amount = 0;
                              }
                              stateLandNamelist.LandPurchasedInstallment?.map(
                                (val) => {
                                  if (val.checked === true) {
                                    amount +=
                                      parseFloat(val.InstallmentAmount) -
                                      parseFloat(val.PaidAmount);
                                    // installmentlist.push(val);
                                    installmentlist.push({
                                      InstallmentId:
                                        val.LandPurchasedInstallmentId,
                                      Particular: "Installment payment",
                                      Narration: "Installment received",
                                      Amount: (
                                        parseFloat(val.InstallmentAmount) -
                                        parseFloat(val.PaidAmount)
                                      ).toLocaleString(),
                                    });
                                  }
                                  return true;
                                }
                              );
                              document.getElementById("total").value =
                                amount.toLocaleString();
                              document.getElementById("netPaid").value =
                                amount.toLocaleString();
                              setInstallments([...installmentlist]);
                            }}
                          />
                        </td>
                      </tr>
                    ) : null
                  )
                : null}
              {stateLandNamelist !== undefined ? (
                stateLandNamelist.LandPurchasedInstallment?.length === 0 ? (
                  <tr>
                    <td>Other Amount</td>
                    <td>
                      {parseFloat(
                        parseFloat(stateLandNamelist.LandValue) -
                          parseFloat(stateLandNamelist.LandValuePaid)
                      )}
                    </td>
                    <td className="text-center">
                      <input
                        id="otherCheck"
                        type="checkbox"
                        name="check"
                        onClick={(e) => {
                          handleDisableBtn();
                          let amount = 0;
                          let installmentlist = [];
                          if (e.target.checked === true) {
                            installmentlist.push({
                              InstallmentId: 0,
                              Particular: "Advance payment",
                              Narration: "advance received",
                              Amount: parseFloat(
                                parseFloat(stateLandNamelist.LandValue) -
                                  parseFloat(stateLandNamelist.LandValuePaid)
                              ).toLocaleString(),
                            });

                            amount = parseFloat(
                              parseFloat(stateLandNamelist.LandValue) -
                                parseFloat(stateLandNamelist.LandValuePaid)
                            ).toLocaleString();
                          } else {
                            amount = 0;
                          }

                          // stateLandNamelist.LandPurchasedInstallment.map((val) => {
                          //     if (val.checked === true)
                          //         amount += parseFloat(val.InstallmentAmount) - parseFloat(val.PaidAmount);
                          //     installmentlist.push(val);
                          // })
                          document.getElementById("total").value = amount;
                          document.getElementById("netPaid").value = amount;

                          setInstallments([...installmentlist]);
                        }}
                      />
                    </td>
                  </tr>
                ) : null
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row  ">
        <div className="col-md-7 p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Total:</label>
        </div>
        <div className="col-md-3 mt-4    pr-0 pl-0">
          <input
            type="text "
            className="input_styling  ml-md-3"
            id="total"
            disabled
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-7 mt-3  p-0"></div>
        <div className="col-md-2 mt-2  text-left p-0">
          <label className="input_label m-0">Net Paid:</label>
        </div>
        <div className="col-md-3 mt-2   mt-3  pr-0 pl-0">
          <input
            type="text "
            className="input_styling ml-md-3"
            id="netPaid"
            onChange={(e) => {
              handleDisableBtn();
              if (e.target.value.length > 0)
                document.getElementById("netPaid").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-7 mt-3  p-0"></div>
        <div className="col-md-2 mt-3 text-left p-0">
          <label className="input_label m-0">Net Amt:</label>
        </div>
        <div className="col-md-3 mt-3    pr-0 pl-0">
          <input
            type="text"
            className="input_styling  ml-md-3 "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount?.replace(/,/g, "")) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill
              size="1em"
              className="mr-1"
            />
            Detail
          </button>
        </span>
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              if (document.getElementById("netPaid").value !== "") {
                setamount(document.getElementById("netPaid").value);
                setshowTaxmodal(true);
              }
            }}
          >
            Add Tax
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => {
              // props.cancelunittable;
              history.goBack();
            }}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2 "
            id="landsave"
            onClick={() => {
              savedata();
            }}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
        {localStorage.getItem("Designation") === "Admin" && (
          <span>
            <button
              disabled={disableBtn}
              className="save_btn ml-2  "
              id="headingsubmit"
              onClick={submitdata}
            >
              {/* <RiSave3Fill size="1.3em" /> */}
              Submit
            </button>
          </span>
        )}
      </div>
      {/* <div id="mymodal" className="modal">

                <div className="modal-content-cat">
                    <div className="row">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center pt-2">Select Taxes</h4>
                            <span
                                class="close pr-2  "
                                onClick={closemodal
                                }
                            >
                                &times;
                            </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-12  table_height pr-0 pl-0">

                            <div className="m-2 mt-4">

                                <table className="table table-borderless m-0">
                                    <thead>

                                        <tr >


                                            <th>Select</th>
                                            <th>Name</th>

                                            <th>Percentage</th>
                                            <th>Tax Amount</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resultalltaxes.data.map((val, index) => (
                                            <tr key={index}  >

                                                <td><input id={`advCheck${index}`} type="checkbox" name="check" onChange={(e) => {
                                                    resultalltaxes.data[index].Checked = e.target.checked

                                                    if (e.target.checked == true) {
                                                        settaxtotal(parseFloat(parseFloat(taxtotal.replace(/,/g, '')) + parseFloat(document.getElementById(`taxtotal${index}`).value.replace(/,/g, ''))).toLocaleString());


                                                    } else {
                                                        settaxtotal(parseFloat(parseFloat(taxtotal.replace(/,/g, '')) - parseFloat(document.getElementById(`taxtotal${index}`).value.replace(/,/g, ''))).toLocaleString());

                                                    }

                                                }} /></td>
                                                <td>{val.TaxName}</td>

                                                <td><input type="text" id={`taxper${index}`} defaultValue={val.TaxPer} onChange={(e) => {
                                                    resultalltaxes.data[index].TaxPer = e.target.value;
                                                    document.getElementById(`taxtotal${index}`).value = parseFloat((e.target.value / 100) * parseFloat(document.getElementById("total").value.replace(/,/g, ''))).toLocaleString();

                                                }}></input></td>

                                                <td><input type="text" id={`taxtotal${index}`} value={parseFloat((parseFloat(val.TaxPer) / 100) * parseFloat(stateamount.replace(/,/g, ''))).toLocaleString()} disabled></input>
                                                </td>


                                            </tr>
                                        ))}

                                    </tbody>


                                </table>
                            </div>
                            <div className="row">

                                <div className=" col-11 text-right pt-2 pr-0 pl-0">
                                    <label className="input_label m-0">  Total Tax:</label> <input type="text" value={taxtotal.toLocaleString()} disabled></input>

                                </div></div>
                        </div>
                    </div>

                </div>
            </div> */}
      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="General Payment"
        />
      ) : null}
      {showTaxmodal === true ? (
        <TaxModal
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          // vouchData={val}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
    </>
  );
};
