import { useEffect, useState } from "react";
import { useRef } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getbalancesheet } from "../../../actions/AccountReports/balancesheet";
import BalanceSheetPrint from "../ReportPrints/BalanceSheetPrint";
import moment from "moment/moment";
import { toast } from "react-toastify";

const BalanceSheet = () => {
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateLoading, setDateLoading] = useState(false);
  const [stateolddata, setstatealldata] = useState(null);
  const [statenewdata, setstatenewdata] = useState(null);

  const statealldata = useSelector((state) => state.getBalanceSheet);

  useEffect(() => {
    if (
      statealldata?.data?.OldBalanceSheet &&
      statealldata.data.NewBalanceSheet
    ) {
      sumBalances(
        statealldata.data.OldBalanceSheet !== undefined
          ? statealldata.data.OldBalanceSheet
          : null,
        statealldata.data.NewBalanceSheet !== undefined
          ? statealldata.data.NewBalanceSheet
          : null
      );
    }
  }, [statealldata]);

  let dispatch = useDispatch();
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(
      getbalancesheet(
        moment().subtract(1, "year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
  }, []);

  const handleGoClick = () => {
    const startDateMoment = moment(fromDate);
    const endDateMoment = moment(toDate);

    const differenceInDays = endDateMoment.diff(startDateMoment, "days");

    if (differenceInDays > 365) {
      toast.error(
        "Diffrence between Start Date and End Date should not be greater than 1 year"
      );
      return;
    }
    setDateLoading(true);
    dispatch(getbalancesheet(fromDate, toDate, setDateLoading));
  };
  const sumBalances = (oldSheet, newSheet) => {
    // // Iterate through CurrentAsset arrays
    // oldSheet.CurrentAsset.forEach((oldAsset, index) => {
    //   newSheet.CurrentAsset[index].Balance += oldAsset.Balance;
    // });

    // // Iterate through FixedAsset arrays
    // oldSheet.FixedAsset.forEach((oldAsset, index) => {
    //   newSheet.FixedAsset[index].Balance += oldAsset.Balance;
    // });

    // // Iterate through FixedLiabilities arrays
    // oldSheet.FixedLiabilities.forEach((oldLiability, index) => {
    //   newSheet.FixedLiabilities[index].Balance += oldLiability.Balance;
    // });

    // // Iterate through CurrentLiabilities arrays
    // oldSheet.CurrentLiabilities.forEach((oldLiability, index) => {
    //   newSheet.CurrentLiabilities[index].Balance += oldLiability.Balance;
    // });

    // // Iterate through ShareCapital arrays
    // oldSheet.ShareCapital.forEach((oldCapital, index) => {
    //   newSheet.ShareCapital[index].Balance += oldCapital.Balance;
    // });
    //
    setstatealldata(oldSheet);
    setstatenewdata(newSheet);
  };
  return (
    <>
      <div hidden>
        <BalanceSheetPrint
          ref={componentRef}
          stateolddata={stateolddata}
          statenewdata={statenewdata}
          statealldata={statealldata}
        />
      </div>
      <div className="row   app_font ">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-2 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              ></input>
            </div>
            <div className="col-md-2 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button
                className="btn_Go"
                disabled={!fromDate || !toDate || dateLoading}
                onClick={handleGoClick}
              >
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {dateLoading && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </div>
          </div>
          <div
            style={{ backgroundColor: "#CAF5F5", height: "1px" }}
            className="col-12 m-2"
          ></div>
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0">
              <label className=" m-0">
                <b>
                  GRAVITY ASSOCIATES (PRIVATE) LIMITED STATEMENT OF FINANCIAL
                  POSITION:
                </b>
              </label>
            </div>
            <div className="col-md-6 pr-0 pl-0">
              {statealldata.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>
          {/* assets */}
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0 pt-3 ">
              <h5>
                <b>Assets</b>
              </h5>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <div>
                <b>
                  {/* {statealldata.data !== undefined
                    ? statealldata.data.PastYear
                    : null} */}
                  {/* {statealldata.data?.LastOpeningDate &&
                    moment(statealldata.data?.LastOpeningDate)?.format(
                      "DD-MMM-YYYY"
                    )}{" "}
                  {statealldata.data?.LastClosingDate &&
                  statealldata.data?.LastClosingDate
                    ? "To"
                    : ""}{" "} */}
                  {statealldata.data?.LastClosingDate &&
                    moment(statealldata.data?.LastClosingDate)?.format(
                      "DD-MMM-YYYY"
                    )}
                </b>
              </div>
              <div>
                <b>Rupees</b>
              </div>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <div>
                <b>
                  {/* {statealldata.data !== undefined
                    ? statealldata.data.CurrentYear
                    : null} */}
                  {/* {statealldata.data?.CurrentOpeningDate &&
                    moment(statealldata.data?.CurrentOpeningDate)?.format(
                      "DD-MMM-YYYY"
                    )}{" "}
                  {statealldata.data?.CurrentOpeningDate &&
                  statealldata.data?.CurrentClosingDate
                    ? "To"
                    : ""}{" "} */}
                  {statealldata.data?.CurrentClosingDate &&
                    moment(statealldata.data?.CurrentClosingDate)?.format(
                      "DD-MMM-YYYY"
                    )}
                </b>
              </div>
              <div>
                <b>Rupees</b>
              </div>
            </div>
          </div>
          {/* non current assets */}
          <div className="row ">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Non Current Assets</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata?.FixedAsset !== undefined
                ? statenewdata?.FixedAsset.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.FixedAsset !== undefined
                ? stateolddata?.FixedAsset.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.FixedAsset !== undefined
                ? statenewdata?.FixedAsset.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* Current Assets */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Current Assets</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata?.CurrentAsset !== undefined
                ? statenewdata.CurrentAsset.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.CurrentAsset !== undefined
                ? stateolddata?.CurrentAsset?.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.CurrentAsset !== undefined
                ? statenewdata?.CurrentAsset?.map((val) => (
                    <div>{val.Balance?.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 text-center">
              <label className="input_label m-0">
                <b>Total Assets</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata
                ? parseFloat(
                    stateolddata?.FixedAssetSum + stateolddata?.CurrentAssetSum
                  ).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {parseFloat(
                statenewdata?.FixedAssetSum + statenewdata?.CurrentAssetSum
              ).toLocaleString()}
            </div>
          </div>
          {/* Equity and liability */}
          <div className="row pt-3">
            <div className="col-md-12 pr-0 pl-0 text-center">
              <h5>
                <b>Equity and Liability </b>
              </h5>
            </div>
            {/* <div className="col-md-3 pr-0 pl-0">

                  
                           
                        </div>
                        <div className="col-md-3 pr-0 pl-0">

       
                        </div> */}
          </div>
          {/* SHARE CAPITAL AND RESERVES */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Share Capital and Reserves</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          {/* Authorized share capital (No Financial Effect) */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Authorized share capital (No Financial Effect)</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>

          {/* share capital */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Share Capital</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata?.ShareCapital !== undefined
                ? statenewdata.ShareCapital.map((val) => <div>{val.Title}</div>)
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.ShareCapital !== undefined
                ? stateolddata.ShareCapital.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.ShareCapital !== undefined
                ? statenewdata?.ShareCapital.map((val) => (
                    <div>{val?.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Retained Earning</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statealldata?.data?.OldProfit
                ? parseFloat(statealldata.data.OldProfit).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statealldata?.data?.NewProfit
                ? parseFloat(statealldata.data.NewProfit).toLocaleString()
                : 0}
            </div>
            <div className="col-md-6 pr-0 pl-0 text-center">
              <label className="input_label m-0">
                <b> Profit / Loss for the Period</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statealldata?.data?.OldProfit
                ? parseFloat(statealldata.data.OldProfit).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statealldata?.data?.NewProfit
                ? parseFloat(statealldata.data.NewProfit).toLocaleString()
                : 0}
            </div>
          </div>
          {/* non current laibilites */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Non Current Laibilites</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata?.FixedLiabilities !== undefined
                ? statenewdata?.FixedLiabilities.map((val) => (
                    <div>{val.Title}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.FixedLiabilities !== undefined
                ? stateolddata.FixedLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.FixedLiabilities !== undefined
                ? statenewdata?.FixedLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/*  current laibilites */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              <label className="input_label m-0">
                <b>Current Laibilites</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0"></div>
            <div className="col-md-3 pr-0 pl-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0">
              {statenewdata?.CurrentLiabilities !== undefined
                ? statenewdata?.CurrentLiabilities.map((val) => (
                    <div>{val.Title}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata?.CurrentLiabilities !== undefined
                ? stateolddata?.CurrentLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {statenewdata?.CurrentLiabilities !== undefined
                ? statenewdata?.CurrentLiabilities.map((val) => (
                    <div>{val.Balance.toLocaleString()}</div>
                  ))
                : null}
            </div>
          </div>
          {/* CONTINGENCIES AND COMMITMENTS */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Contingencies And Commitments</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata
                ? parseFloat(
                    stateolddata?.FixedLiabilitiesSum +
                      stateolddata?.CurrentLiabilitiesSum
                  ).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {parseFloat(
                statenewdata?.FixedLiabilitiesSum +
                  statenewdata?.CurrentLiabilitiesSum
              ).toLocaleString()}
            </div>
          </div>
          {/* CONTINGENCIES AND COMMITMENTS */}
          <div className="row pt-3">
            <div className="col-md-6 pr-0 pl-0 ">
              <label className="input_label m-0">
                <b>Total Equities And Liabilities</b>
              </label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {stateolddata
                ? parseFloat(
                    stateolddata?.FixedLiabilitiesSum +
                      stateolddata?.CurrentLiabilitiesSum +
                      stateolddata?.ShareCapitalSum +
                      statealldata?.data.OldProfit
                  ).toLocaleString()
                : 0}
            </div>
            <div className="col-md-3 pr-0 pl-0">
              {parseFloat(
                statenewdata?.FixedLiabilitiesSum +
                  // stateolddata?.FixedLiabilitiesSum +
                  statenewdata?.CurrentLiabilitiesSum +
                  // stateolddata?.CurrentLiabilitiesSum +
                  statenewdata?.ShareCapitalSum +
                  // stateolddata?.ShareCapitalSum +
                  statealldata?.data.NewProfit
                // statealldata?.data.OldProfit
              ).toLocaleString()}
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12  text-right pr-0 pl-0">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceSheet;
