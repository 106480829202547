import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { GetAllChartOfAcc } from "../../../actions/chartofacc/getchartofacc";
import { MdExpandMore, MdChevronRight } from "react-icons/md";
import ChartofAccountModal from "./ChartofAccountModal";

const ChartofAccount = () => {
  const chartTreeView = useSelector((state) => state.getChartOfAcc);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllChartOfAcc());
  }, [dispatch]);

  const TreeNode = ({ node, level = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const indent = level * 20;

    return (
      <div className="tree-node" style={{ paddingLeft: `${indent}px` }}>
        <div className="node-label" onClick={toggleExpand}>
          {node.account || node.inverseParent ? (
            isExpanded ? <MdExpandMore /> : <MdChevronRight />
          ) : null}
          <span className="node-text">
            {node.headingIdCode || node.accountIdCode} |{" "}
            {node.headingTitle || node.accountTitle}
          </span>
          <span className="balance">
            {parseFloat(node?.balance ?? node?.currentBalance ?? 0).toLocaleString()}
          </span>
        </div>
        {isExpanded && (node.account || node.inverseParent) && (
          <div className="node-children">
            {node.account &&
              node.account.map((child) => (
                <TreeNode key={child.accountIdCode} node={child} level={level + 1} />
              ))}
            {node.inverseParent &&
              node.inverseParent.map((child) => (
                <TreeNode key={child.headingIdCode} node={child} level={level + 1} />
              ))}
          </div>
        )}
      </div>
    );
  };

  const TreeView = ({ data }) => {
    return (
      <div className="tree-view">
        {data.map((node) => (
          <TreeNode key={node.headingIdCode} node={node} />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="dash_background col-12">
          <div className="row pb-3">
            <div className="col-md-12 text-left page_heading">
              <h4 className="m-0">Chart of Account </h4>
              <ChartofAccountModal />
            </div>
          </div>
          <div>
            {chartTreeView.loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
          <div className="w-100">
            {chartTreeView.data && <TreeView data={chartTreeView.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartofAccount;