import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallHierarchy } from "../../actions/Crmactions/hierarchyaction";
import Loader from "react-loader-spinner";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";
// import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
const MainHierarchyComp = () => {
  const hierarchyData = useSelector((state) => state.GetAllHierarchy);
  let dispatch = useDispatch();
  let allDesignations = hierarchyData.data.Designations;
  let allHierarchydata = hierarchyData.data.TeamHierarchy;
  const [StateDesignationID, SetDeisgnationId] = useState(0);
  const [alltabledata, setalltabledata] = useState(allHierarchydata);
  const [toggle, settoggle] = useState(false);

  //

  // var suggestionlist1 = [];

  // const designationSelecttion = (e) => {
  //   const value = e.target.value;
  //   if (value==="") {
  //     ReactDOM.render(null, document.getElementById("unitdemo"));
  //   } else {
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");
  //       suggestions = allDesignations
  //         .sort()
  //         .filter((v) =>
  //           regex.test(v.DesignationName !== null ? v.DesignationName : null)
  //         );
  //     }
  //     suggestionlist1 = suggestions;

  //     if (suggestionlist1.length === 0) {
  //       ReactDOM.render(null, document.getElementById("unitdemo"));
  //     } else {
  //       ReactDOM.render(
  //         <ul className="suggestbox">
  //           {suggestionlist1.map((item) => (
  //             <li className="listcss" onClick={() => suggestionselected1(item)}>
  //               {item === undefined ? item : item.DesignationName}
  //             </li>
  //           ))}
  //         </ul>,
  //         document.getElementById("unitdemo")
  //       );
  //     }
  //   }
  // };
  const onTextChangeEmployeeName = () => {
    var inputValue = document.getElementById("Designation").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allDesignations.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        SetDeisgnationId(val.DesignationId);

        break;
      }
    }
  };
  // const suggestionselected1 = (val) => {
  //   document.getElementById("Designation").value = val.DesignationName;

  //   SetDeisgnationId(val.DesignationId);

  //   suggestionlist1 = [];
  //   ReactDOM.render(null, document.getElementById("unitdemo"));
  // };
  function addtoarray() {
    let check = 0;

    alltabledata.forEach((x) => {
      if (parseInt(x.DesignationId) === parseInt(StateDesignationID)) {
        // toast.info("Multiple Designation cannot be added!");
        check = 1;
      } else {
        check = 0;
      }
    });
    if (document.getElementById("Designation").value === "") {
      toast.info("Select Designation");
    } else if (check === 1) {
      toast.info(
        "Multiple Designation cannot be added. Select Other Designation"
      );
    } else {
      let listofalltabledata = {
        HierarchyLevel: alltabledata.length + 1,
        DesignationId: StateDesignationID,

        Designation: {
          DesignationName: document.getElementById("Designation").value,
          SalesTeamCommissionPercentagesShareHolderDesignation: [],
        },
      };

      setalltabledata([...alltabledata, listofalltabledata]);
      document.getElementById("Designation").selectedIndex = 0;
    }
  }
  const deleteinstallment = (idx) => {
    const rows = [...alltabledata];

    rows.splice(idx, 1);

    setalltabledata(rows);
  };
  const savedata = () => {
    let check = true;
    for (let i = 0; i < alltabledata.length; i++) {
      let sum = 0;
      for (let j = 0; j < alltabledata[i].HierarchyLevel; j++) {
        sum += parseInt(
          document.getElementById(`${alltabledata[i].DesignationId}${j}`).value
        );
      }
      if (sum !== 100) {
        check = false;
        break;
      }
    }
    if (check === false) {
      toast.info("Sum of commission percentage should be 100");
    } else {
      let data = alltabledata;
      alltabledata.map((val, index) => {
        data[
          index
        ].Designation.SalesTeamCommissionPercentagesShareHolderDesignation = [];
        alltabledata.map((val2, index2) => {
          if (index <= index2) {
            data[
              index
            ].Designation.SalesTeamCommissionPercentagesShareHolderDesignation.push(
              {
                CommissionPercentage: document.getElementById(
                  `${val2.DesignationId}${index}`
                ).value,
                ShareHolderDesignationId: val.DesignationId,
                SellerDesignationId: val2.DesignationId,
              }
            );
          }
        });
      });
      setalltabledata(data);

      document.getElementById("teamsave").disabled = true;
      axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}CRMTeams/Hierarchy/Update`,
        data: JSON.stringify({
          Hierarchies: data,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("teamsave").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            dispatch(getallHierarchy());
            // props.setadvanceedit([]);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("teamsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallHierarchy());
  }, [dispatch]);
  useEffect(() => {
    setalltabledata(hierarchyData.data.TeamHierarchy);
  }, [hierarchyData]);
  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {/* start  */}
              <div className="row ">
                <div className="col-md-1 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Designation:</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="text"
                    list="employeenamelist"
                    className="input_styling  ml-md-3"
                    placeholder="suggestion list"
                    id="Designation"
                    onChange={onTextChangeEmployeeName}
                  ></input>
                  <datalist id="employeenamelist">
                    {allDesignations?.map((x, idx) => (
                      <option index={idx} value={x.DesignationName}>
                        {x.DesignationId}
                      </option>
                    ))}
                  </datalist>
                  <span id="unitdemo"></span>
                </div>

                <div className="col-md-2 mt-md-4   p-0">
                  <button className="save_btn" onClick={addtoarray}>
                    Add
                  </button>
                </div>
                <div className="col-md-2 mt-md-4   p-0">
                  {hierarchyData.loading ? (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <div className="table-responsive mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Hierarchy Level </th>

                        <th>Designation</th>
                        <th>Dell</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alltabledata !== undefined
                        ? alltabledata.map((val, index) => (
                          <tr key={index}>
                            {/* <td>{val.HierarchyLevel}</td> */}
                            <td>{index + 1}</td>
                            <td>{val.Designation.DesignationName}</td>

                            <td
                              onClick={() => {
                                deleteinstallment(index);
                                settoggle(!toggle);
                              }}
                            >
                              <MdDeleteForever size="1.6em" />
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-4">
                <div className="table-responsive mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th></th>
                        {alltabledata !== undefined
                          ? alltabledata.map((val, index) => (
                            <th>{val.Designation.DesignationName}</th>
                          ))
                          : null}{" "}
                      </tr>
                    </thead>
                    <tbody>
                      {alltabledata !== undefined
                        ? alltabledata.map((val, index) => (
                          <tr key={index}>
                            <td>{val.Designation.DesignationName}</td>

                            {alltabledata.map((val2, index2) =>
                              index <= index2 ? (
                                <td>
                                  <input
                                    id={`${val2.DesignationId}${index}`}
                                    type="number"
                                    className="input_styling"
                                    defaultValue={
                                      val.Designation
                                        .SalesTeamCommissionPercentagesShareHolderDesignation[
                                        index2 - index
                                      ] !== undefined
                                        ? val.Designation
                                          .SalesTeamCommissionPercentagesShareHolderDesignation[
                                          index2 - index
                                        ].CommissionPercentage
                                        : 0
                                    }
                                  />
                                </td>
                              ) : (
                                <td></td>
                              )
                            )}

                            {/* {val.Designation.SalesTeamCommissionPercentagesShareHolderDesignation.map((val2, index2) =>
                                                            index <= index2 ? <td><input type="text" defaultValue={val2.CommissionPercentage} className="input_styling" /></td> : <td></td>
                                                        )} */}
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* end */}
            </div>
          </div>
          <div className=" float-right mt-md-4 ">
            <span>
              <button
                className="save_btn ml-2  "
                id="teamsave"
                onClick={savedata}
              >
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainHierarchyComp;
