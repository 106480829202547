import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload, BsFillInfoSquareFill } from "react-icons/bs";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getrecoveryreport } from "../../../actions/AccountReports/recoveryreport";
import { getallTaxTable } from "../../../actions/accountsactions/addtax";
import { getallbanks, getallcash } from "../../../actions/getbanks";
import { getallProject } from "../../../actions/getprojects";
import { getallcomissionpaymentpopulate } from "../../../actions/vouchersactions/getcompayment";
import ScreenInfoModal from "../../../ScreenInfoModal/ScreenInfoModal";
import TaxModal from "../../TaxModal/TaxModal";
import RectifiyModal from "../../Vouchers/RectifyModal/rectifiyModal";
import Loader from "react-loader-spinner";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

export const CommissionPaymentForm = () => {
  let vouchernumber = `CP-${Math.floor(Date.now())}`;
  const CommissionPaymentData = useSelector(
    (state) => state.getCommissionPayment
  );
  const history = useHistory();

  const resultsproject = useSelector((state) => state.getProject);
  const resultsallbanks = useSelector((state) => state.getAllBanksReducer);
  const resultsallcash = useSelector((state) => state.getAllCash);
  const resultalltaxes = useSelector((state) => state.getAllTaxTable);
  const [stateamount, setamount] = useState("0");
  const [updatedAmt, setupdatedAmt] = useState(null);
  const [taxtotal, settaxtotal] = useState("0");
  const [allvalues, setallvalues] = useState([]);
  const [showTaxmodal, setshowTaxmodal] = useState(false);
  const [showDetailModal, setshowDetailModal] = useState(false);
  const [CommType, setCommType] = useState("Commission Payment");
  const [loading1, setLoading1] = useState(false);

  //
  let dispatch = useDispatch();
  const [RevertModal, setRevertModal] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const [CommissionPaymentList, setCommissionPaymentList] = useState([]);
  const [CommissionPaymentEmployeeList, setCommissionPaymentEmployeeList] =
    useState([]);

  const [statecompay, setstatecompay] = useState(null);
  const location = useLocation();
  const { state } = location;
  const [vouchData, setvouchData] = useState(null);
  useEffect(() => {
    if (state?.vouchData) setvouchData(state.vouchData);
  }, [state]);
  const savedata = () => {
    if (CommType === "") {
      toast.info("Select Comm Type");
    } else if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("Select Project");
    } else if (document.getElementById("manager").value === "") {
      toast.info("Select Manager");
    } else if (document.getElementById("unit").value === "") {
      toast.info("Select Unit");
    } else if (document.getElementById("narration").value === "") {
      toast.info("Narration can't be empty");
    } else if (document.getElementById("vouchertype").selectedIndex === 0) {
      toast.info("Select Type");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Bank Voucher" &&
      (document.getElementById("banks").options[
        document.getElementById("banks").selectedIndex
      ].value === "Select" ||
        document.getElementById("chequeno").value === "")
    ) {
      toast.info("Cheque no and Bank name are Required");
    } else if (
      document.getElementById("vouchertype").options[
        document.getElementById("vouchertype").selectedIndex
      ].value === "Cash Voucher" &&
      document.getElementById("cashs").options[
        document.getElementById("cashs").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Selech Cash Account");
    } else if (document.getElementById("total").value === "") {
      toast.info("Total can't be empty ");
    } else if (
      parseFloat(document.getElementById("total").value.replace(/,/g, "")) >
      updatedAmt
    ) {
      toast.info(
        `Amount should be less than ${statecompay !== undefined
          ? parseFloat(statecompay.ComissionAmount) -
          parseFloat(statecompay.ComissionPaid)
          : null
        }`
      );
    } else {
      let paymentType =
        document.getElementById("vouchertype").options[
          document.getElementById("vouchertype").selectedIndex
        ].value;
      if (paymentType === "Bank Voucher") {
        paymentType = "Bank";
      } else {
        paymentType = "Cash";
      }
      let bankId =
        document.getElementById("banks").options[
          document.getElementById("banks").selectedIndex
        ].value;
      if (bankId === "Select") {
        bankId = null;
      } else {
        bankId =
          document.getElementById("banks").options[
            document.getElementById("banks").selectedIndex
          ].dataset.id;
      }
      let cashId =
        document.getElementById("cashs").options[
          document.getElementById("cashs").selectedIndex
        ].value;
      if (cashId === "Select") {
        cashId = 0;
      } else {
        cashId =
          document.getElementById("cashs").options[
            document.getElementById("cashs").selectedIndex
          ].dataset.id;
      }
      let checkNumber;

      if (paymentType === "Cash") {
        checkNumber = null;
      } else {
        checkNumber = document.getElementById("chequeno").value;
      }

      // let recoveryBookingId = 0;

      let landContractId = 0;
      // recoveryBookingId = statecompay.RecoveryBookingVoucherId;
      landContractId = vouchData?.Contract?.ContractId
        ? vouchData?.Contract?.ContractId
        : statecompay.ContractId;
      document.getElementById("recoverysave").disabled = true;
      setLoading1(true);
      let TaxList = [];
      allvalues?.map((val, i) => {
        if (val.Checked === true) {
          TaxList.push({
            TaxId: val.TaxId,
            TaxPer: val.TaxPer,
            TaxAmount: val.TaxAmount,
            Checked: true,
            TaxSubmitedDetailId: val.TaxSubmitedDetailId
              ? val.TaxSubmitedDetailId
              : 0,
            ProjectId: localStorage.getItem("ProjectId"),
            ReferenceId: "",
            ReferenceType: "",
          });
        }
      });

      let Voucher = {
        EntryDate: document.getElementById("Date").value,
        BankAccountId:
          paymentType === "Cash" ? parseInt(cashId) : parseInt(bankId),
        PaymentType: paymentType,
        VoucherType: paymentType === "Cash" ? "C-CPV" : "B-CPV",
        ChequeNumber: checkNumber,
        Narration: document.getElementById("narration").value,
        ContractId: landContractId,

        AffiliateCnic: individualcheck === true ? affiliateEmployeeCnic : null,
        EmployeeId: individualcheck !== true ? affiliateEmployeeCnic : null,
        Amount: parseFloat(
          document.getElementById("total").value.replace(/,/g, "")
        ),
        ProjectId: parseInt(
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id
        ),
        UnitId: parseInt(unitid),
        // ReceivedAmount: parseFloat(document.getElementById("total").value),
        CommissionPaymentVoucherId: document.getElementById("Voucher").value,
        // RecoveryBookingId: recoveryBookingId,
        CommissionType: CommType,
      };
      document.getElementById("recoverysave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Vouchers/CommissionPayment/Add`,
        data: JSON.stringify({
          Voucher,
          TaxList,
          OldCommissionAmount: vouchData?.Amount ? vouchData?.Amount : null,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("recoverysave").disabled = false;
          setLoading1(false);

          if (result.data.IsSuccess === true) {
            setCommType("Commission Payment");
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("unit").value = "";
            document.getElementById("manager").value = "";
            document.getElementById("banks").selectedIndex = 0;
            document.getElementById("chequeno").value = "";
            document.getElementById("vouchertype").selectedIndex = 0;

            document.getElementById("total").value = "";
            document.getElementById("narration").value = "";

            dispatch(getallcomissionpaymentpopulate());

            toast.info(result.data.Message);
            dispatch(getrecoveryreport());
            // props.cancelunittable;
            history.goBack();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("recoverysave").disabled = false;
          setLoading1(false);
        }
      );
      // }
    }
  };
  const submitdata = () => {
    setdisableBtn(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL
        }Vouchers/CommissionPayment/Submit/New?voucherId=${document.getElementById("Voucher").value
        }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setCommType("Commission Payment");
          document.getElementById("project").selectedIndex = 0;
          document.getElementById("unit").value = "";
          document.getElementById("manager").value = "";
          document.getElementById("banks").selectedIndex = 0;
          document.getElementById("chequeno").value = "";
          document.getElementById("vouchertype").selectedIndex = 0;

          document.getElementById("total").value = "";
          document.getElementById("narration").value = "";

          dispatch(getallcomissionpaymentpopulate());

          toast.info(result.data.Message);
          dispatch(getrecoveryreport());
        } else {
          toast.info(result.data.Message);
          setdisableBtn(false);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        setdisableBtn(false);
      }
    );
  };
  useEffect(() => {
    if (vouchData?.CommissionPaymentVoucherId) {
      let sum = 0;
      let PreviousSelectedTax = vouchData.VoucherTaxSubmitedDetail.map(
        (data) => {
          sum = sum + data.TaxAmount;
          let obj = { ...data, Checked: true };
          return obj;
        }
      );
      settaxtotal(sum);

      PreviousSelectedTax = PreviousSelectedTax.map((value) => {
        let obj = value.Tax.TaxId;
        return { ...value, TaxId: obj };
      });
      setallvalues(PreviousSelectedTax);
      document.getElementById("manager").disabled = true;
      document.getElementById("unit").disabled = true;
      document.getElementById("commtype").disabled = true;

      setdisableBtn(false);
      if (vouchData?.PaymentType === "CASH") {
        document.getElementById("vouchertype").options[1].selected = true;
        document.getElementById("unit").value = vouchData?.Unit?.UnitName;
        for (
          let index = 0;
          index < document.getElementsByClassName("bankdiv")?.length;
          index++
        ) {
          document.getElementsByClassName("bankdiv")[index].style.display =
            "none";
        }
        // document.getElementById("bankdiv").style.display = "none";
        // document.getElementsByClassName("cashdiv").style.display = "block";
        document.getElementsByClassName("cashdiv")[0].style.display = "block";
        document.getElementsByClassName("cashdiv")[1].style.display = "block";

        // document.getElementById("Account").value = vouchData?.DebitAccount.AccountTitle;
      } else {
        document.getElementById("vouchertype").options[2].selected = true;
        // document.getElementById("bankdiv").style.display = "block";
        for (
          let index = 0;
          index < document.getElementsByClassName("bankdiv")?.length;
          index++
        ) {
          document.getElementsByClassName("bankdiv")[index].style.display =
            "block";
        }
        // document.getElementsByClassName("cashdiv").style.display = "none";
        document.getElementsByClassName("cashdiv")[0].style.display = "none";
        document.getElementsByClassName("cashdiv")[1].style.display = "none";
        document.getElementById("chequeno").value = vouchData?.ChequeNumber;
        document.getElementById("banks").value =
          vouchData?.BankAccount.AccountTitle;
        // document.getElementById("Account").value = vouchData?.DebitAccount.AccountTitle;
      }
      setunitid(vouchData?.Unit?.UnitId);
      document.getElementById("unit").value = vouchData?.Unit?.UnitName;
      document.getElementById("project").value = vouchData?.ProjectId;
      document.getElementById("Voucher").value =
        vouchData?.CommissionPaymentVoucherId;
      document.getElementById("Date").value = vouchData?.EntryDate?.slice(
        0,
        10
      );
      if (
        CommissionPaymentData?.data?.Data &&
        vouchData?.Unit?.UnitId &&
        vouchData?.AffiliateCnic
      ) {
        let findObj = CommissionPaymentData?.data?.Data?.find(
          (itm) => itm.AffiliateCnic === vouchData?.AffiliateCnic
        );

        if (findObj) {
          let findUnit = findObj?.Contract?.find(
            (itm) => itm.UnitId === vouchData?.Unit?.UnitId
          );

          if (findUnit) {
            if (findUnit?.CommissionAmountNew) {
              setupdatedAmt(findUnit?.CommissionAmountNew + vouchData?.Amount);
            } else {
              setupdatedAmt(vouchData?.Amount);
            }
          } else {
            setupdatedAmt(vouchData?.Amount);
          }
        }
      }
      document.getElementById("total").value =
        vouchData?.Amount?.toLocaleString();
      document.getElementById("narration").value = vouchData?.Narration;
      setAffiliateEmployeeCnic(
        vouchData?.AffiliateCnicNavigation?.AffiliateCnic
      );
      if (vouchData?.Employee?.EmpName === null) {
        setindividualcheck(true);
        document.getElementById("manager").value =
          vouchData?.AffiliateCnicNavigation?.AffiliateName;

        setAffiliateEmployeeCnic(vouchData?.AffiliateCnic);
      } else {
        setindividualcheck(false);
        document.getElementById("manager").value =
          vouchData?.Employee?.EmployeeName;
        setAffiliateEmployeeCnic(vouchData?.Employee?.EmployeeCnic);
      }
      let totalTax = 0;
      vouchData?.VoucherTaxSubmitedDetail?.forEach((itm, idx) => {
        totalTax = totalTax + itm.TaxAmount;
      });
      setamount(vouchData?.Amount?.toString());
      settaxtotal(parseInt(totalTax)?.toString());
    }
  }, [vouchData, CommissionPaymentData]);
  // useEffect(() => {
  //   if (vouchData?.VoucherTaxSubmitedDetail) {
  //     vouchData.VoucherTaxSubmitedDetail?.forEach((item) => {
  //       resultalltaxes.data?.forEach((item2) => {
  //         if (item.TaxId === item2.TaxId) {
  //           item2.Checked = true;
  //           item2.TaxPer = item.TaxPer;
  //           item2.TaxAmount = item.TaxAmount;
  //           item2.TaxSubmitedDetailId = item.TaxSubmitedDetailId;

  //         }

  //       })
  //     })
  //   }
  // }, [resultalltaxes]);

  useEffect(() => {
    const CommissionType = CommType;
    if (statecompay) {
      if (individualcheck === true) {
        if (
          CommissionType === "Commission Payment" &&
          statecompay !== undefined &&
          statecompay.ComissionAmount !== undefined &&
          statecompay.Unit !== undefined
        ) {
          let tamt = 0;
          if (statecompay.Unit.DealBoughtId === null) {
            tamt =
              parseFloat(statecompay.ComissionAmount) +
              parseFloat(statecompay.AdditionalMarginAmount) -
              parseFloat(statecompay.CommissionAndMarginPaid);
            setamount(tamt);
            setupdatedAmt(tamt);
          } else {
            tamt =
              parseFloat(statecompay.ComissionAmount) -
              parseFloat(statecompay.CommissionAndMarginPaid);
            setamount(tamt ? tamt : 0);
            setupdatedAmt(tamt);
          }

          document.getElementById("total").value = tamt.toLocaleString();
        } else if (
          CommissionType === "Admin Share Payable" &&
          statecompay !== undefined &&
          statecompay.AdminShareAmount !== undefined &&
          statecompay.Unit !== undefined
        ) {
          let tamt = statecompay.AdminShareAmount
            ? parseFloat(statecompay.AdminShareAmount)
            : 0;
          document.getElementById("total").value = tamt.toLocaleString();
          setupdatedAmt(tamt);
          setamount(tamt);
        } else if (
          CommissionType === "Reserved Commission" &&
          statecompay !== undefined &&
          statecompay.ReservedCommissionAmount !== undefined &&
          statecompay.Unit !== undefined
        ) {
          let tamt = statecompay.ReservedCommissionAmount
            ? parseFloat(statecompay.ReservedCommissionAmount)
            : 0;
          document.getElementById("total").value = tamt.toLocaleString();
          setupdatedAmt(tamt);
          setamount(tamt);
        } else {
          document.getElementById("total").value = 0;
          setamount(0);
        }
      } else if (individualcheck === false) {
        if (
          statecompay !== undefined &&
          statecompay.CommissionPayable !== undefined &&
          statecompay.Contract !== undefined
        ) {
          let tamt = 0;

          tamt =
            parseFloat(statecompay.CommissionPayable) -
            parseFloat(statecompay.CommissionAmountPaid);
          setupdatedAmt(tamt);

          document.getElementById("total").value = tamt.toLocaleString();
        }
      }
    } else {
      setamount("0");
    }
  }, [statecompay, CommType]);
  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallcomissionpaymentpopulate());
    dispatch(getallbanks());
    dispatch(getallcash());
    dispatch(getallTaxTable());
  }, [dispatch]);

  const [individualcheck, setindividualcheck] = useState(true);
  // const [showhide, setshowhide] = useState(true);
  const [affiliateEmployeeCnic, setAffiliateEmployeeCnic] = useState();
  const [unitid, setunitid] = useState();

  const onAffiliateDatalistChange = () => {
    handleDisableBtn();
    var inputValue = document.getElementById("manager").value;
    var options = document.getElementById("affiliatelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        CommissionPaymentData?.data?.Data?.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
            setAffiliateEmployeeCnic(val?.AffiliateCnic);
            setCommissionPaymentList(val?.Contract);
          }
        });
        break;
      }
    }
  };

  const onEmployeeDatalistChange = () => {
    handleDisableBtn();

    var inputValue = document.getElementById("manager").value;
    var options = document.getElementById("affiliatelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        CommissionPaymentData.data.Employees.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
            setAffiliateEmployeeCnic(val?.EmployeeCnic);
            setCommissionPaymentEmployeeList(val?.SalesTeamCommissionPayables);
          }
        });
        break;
      }
    }
  };

  const onUnitDatalistChange = () => {
    handleDisableBtn();

    var inputValue = document.getElementById("unit").value;
    var options = document.getElementById("unitlist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        CommissionPaymentList.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
            setstatecompay(val);
            individualcheck
              ? setunitid(val.Unit.UnitId)
              : setunitid(val.Contract.Unit.UnitId);
          }
        });
        break;
      }
      setstatecompay(null);
      individualcheck ? setunitid(null) : setunitid(null);
      document.getElementById("total").value = "0";
    }
  };
  const handleDisableBtn = () => {
    setdisableBtn(true);
    // if (vouchData?.CommissionPaymentVoucherId && localStorage.getItem("UserName") === "Admin") {

    // }
  };

  return (
    <>
      <div className="row ">
        {resultalltaxes?.loading ||
          resultsallcash?.loading ||
          resultsallbanks?.loading ||
          resultsproject?.loading ||
          CommissionPaymentData?.loading ? (
          <div className="col-md-12 pr-0 pl-0 text-right">
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          </div>
        ) : null}

        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Affliate</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              id="affiliatetype"
              name="affiliateType"
              checked={individualcheck}
              onChange={handleDisableBtn}
              onClick={() => {
                // document.getElementById("showhide").style.display = "none";
                // document.getElementById("showhide2").style.display = "block";
                // document.getElementById("cityshowhide").style.display = "block";
                setindividualcheck(true);
                document.getElementById("total").value = "";
                document.getElementById("manager").selectedIndex = 0;
                document.getElementById("unit").selectedIndex = 0;
              }}
            ></input>
          </span>
        </div>
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Employee</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              name="employeeType"
              id="employeeproject"
              onChange={handleDisableBtn}
              checked={!individualcheck}
              onClick={() => {
                setindividualcheck(false);
                document.getElementById("total").value = "";
                document.getElementById("manager").selectedIndex = 0;
                document.getElementById("unit").selectedIndex = 0;

                // document.getElementById("showhide2").style.display = "none";
                // document.getElementById("showhide").style.display = "block";
              }}
            ></input>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4 ">Comm Type:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            id="commtype"
            type="text"
            disabled
            className="input_styling ml-md-3  "
            value={CommType}
            onChange={(e) => {
              setCommType(e.target.value);
              handleDisableBtn(e);
            }}
          >
            <option value="">Select</option>
            <option value="Commission Payment">Commission Payment</option>
            <option value="Admin Share Payable">Admin Share Payable</option>
            <option value="Reserved Commission">Reserved Commission</option>
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_styling ml-md-3 "
            onChange={handleDisableBtn}
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
          ></input>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Voucher No:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            disabled
            value={
              vouchData?.CommissionPaymentVoucherId
                ? vouchData?.CommissionPaymentVoucherId
                : vouchernumber
            }
            className="input_styling ml-md-3 "
            id="Voucher"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            onChange={handleDisableBtn}
            className="input_styling ml-md-3 "
            id="project"
          >
            <option>Select</option>
            {resultsproject.data.map((val, index) =>
              parseInt(`${localStorage.getItem("ProjectId")}`) ===
                parseInt(val.ProjectId) ? (
                <option
                  key={index}
                  value={val.ProjectId}
                  data-id={val.ProjectId}
                >
                  {val.ProjectName}
                </option>
              ) : null
            )}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          {individualcheck === true ? (
            <label className="input_label m-0 pl-md-4">Affiliate:</label>
          ) : (
            <label className="input_label m-0 pl-md-4">Employee:</label>
          )}
        </div>
        <div className="col-md-3 mt-4">
          {individualcheck === true ? (
            <>
              <input
                type="text"
                className="input_styling ml-md-3"
                list="affiliatelist"
                id="manager"
                onChange={onAffiliateDatalistChange}
              />
              <datalist id="affiliatelist">
                {CommissionPaymentData?.data?.Data?.map((val, index) => (
                  <option index={index} value={val?.AffiliateName}>
                    {val.AffiliateCnic}
                  </option>
                ))}
              </datalist>
              {/* <select
                  className="input_styling ml-md-3 "
                  id="manager"
                  onChange={() => {
                    setCommissionPaymentList(
                      CommissionPaymentData.data.Data[
                        document.getElementById("manager").options[
                          document.getElementById("manager").selectedIndex
                        ].dataset.idx
                      ] !== undefined
                        ? CommissionPaymentData.data.Data[
                            document.getElementById("manager").options[
                              document.getElementById("manager").selectedIndex
                            ].dataset.idx
                          ].Contract
                        : []
                    );
                  }}
                >
                  <option>Select</option>
                  {CommissionPaymentData.data.Data !== undefined
                    ? CommissionPaymentData.data.Data.map((val, index) => (
                        <option data-idx={index} data-id={val.AffiliateCnic}>
                          {val.AffiliateName !== undefined
                            ? val.AffiliateName
                            : null}
                        </option>
                      ))
                    : null}
                </select>
                <span className="estaric">*</span> */}
            </>
          ) : (
            <>
              <input
                type="text"
                className="input_styling ml-md-3"
                list="affiliatelist"
                id="manager"
                onChange={onEmployeeDatalistChange}
              />
              <datalist id="affiliatelist">
                {CommissionPaymentData.data.Employees?.map((val, index) => (
                  <option index={index} value={val?.EmpName}>
                    {val.EmployeeId}
                  </option>
                ))}
              </datalist>
              {/* <select
                  className="input_styling ml-md-3 "
                  id="manager"
                  onChange={() => {
                    setCommissionPaymentEmployeeList(
                      CommissionPaymentData.data.Employees[
                        document.getElementById("manager").options[
                          document.getElementById("manager").selectedIndex
                        ].dataset.idx
                      ] !== undefined
                        ? CommissionPaymentData.data.Employees[
                            document.getElementById("manager").options[
                              document.getElementById("manager").selectedIndex
                            ].dataset.idx
                          ].SalesTeamCommissionPayables
                        : []
                    );
                  }}
                >
                  <option>Select</option>
                  {CommissionPaymentData.data.Employees !== undefined
                    ? CommissionPaymentData.data.Employees.map((val, index) => (
                        <option data-idx={index} data-id={val.EmployeeId}>
                          {val.EmpName !== undefined ? val.EmpName : null}
                        </option>
                      ))
                    : null}
                </select>
                <span className="estaric">*</span> */}
            </>
          )}
        </div>
        {/* <div className="col-md-1 mt-4 p-0" hidden={showhide}>
                      <label className="input_label m-0 pl-md-4" >Employee:</label>
                  </div>
                  <div className="col-md-3 mt-4" >
                     
                  </div> */}
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Unit:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          {individualcheck === true ? (
            <>
              <input
                type="text"
                className="input_styling ml-md-3"
                list="unitlist"
                id="unit"
                onChange={onUnitDatalistChange}
              />
              <datalist id="unitlist">
                {CommissionPaymentList?.map((val, index) => (
                  <option index={index} value={val?.Unit.UnitName}>
                    {val?.Unit?.UnitId}
                  </option>
                ))}
              </datalist>
              {/* <select
                  className="input_styling ml-md-3 "
                  id="unit"
                  onChange={() => {
                    setstatecompay(
                      CommissionPaymentList[
                        document.getElementById("unit").options[
                          document.getElementById("unit").selectedIndex
                        ].dataset.idx
                      ]
                    );
                  }}
                >
                  <option>Select</option>
                  {CommissionPaymentList !== undefined &&
                  CommissionPaymentList !== undefined
                    ? CommissionPaymentList.map((x, index) => (
                        <option
                          key={index}
                          data-idx={index}
                          data-id={x.Unit.UnitId}
                        >
                          {x.Unit.UnitName}
                        </option>
                      ))
                    : null}
                </select>
                <span className="estaric">*</span> */}
            </>
          ) : (
            <>
              <input
                type="text"
                className="input_styling ml-md-3"
                list="unitlist"
                id="unit"
                onChange={onUnitDatalistChange}
              />
              <datalist id="unitlist">
                {CommissionPaymentEmployeeList?.map((val, index) => (
                  <option index={index} value={val?.Contract?.Unit?.UnitName}>
                    {val?.Contract?.Unit?.UnitId}
                  </option>
                ))}
              </datalist>
              {/* <select
                  className="input_styling ml-md-3 "
                  id="unit"
                  onChange={() => {
                    setstatecompay(
                      CommissionPaymentEmployeeList[
                        document.getElementById("unit").options[
                          document.getElementById("unit").selectedIndex
                        ].dataset.idx
                      ]
                    );
                  }}
                >
                  <option>Select</option>
                  {CommissionPaymentEmployeeList !== undefined &&
                  CommissionPaymentEmployeeList !== undefined
                    ? CommissionPaymentEmployeeList.map((x, index) => (
                        <option
                          key={index}
                          data-idx={index}
                          data-id={x.Contract.Unit.UnitId}
                        >
                          {x.Contract.Unit.UnitName}
                        </option>
                      ))
                    : null}
                </select>
                <span className="estaric">*</span> */}
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4 ">Type:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select
            id="vouchertype"
            onChange={(e) => {
              handleDisableBtn();

              if (e.target.value === "Bank Voucher") {
                // document.getElementsByClassName("bankdiv").style.display = "block";
                for (
                  let index = 0;
                  index < document.getElementsByClassName("bankdiv")?.length;
                  index++
                ) {
                  document.getElementsByClassName("bankdiv")[
                    index
                  ].style.display = "block";
                }
                for (
                  let index = 0;
                  index < document.getElementsByClassName("cashdiv").length;
                  index++
                ) {
                  document.getElementsByClassName("cashdiv")[
                    index
                  ].style.display = "none";
                }
              } else if (e.target.value === "Cash Voucher") {
                // document.getElementById("bankdiv").style.display = "none";

                for (
                  let index = 0;
                  index < document.getElementsByClassName("bankdiv")?.length;
                  index++
                ) {
                  document.getElementsByClassName("bankdiv")[
                    index
                  ].style.display = "none";
                }
                for (
                  let index = 0;
                  index < document.getElementsByClassName("cashdiv").length;
                  index++
                ) {
                  document.getElementsByClassName("cashdiv")[
                    index
                  ].style.display = "block";
                }
              } else {
                // document.getElementById("bankdiv").style.display = "none";
                for (
                  let index = 0;
                  index < document.getElementsByClassName("bankdiv")?.length;
                  index++
                ) {
                  document.getElementsByClassName("bankdiv")[
                    index
                  ].style.display = "none";
                }
                for (
                  let index = 0;
                  index < document.getElementsByClassName("cashdiv").length;
                  index++
                ) {
                  document.getElementsByClassName("cashdiv")[
                    index
                  ].style.display = "none";
                }
              }
            }}
            type="text"
            className="input_styling ml-md-3  "
          >
            <option>Select</option>
            <option selected>Cash Voucher</option>
            <option>Bank Voucher</option>
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 p-0 cashdiv">
          <label className="input_label m-0 pl-md-4">Cash:</label>
        </div>
        <div className="col-md-3 mt-4 cashdiv">
          <select
            id="cashs"
            onChange={handleDisableBtn}
            className="input_styling ml-md-3  "
          >
            <option>Select</option>
            {resultsallcash.data.map((x) => (
              <option selected data-id={x.AccountId}>
                {x.AccountTitle}
              </option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        {/* <div  className="col-md-4 mt-4 p-0 m-0" id="cashdiv">
          <div  className="row m-0 p-0" >
            <div className="col-md-4 pr-0 m-0" >
              <label className="input_label m-0 pl-md-2">Cash:</label>
            </div>
            <div   className="col-md-8 p-0 m-0" >
              <select
                id="cashs"
                onChange={handleDisableBtn}
                className="input_styling"
              >
                <option>Select</option>
                {resultsallcash.data.map((x) => (
                  <option selected data-id={x.AccountId}>
                    {x.AccountTitle}
                  </option>
                ))}
              </select>
              <span className="estaric">*</span>
            </div>
          </div>
        </div>  */}

        {/* <div
          className="col-md-8 mt-4 p-0"
          id="bankdiv"
          style={{ display: "none" }}
        > */}
        <div style={{ display: "none" }} className="col-md-1 mt-4 p-0 bankdiv">
          <label className="input_label m-0 pl-md-4">Bank:</label>
        </div>
        <div style={{ display: "none" }} className="col-md-3 mt-4 bankdiv">
          <select
            onChange={handleDisableBtn}
            id="banks"
            className="input_styling  ml-md-3"
          >
            <option>Select</option>
            {resultsallbanks.data.map((x) => (
              <option data-id={x.AccountId}>{x.AccountTitle}</option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
        <div style={{ display: "none" }} className="col-md-1 mt-4 p-0 bankdiv">
          <label className="input_label m-0 pl-md-4"> Cheque No::</label>
        </div>
        <div style={{ display: "none" }} className="col-md-3 mt-4 bankdiv">
          <input
            onChange={handleDisableBtn}
            id="chequeno"
            type="text"
            placeholder="Cheque No"
            className="input_styling ml-md-3 "
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="row ">
          {/* <div className="col-md-2">
              <label className="input_label m-0 pl-md-3 pr-md-0">Bank:</label>
            </div>
            <div className="col-md-4 p-0">
              <select
                onChange={handleDisableBtn}
                id="banks"
                className="input_styling  ml-md-0"
              >
                <option>Select</option>
                {resultsallbanks.data.map((x) => (
                  <option data-id={x.AccountId}>{x.AccountTitle}</option>
                ))}
              </select>
              <span className="estaric">*</span>
            </div> */}

          {/* <div className="col-md-2">
              <label className="input_label m-0 pl-md-3 pr-md-0">
                Cheque No:
              </label>
            </div>
            <div className="col-md-4 p-0">
              <input
                onChange={handleDisableBtn}
                id="chequeno"
                type="text"
                placeholder="Cheque No"
                className="input_styling ml-md-0 "
              ></input>
              <span className="estaric">*</span>
            </div> */}
        </div>
        {/* </div> */}
      </div>

      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Amount:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            disabled={
              statecompay || vouchData?.CommissionPaymentVoucherId
                ? false
                : true
            }
            type="text "
            className="input_styling ml-md-3 "
            id="total"
            // disabled
            onChange={(e) => {
              handleDisableBtn();
              if (
                e.target.value &&
                e.target.value !== "" &&
                Number(e.target.value.replace(/,/g, "")) <= updatedAmt
              ) {
                let taxAmt = 0;
                resultalltaxes.data?.forEach((item2) => {
                  if (item2.Checked === true) {
                    item2.TaxAmount = parseInt(
                      (item2.TaxPer / 100) *
                      parseInt(e.target.value.replace(/,/g, ""))
                    );
                    taxAmt =
                      taxAmt +
                      parseInt(
                        (item2.TaxPer / 100) *
                        parseInt(e.target.value.replace(/,/g, ""))
                      );
                  }
                });
                settaxtotal(taxAmt?.toString());
                if (e.target.value.length > 0) {
                  document.getElementById("total").value = parseInt(
                    e.target.value.replace(/,/g, "")
                  ).toLocaleString();
                }
                if (e.target.value) {
                  document.getElementById("taxnet").value = (
                    e.target.value.replace(/,/g, "") - taxtotal
                  ).toLocaleString();
                  settaxtotal(taxtotal);
                }
              } else {
                settaxtotal(taxtotal);

                if (Number(e.target.value.replace(/,/g, "")) > updatedAmt) {
                  toast.info(
                    `Amount must be less than or equal to ${updatedAmt}`
                  );
                }
                document.getElementById("taxnet").value = "0";
                e.target.value = "0";
              }
              // handleDisableBtn();

              //
              //

              // settaxtotal(taxtotal?.toString());

              // else{

              // }
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Net Amt:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            className="input_styling  ml-md-3 "
            disabled
            id="taxnet"
            value={parseFloat(
              parseFloat(stateamount) - parseFloat(taxtotal)
            ).toLocaleString()}
          ></input>
        </div>
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Narration:</label>
        </div>
        <div className="col-md-3 mt-4">
          <textarea
            onChange={handleDisableBtn}
            className="input_address ml-md-3"
            id="narration"
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className=" float-right mt-md-4 mr-md-3">
        {loading1 && (
          <Loader type="ThreeDots" color="green" height={40} width={40} />
        )}
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              setshowDetailModal(true);
            }}
          >
            <BsFillInfoSquareFill size="1em" className="mr-1" />
            Detail
          </button>
        </span>
        {localStorage.getItem("UserName") === "Admin" && vouchData && (
          <span>
            <button
              className="save_btn ml-2  "
              id="headingsave"
              onClick={() => setRevertModal(true)}
            >
              <RiArrowGoBackFill size="1em" />
              Revert
            </button>
          </span>
        )}
        <span>
          <button
            className="save_btn ml-2  "
            onClick={() => {
              if (document.getElementById("total").value !== "") {
                setamount(
                  document.getElementById("total").value?.replace(/,/g, "")
                );
                setshowTaxmodal(true);
              }
            }}
          >
            Add Tax
          </button>
        </span>
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => {
              // props.cancelunittable;
              history.goBack();
            }}
          >
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2 "
            id="recoverysave"
            disabled={!disableBtn}
            onClick={() => {
              savedata();
            }}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
          {localStorage.getItem("UserName") === "Admin" && (
            <span>
              <button
                disabled={disableBtn}
                className="save_btn ml-2  "
                id="commSubmit"
                onClick={submitdata}
              >
                {/* <RiSave3Fill size="1.3em" /> */}
                Submit
              </button>
            </span>
          )}
        </span>
      </div>
      {/* <div id="mymodal" className="modal">
          <div className="modal-content-cat">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Select Taxes</h4>
                <span class="close pr-2  " onClick={closemodal}>
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className=" col-12  table_height pr-0 pl-0">
                <div className="m-2 mt-4">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Name</th>
  
                        <th>Percentage</th>
                        <th>Tax Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultalltaxes.data.map((val, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              id={`advCheck${index}`}
                              type="checkbox"
                              name="check"
                              checked={val.Checked ? val.Checked : false}
                              onChange={(e) => {
                                handleDisableBtn()
                                resultalltaxes.data[index].Checked =
                                  e.target.checked;
                                resultalltaxes.data[index].TaxAmount = 0;
  
                                if (e.target.checked===true) {
                                  resultalltaxes.data[index].TaxAmount = parseFloat(
                                    document
                                      .getElementById(`taxtotal${index}`)
                                      .value.replace(/,/g, "")
                                  )
                                  settaxtotal(
                                    parseFloat(
                                      parseFloat(taxtotal.replace(/,/g, "")) +
                                      parseFloat(
                                        document
                                          .getElementById(`taxtotal${index}`)
                                          .value.replace(/,/g, "")
                                      )
                                    ).toLocaleString()
                                  );
                                } else {
                                  settaxtotal(
                                    parseFloat(
                                      parseFloat(taxtotal.replace(/,/g, "")) -
                                      parseFloat(
                                        document
                                          .getElementById(`taxtotal${index}`)
                                          .value.replace(/,/g, "")
                                      )
                                    ).toLocaleString()
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>{val.TaxName}</td>
  
                          <td>
                            <input
                              type="text"
                              id={`taxper${index}`}
                              defaultValue={val.TaxPer}
                              onChange={(e) => {
                                handleDisableBtn()
                                resultalltaxes.data[index].TaxPer =
                                  e.target.value;
                                resultalltaxes.data[index].TaxAmount =
                                  parseFloat(
                                    (e.target.value / 100) *
                                    parseFloat(
                                      document
                                        .getElementById("total")
                                        .value.replace(/,/g, "")
                                    )
                                  )
                                document.getElementById(
                                  `taxtotal${index}`
                                ).value = parseFloat(
                                  (e.target.value / 100) *
                                  parseFloat(
                                    document
                                      .getElementById("total")
                                      .value.replace(/,/g, "")
                                  )
                                ).toLocaleString();
                              }}
                            ></input>
                          </td>
  
                          <td>
                            <input
                              type="text"
                              id={`taxtotal${index}`}
                              value={parseFloat(
                                (parseFloat(val.TaxPer) / 100) *
                                parseFloat(stateamount.replace(/,/g, ""))
                              ).toLocaleString()}
                              disabled
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className=" col-11 text-right pt-2 pr-0 pl-0">
                    <label className="input_label m-0"> Total Tax:</label>{" "}
                    <input
                      type="text"
                      value={taxtotal.toLocaleString()}
                      disabled
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {showDetailModal ? (
        <ScreenInfoModal
          setshowModal={setshowDetailModal}
          screenName="Commission Payment"
        />
      ) : null}
      {showTaxmodal === true ? (
        <TaxModal
          enableSaveBtn={handleDisableBtn}
          setshowTaxmodal={setshowTaxmodal}
          stateamount={stateamount}
          taxtotal={taxtotal}
          settaxtotal={settaxtotal}
          vouchData={vouchData}
          allvalues={allvalues}
          setallvalues={setallvalues}
        />
      ) : null}
      {RevertModal && (
        <RectifiyModal
          entryId={document.getElementById("Voucher").value}
          entryType={"Commission Payment"}
          addedById={vouchData.AddedById}
          setshowModal={setRevertModal}
        />
      )}
    </>
  );
};
