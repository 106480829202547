import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ data }) => {
  const labelsArray = [];
  const valuesArray = [];

  // Separate the arrays
  for (let i = 0; i < data.length; i++) {
    labelsArray.push(data[i][0]);
    valuesArray.push(data[i][1]);
  }
  if (labelsArray.length > 0 && valuesArray.length > 0) {
    labelsArray.shift();
    valuesArray.shift();
  }

  const options = {
    chart: {
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
    labels: labelsArray,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart" style={{ paddingTop: 20 }}>
      <ReactApexChart
        options={options}
        series={valuesArray}
        type="pie"
        height={425}
      />
    </div>
  );
};

export default PieChart;
