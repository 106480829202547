import { useEffect } from "react";
import { useState } from "react";

import Loader from "react-loader-spinner";

const AvailableModal = ({ data }) => {
  const [filterdata, setfilterdata] = useState([]);
  // print function

  const onTextChange = (e) => {
    if (
      document.getElementById("namefilter").value === "" &&
      document.getElementById("statusfilter").value === "" &&
      document.getElementById("contractstatusfilter").value === ""
    ) {
      setfilterdata(data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("namefilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("statusfilter").value,
          "i"
        );

        suggestions = data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("namefilter").value === "" ||
                regex.test(val.UnitName)) &&
              (document.getElementById("statusfilter").value === "" ||
                regex1.test(val.Status))
          );
      }
      setfilterdata(suggestions);
    }
  };

  useEffect(() => {
    if (data) setfilterdata(data);
  }, [data]);

  return (
    <>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="namefilter"
                onChange={onTextChange}
                placeholder="Unit Name"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="statusfilter"
                onChange={onTextChange}
                placeholder="Status"
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {filterdata !== undefined ? (
                filterdata.loading ? (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                ) : null
              ) : null}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr.</th>

                    <th>Unit Name </th>
                    <th>Status</th>
                    <th>Dimension </th>
                    <th>Area</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val?.UnitName}</td>
                          <td>{val?.Status}</td>
                          <td>
                            {val?.DimensionLength}x{val.DimensionWidth}
                          </td>
                          <td>
                            {val?.Marla}M{val?.Sarsai}S
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableModal;
