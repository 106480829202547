import React, { Component } from "react";
import moment from "moment";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

class LandInstallTrackerPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
        // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Land Installment Track</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr</th>
                      <th scope="col">Land Creditor</th>
                      <th scope="col">SQA #</th>
                      <th scope="col">Net Marla</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Paid Amount</th>
                      <th scope="col">Rem. Amount</th>
                      <th scope="col">Installment | Due Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props?.Data !== undefined
                      ? this.props?.Data?.map((x, id) => (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td style={{ maxWidth: "350px" }}>
                            {x?.LandCreditor?.LandCreditorName}
                          </td>
                          <td>{x?.SqaNumber}</td>
                          <td>{x?.NetMarla}</td>{" "}
                          <td>{x?.LandValue?.toLocaleString()}</td>
                          <td>{x?.LandValuePaid?.toLocaleString()}</td>
                          <td>
                            {(
                              x?.LandValue - x?.LandValuePaid
                            )?.toLocaleString()}
                          </td>
                          <td>
                            <ol
                              type="i"
                              style={{
                                // listStyleType: "none",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {x?.LandPurchasedInstallment?.map(
                                (land, idx) => (
                                  <li key={idx}>
                                    {(
                                      land?.InstallmentAmount -
                                      land?.PaidAmount
                                    )?.toLocaleString() +
                                      " | " +
                                      moment(land?.DueDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                  </li>
                                )
                              )}
                            </ol>
                          </td>
                          {/* <td>
                              <GrPrint
                                className="reportcommissiondetails"
                                onClick={() => {
                                  setModalData(x);
                                }}
                              />
                            </td> */}
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandInstallTrackerPrint;
