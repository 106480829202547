import React, { Component } from "react";
// import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class AffiliatesPrint extends Component {
  render() {

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
        // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Affiliates</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>CNIC/Reg</th>

                      <th>Contact No.</th>
                      <th>City</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData.data !== undefined
                      ? this.props.printData.data.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.AffiliateName}</td>
                          <td>{val.AffiliateEmail}</td>
                          <td>{val.AffiliateCnic}</td>

                          <td>{val.AffiliateContactNo1}</td>
                          <td>{val.AffiliateCity}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AffiliatesPrint;