import React, { Component } from "react";
// import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

class DealBoughtPrint extends Component {
  render() {

    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
        // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Deals Bought</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th> Deal Name</th>
                      <th>Project </th>

                      <th> Seller</th>
                      <th>Total Amt.</th>
                      <th>Deal Amt.</th>

                      <th>Amt Per</th>
                      <th>Comm Per</th>

                      <th>Profit Mrg Per</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.printData.data !== undefined
                      ? this.props.printData.data.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{val.DealName}</td>
                          <td>
                            {val.Project !== undefined &&
                              val.Project !== null
                              ? val.Project.ProjectName
                              : null}
                          </td>
                          <td>
                            {val.Seller !== undefined &&
                              val.Seller !== null
                              ? val.Seller.SellerName
                              : null}
                          </td>
                          <td>
                            {parseFloat(val.TotalAmount).toLocaleString()}
                          </td>
                          <td>
                            {parseFloat(val.DealAmount).toLocaleString()}
                          </td>
                          <td>{val.DealAmountPer}</td>
                          <td>{val.CommissionPer}</td>
                          <td>{val.ProfitMarginPer}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DealBoughtPrint;