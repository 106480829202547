import React, { useEffect, useState } from "react";
import Reserved from "../../../assets/house.png";
import brokerPic from "../../../assets/contract.png";
import { GrUserManager } from "react-icons/gr";
import { getallProject } from "../../actions/getprojects";
import { useSelector, useDispatch } from "react-redux";
import { GiCash } from "react-icons/gi";
import axios from "axios";
import { toast } from "react-toastify";
import PieChart from "../../Accounts/DashboardPage/pieChart";
const CrmDashboard = () => {
  const resultsproject = useSelector((state) => state.getProject);
  const [stateprojectid, setprojectid] = useState(0);
  const [newplotcount, setnewplotcount] = useState([]);
  const [statesectionunits, setsectionunits] = useState([]);
  const [stateSectionList, setSectionList] = useState([]);
  const [sectionwiseunits, setsectionwiseunits] = useState([]);
  console.log(sectionwiseunits);
  const [resultsalldashbuttons, setresultsalldashbuttons] = useState({});
  const [resultsallpending, setresultsallpending] = useState([]);
  let dispatch = useDispatch();
  function getdashboardbuttons() {
    axios({
      url: `${process.env.REACT_APP_API_URL}CRMReports/DashBoardButtons`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setresultsalldashbuttons(result.data);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  function getpendingtasks() {
    axios({
      url: `${process.env.REACT_APP_API_URL}CRMReports/PendingTasks`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setresultsallpending(result.data.PendingTasks);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  const sectionunits = (id) => {
    axios({
      method: "get",
      // url: `${process.env.REACT_APP_API_URL}Reports/Inventory/SectionWiseUnits?SectionId=${document.getElementById("sectionname").options[document.getElementById("sectionname").selectedIndex].dataset.id !== undefined ? document.getElementById("sectionname").options[document.getElementById("sectionname").selectedIndex].dataset.id : 0}`,
      url: `${process.env.REACT_APP_API_URL
        }Reports/Inventory/SectionWiseUnits?SectionId=${id === undefined ? 0 : id
        }`,

      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(
      (result) => {
        setsectionunits(result.data.Data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };
  // const datapie = {
  //   maintainAspectRatio: false,
  //   responsive: false,
  //   labels: ["Sold", "Available", "Reserved"],
  //   datasets: [
  //     {
  //       data: [
  //         newplotcount !== undefined ? newplotcount.Sold : 0,
  //         newplotcount !== undefined ? newplotcount.Avaiable : 0,
  //         newplotcount !== undefined ? newplotcount.Reserved : 0,
  //         newplotcount !== undefined ? newplotcount.DealReserved : 0,
  //       ],
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(75,192,192,0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(75,192,192,1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(54, 162, 235, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   maintainAspectRatio: false,
  // };
  const pieChartData = [
    newplotcount?.Sold || 0,
    newplotcount?.Avaiable || 0,
    newplotcount?.Reserved || 0,
    newplotcount?.DealReserved || 0,
  ];
  const getunitscount = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}Reports/Inventory/PiChart?ProjectId=${stateprojectid}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(
      (result) => {
        setnewplotcount(result.data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };
  const sectionwisenoofunits = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}Reports/Inventory/SectionWiseNoOfUnits?ProjectId=${stateprojectid}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setsectionwiseunits(result.data.Data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };
  useEffect(() => {
    getunitscount();
    sectionunits();
    sectionwisenoofunits();
  }, [stateSectionList, stateprojectid]);
  useEffect(() => {
    getdashboardbuttons();
    getpendingtasks();
  }, []);
  useEffect(() => {
    dispatch(getallProject());
  }, [dispatch]);
  return (
    <>
      <div className="row mr-2">
        <div className="col-12 p-0">
          <div className="row">
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div className="cur_sor">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {" "}
                    {""}
                    {Math.round(
                      resultsalldashbuttons.NoOfMeetings
                    ).toLocaleString()}{" "}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(75,192,192,1)" }}
                  >
                    No. of Meetings
                  </div>
                  <div className="text-center pb-2">
                    <GrUserManager size="1.4em" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div className="cur_sor">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {" "}
                    {""}
                    {Math.round(
                      resultsalldashbuttons.TokenUnits
                    ).toLocaleString()}{" "}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(255, 99, 132, 1)" }}
                  >
                    Token Units
                  </div>
                  <div className="text-center  pb-2">
                    <GiCash size="1.6em" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div className="cur_sor">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {" "}
                    {""}
                    {Math.round(
                      resultsalldashbuttons.ReservedPlots
                    ).toLocaleString()}{" "}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(255, 206, 86, 1)" }}
                  >
                    Reserved Units
                  </div>
                  <div className="text-center  pb-2">
                    <img src={Reserved} alt="Loan" style={{ width: "28px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div className="cur_sor">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {" "}
                    {""}
                    {Math.round(
                      resultsalldashbuttons.HotLeads
                    ).toLocaleString()}{" "}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(54, 162, 235, 1)" }}
                  >
                    Hot Deals
                  </div>
                  <div className="text-center  pb-2">
                    <img src={brokerPic} alt="Loan" style={{ width: "25px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <div className="row ">
                  <div className="col-md-12 mt-1 pl-2 text-center p-0">
                    <h4
                      className="input_label m-0 "
                      style={{ fontSize: "20px", color: "gray" }}
                    >
                      Leads Report
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <div className="row ">
                  <div className="col-md-12 mt-1  text-center p-0">
                    <h4
                      className="input_label m-0 "
                      style={{ fontSize: "20px", color: "gray" }}
                    >
                      Pending Tasks For Today
                    </h4>
                  </div>
                  <div className="table-responsive mt-1">
                    <table className="table table-borderless table-hover m-0">
                      <thead>
                        <tr>
                          <th style={{ fontSize: ".9em" }}>Sr</th>

                          <th style={{ fontSize: ".9em" }}>TaskFor</th>
                          <th style={{ fontSize: ".9em" }}>Type</th>
                          <th style={{ fontSize: ".9em" }}>SubType</th>
                          <th style={{ fontSize: ".9em" }}>Priority</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resultsallpending !== undefined
                          ? resultsallpending.map((val, index) => (
                            <tr key={index}>
                              <td style={{ fontSize: ".8em" }}>
                                {index + 1}
                              </td>

                              <td style={{ fontSize: ".8em" }}>
                                {val.TaskFor.EmpName}
                              </td>
                              <td style={{ fontSize: ".8em" }}>
                                {val.TaskType.TypeName}
                              </td>
                              <td style={{ fontSize: ".8em" }}>
                                {val.TaskSubType.SubTypeName}
                              </td>

                              <td style={{ fontSize: ".8em" }}>
                                {val.Priority}
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="customcard mt-2"></div>
              </div>
            </div>

            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <div className="row ">
                  <div className="col-md-4 mt-1 pl-2 text-left p-0">
                    <label className="input_label m-0 ">Project Name:</label>
                  </div>
                  <div className="col-md-6 mt-1 p-0">
                    <select
                      id="projectname"
                      className="input_styling  ml-md-3"
                      onChange={(e) => {
                        if (e.target.value !== "Select") {
                          setSectionList(
                            resultsproject.data[
                            document.getElementById("projectname").options[
                              document.getElementById("projectname")
                                .selectedIndex
                            ].dataset.idx
                            ]
                          );
                          setprojectid(
                            document.getElementById("projectname").options[
                              document.getElementById("projectname")
                                .selectedIndex
                            ].dataset.id
                          );
                          document.getElementById(
                            "sectionname"
                          ).selectedIndex = 0;
                        }
                      }}
                    >
                      <option>Select</option>
                      {resultsproject.data.map((x, index) => (
                        <option
                          data-id={x.ProjectId}
                          data-idx={index}
                          key={index}
                        >
                          {x.ProjectName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <Doughnut data={datapie} options={options} /> */}
                <PieChart
                  data={pieChartData}
                  labels={["Sold", "Available", "Reserved", "Deal Reserved"]}
                  colors={[
                    "rgba(255, 99, 132, 1)",
                    "rgba(75,192,192,1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(54, 162, 235, 1)",
                  ]}
                  height={260}
                />
              </div>
            </div>
            <div className=" col-md-6">
              <div className="dashboardtoprow pl-2">
                <div className="row ">
                  <div className="col-md-2 mt-1  text-left p-0">
                    <label className="input_label m-0 ">Section:</label>
                  </div>
                  <div className="col-md-6 mt-1  p-0">
                    <select
                      id="sectionname"
                      className="input_styling  ml-md-3"
                      onChange={() => {
                        sectionunits(
                          document.getElementById("sectionname").options[
                            document.getElementById("sectionname").selectedIndex
                          ].dataset.id
                        );
                      }}
                    >
                      <option>Select</option>
                      {stateSectionList !== undefined &&
                        stateSectionList.Sections !== undefined
                        ? stateSectionList.Sections.map((x, index) => (
                          <option
                            data-id={x.SectionId}
                            data-idx={index}
                            key={index}
                          >
                            {x.SectionName}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="customcard mt-2">
                  {statesectionunits !== undefined
                    ? statesectionunits.map((x) => (
                      <div className="customcardcontainer">
                        <h6>{x.Unit.UnitName}</h6>

                        <span>{x.Unit.Status}</span>
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrmDashboard;
