import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import Loader from "react-loader-spinner";
import { getAllContractTable } from "../../../actions/accountsactions/addcontract";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function UpdateNominee({ nomineeData, setshowModal, updateData}) {
  const [loading, setloading] = useState(false);
  const [formData, setformData] = useState({
    NomineeName: "",
    NomineeGuardianName: "",
    NomineeCountry: "",
    NomineeCity: "",
    NomineeCnic: "",
    NomineeCellNoOne: "",
    NomineeAddress: "",
  });
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setformData({ ...formData, [name]: value });
  };
  function convertFirstLetterCapital(str) {
    // Convert the entire string to lowercase
    str = str.toLowerCase();

    // Split the string into an array of words
    var words = str.split(" ");

    // Capitalize the first letter of each word
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    // Join the words back into a single string
    var result = words.join(" ");

    return result;
  }

  const [isFile, setIsFile] = useState(true);
  const path = window.location.pathname;
  const lastSegment = path.split("/").filter(Boolean).pop();

  useEffect(() => {
    if (lastSegment == "without-unit") {
      setIsFile(true);
    } else {
      setIsFile(false);
    }
  }, [path]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (nomineeData) {
      setformData({
        NomineeName: nomineeData?.NomineeName,
        NomineeGuardianName: nomineeData?.NomineeGuardianName,
        NomineeCountry: nomineeData?.NomineeCountry
          ? convertFirstLetterCapital(nomineeData?.NomineeCountry)
          : "",
        NomineeCity: nomineeData?.NomineeCity
          ? convertFirstLetterCapital(nomineeData?.NomineeCity)
          : "",
        NomineeCnic: nomineeData?.NomineeCnic,
        NomineeCellNoOne: nomineeData?.NomineeCellNoOne,
        NomineeAddress: nomineeData?.NomineeAddress?.toLowerCase(),
      });
    }
  }, [nomineeData]);

  const handleUpdateNominee = () => {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");

    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    if (formData.NomineeName === "" || !formData.NomineeName) {
      toast.info("Please Enter Name");
      return;
    }
    if (formData.NomineeCnic === "" || !formData.NomineeCnic) {
      toast.info("Please Enter Cnic");
      return;
    }
    if (formData.NomineeCellNoOne === "" || !formData.NomineeCellNoOne) {
      toast.info("Please Enter Cell");
      return;
    }
    if (formData.NomineeGuardianName === "" || !formData.NomineeGuardianName) {
      toast.info("Please Enter Guradian Name");
      return;
    }
    if (formData.NomineeAddress === "" || !formData.NomineeAddress) {
      toast.info("Please Enter Address");
      return;
    }
    if (formData.NomineeCountry === "" || !formData.NomineeCountry) {
      toast.info("Please Select Country");
      return;
    }
    if (formData.NomineeCity === "" || !formData.NomineeCity) {
      toast.info("Please Select City");
      return;
    }
    if (!regexp.test(formData.NomineeCnic)) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
      return;
    }
    if (!regexphone.test(formData.NomineeCellNoOne)) {
      toast.info("Invalid Cell. Format should be like xxxx-xxxxxxx");
      return;
    }
    setloading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Contracts/Nomineeupdate`,
      data: {
        ...formData,
        NomineeId: nomineeData?.NomineeId,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.IsSuccess === true) {
          toast.info(res.data.Message);
          dispatch(
            getAllContractTable({
              ProjectId: updateData?.ProjectId,
              isFile: isFile,
            })
          );
          setshowModal(false);
        } else {
          toast.info(res.data.Message);
        }
        setloading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setloading(false);
      });
  };
  return (
    <div className="container">
      <div className="row py-4">
        <div className="col-md-6 pb-2 row">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">Name :</label>
          </div>
          <div className="p-1 col-md-8">
            <input
              type="text"
              name="NomineeName"
              value={formData.NomineeName}
              className="input_styling ml-md-3"
              onChange={handleChange}
            />
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="col-md-6 row pb-2">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">Cnic :</label>
          </div>
          <div className="p-1 col-md-8">
            <input
              placeholder="xxxxx-xxxxxxx-x"
              type="text"
              value={formData.NomineeCnic}
              name="NomineeCnic"
              className="input_styling ml-md-3"
              onChange={handleChange}
            />
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="col-md-6 pb-2 row">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">Cell No :</label>
          </div>
          <div className="p-1 col-md-8">
            <input
              type="text"
              name="NomineeCellNoOne"
              value={formData.NomineeCellNoOne}
              className="input_styling ml-md-3"
              onChange={handleChange}
              placeholder="xxxx-xxxxxxx"
            />
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="col-md-6 pb-2 row">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">Guardian Name :</label>
          </div>
          <div className="p-1 col-md-8">
            <input
              type="text"
              value={formData.NomineeGuardianName}
              name="NomineeGuardianName"
              className="input_styling ml-md-3"
              onChange={handleChange}
            />
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="col-md-6 pb-2 row">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">Country :</label>
          </div>
          <div className="p-1 col-md-8">
            <select
              className="input_styling ml-md-3"
              value={formData.NomineeCountry}
              onChange={handleChange}
              name="NomineeCountry"
            >
              <option value={""}>Select</option>

              <option value="Pakistan">Pakistan</option>
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="col-md-6 pb-2 row">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">City :</label>
          </div>
          <div className="p-1 col-md-8">
            <select
              className="input_styling ml-md-3"
              value={formData.NomineeCity}
              onChange={handleChange}
              name="NomineeCity"
            >
              <option value={""}>Select</option>

              <option value="Islamabad">Islamabad</option>
              <option
                value=""
                disabled
              >
                Punjab Cities
              </option>
              <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
              <option value="Ahmadpur East">Ahmadpur East</option>
              <option value="Ali Khan Abad">Ali Khan Abad</option>
              <option value="Alipur">Alipur</option>
              <option value="Arifwala">Arifwala</option>
              <option value="Attock">Attock</option>
              <option value="Bhera">Bhera</option>
              <option value="Bhalwal">Bhalwal</option>
              <option value="Bahawalnagar">Bahawalnagar</option>
              <option value="Bahawalpur">Bahawalpur</option>
              <option value="Bhakkar">Bhakkar</option>
              <option value="Burewala">Burewala</option>
              <option value="Chillianwala">Chillianwala</option>
              <option value="Chakwal">Chakwal</option>
              <option value="Chichawatni">Chichawatni</option>
              <option value="Chiniot">Chiniot</option>
              <option value="Chishtian">Chishtian</option>
              <option value="Daska">Daska</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
              <option value="Dhaular">Dhaular</option>
              <option value="Dina">Dina</option>
              <option value="Dinga">Dinga</option>
              <option value="Dipalpur">Dipalpur</option>
              <option value="Faisalabad">Faisalabad</option>
              <option value="Ferozewala">Ferozewala</option>
              <option value="Fateh Jhang">Fateh Jang</option>
              <option value="Ghakhar Mandi">Ghakhar Mandi</option>
              <option value="Gojra">Gojra</option>
              <option value="Gujranwala">Gujranwala</option>
              <option value="Gujrat">Gujrat</option>
              <option value="Gujar Khan">Gujar Khan</option>
              <option value="Hafizabad">Hafizabad</option>
              <option value="Haroonabad">Haroonabad</option>
              <option value="Hasilpur">Hasilpur</option>
              <option value="Haveli Lakha">Haveli Lakha</option>
              <option value="Jatoi">Jatoi</option>
              <option value="Jalalpur">Jalalpur</option>
              <option value="Jattan">Jattan</option>
              <option value="Jampur">Jampur</option>
              <option value="Jaranwala">Jaranwala</option>
              <option value="Jhang">Jhang</option>
              <option value="Jhelum">Jhelum</option>
              <option value="Kalabagh">Kalabagh</option>
              <option value="Karor Lal Esan">Karor Lal Esan</option>
              <option value="Kasur">Kasur</option>
              <option value="Kamalia">Kamalia</option>
              <option value="Kamoke">Kamoke</option>
              <option value="Khanewal">Khanewal</option>
              <option value="Khanpur">Khanpur</option>
              <option value="Kharian">Kharian</option>
              <option value="Khushab">Khushab</option>
              <option value="Kot Addu">Kot Addu</option>
              <option value="Jauharabad">Jauharabad</option>
              <option value="Lahore">Lahore</option>
              <option value="Lalamusa">Lalamusa</option>
              <option value="Layyah">Layyah</option>
              <option value="Liaquat Pur">Liaquat Pur</option>
              <option value="Lodhran">Lodhran</option>
              <option value="Malakwal">Malakwal</option>
              <option value="Mamoori">Mamoori</option>
              <option value="Mailsi">Mailsi</option>
              <option value="Mandi Bahauddin">Mandi Bahauddin</option>
              <option value="Mian Channu">Mian Channu</option>
              <option value="Mianwali">Mianwali</option>
              <option value="Multan">Multan</option>
              <option value="Murree">Murree</option>
              <option value="Muridke">Muridke</option>
              <option value="Mianwali Bangla">Mianwali Bangla</option>
              <option value="Muzaffargarh">Muzaffargarh</option>
              <option value="Narowal">Narowal</option>
              <option value="Nankana Sahib">Nankana Sahib</option>
              <option value="Okara">Okara</option>
              <option value="Renala Khurd">Renala Khurd</option>
              <option value="Pakpattan">Pakpattan</option>
              <option value="Pattoki">Pattoki</option>
              <option value="Pir Mahal">Pir Mahal</option>
              <option value="Qaimpur">Qaimpur</option>
              <option value="Qila Didar Singh">Qila Didar Singh</option>
              <option value="Rabwah">Rabwah</option>
              <option value="Raiwind">Raiwind</option>
              <option value="Rajanpur">Rajanpur</option>
              <option value="Rahim Yar Khan">Rahim Yar Khan</option>
              <option value="Rawalpindi">Rawalpindi</option>
              <option value="Sadiqabad">Sadiqabad</option>
              <option value="Safdarabad">Safdarabad</option>
              <option value="Sahiwal">Sahiwal</option>
              <option value="Sangla Hill">Sangla Hill</option>
              <option value="Sarai Alamgir">Sarai Alamgir</option>
              <option value="Sargodha">Sargodha</option>
              <option value="Shakargarh">Shakargarh</option>
              <option value="Sheikhupura">Sheikhupura</option>
              <option value="Sialkot">Sialkot</option>
              <option value="Sohawa">Sohawa</option>
              <option value="Soianwala">Soianwala</option>
              <option value="Siranwali">Siranwali</option>
              <option value="Talagang">Talagang</option>
              <option value="Taxila">Taxila</option>
              <option value="Toba Tek Singh">Toba Tek Singh</option>
              <option value="Vehari">Vehari</option>
              <option value="Wah Cantonment">Wah Cantonment</option>
              <option value="Wazirabad">Wazirabad</option>
              <option
                value=""
                disabled
              >
                Sindh Cities
              </option>
              <option value="Badin">Badin</option>
              <option value="Bhirkan">Bhirkan</option>
              <option value="Rajo Khanani">Rajo Khanani</option>
              <option value="Chak">Chak</option>
              <option value="Dadu">Dadu</option>
              <option value="Digri">Digri</option>
              <option value="Diplo">Diplo</option>
              <option value="Dokri">Dokri</option>
              <option value="Ghotki">Ghotki</option>
              <option value="Haala">Haala</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Islamkot">Islamkot</option>
              <option value="Jacobabad">Jacobabad</option>
              <option value="Jamshoro">Jamshoro</option>
              <option value="Jungshahi">Jungshahi</option>
              <option value="Kandhkot">Kandhkot</option>
              <option value="Kandiaro">Kandiaro</option>
              <option value="Karachi">Karachi</option>
              <option value="Kashmore">Kashmore</option>
              <option value="Keti Bandar">Keti Bandar</option>
              <option value="Khairpur">Khairpur</option>
              <option value="Kotri">Kotri</option>
              <option value="Larkana">Larkana</option>
              <option value="Matiari">Matiari</option>
              <option value="Mehar">Mehar</option>
              <option value="Mirpur Khas">Mirpur Khas</option>
              <option value="Mithani">Mithani</option>
              <option value="Mithi">Mithi</option>
              <option value="Mehrabpur">Mehrabpur</option>
              <option value="Moro">Moro</option>
              <option value="Nagarparkar">Nagarparkar</option>
              <option value="Naudero">Naudero</option>
              <option value="Naushahro Feroze">Naushahro Feroze</option>
              <option value="Naushara">Naushara</option>
              <option value="Nawabshah">Nawabshah</option>
              <option value="Nazimabad">Nazimabad</option>
              <option value="Qambar">Qambar</option>
              <option value="Qasimabad">Qasimabad</option>
              <option value="Ranipur">Ranipur</option>
              <option value="Ratodero">Ratodero</option>
              <option value="Rohri">Rohri</option>
              <option value="Sakrand">Sakrand</option>
              <option value="Sanghar">Sanghar</option>
              <option value="Shahbandar">Shahbandar</option>
              <option value="Shahdadkot">Shahdadkot</option>
              <option value="Shahdadpur">Shahdadpur</option>
              <option value="Shahpur Chakar">Shahpur Chakar</option>
              <option value="Shikarpaur">Shikarpaur</option>
              <option value="Sukkur">Sukkur</option>
              <option value="Tangwani">Tangwani</option>
              <option value="Tando Adam Khan">Tando Adam Khan</option>
              <option value="Tando Allahyar">Tando Allahyar</option>
              <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
              <option value="Thatta">Thatta</option>
              <option value="Umerkot">Umerkot</option>
              <option value="Warah">Warah</option>
              <option
                value=""
                disabled
              >
                Khyber Cities
              </option>
              <option value="Abbottabad">Abbottabad</option>
              <option value="Adezai">Adezai</option>
              <option value="Alpuri">Alpuri</option>
              <option value="Akora Khattak">Akora Khattak</option>
              <option value="Ayubia">Ayubia</option>
              <option value="Banda Daud Shah">Banda Daud Shah</option>
              <option value="Bannu">Bannu</option>
              <option value="Batkhela">Batkhela</option>
              <option value="Battagram">Battagram</option>
              <option value="Birote">Birote</option>
              <option value="Chakdara">Chakdara</option>
              <option value="Charsadda">Charsadda</option>
              <option value="Chitral">Chitral</option>
              <option value="Daggar">Daggar</option>
              <option value="Dargai">Dargai</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ismail Khan">Dera Ismail Khan</option>
              <option value="Doaba">Doaba</option>
              <option value="Dir">Dir</option>
              <option value="Drosh">Drosh</option>
              <option value="Hangu">Hangu</option>
              <option value="Haripur">Haripur</option>
              <option value="Karak">Karak</option>
              <option value="Kohat">Kohat</option>
              <option value="Kulachi">Kulachi</option>
              <option value="Lakki Marwat">Lakki Marwat</option>
              <option value="Latamber">Latamber</option>
              <option value="Madyan">Madyan</option>
              <option value="Mansehra">Mansehra</option>
              <option value="Mardan">Mardan</option>
              <option value="Mastuj">Mastuj</option>
              <option value="Mingora">Mingora</option>
              <option value="Nowshera">Nowshera</option>
              <option value="Paharpur">Paharpur</option>
              <option value="Pabbi">Pabbi</option>
              <option value="Peshawar">Peshawar</option>
              <option value="Saidu Sharif">Saidu Sharif</option>
              <option value="Shorkot">Shorkot</option>
              <option value="Shewa Adda">Shewa Adda</option>
              <option value="Swabi">Swabi</option>
              <option value="Swat">Swat</option>
              <option value="Tangi">Tangi</option>
              <option value="Tank">Tank</option>
              <option value="Thall">Thall</option>
              <option value="Timergara">Timergara</option>
              <option value="Tordher">Tordher</option>
              <option
                value=""
                disabled
              >
                Balochistan Cities
              </option>
              <option value="Awaran">Awaran</option>
              <option value="Barkhan">Barkhan</option>
              <option value="Chagai">Chagai</option>
              <option value="Dera Bugti">Dera Bugti</option>
              <option value="Gwadar">Gwadar</option>
              <option value="Harnai">Harnai</option>
              <option value="Jafarabad">Jafarabad</option>
              <option value="Jhal Magsi">Jhal Magsi</option>
              <option value="Kacchi">Kacchi</option>
              <option value="Kalat">Kalat</option>
              <option value="Kech">Kech</option>
              <option value="Kharan">Kharan</option>
              <option value="Khuzdar">Khuzdar</option>
              <option value="Killa Abdullah">Killa Abdullah</option>
              <option value="Killa Saifullah">Killa Saifullah</option>
              <option value="Kohlu">Kohlu</option>
              <option value="Lasbela">Lasbela</option>
              <option value="Lehri">Lehri</option>
              <option value="Loralai">Loralai</option>
              <option value="Mastung">Mastung</option>
              <option value="Musakhel">Musakhel</option>
              <option value="Nasirabad">Nasirabad</option>
              <option value="Nushki">Nushki</option>
              <option value="Panjgur">Panjgur</option>
              <option value="Pishin Valley">Pishin Valley</option>
              <option value="Quetta">Quetta</option>
              <option value="Sherani">Sherani</option>
              <option value="Sibi">Sibi</option>
              <option value="Sohbatpur">Sohbatpur</option>
              <option value="Washuk">Washuk</option>
              <option value="Zhob">Zhob</option>
              <option value="Ziarat">Ziarat</option>
            </select>
            <span className="estaric">*</span>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="p-1 col-md-4">
            <label className="input_label m-0 ">Address :</label>
          </div>
          <div className="p-1 col-md-8">
            <textarea
              name="NomineeAddress"
              value={formData.NomineeAddress}
              onChange={handleChange}
              className="input_address ml-md-3 "
            ></textarea>
            <span className="estaric">*</span>
          </div>
        </div>
      </div>
      <div className="row pr-md-2 pt-md-4 m-0 p-0">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div className=" col-6 pr-0 pl-0  text-right ">
          {loading && (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          )}

          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => setshowModal(false)}
            >
              Cancel
            </button>
          </span>
          <span>
            <button
              className="save_btn ml-2  "
              id="instasatn"
              disabled={!loading ? false : true}
              onClick={() => {
                handleUpdateNominee();
              }}
            >
              <BsCloudUpload size="1.3em" />
              Update
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default UpdateNominee;
