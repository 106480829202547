import { useState } from "react";
import { TaxReceivableForm } from "./TaxReceivableForm";
import { TaxReceivableTable } from "./TaxReceivableTable";

const TaxReceivableMain = () => {
    const [statetaxList, settaxList] = useState();
    const [statetaxList2, settaxList2] = useState();
    const showtaxtable = () => {
        stateshowtax("TaxPayable");
    }
    const canceltaxtable = () => {
        stateshowtax("TaxPayableT");
    }
    const [showtax, stateshowtax] = useState("TaxPayableT");

    return (

        <>
            <div className="row  ">
                <div className="dash_background col-12 p-0">
                    <div className="row ">

                        <div className="col-md-12">
                            {showtax === "TaxPayableT" ? <TaxReceivableTable showtaxtable={showtaxtable} canceltaxtable={canceltaxtable} settaxList={settaxList} settaxList2={settaxList2} /> : <TaxReceivableForm canceltaxtable={canceltaxtable} statetaxList2={statetaxList2} statetaxList={statetaxList} />}

                        </div>


                    </div>


                </div>
            </div>
        </>

    )
}
export default TaxReceivableMain;