import React, { useState, useEffect } from "react";
import soldpic from "../../../src/assets/sold.png";
import salePic from "../../../src/assets/forsale.png";
import Reserved from "../../../src/assets/house.png";
import brokerPic from "../../../src/assets/contract.png";
import axios from "axios";
import { getallProject } from "../actions/getprojects";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { Chart } from "react-google-charts";
import Loader from "react-loader-spinner";
import SoldModal from "./soldModal";
import AvailableModal from "./availableModal";
import PieChart from "../Accounts/DashboardPage/pieChart";
import BarChart from "./barChart";
const InventoryDashboard = () => {
  const resultsproject = useSelector((state) => state.getProject);

  let dispatch = useDispatch();
  const [stateSectionList, setSectionList] = useState([]);
  const [stateprojectid, setprojectid] = useState(0);
  const [newplotcount, setnewplotcount] = useState([]);
  const [sectionwiseunits, setsectionwiseunits] = useState([]);
  const [statesectionunits, setsectionunits] = useState([]);
  const [bargraphdata, setbargraphdata] = useState([]);
  const openmodalinvsold = (x) => {
    document.getElementById("modalofinvsold").style.display = "block";
  };
  const openmodalinvavailable = (x) => {
    document.getElementById("modalofinvavailable").style.display = "block";
  };

  const getunitscount = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}Reports/Inventory/PiChart?ProjectId=${stateprojectid}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(
      (result) => {
        setnewplotcount(result.data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };

  // const datapie = {
  //   maintainAspectRatio: false,
  //   responsive: false,
  //   labels: ["Sold", "Available", "Reserved"],
  //   datasets: [
  //     {
  //       data: [
  //         newplotcount !== undefined ? newplotcount.Sold : 0,
  //         newplotcount !== undefined ? newplotcount.Avaiable : 0,
  //         newplotcount !== undefined ? newplotcount.Reserved : 0,
  //         newplotcount !== undefined ? newplotcount.DealReserved : 0,
  //       ],
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(75,192,192,0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(75,192,192,1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(54, 162, 235, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   maintainAspectRatio: false,
  // };
  // // stacked bar graph
  // const data = {
  //   labels:
  //     sectionwiseunits !== undefined
  //       ? sectionwiseunits?.map((val) => val.Section)
  //       : null,
  //   previousDate: {
  //     label: "Available",
  //     dataSet: sectionwiseunits?.map((val) => val.Available),
  //   },
  //   currentDate: {
  //     label: "Reserved",
  //     dataSet: sectionwiseunits?.map((val) => val.Reserved),
  //   },
  //   nextDate: {
  //     label: "Sold",
  //     dataSet: sectionwiseunits?.map((val) => val.Sold),
  //   },
  // };
  const pieChartData = [
    newplotcount?.Sold || 0,
    newplotcount?.Avaiable || 0,
    newplotcount?.Reserved || 0,
    newplotcount?.DealReserved || 0,
  ];
  const sectionwisenoofunits = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}Reports/Inventory/SectionWiseNoOfUnits?ProjectId=${stateprojectid}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(
      (result) => {
        setsectionwiseunits(result.data.Data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };
  const sectionunits = (id) => {
    axios({
      method: "get",
      // url: `${process.env.REACT_APP_API_URL}Reports/Inventory/SectionWiseUnits?SectionId=${document.getElementById("sectionname").options[document.getElementById("sectionname").selectedIndex].dataset.id !== undefined ? document.getElementById("sectionname").options[document.getElementById("sectionname").selectedIndex].dataset.id : 0}`,
      url: `${process.env.REACT_APP_API_URL
        }Reports/Inventory/SectionWiseUnits?SectionId=${id === undefined ? 0 : id
        }`,

      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(
      (result) => {
        setsectionunits(result.data.Data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };

  useEffect(() => {
    getunitscount();
    sectionwisenoofunits();
    sectionunits();
  }, [stateprojectid, stateSectionList]);
  useEffect(() => {
    dispatch(getallProject());
  }, [dispatch]);
  useEffect(() => {
    stackedbargraph();
  }, []);
  const stackedbargraph = () => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Reports/Inventory/AllProjectsSummary`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        let array = [
          ["Project", "Available", "Sold", "Reserved", "DealReserved"],
        ];
        result?.data?.Summary?.map((x) => {
          let newarray = Object.values([
            x.ProjectName,
            x.Available,
            x.Sold,
            x.Reserved,
            x.DealReserved,
          ]);
          array.push(newarray);
        });

        setbargraphdata(array);

        // let array2 = [["Departments", "Available", "Sold", "Reserved", "DealReserved"],];
        // result.data.map((x) => {
        //   let newarray2 = Object.values({
        //     Available: x.Available,
        //     Sold: x.Sold,
        //     Reserved: x.Reserved,
        //     DealReserved: x.DealReserved,
        //   })
        //   array2.push(newarray2)
        // })

        //
        // setpiechartdata(array2)

        // let check = 0;
        // result.data.forEach((x) => {
        //   if (x.present !== 0) {
        //     check = 1
        //   }
        // })

        // if (check === 1) {
        //   setpiedataavailable(true)
        // }
      },
      (error) => { }
    );
  };
  return (
    <>
      <div className="row mr-2">
        <div className="col-12 p-0">
          <div className="row">
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div
                  className=""
                  onClick={openmodalinvavailable}
                  style={{ cursor: "pointer" }}
                >
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {" "}
                    {newplotcount !== undefined ? newplotcount.Avaiable : 0}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(75,192,192,1)" }}
                  >
                    Available
                  </div>
                  <div className="text-center pb-2">
                    <img src={salePic} alt="Loan" style={{ width: "30px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-3">
              <div
                className="dashboardboxsmall "
                onClick={openmodalinvsold}
                style={{ cursor: "pointer" }}
              >
                <div className="">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {newplotcount !== undefined ? newplotcount.Sold : 0}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(255, 99, 132, 1)" }}
                  >
                    {" "}
                    Sold
                  </div>
                  <div className="text-center  pb-2">
                    <img src={soldpic} alt="Loan" style={{ width: "28px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div className="">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {newplotcount !== undefined ? newplotcount.Reserved : 0}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(255, 206, 86, 1)" }}
                  >
                    Reserved
                  </div>
                  <div className="text-center  pb-2">
                    <img src={Reserved} alt="Loan" style={{ width: "28px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-3">
              <div className="dashboardboxsmall ">
                <div className="">
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    {newplotcount !== undefined ? newplotcount.DealReserved : 0}
                  </div>
                  <div
                    className="text-center"
                    style={{ fontSize: "17px", color: "rgba(54, 162, 235, 1)" }}
                  >
                    Deals
                  </div>
                  <div className="text-center  pb-2">
                    <img src={brokerPic} alt="Loan" style={{ width: "25px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <div className="row ">
                  <div className="col-md-12 mt-1  text-center p-0">
                    <h4
                      className="input_label m-0 "
                      style={{ fontSize: "20px", color: "gray" }}
                    >
                      Units Summary
                    </h4>
                  </div>
                  <div className="col-md-4 mt-1 pl-2 text-left p-0">
                    <label className="input_label m-0 ">Project Name:</label>
                  </div>
                  <div className="col-md-6 mt-1 p-0">
                    <select
                      id="projectname"
                      className="input_styling  ml-md-3"
                      onChange={(e) => {
                        if (e.target.value !== "Select") {
                          setSectionList(
                            resultsproject?.data[
                            document.getElementById("projectname").options[
                              document.getElementById("projectname")
                                .selectedIndex
                            ].dataset.idx
                            ]
                          );
                          setprojectid(
                            document.getElementById("projectname").options[
                              document.getElementById("projectname")
                                .selectedIndex
                            ].dataset.id
                          );
                          document.getElementById(
                            "sectionname"
                          ).selectedIndex = 0;
                        }
                      }}
                    >
                      <option>Select</option>
                      {resultsproject?.data?.map((x, index) => (
                        <option
                          data-id={x.ProjectId}
                          data-idx={index}
                          key={index}
                        >
                          {x.ProjectName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2 mt-1 p-0 text-right">
                    <div style={{ paddingRight: "10px" }}>
                      {resultsproject?.loading ? (
                        <span>
                          <Loader
                            type="ThreeDots"
                            color="green"
                            height={40}
                            width={40}
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <Doughnut data={datapie} options={options} /> */}
                <PieChart
                  data={pieChartData}
                  labels={["Sold", "Available", "Reserved", "Deal Reserved"]}
                  colors={[
                    "rgba(255, 99, 132, 1)",
                    "rgba(75,192,192,1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(54, 162, 235, 1)",
                  ]}
                  height={200}
                />
              </div>
            </div>
            <div className=" col-md-6">
              <div className="dashboardtoprow pl-2">
                <div className="row ">
                  <div className="col-md-12 mt-1  text-center p-0">
                    <h4
                      className="input_label m-0 "
                      style={{ fontSize: "20px", color: "gray" }}
                    >
                      Section Units
                    </h4>
                  </div>
                  <div className="col-md-2 mt-1  text-left p-0">
                    <label className="input_label m-0 ">Section:</label>
                  </div>
                  <div className="col-md-6 mt-1  p-0">
                    <select
                      id="sectionname"
                      className="input_styling  ml-md-3"
                      onChange={() => {
                        sectionunits(
                          document.getElementById("sectionname").options[
                            document.getElementById("sectionname").selectedIndex
                          ].dataset.id
                        );
                      }}
                    >
                      <option>Select</option>
                      {stateSectionList !== undefined &&
                        stateSectionList.Sections !== undefined
                        ? stateSectionList.Sections.map((x, index) => (
                          <option
                            data-id={x.SectionId}
                            data-idx={index}
                            key={index}
                          >
                            {x.SectionName}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="customcard mt-2">
                  {statesectionunits !== undefined
                    ? statesectionunits.map((x) => (
                      <div className="customcardcontainer">
                        <h6>{x.Unit.UnitName}</h6>

                        <span>{x.Unit.Status}</span>
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </div>

            <div className=" col-12">
              <div className="dashboardtoprow ">
                {/* <Bar
                  pointStyle="star"
                  data={{
                    labels: data.labels,
                    responsive: true,
                    offset: true,
                    datasets: [
                      {
                        label: "Available",
                        pointStyle: "rectRounded",
                        backgroundColor: "rgba(75,192,192,0.2)",
                        barThickness: 40,
                        categoryPercentage: 1,
                        data: data.previousDate.dataSet //From API
                      },
                      {
                        label: "Reserved",
                        backgroundColor: "rgba(255, 206, 86, 0.2)",
                        barThickness: 40,
                        categoryPercentage: 1,
                        pointStyle: "triangle",
                        data: data.currentDate.dataSet //From API
                      }, {
                        label: "Sold",
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        barThickness: 40,
                        categoryPercentage: 1,
                        pointStyle: "triangle",
                        data: data.nextDate.dataSet //From API
                      }
                    ]
                  }}
                  height={220}
                  options={{
                    offsetGridLines: true,
                    drawTicks: true,
                    layout: {
                      padding: {
                        top: 30,
                        right: 40,
                        bottom: 40
                      }
                    },
                    legend: {
                      display: true,
                      position: "right",
                      align: "start",
                      labels: {
                        usePointStyle: true
                      }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            padding: 5
                          },
                          gridLines: {
                            display: false
                          }
                        }
                      ],
                      yAxes: [
                        {
                          stacked: false,
                          gridLines: {
                            drawBorder: false
                          },
                          ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 6,
                            padding: 20,
                            callback(n) {
                              if (n < 1e3) return n;
                              if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
                            }
                          }
                        }
                      ]
                    }
                  }}
                /> */}
                {/* <div className="row">
                  <div className="col-12"> */}
                {/* <Chart
                  width={"70vw"}
                  height={"32vh"}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={bargraphdata}
                  options={{
                    chartArea: { width: "50%" },

                    isStacked: true,
                    hAxis: {
                      title: "Units",
                      minValue: 0,
                    },
                    vAxis: {
                      title: "Project",
                    },
                    backgroundColor: "#FFFFFF",
                  }}
                  // For tests
                  rootProps={{ "data-testid": "3" }}
                /> */}
                {/* </div>
                </div> */}
                <div className="col-md-12 mt-1  text-center p-0">
                  <h4
                    className="input_label m-0 "
                    style={{ fontSize: "20px", color: "gray" }}
                  >
                    Projects Summary
                  </h4>
                </div>
                <BarChart data={bargraphdata} height={270} />
              </div>
            </div>
            {/* <div className=" col-md-6">
                            <div className="dashboardtoprow ">

                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <div id="modalofinvsold" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Sold</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofinvsold").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12  mt-3">
              <SoldModal data={newplotcount.SoldList} />
            </div>
          </div>
        </div>
      </div>
      <div id="modalofinvavailable" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Available</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofinvavailable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12  mt-3">
              <AvailableModal data={newplotcount.AvaiableList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryDashboard;
