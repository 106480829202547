import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import ListImage from "../../ModalImages/ListImage";

function AddAccountModal() {
  return (
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <span class="modal-btn" data-toggle="modal" data-target="#myModal2">
          <BiInfoCircle />
        </span>
      </div>

      <div
        class="modal right fade my-modal"
        id="myModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div class="modal-dialog modal-lg " style={{}} role="document">
          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel2">
                Account Info!
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <ul className="modal-ul">
                <li>
                  <ListImage />
                  Users can add an account.
                </li>
                <li>
                  <ListImage />
                  Users can update an account until no transaction hits against
                  that account.
                </li>
                <li>
                  <ListImage />
                  Account balance with 0 can be deleted.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAccountModal;
