import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import AltertModal from "./component/AlertModal/AltertModal";
import { AuthProvider } from "./context/useAuth.js";
import { AdminRoutes } from "./component/AllRoutes/AllRoutes.jsx";
import RequireAuth from "./component/RequireAuth/RequireAuth.jsx";
import Layout from "./Layout/Layout.js";
import {
  ChangeRequest,
  Roles,
  Users,
  accountsPathsArr,
  assetsArray,
  crmArray,
  hrArray,
  inventoryArray,
  revertVouchers,
} from "./ListOfPaths/ListOfPaths.jsx";
import { SubbtnProvider } from "./context/subBtn.js";
import { RolesProvider } from "./context/roles.js";
// import { NewPurchasePrint } from "./component/Inventory/Projects/ProjectsPrint/NewPurchasePrint";

function App() {
  // const { changeState } = useContext(monthlyAlert);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history]);

  // useEffect(() => {
  //   axios
  //     .get("https://sheetdb.io/api/v1/dskt971mukoor")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "FALSE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  // sessionStorage.setItem("isPaid", false);
  // changeState(true);
  //       } else {
  //         sessionStorage.setItem("isPaid", true);

  //         changeState(false);
  //       }
  //     })
  //     .catch((err) => {
  //
  //     });
  // }, []);

  return (
    <BrowserRouter>
      <SubbtnProvider>
        <RolesProvider>
          <Switch>
            <AuthProvider>
              {AdminRoutes?.map((route, idx) =>
                [
                  "/",
                  "/reset",
                  "/forgot",
                  "/verify",
                  "/projectSelection",
                ].includes(route.path) ? (
                  <Route key={idx} exact path={route.path}>
                    {route.component}
                  </Route>
                ) : (
                  <Route key={idx} exact path={route.path}>
                    <RequireAuth>
                      <Layout
                        arr={
                          route.path.includes("account")
                            ? accountsPathsArr
                            : route.path.includes("inventory")
                              ? inventoryArray
                              : route.path.includes("hr")
                                ? hrArray
                                : route.path.includes("crm")
                                  ? crmArray
                                  : route.path.includes("asset")
                                    ? assetsArray
                                    : route.path.includes("Roles")
                                      ? Roles
                                      : route.path.includes("Users")
                                        ? Users
                                        : route.path.includes("change-request")
                                          ? ChangeRequest
                                          : route.path.includes("revert-back-vouchers")
                                            ? revertVouchers
                                            : []
                        }
                      >
                        {route.component}
                      </Layout>
                    </RequireAuth>
                  </Route>

                  // <Layout> {route.component}</Layout>
                )
              )}
            </AuthProvider>
            {/* <Route exact path="/" component={Login} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/forgot" component={Forget} />
        <Route exact path="/reset" component={ResetPass} />
        <Route exact path="/account" component={AccountMainPage} />
        <Route exact path="/hr" component={HrMainPage} />
        <Route exact path="/assets" component={AssetsMainPage} />
        <Route exact path="/inventory" component={InventoryMainPage} />
        <Route exact path="/crm" component={CrmMainPage} />
        <Route exact path="/projectSelection" component={ProjectSelection} /> */}
            {/* <Route exact path="/test" component={NewPurchasePrint} /> */}
            {/* <Route exact path="/" component={UnderMaintenancePage} /> */}
            <ToastContainer autoClose={2000} />
            <AltertModal />
          </Switch>
        </RolesProvider>
      </SubbtnProvider>
    </BrowserRouter>
  );
}

export default App;
