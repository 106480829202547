import Layout from "../../Layout/Layout";
import Account from "../Accounts/ChartofAccount/AddAccount/AddAccount";
import ChartofAccount from "../Accounts/ChartofAccount/ChartofAcc/ChartofAccount";
import AccDashboard from "../Accounts/DashboardPage/accdashboard";
import AccountPayable from "../Accounts/Reports/AccountPayable/AccountPayable";
import AccountReceivable from "../Accounts/Reports/AccountReceviable/AccountReceivable";
import AdminSharePayableReport from "../Accounts/Reports/AdminSharePayable/AdminSharePayableReport";
import BalanceSheet from "../Accounts/Reports/BalanceSheet/BalanceSheet";
import CashBook from "../Accounts/Reports/CashBook/CashBook";
import CommissionPayment from "../Accounts/Reports/CommissionPayment/CommissionPayment";
import CommissionReceipt from "../Accounts/Reports/CommissionReceipt/CommissionReceipt";
import GeneralJournalReport from "../Accounts/Reports/GeneralJournal/GeneralJournal";
import GeneralLedger from "../Accounts/Reports/GeneralLedger/GeneralLedger";
import IncomeStatement from "../Accounts/Reports/IncomeStatement/IncomeStatement";
import InstallmentPayment from "../Accounts/Reports/InstallPayment/InstallPayment";
import InstallmentReceipt from "../Accounts/Reports/InstallReceipt/InstallReceipt";
import ManagerRecovery from "../Accounts/Reports/ManagerRecovery/ManagerRecovery";
import ProjectReport from "../Accounts/Reports/ProjectReport/ProjectReport";
import { RecoveryReceiptReport } from "../Accounts/Reports/RecoveryReceipt/RecoveryReceipt";
import ReserveCommissionPayableReport from "../Accounts/Reports/ReserveCommissionPayable/ReserveCommissionPayableReport";
import TrailBalance from "../Accounts/Reports/TrailBalance/TrailBalance";
import TaxReceivableMain from "../Accounts/Tax/TaxReceivable/TaxReceivableMain";
import AllNotification from "../Accounts/Vouchers/AllNotification/AllNotification";
import SubmittedVouchers from "../Accounts/Vouchers/SubmittedVouchers/SubmittedVouchers";
import UnSubmittedVouchers from "../Accounts/Vouchers/UnSumbittedVouhcers/UnSubmittedVouchers";
import AssestsDashboard from "../Assets/dashboard";
import { AddClient, ClientTableComp } from "../CRM/Clientpages/addclient";
import CrmDashboard from "../CRM/DashboardPages/crmdashboard";
import { AddLeadTable, LeadAddPage } from "../CRM/LeadPages/leadaddpage";
import AvailabilityComp from "../CRM/Reports/Availability";
import { AddCall, AddCallTable } from "../CRM/TaskPages/addcall";
import { AddMeeting, AddMeetingTable } from "../CRM/TaskPages/addmeeting";
import { AddTask, AddtaskTable } from "../CRM/TaskPages/addtask";
import { AddMember, MemberTable } from "../CRM/Teams/addmembers";
import { AddTeam, TeamTable } from "../CRM/Teams/addteams";
import MainHierarchyComp from "../CRM/Teams/hierarchy";
import MainAttendanceCom from "../HR/attendence";
import HRDashboard from "../HR/dashboard";
import MainAddEmployeeComp from "../HR/empolyees/addEmployee";
import MainSalaryCom from "../HR/empolyees/assignsalary";
import MainDesignationCom from "../HR/empolyees/designation";
import MainPaySlipCom from "../HR/payroll/payslip";
import { NewPurchaseTable } from "../Inventory/Projects/NewPurchase/NewPurchaseTable";
import { RePurchase } from "../Inventory/Projects/RePurchase/RePurchase";
import { RePurchaseTable } from "../Inventory/Projects/RePurchase/RePurchaseTable";
import { Unit } from "../Inventory/Projects/Units/Unit";
import { UnitTable } from "../Inventory/Projects/Units/UnitTable";
import InventoryDashboard from "../Inventory/dashboard";
import AvailabilityComp2 from "../Inventory/Availability/Availability";
import MainAffiliates from "../Inventory/deals/Affiliates/MainAffiliates";
import DealSoldMain from "../Inventory/deals/DealSold/MainDealSold";
import Forget from "../forgetPass";
import Login from "../login";
import ProjectSelection from "../projectSelection";
import ResetPass from "../resetPass";
import Verify from "../verification";
// import MainAddSellerComp from "../Inventory/landcreditor/addseller";
import { GeneralReceiptTable } from "../Accounts/ReceiptEntry/GeneralReceipt/GeneralReceiptTable";
import { UnitReceiptTable } from "../Accounts/ReceiptEntry/UnitReceipt/UnitReceiptTable";
import { DealSoldRecTable } from "../Accounts/ReceiptEntry/DealSoldRec/DealSoldRecTable";
import { RecoveryReceiptTable } from "../Accounts/ReceiptEntry/RecoveryReceipt/RecoveryReceiptTable";
import { InvestReceiptTable } from "../Accounts/ReceiptEntry/InvestReceipt/InvestReceiptTable";
import { CommissionReceiptTable } from "../Accounts/ReceiptEntry/CommissionReceipt/CommissionReceiptTable";
import Changeform from "../Accounts/changeform/changeform";
import { Users } from "../Users/Users/Users";
import { ProjectTable } from "../Inventory/Projects/Project/ProjectTable";
import { ProjectForm } from "../Inventory/Projects/Project/ProjectForm";
import { SectionForm } from "../Inventory/Projects/Section/SectionForm";
import { SectionTable } from "../Inventory/Projects/Section/SectionTable";
import { NewPurchaseForm } from "../Inventory/Projects/NewPurchase/NewPurchase";
import { DealBoughtTable } from "../Inventory/deals/DealBought/DealBoughtTable";
import { DealBought } from "../Inventory/deals/DealBought/DealBought";
import { GeneralPaymentTable } from "../Accounts/PaymentEntry/GeneralPayment/GeneralPaymentTable";
import { CommissionPaymentTable } from "../Accounts/PaymentEntry/CommissionPayment/CommissionPaymentTable";
import { DealPaymentTable } from "../Accounts/PaymentEntry/DealPayment/DealPaymentTable";
import { InvestPaymentTable } from "../Accounts/PaymentEntry/InvestPayment/InvestPaymentTable";
import { SalaryPaymentTable } from "../Accounts/PaymentEntry/SalaryPayment/SalaryPaymentTable";
import { LandPaymentTable } from "../Accounts/PaymentEntry/LandPayment/LandPaymentTable";
import { GeneralReceiptForm } from "../Accounts/ReceiptEntry/GeneralReceipt/GeneralReceiptForm";
import { UnitReceiptForm } from "../Accounts/ReceiptEntry/UnitReceipt/UnitReceiptForm";
import { RecoveryReceiptForm } from "../Accounts/ReceiptEntry/RecoveryReceipt/RecoveryReceiptForm";
import { InvestReceiptForm } from "../Accounts/ReceiptEntry/InvestReceipt/InvestReceiptForm";
import { CommissionReceiptForm } from "../Accounts/ReceiptEntry/CommissionReceipt/CommissionReceiptForm";
import GeneralPaymentForm from "../Accounts/PaymentEntry/GeneralPayment/GeneralPaymentForm";
import { LandPaymentForm } from "../Accounts/PaymentEntry/LandPayment/LandPaymentForm";
import { SalaryPaymentForm } from "../Accounts/PaymentEntry/SalaryPayment/SalaryPaymentForm";
import { InvestPaymentForm } from "../Accounts/PaymentEntry/InvestPayment/InvestPaymentForm";
import { DealPaymentForm } from "../Accounts/PaymentEntry/DealPayment/DealPaymentForm";
import { CommissionPaymentForm } from "../Accounts/PaymentEntry/CommissionPayment/CommissionPaymentForm";
import { DealSoldRecForm } from "../Accounts/ReceiptEntry/DealSoldRec/DealSoldRecForm";
import { RoleAddTable } from "../Accounts/roles/roles";
import { LandTable } from "../Inventory/LandAndSeller/Land/LandTable";
import { LandForm } from "../Inventory/LandAndSeller/Land/LandForm";
import { LandSellerForm } from "../Inventory/LandAndSeller/LandSeller/LandSellerForm";
import { LandSellerTable } from "../Inventory/LandAndSeller/LandSeller/LandSellerTable";
import { SellerForm } from "../Inventory/LandAndSeller/Seller/SellerForm";
import { SellerTable } from "../Inventory/LandAndSeller/Seller/SellerTable";
import { ReceiptInstallmentsTable } from "../Accounts/Vouchers/Installments/ReceiptInstallment/ReceiptInstallmentsTable";
import { PaymentInstallmentsTable } from "../Accounts/Vouchers/Installments/PaymentInstallment/PaymentInstallemnetTable";
import { ReceiptInstallmentsForm } from "../Accounts/Vouchers/Installments/ReceiptInstallment/ReceiptInstallmentsForm";
import { PaymentInstallmentForm } from "../Accounts/Vouchers/Installments/PaymentInstallment/PaymentInstallmentForm";
import ExternalCommissionTable from "../Accounts/Vouchers/ExternalCommission/ExternalCommissionTable";
import ExternalCommissionForm from "../Accounts/Vouchers/ExternalCommission/ExternalCommissionForm";
import { GeneralAdjustmentForm } from "../Accounts/Vouchers/GeneralAdjustment/GeneralAdjustmentForm";
import { JournalEntryForm } from "../Accounts/Vouchers/JournalEntry/JournalEntryForm";
import { RecoveryBookingForm } from "../Accounts/Vouchers/RecoveryBooking/RecoveryBookingForm";
import { RecoveryBookingTable } from "../Accounts/Vouchers/RecoveryBooking/RecoveryBookingTable";
import { JournalEntryTable } from "../Accounts/Vouchers/JournalEntry/JournalEntryTable";
import { GeneralAdjTable } from "../Accounts/Vouchers/GeneralAdjustment/GeneralAdjustmentTable";
import { ContractTable } from "../Accounts/Contracts/Contract/ContractTable";
import { CustomerTable } from "../Accounts/Contracts/Customer/CustomerTable";
import { InstallResceduleTable } from "../Accounts/Contracts/InstallmentRescedule/InstallResceduleTable";
import { TransferContractTable } from "../Accounts/Contracts/TransferContract/TransferContractTable";
import { TaxTable } from "../Accounts/Tax/Tax/TaxTable";
import { TaxPayableTable } from "../Accounts/Tax/TaxPayable/TaxPayableTable";
import { InvestorTable } from "../Accounts/Investments/Investor/InvestorTable";
import { ContractForm } from "../Accounts/Contracts/Contract/ContractForm";
import AddCustomerForm from "../Accounts/Contracts/Customer/CustomerForm";
import { TransferContractPage } from "../Accounts/Contracts/TransferContract/TransferContractForm";
import { TaxForm } from "../Accounts/Tax/Tax/TaxForm";
import { TaxPaybleForm } from "../Accounts/Tax/TaxPayable/TaxPayableForm";
import { TaxReceivableForm } from "../Accounts/Tax/TaxReceivable/TaxReceivableForm";
import { PayAdvance, PayAdvanceTable } from "../HR/payroll/addadvance";
import { PayLoan, PayLoanTable } from "../HR/payroll/addloan";
import { RoleAddForm } from "../Accounts/roles/rolesForm";
import { InstallResceduleform } from "../Accounts/Contracts/InstallmentRescedule/InstallResceduleForm";
import { AddInvestor } from "../Accounts/Investments/Investor/AddInvestor";
import { InvestmentTable } from "../Accounts/Investments/Investment/InvestmentTable";
import { Investment } from "../Accounts/Investments/Investment/Investment";
import UserForm from "../Users/Users/UserForm";
// import MainAddSellerComp from "../Inventory/landcreditor/addseller";
import { AssestsAdd, AssetsTable } from "../Assets/assetspages/assets";
import {
  SellAssestsAdd,
  SellAssetsTable,
} from "../Assets/assetspages/sellassets";
import { AddLender, LenderTableComp } from "../Assets/assetspages/assetbuyer";
import DepriciationComp from "../Assets/assetspages/depreciation";
import {
  MaintenenceAssestsAdd,
  MaintenenceAssetsTable,
} from "../Assets/assetspages/maintainence";
import BookingUnits from "../Inventory/Availability/BookedUnits";
import { RefundPaymentTable } from "../Accounts/Vouchers/RefundPayment/RefundPaymentTable";
import RefundPaymentForm from "../Accounts/Vouchers/RefundPayment/RefundPaymentForm";
import LandInstallTracker from "../Accounts/Reports/LandInstallmentTracker/LandInstallTracker";
import RevertBackVouchers from "../Accounts/Vouchers/RevertBackVouchers/RevertBackVouchers";
import { AffiliateForm } from "../Inventory/deals/Affiliates/AffiliateForm";
import { ContractForm2 } from "../Accounts/Contracts/Contract/ContractForm2";
import { TransferUnitTable } from "../Accounts/Contracts/TransferUnit/TransferUnitTable";
import { TransferUnitPage } from "../Accounts/Contracts/TransferUnit/TransferUnitForm";

export const AdminRoutes = [
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/verify",
    component: <Verify />,
  },
  {
    path: "/forgot",
    component: <Forget />,
  },
  {
    path: "/reset",
    component: <ResetPass />,
  },
  {
    path: "/account",
    component: <Layout />,
  },

  {
    path: "/account/dashboard",
    // component:  <AccDashboard /> ,
    component: <AccDashboard />,
  },

  {
    path: "/account/vouchers/receipt-entry",
    component: <GeneralReceiptTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/general-receipt",
    component: <GeneralReceiptTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/general-receipt/add",
    component: <GeneralReceiptForm />,
  },
  {
    path: "/account/vouchers/receipt-entry/unit-receipt",
    component: <UnitReceiptTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/unit-receipt/add",
    component: <UnitReceiptForm />,
  },
  {
    path: "/account/vouchers/receipt-entry/deal-sold-rec",
    component: <DealSoldRecTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/deal-sold-rec/add",
    component: <DealSoldRecForm />,
  },
  {
    path: "/account/vouchers/receipt-entry/recovery-receipt",
    component: <RecoveryReceiptTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/recovery-receipt/add",
    component: <RecoveryReceiptForm />,
  },
  {
    path: "/account/vouchers/receipt-entry/invest-receipt",
    component: <InvestReceiptTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/invest-receipt/add",
    component: <InvestReceiptForm />,
  },
  {
    path: "/account/vouchers/receipt-entry/com-receipt",
    component: <CommissionReceiptTable />,
  },
  {
    path: "/account/vouchers/receipt-entry/com-receipt/add",
    component: <CommissionReceiptForm />,
  },
  {
    path: "/account/vouchers/payment-entry",
    component: <GeneralPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/general-payment",
    component: <GeneralPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/general-payment/add",
    component: <GeneralPaymentForm />,
  },
  {
    path: "/account/vouchers/payment-entry/land-payment",
    component: <LandPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/land-payment/add",
    component: <LandPaymentForm />,
  },
  {
    path: "/account/vouchers/payment-entry/salary-payment",
    component: <SalaryPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/salary-payment/add",
    component: <SalaryPaymentForm />,
  },
  {
    path: "/account/vouchers/payment-entry/invest-payment",
    component: <InvestPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/invest-payment/add",
    component: <InvestPaymentForm />,
  },
  {
    path: "/account/vouchers/payment-entry/deal-payment",
    component: <DealPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/deal-payment/add",
    component: <DealPaymentForm />,
  },
  {
    path: "/account/vouchers/payment-entry/com-payment",
    component: <CommissionPaymentTable />,
  },
  {
    path: "/account/vouchers/payment-entry/com-payment/add",
    component: <CommissionPaymentForm />,
  },
  {
    path: "/account/vouchers/refund-voucher",
    component: <RefundPaymentTable />,
  },
  {
    path: "/account/vouchers/refund-voucher/add",
    component: <RefundPaymentForm />,
  },
  {
    path: "/account/vouchers/recovery-booking",
    component: <RecoveryBookingTable />,
  },
  {
    path: "/account/vouchers/recovery-booking/add",
    component: <RecoveryBookingForm />,
  },
  {
    path: "/account/vouchers/external-commission",
    component: <ExternalCommissionTable CommissionType="INCOME" />,
  },
  {
    path: "/account/vouchers/external-commission/income",
    component: <ExternalCommissionTable CommissionType="INCOME" />,
  },
  {
    path: "/account/vouchers/external-commission/income/add",
    component: <ExternalCommissionForm CommissionType="INCOME" />,
  },
  {
    path: "/account/vouchers/external-commission/expense/add",
    component: <ExternalCommissionForm CommissionType="EXPENSE" />,
  },
  {
    path: "/account/vouchers/external-commission/expense",
    component: <ExternalCommissionTable CommissionType="EXPENSE" />,
  },
  {
    path: "/account/vouchers/general-adjustment",
    component: <GeneralAdjTable />,
  },
  {
    path: "/account/vouchers/general-adjustment/add",
    component: <GeneralAdjustmentForm />,
  },

  {
    path: "/account/vouchers/journal-entry",
    component: <JournalEntryTable />,
  },
  {
    path: "/account/vouchers/journal-entry/add",
    component: <JournalEntryForm />,
  },
  {
    path: "/account/vouchers/installments",
    component: <ReceiptInstallmentsTable />,
  },

  {
    path: "/account/vouchers/installments/receipt-installments",
    component: <ReceiptInstallmentsTable />,
  },
  {
    path: "/account/vouchers/installments/receipt-installments/add",
    component: <ReceiptInstallmentsForm />,
  },
  {
    path: "/account/vouchers/installments/payment-installments",
    component: <PaymentInstallmentsTable />,
  },
  {
    path: "/account/vouchers/installments/payment-installments/add",
    component: <PaymentInstallmentForm />,
  },
  {
    path: "/account/vouchers/unsubmitted-vouchers",
    component: <UnSubmittedVouchers />,
  },
  {
    path: "/account/vouchers/submitted-vouchers",
    component: <SubmittedVouchers />,
  },
  {
    path: "/account/vouchers/notifications",
    component: <AllNotification />,
  },
  {
    path: "/account/contract/contracts/with-unit",
    component: <ContractTable />,
  },
  {
    path: "/account/contract/contracts/without-unit",
    component: <ContractTable />,
  },
  {
    path: "/account/contract/contracts/add-with-unit",
    component: <ContractForm />,
  },
  {
    path: "/account/contract/contracts/add-without-unit",
    component: <ContractForm2 />,
  },
  {
    path: "/account/contract/customers",
    component: <CustomerTable />,
  },
  {
    path: "/account/contract/customers/add",
    component: <AddCustomerForm />,
  },
  {
    path: "/account/contract/install-reschedule",
    component: <InstallResceduleTable />,
  },
  {
    path: "/account/contract/install-reschedule/add",
    component: <InstallResceduleform />,
  },
  {
    path: "/account/contract/transfer-contract",
    component: <TransferContractTable />,
  },
  {
    path: "/account/contract/transfer-contract/add",
    component: <TransferContractPage />,
  },
  {
    path: "/account/contract/transfer-unit",
    component: <TransferUnitTable />,
  },
  {
    path: "/account/contract/transfer-unit/add",
    component: <TransferUnitPage />,
  },
  {
    path: "/accounts/chart-of-acc/Chart",
    component: <ChartofAccount />,
  },
  {
    path: "/accounts/chart-of-acc/account",
    component: <Account />,
  },
  {
    path: "/account/tax/Tax",
    component: <TaxTable />,
  },
  {
    path: "/account/tax/Tax/add",
    component: <TaxForm />,
  },
  {
    path: "/account/tax/tax-payable",
    component: <TaxPayableTable />,
  },
  {
    path: "/account/tax/tax-payable/pay",
    component: <TaxPaybleForm />,
  },
  {
    path: "/account/tax/tax-receivable",
    component: <TaxReceivableMain />,
  },
  {
    path: "/account/tax/tax-receivable/pay",
    component: <TaxReceivableForm />,
  },
  {
    path: "/account/investment/Investment",
    component: <InvestmentTable />,
  },
  {
    path: "/account/investment/Investment/add",
    component: <Investment />,
  },
  {
    path: "/account/investment/Investor",
    component: <InvestorTable />,
  },
  {
    path: "/account/investment/Investor/add",
    component: <AddInvestor />,
  },

  {
    path: "/account/reports/trail-balance",
    component: <TrailBalance />,
  },
  {
    path: "/account/reports/general-journal",
    component: <GeneralJournalReport />,
  },
  {
    path: "/account/reports/general-ledger",
    component: <GeneralLedger />,
  },
  {
    path: "/account/reports/land-installment-tracker",
    component: <LandInstallTracker />,
  },
  {
    path: "/account/reports/balance-sheet",
    component: <BalanceSheet />,
  },
  {
    path: "/account/reports/income-statement",
    component: <IncomeStatement />,
  },
  {
    path: "/account/reports/cash-book",
    component: <CashBook />,
  },
  {
    path: "/account/reports/manager-recovery",
    component: <ManagerRecovery />,
  },
  {
    path: "/account/reports/recovery-receipt",
    component: <RecoveryReceiptReport />,
  },
  {
    path: "/account/reports/account-payable",
    component: <AccountPayable />,
  },
  {
    path: "/account/reports/account-receivable",
    component: <AccountReceivable />,
  },
  {
    path: "/account/reports/install-receipt",
    component: <InstallmentReceipt />,
  },
  {
    path: "/account/reports/install-payment",
    component: <InstallmentPayment />,
  },
  {
    path: "/account/reports/commission-payment",
    component: <CommissionPayment />,
  },
  {
    path: "/account/reports/commission-receipt",
    component: <CommissionReceipt />,
  },
  {
    path: "/account/reports/admin-share-payable",
    component: <AdminSharePayableReport />,
  },
  {
    path: "/account/reports/reserved-commission-payable",
    component: <ReserveCommissionPayableReport />,
  },
  {
    path: "/account/reports/project-report",
    component: <ProjectReport />,
  },

  // Inventory Routes
  {
    path: "/inventory/dashboard",
    component: <InventoryDashboard />,
  },
  {
    path: "/inventory/projects/Projects",
    component: <ProjectTable />,
  },
  {
    path: "/inventory/projects/Projects/add",
    component: <ProjectForm />,
  },
  {
    path: "/inventory/projects/section",
    component: <SectionTable />,
  },
  {
    path: "/inventory/projects/section/add",
    component: <SectionForm />,
  },
  {
    path: "/inventory/projects/units",
    component: <UnitTable />,
  },
  {
    path: "/inventory/projects/units/add",
    component: <Unit />,
  },
  {
    path: "/inventory/projects/newpurchase",
    component: <NewPurchaseTable />,
  },
  {
    path: "/inventory/projects/newpurchase/add",
    component: <NewPurchaseForm />,
  },
  {
    path: "/inventory/projects/repurchase",
    component: <RePurchaseTable />,
  },
  {
    path: "/inventory/projects/repurchase/add",
    component: <RePurchase />,
  },

  {
    path: "/inventory/deals/dealbought",
    component: <DealBoughtTable />,
  },
  {
    path: "/inventory/deals/dealbought/add",
    component: <DealBought />,
  },
  // {
  //   path: "/inventory/deals/dealsold",
  //   component:  <DealSoldMain /> ,
  // },
  // {
  //   path: "/inventory/deals/dealsold/add",
  //   component:  <DealSoldMain type={"DealSoldComp"}/> ,
  // },
  // {
  //   path: "/inventory/deals/dealsold/cancel",
  //   component: (
  //     <Layout
  //       arr={inventoryArray}
  //       activePage={<DealSoldMain type={"DealSoldComp" }
  //     />
  //   ),
  // },
  {
    path: "/inventory/deals/dealsold",
    component: <DealSoldMain type={"DealSoldTableComp"} />,
  },
  {
    path: "/inventory/deals/dealsold/add",
    component: <DealSoldMain type={"DealSoldComp"} />,
  },
  {
    path: "/inventory/deals/dealsold/cancel",
    component: <DealSoldMain type={"DealSoldCancel"} />,
  },

  {
    path: "/inventory/deals/affliates",
    component: <MainAffiliates type={"AffliateTable"} />,
  },
  // {
  //   path: "/inventory/deals/affliates/add",
  //   component: <MainAffiliates type={"AffliateForm"} />,
  // },
  {
    path: "/inventory/deals/affliates/add",
    component: <AffiliateForm />,
  },
  {
    path: "/inventory/availability/availability",
    component: <AvailabilityComp2 />,
  },
  {
    path: "/inventory/availability/units",
    component: <BookingUnits />,
  },

  {
    path: "/inventory/land-and-seller/land",
    component: <LandTable />,
  },
  {
    path: "/inventory/land-and-seller/land/add",
    component: <LandForm />,
  },
  {
    path: "/inventory/land-and-seller/sellerland",
    component: <LandSellerTable />,
  },
  {
    path: "/inventory/land-and-seller/sellerland/add",
    component: <LandSellerForm />,
  },
  {
    path: "/inventory/land-and-seller/sellers",
    component: <SellerTable />,
  },
  {
    path: "/inventory/land-and-seller/sellers/add",
    component: <SellerForm />,
  },

  //Inventory End

  //HumanResource

  {
    path: "/hr/dashboard",
    component: <HRDashboard />,
  },
  {
    path: "/hr/attendence",
    component: <MainAttendanceCom />,
  },

  {
    path: "/hr/payroll/assignsalary",
    component: <MainSalaryCom type={"SalaryTable"} />,
  },
  {
    path: "/hr/payroll/assignsalary/add",
    component: <MainSalaryCom type={"SalaryAdd"} />,
  },
  // {
  //   path: "/hr/payroll/advanceloan",
  //   component: <MainAdvanceLoanComp />,
  // },
  {
    path: "/hr/payroll/advanceloan/advance-table",
    component: <PayAdvanceTable />,
  },
  {
    path: "/hr/payroll/advanceloan/advance-table/add",
    component: <PayAdvance />,
  },
  {
    path: "/hr/payroll/advanceloan/loan-table",
    component: <PayLoanTable />,
  },
  {
    path: "/hr/payroll/advanceloan/loan-table/add",
    component: <PayLoan />,
  },
  // {
  //   path: "/hr/payroll/payloan",
  //   component: <MainAdvanceLoanComp type={'payadd'} />,
  // },

  {
    path: "/hr/payroll/payslip",
    component: <MainPaySlipCom type={"PaySlipTable"} />,
  },
  {
    path: "/hr/payroll/payslip/generate-slip",
    component: <MainPaySlipCom type={"GenerateSlip"} />,
  },
  {
    path: "/hr/payroll/payslip/Edit",
    component: <MainPaySlipCom type={"EditPaySlip"} />,
  },
  {
    path: "/hr/employees/employee",
    component: <MainAddEmployeeComp type={"EmployeeTable"} />,
  },
  {
    path: "/hr/employees/employee/add",
    component: <MainAddEmployeeComp type={"AddEmployee"} />,
  },

  {
    path: "/hr/employees/designation",
    component: <MainDesignationCom type={"DesignationTable"} />,
  },
  {
    path: "/hr/employees/designation/add",
    component: <MainDesignationCom type={"Designation"} />,
  },

  //HumanResource End
  //CRM
  {
    path: "/crm/dashboard",
    component: <CrmDashboard />,
  },
  {
    path: "/crm/leads",
    component: <AddLeadTable />,
  },
  {
    path: "/crm/leads/add",
    component: <LeadAddPage />,
  },

  {
    path: "/crm/client/clients",
    component: <ClientTableComp />,
  },
  {
    path: "/crm/client/clients/add",
    component: <AddClient />,
  },

  {
    path: "/crm/tasks/meeting",
    component: <AddMeetingTable />,
  },
  {
    path: "/crm/tasks/meeting/add",
    component: <AddMeeting />,
  },
  {
    path: "/crm/tasks/call",
    component: <AddCallTable />,
  },
  {
    path: "/crm/tasks/call/add",
    component: <AddCall />,
  },
  {
    path: "/crm/tasks/othertask",
    component: <AddtaskTable />,
  },
  {
    path: "/crm/tasks/othertask/add",
    component: <AddTask />,
  },

  {
    path: "/crm/teams/teams",
    component: <TeamTable />,
  },
  {
    path: "/crm/teams/teams/add",
    component: <AddTeam />,
  },
  {
    path: "/crm/teams/member",
    component: <MemberTable />,
  },
  {
    path: "/crm/teams/member/add",
    component: <AddMember />,
  },
  {
    path: "/crm/teams/hierarchy",
    component: <MainHierarchyComp />,
  },
  {
    path: "/crm/availability/availability",
    component: <AvailabilityComp />,
  },
  {
    path: "/crm/availability/units",
    component: <BookingUnits />,
  },
  //CRM End

  //Assets
  {
    path: "/asset/dashboard",
    component: <AssestsDashboard />,
  },
  {
    path: "/assets/asset",
    component: <AssetsTable />,
  },
  {
    path: "/assets/asset/add",
    component: <AssestsAdd />,
  },

  {
    path: "/assets/sale",
    component: <SellAssetsTable />,
  },
  {
    path: "/assets/sale/add",
    component: <SellAssestsAdd />,
  },
  {
    path: "/assets/buyer",
    component: <LenderTableComp />,
  },
  {
    path: "/assets/buyer/add",
    component: <AddLender />,
  },
  {
    path: "/assets/seller",
    component: <SellerTable />,
  },
  {
    path: "/asset/maintainance",
    component: <MaintenenceAssetsTable />,
  },
  {
    path: "/asset/maintainance/add",
    component: <MaintenenceAssestsAdd />,
  },
  {
    path: "/asset/depriciation",
    component: <DepriciationComp />,
  },
  //Assets End
  {
    path: "/Users",
    component: <Users />,
  },
  {
    path: "/Users/add",
    component: <UserForm />,
  },
  {
    path: "/revert-back-vouchers",
    component: <RevertBackVouchers />,
  },
  // {
  //   path: "/change-request",
  //   component: <ChangeTabel />,
  // },
  {
    path: "/change-request",
    component: <Changeform />,
  },
  {
    path: "/Roles",
    component: <RoleAddTable />,
  },
  // {
  //   path: "/Roles/add",
  //   component: <RoleAdd />,
  // },
  {
    path: "/Roles/addform",
    component: <RoleAddForm />,
  },

  // {
  //   path: "/assets",
  //   component: <AssetsMainPage />,
  // },

  // {
  //   path: "/crm",
  //   component: <CrmMainPage />,
  // },
  {
    path: "/projectSelection",
    component: <ProjectSelection />,
  },
  // {
  //   path: "/inventory",
  //   component: <InventoryMainPage />,
  // },
  // {
  //   path: "/hr",
  //   component: <HrMainPage />,
  // },
];
