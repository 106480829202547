import React, { useState, useEffect } from "react";
import axios from "axios";
import depriciationPic from "../../assets/depriciation.svg";
import maintainPic from "../../assets/maintenence.svg";
import assestsPic from "../../assets/assets.svg";
import { toast } from "react-toastify";
import PieChart from "../Accounts/DashboardPage/pieChart";

const AssestsDashboard = () => {
  const [resultsalldashbuttons, setresultsalldashbuttons] = useState({});
  const [resultsallbalance, setresultsallbalance] = useState({});
  const [resultsallcounter, setresultsallcounter] = useState([]);

  function getdashboardbuttons() {
    axios({
      url: `${process.env.REACT_APP_API_URL}Dashboard/Buttons`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setresultsalldashbuttons(result.data);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  function getdashboardDonut() {
    axios({
      url: `${process.env.REACT_APP_API_URL}Dashboard/ByStatus`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setresultsallbalance(result.data);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  function getdashboardCounter() {
    axios({
      url: `${process.env.REACT_APP_API_URL}Dashboard/CategoryBaseCount`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setresultsallcounter(result.data.CategoryBasedAssets);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  // const datapie = {
  //   maintainAspectRatio: false,
  //   responsive: false,
  //   labels: ["Available", "Sold ", "Maintenance "],
  //   datasets: [
  //     {
  //       data: [
  //         resultsallbalance.Available,
  //         resultsallbalance.Sold,
  //         resultsallbalance.UnderMaintenance,
  //       ],
  //       backgroundColor: [
  //         "rgba(75,192,192,0.2)",
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(75,192,192,1)",
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(54, 162, 235, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   maintainAspectRatio: false,
  // };
  const pieChartData = [
    resultsallbalance?.Available || 0,
    resultsallbalance?.Sold || 0,
    resultsallbalance?.UnderMaintenance || 0,
  ];
  useEffect(() => {
    getdashboardbuttons();
    getdashboardDonut();
    getdashboardCounter();
  }, []);
  return (
    <>
      <div className="row ">
        <div className=" col-md-3">
          <div className="dashboardboxsmall ">
            <div className="cur_sor">
              <div className="text-center" style={{ fontSize: "20px" }}>
                {" "}
                {""}
                {Math.round(
                  resultsalldashbuttons.TotalAssetsValue
                ).toLocaleString()}{" "}
              </div>
              <div
                className="text-center"
                style={{ fontSize: "17px", color: "rgba(75,192,192,1)" }}
              >
                Assets Value
              </div>
              <div className="text-center  pb-2">
                <img src={assestsPic} alt="Loan" style={{ width: "15px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-3">
          <div className="dashboardboxsmall ">
            <div className="cur_sor">
              <div className="text-center" style={{ fontSize: "20px" }}>
                {" "}
                {""}
                {Math.round(
                  resultsalldashbuttons.SoldAssetsValue
                ).toLocaleString()}{" "}
              </div>
              <div
                className="text-center"
                style={{ fontSize: "17px", color: "rgba(255, 99, 132, 1)" }}
              >
                Sold Assets
              </div>
              <div className="text-center  pb-2">
                <img src={assestsPic} alt="Loan" style={{ width: "15px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-3">
          <div className="dashboardboxsmall ">
            <div className="cur_sor">
              <div className="text-center" style={{ fontSize: "20px" }}>
                {" "}
                {""}
                {Math.round(
                  resultsalldashbuttons.DepreciatedValue
                ).toLocaleString()}{" "}
              </div>
              <div
                className="text-center"
                style={{ fontSize: "17px", color: "rgba(255, 206, 86, 1)" }}
              >
                Depreciated Value
              </div>
              <div className="text-center  pb-2">
                <img
                  src={depriciationPic}
                  alt="Loan"
                  style={{ width: "15px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-3">
          <div className="dashboardboxsmall ">
            <div className="cur_sor">
              <div className="text-center" style={{ fontSize: "20px" }}>
                {" "}
                {""}
                {Math.round(
                  resultsalldashbuttons.TotalMaintenanceCostPaid
                ).toLocaleString()}{" "}
              </div>
              <div
                className="text-center"
                style={{ fontSize: "17px", color: "rgba(54, 162, 235, 1)" }}
              >
                MaintenanceCost
              </div>
              <div className="text-center  pb-2">
                <img src={maintainPic} alt="Loan" style={{ width: "15px" }} />
              </div>
            </div>
          </div>
        </div>

        <div className=" col-6">
          <div className="dashboardtoprow " style={{ height: "50vh" }}>
            <div className="col-md-12 mt-1  text-center p-0">
              <h4
                className="input_label m-0 "
                style={{ fontSize: "20px", color: "gray" }}
              >
                Balance{" "}
              </h4>
            </div>
            {/* <Doughnut data={datapie} options={options} /> */}
            <PieChart
              data={pieChartData}
              labels={["Available", "Sold ", "Maintenance "]}
              colors={[
                "rgba(75,192,192,1)",
                "rgba(255, 99, 132, 1)",
                "rgba(255, 206, 86, 1)",
              ]}
              height={420}
            />
          </div>
        </div>
        <div className=" col-6">
          <div className="dashboardtoprow " style={{ height: "50vh" }}>
            <div className="row ">
              <div className="col-md-12 mt-1  text-center p-0">
                <h4
                  className="input_label m-0 "
                  style={{ fontSize: "20px", color: "gray" }}
                >
                  Category Based Assets
                </h4>
              </div>
              <div className="table-responsive mt-1">
                <table className="table table-borderless table-hover m-0">
                  <thead>
                    <tr>
                      <th style={{ fontSize: ".9em" }}>Sr</th>

                      <th style={{ fontSize: ".9em" }}>Name</th>
                      <th style={{ fontSize: ".9em" }}>Quantity</th>
                      <th style={{ fontSize: ".9em" }}>Value</th>
                      <th style={{ fontSize: ".9em" }}>Depreciable</th>
                      <th style={{ fontSize: ".9em" }}>Non Depreciable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultsallcounter !== undefined
                      ? resultsallcounter.map((val, index) => (
                        <tr key={index}>
                          <td style={{ fontSize: ".8em" }}>{index + 1}</td>

                          <td style={{ fontSize: ".8em" }}>
                            {val.CategoryName}
                          </td>
                          <td style={{ fontSize: ".8em" }}>
                            {val.NumberOfAssets}
                          </td>
                          <td style={{ fontSize: ".8em" }}>
                            {val.CurrentValue !== undefined &&
                              val.CurrentValue !== null
                              ? val.CurrentValue.toLocaleString()
                              : null}
                          </td>

                          <td style={{ fontSize: ".8em" }}>
                            {val.Depreciable}
                          </td>
                          <td style={{ fontSize: ".8em" }}>
                            {val.Nondepreciable}
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssestsDashboard;
