import { useEffect } from "react";
import { useRef } from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetAllTaxTable,
  getallTaxTable,
  GetTaxData,
} from "../../../actions/accountsactions/addtax";
import { TaxPrint } from "../TaxPrints/TaxPrint";
import { CSVLink } from "react-csv";
import { useState } from "react";
import TaxTableModal from "./TaxTableModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const TaxTable = (props) => {
  const resultalltaxes = useSelector((state) => state.getAllTaxTable);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const history = useHistory();

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getallTaxTable());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultalltaxes.data
          .sort()
          .filter((val) => regex.test(val.TaxName));
      }
      suggestionlist = suggestions;
      dispatch(GetAllTaxTable(suggestionlist));
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallTaxTable());
  }, [dispatch]);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];

    resultalltaxes.data?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        Name: val.TaxName,
        Percentage: val.TaxPer,
      });
    });
    setCsvData(arr);
  }, [resultalltaxes.data]);

  return (
    <>
      <div hidden>
        <TaxPrint
          ref={componentRef}
          printData={resultalltaxes}
        />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label  m-0">Search:</label>
        </div>
        <div className="col-md-3   pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Tax Name"
            onChange={onTextChange}
          ></input>
        </div>
        <TaxTableModal />

        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultalltaxes.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive vouchers_table_height2 mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>

                  <th>Percentage</th>

                  <th className="text-center">
                    {rolesdata?.data?.Access?.includes("C") ? (
                      <button
                        className="save_btn"
                        // onClick={() => {
                        //   props.showtaxtable();

                        // }}
                        onClick={() => history.push("/account/tax/Tax/add")}
                      >
                        New+
                      </button>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultalltaxes.data.map((val, index) => (
                  <tr
                    key={index}
                    // onClick={() => {
                    //   dispatch(GetTaxData(val));
                    //   if (rolesdata?.data?.Access?.includes("U")) {
                    //     // props.showtaxtable();
                    //     history.push("/account/tax/Tax/add", val);
                    //   }
                    // }}
                  >
                    <td className="table_data">{index + 1}</td>
                    <td className="table_data">{val.TaxName}</td>

                    <td className="table_data">{val.TaxPer}</td>

                    <td
                      className="text-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(GetTaxData(val));
                        if (rolesdata?.data?.Access?.includes("U")) {
                          // props.showtaxtable();
                          history.push("/account/tax/Tax/add", val);
                        }
                      }}
                    >
                      {rolesdata?.data?.Access?.includes("U") ? (
                        <FiEdit2 size="1em" />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Tax_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>
    </>
  );
};
