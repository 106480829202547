import React from 'react';
import background from "../assets/background.jpg";
import { Link } from 'react-router-dom';

const divStyle = {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
};
const ResetPass = () => {
    return (
        <>
            <div style={divStyle}  >
                <div className="loginbox ">
                    <div className="row">
                        <div className="col-12 logintext">Reset Password</div>
                        <div className="col-12 mt-5">
                            <input type="text" Placeholder="New Password" className="logininput" id="oldpass" />
                        </div>


                        <div className="col-12 mt-4">
                            {""}
                            <input type="password" Placeholder="Confirm New Password" className="logininput" id="newpass" />
                        </div>

                        <div className="col-12 mt-3">
                            <Link to="/"> <button

                                className=" text-center mt-4 p-2 logIn "
                            >
                      RESET
                    </button>
                            </Link>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}
export default ResetPass;