import React from "react";
import ReactDOM from "react-dom/client"; // Import createRoot from react-dom/client
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { createStore, applyMiddleware, compose } from "redux";
import AllReducers from "./component/reducers/allreducers";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import MonthlyAlertContextProvider from "./component/ContextApi/MonthlyAlert";
import { BrowserRouter } from "react-router-dom";

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(AllReducers, enhancer);


const root = ReactDOM.createRoot(document.getElementById("root")); // Create a root

root.render( // Use root.render instead of ReactDOM.render
  <React.StrictMode>
    <Provider store={store}>
      <MonthlyAlertContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MonthlyAlertContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();